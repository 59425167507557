import '../../css/futures.css'
import '../../css/modal.css'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { changeNum, findSmallest } from "../function"
import { ClipLoader } from "react-spinners";

function Position({ positionList, openorderList, FuturesPositionClose, FuturesAllOpenOrderCancel, FuturesOpenOrderCancel, openorder_cancel, delay, symbol, exchange }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    const positionCancel = (list, z) => {
        if (exchange == "binance") {
            FuturesPositionClose(list, z, findSmallest(Number(list.minSize)))
        } else if (exchange == "okx") {
            FuturesPositionClose(list, z)
        } else if (exchange == "bybit") {
            FuturesPositionClose(list, z, findSmallest(Number(list.minSize)))
        }
    }

    return (
        <div className={`futures-section15-1 ${darkname}`}>
            <div className='futures-section15-1-1'>
                <div className='futures-section15-1-1-1'>Position</div>
                <div className='futures-section15-1-1-3'>
                    {changeNum(Math.abs(positionList?.value ?? 0).toFixed(4, '0'))}
                    <div className='futures-section15-1-1-2'>{symbol}</div>
                </div>

                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Side</div>
                    <div className='futures-section15-1-1-5'>{
                        positionList.side
                            ?
                            positionList.side == "Buy"
                                ?
                                <div className={`${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`${darkname}`}>-</div>
                    }</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Entry Price</div>
                    <div className='futures-section15-1-1-5'>{positionList?.entryPrice ?? "-"}</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Amount</div>
                    <div className='futures-section15-1-1-5'>{positionList?.sz ? changeNum(Math.abs(positionList?.sz)) : '-'}</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Mark Price</div>
                    <div className='futures-section15-1-1-5'>{positionList?.mark ?? "-"}</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Margin Mode</div>
                    <div className='futures-section15-1-1-5'>{positionList?.mode ?? '-'}</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>Liq. Price</div>
                    <div className='futures-section15-1-1-5'>{positionList?.liq ?? "-"}</div>
                </div>
                <div className='futures-section15-1-1-list'>
                    <div className='futures-section15-1-1-4'>UNPNL(ROI)</div>
                    <div className='futures-section15-1-1-5'>{
                        positionList?.pnl
                            ?
                            Number(positionList?.pnl) >= 0 ?
                                <div className={`${darkname} green`}>
                                    {changeNum(Number((positionList?.pnl)).toFixed(3))}
                                    ({(Number(positionList?.pnl) * 100 / ((Number(positionList?.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`${darkname} red`}>
                                    {changeNum(Number((positionList?.pnl)).toFixed(3))}
                                    ({(Number(positionList?.pnl) * 100 / ((Number(positionList?.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                            :
                            "-"
                    }
                    </div>
                </div>
                <div className='futures-section15-1-1-btn'>
                    <div className={`futures-section15-1-1-btn-1 ${positionList?.sz ? "" : "disable"} ${darkname}`} onClick={() => { positionCancel(positionList, 0) }}>50%</div>
                    <div className={`futures-section15-1-1-btn-1 ${positionList?.sz ? "" : "disable"} ${darkname}`} onClick={() => { positionCancel(positionList, 1) }}>All</div>
                </div>
            </div>
            <div className='futures-section15-1-2'>
                <div className='futures-section15-1-1-1'>Open Order
                    {
                        delay
                            ?
                            <div className={`futures-section15-open-1-2 ${darkname} ${openorderList.length == 0 ? "disable" : ""}`} onClick={() => FuturesAllOpenOrderCancel()}>All Cancel</div>
                            :
                            <div className={`futures-section15-open-1-2 ${darkname}`}><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                {
                    openorderList.map(function (a, i) {
                        if (a.symbol == symbol) {
                            return (
                                <OpenOrderItem item={a} delay={delay} openorder_cancel={openorder_cancel} darkname={darkname} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                            )
                        }
                    })
                }
            </div>
            <div className='futuresM-mid-1-btn'>
                <div className={`futures-section4-top-5-3-1 ${darkname}`}>
                    <div className={`futures-section4-top-5-3-1-1`}>{t('futures_17')}</div>
                </div>
                <div className={`futures-section4-top-5-3-2 ${darkname}`}>
                    <div className={`futures-section4-top-5-3-1-2`}>{t('futures_18')}</div>
                </div>
            </div>
        </div>
    )
}

function OpenOrderItem({ item, delay, openorder_cancel, darkname, FuturesOpenOrderCancel }) {
    return (
        <div className={`futures-section15-open ${darkname}`}>
            <div className='futures-section15-open-1'>
                <div className='futures-section15-open-1-1'>
                    <div className='futures-section15-open-1-1-1'>{item?.symbol ?? '-'}</div>
                    <div className={`futures-section15-open-1-1-2 ${item?.side == "Buy" ? "bg-green green" : "bg-red red"} ${darkname}`}>{item?.side ?? '-'}</div>
                </div>
                {
                    openorder_cancel && delay
                        ?
                        <div className={`futures-section15-open-1-2 ${darkname}`} onClick={() => FuturesOpenOrderCancel(item)}>Cancel</div>
                        :
                        <div className={`futures-section15-open-1-2 ${darkname}`} style={{ cursor: "pointer" }}>
                            <ClipLoader color="#595959" size='14px' />
                        </div>
                }
            </div>
            <div className='futures-section15-open-2'>
                <div className='futures-section15-open-2-1'>
                    <div className='futures-section15-open-2-1-1'>Filled/Quantity</div>
                    <div className='futures-section15-open-2-1-2'>{changeNum(0)}/{changeNum(item?.sz ?? 0)}</div>
                </div>
                <div className='futures-section15-open-2-1'>
                    <div className='futures-section15-open-2-1-1'>Price</div>
                    <div className='futures-section15-open-2-1-2'>{item?.px ?? '0'}</div>
                </div>
                <div className='futures-section15-open-2-1'>
                    <div className='futures-section15-open-2-1-1'>Type</div>
                    <div className='futures-section15-open-2-1-2'>{item?.type ?? '-'}</div>
                </div>
            </div>
        </div>
    )
}


function OrderHistory({ setSelectedDate, selectedDate, setSelectedDate1, selectedDate1, order_symbol, spinner }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const darkname = dark ? 'dark' : "";

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src={darkname == "dark" ? `/img/ico_calendar_dark.svg` : `/img/ico_calendar.svg`} className='history-right-bottom-2-2-data-img'></img>
                    {/* <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img' style={{ display: "none" }}></img> */}
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
            </div>
            <div className='menu-2-scroll'>
                <div className={`menu-2-5 ${darkname}`}>
                    <div className={`menu-2-4-1 table-sticky ${darkname}`}>Time</div>
                    <div className='menu-2-4-1'>Symbol</div>
                    <div className='menu-2-4-1'>Type</div>
                    <div className='menu-2-4-1'>Side</div>
                    <div className='menu-2-4-1'>Filled/USDT</div>
                    <div className='menu-2-4-1'>Price</div>
                    <div className='menu-2-4-1'>Status</div>
                </div>
                <div>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            order_symbol && order_symbol.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>{t('message_0')}</p>
                                </div>
                                :
                                <>
                                    {
                                        order_symbol.map(function (a, i) {
                                            return (
                                                <OrderDetailItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function OrderDetailItem({ item, darkname }) {
    return (
        <div className={`menu-2-5-item ${darkname}`}>
            <div className={`menu-2-4-1 table-sticky ${darkname}`}>{item.time}</div>
            <div className='menu-2-4-1'>{item.ccy}USDT</div>
            <div className='menu-2-4-1'>{item.type}</div>
            <div className='menu-2-4-1'>{item.side}</div>
            <div className='menu-2-4-1'>{item.size}/{(Number(item.size) * Number(item.price)).toFixed(4)}</div>
            <div className='menu-2-4-1'>{item.price}</div>
            <div className='menu-2-4-1'>{item.status}</div>
        </div>
    )
}

function TradeHistory({ setSelectedDate, selectedDate, setSelectedDate1, selectedDate1, order_symbol, spinner }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const darkname = dark ? 'dark' : "";

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src={darkname == "dark" ? `/img/ico_calendar_dark.svg` : `/img/ico_calendar.svg`} className='history-right-bottom-2-2-data-img'></img>
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
            </div>
            <div className='menu-2-scroll'>
                <div className={`menu-2-5-1 ${darkname}`}>
                    <div className={`menu-2-4-1 table-sticky ${darkname}`}>Time</div>
                    <div className='menu-2-4-1'>Symbol</div>
                    <div className='menu-2-4-1'>Volume</div>
                    <div className='menu-2-4-1'>Side</div>
                    <div className='menu-2-4-1'>Price</div>
                    <div className='menu-2-4-1'>Amount/USDT</div>
                    <div className='menu-2-4-1'>PNL</div>
                    <div className='menu-2-4-1'>Fee</div>
                    <div className='menu-2-4-1'>Reward</div>
                    <div className='menu-2-4-1'>Total</div>
                </div>
                <div>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            order_symbol && order_symbol.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>{t('message_0')}</p>
                                </div>
                                :
                                <>
                                    {
                                        order_symbol.map(function (a, i) {
                                            return (
                                                <TradeDetailItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function TradeDetailItem({ item, darkname }) {

    return (
        <div className={`menu-2-5-item-1 ${darkname}`}>
            <div className={`menu-2-4-1 table-sticky-1 ${darkname}`}>{item.time}</div>
            <div className='menu-2-4-1'>{item.ccy}USDT</div>
            <div className='menu-2-4-1'>{changeNum(item.volume.toFixed(4))}</div>
            <div className='menu-2-4-1'>{item.side}</div>
            <div className='menu-2-4-1'>{changeNum(item.price)}</div>
            <div className='menu-2-4-1'>{changeNum(item.size)}/{changeNum((Number(item.size) * Number(item.price)).toFixed(4))}</div>
            <div className={`menu-2-4-1 ${Number(item.pnl) >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(item.pnl).toFixed(4))}</div>
            <div className='menu-2-4-1'>{changeNum(Math.abs(item.fee).toFixed(4))}</div>
            <div className='menu-2-4-1 green'>{changeNum((item.reward).toFixed(4))}</div>
            <div className='menu-2-4-1'>{changeNum((item.pnl - item.fee + item.reward).toFixed(4))}</div>
        </div>
    )
}

export { Position, OrderHistory, TradeHistory }