import '../css/spot.css'
import Chart from '../spotchart.js'
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectType, checkLogin, check_Error } from '../../store.js';
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary.js"
import isEqual from 'lodash/isEqual';
import { changeNum, changeTime, makeSamllest, findSmallest } from "../common/function.js"

import { W_goex6 } from '../common/C_spot/W_goex6.js';
import '@fortawesome/fontawesome-free/css/all.css';

import GoexOKXS1 from './goexOKXS/goexOKXS1.js';
import GoexOKXS4 from './goexOKXS/goexOKXS4.js';
import GoexOKXS7 from './goexOKXS/goexOKXS7.js';
import GoexOKXS8 from './goexOKXS/goexOKXS8.js';
import GoexOKXS1_1 from './goexOKXS/goexOKXS1_1.js';
import GoexOKXS8_1 from './goexOKXS/goexOKXS8_1.js';
import GoexOKXS8_2 from './goexOKXS/goexOKXS8_2.js';

let data = []
let data_first = 0

let prePrice = 0
let balance_data = []

function GOEXOKXSpot() {
    const { t } = useTranslation();
    let OKXS = useSelector((state) => { return state.OKX_coinS });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let OKX_wallet = useSelector((state) => { return state.OKX_wallet });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)

    const [openorder, setOpenorder] = useState([])
    const [balance, setBalance] = useState([])
    const [currentUSDT, setCurrentUSDT] = useState("")

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab5, setTab5] = useState(0)
    const [tradeVolume, setTradeVolume] = useState("10000")
    const [btnbtn, setBtnbtn] = useState(0);
    const [orderReload, setOrderReload] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(0.000001)
    const [max, setMax] = useState(0)
    const [instrumentsData, setInstrumentData] = useState([])
    const [orderbookReload, setOrderbookReload] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
        data_first = 0
    }, [symbol])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let USDTSymbolS = OKXS ? OKXS : [];
    dispatch(checkSelectType(0))

    USDTSymbolS.map(function (a, i) {
        if (a.symbol === symbol) {
            if (data_first === 0) {
                setCurrentPrice(a.lastPrice)
                setMax(a.point)
                data_first = 1
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    const SpotOpenOrder = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/spot/open-orders", {
            headers: { Authorization: `Bearer ${token}` }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setOpenorder(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 110, "msg": error }))
        })
    }

    const SpotInstruments = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/market/okx/instruments/spot", {
            params: {
                instId: symbol
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                setInstrumentData(tmp[0])
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 112, "msg": error }))
        })
    }

    useEffect(() => {
        SpotInstruments()
    }, [symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            SpotOpenOrder(token);
        }
    }, [balance])

    useEffect(() => {
        if (OKX_wallet) {
            let okx_wallet_data = []
            for (let i = 0; i < OKX_wallet.length; i++) {
                if (OKX_wallet[i].ccy != "USDT") {
                    okx_wallet_data.push({ "ccy": OKX_wallet[i].ccy, "bal": OKX_wallet[i].availBal })
                }
            }

            let ArraysEqual = isEqual(balance_data, okx_wallet_data);

            if (!ArraysEqual) {
                setBalance(OKX_wallet)
                balance_data = okx_wallet_data
            }
        }
    }, [OKX_wallet])

    useEffect(() => {
        if (OKX_wallet_usdt && OKX_wallet_usdt[0] && OKX_wallet_usdt[0].availBal) {
            setCurrentUSDT(OKX_wallet_usdt[0].availBal)
        }
    }, [OKX_wallet_usdt])

    // *********************************************************************************************************************
    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)
    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "/USDT" : ""} | OKX`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "/USDT" : ""} | OKX`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    let balanceD = balance.filter((item) => item.coin + "USDT" == symbol)

    return (
        <div className={`futures ${darkname} font-family`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            {
                windowtypeM
                    ?
                    <div className='futures-box'>
                        <div className='futures-section11'>
                            <W_goex6 isBlinking={isBlinking} handleButtonClick={handleButtonClick} changeTab={changeTab} tab={tab} data={data} currentPrice={currentPrice} priceDirect={priceDirect} max={max} />
                            <div className='futures-section14'>
                                <ErrorBoundary>
                                    <GoexOKXS1 tab={tab} changeTab={changeTab} USDTSymbolS={USDTSymbolS} symbol={symbol} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div className={tab1 ? `futures-section16 ${darkname}` : `futures-section16-check ${darkname}`}>
                                        <div style={{ width: "100%", height: "880px", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                            <Chart exchange={"okx"} market={"spot"} />
                                            <div className={`img-bg ${darkname}`}><img src={dark ? '/img/okx_logo_dark.png' : '/img/okx_logo.png'} style={{ maxWidth: "250px", width: "50%" }} /></div>
                                        </div>
                                    </div>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <GoexOKXS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                            </div>
                            <div className={`futures-section15 ${darkname}`}>
                                <ErrorBoundary>
                                    <GoexOKXS8 tab5={tab5} setTab5={setTab5} symbol={symbol} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} currentPrice={currentPrice} orderReload={orderReload} openorder={openorder} balance={balance} />
                                </ErrorBoundary>
                            </div>
                        </div>
                        <ErrorBoundary>
                            <GoexOKXS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} instrumentsData={instrumentsData} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn} />
                        </ErrorBoundary>
                    </div>
                    :
                    <div className={`futuresM ${darkname}`}>
                        <div className='futuresM-list' onClick={() => setMtab(2)}>
                            <div className={`futuresM-list-1 ${darkname}`}>{symbol}</div>
                            <div className={`futuresM-list-2 ${data?.priceChangePercent >= 0 ? "green" : "red"}`}>
                                <div className='futuresM-list-2-1'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                <div className='futuresM-list-2-2'>{Number(data?.priceChangePercent ?? 0).toFixed(2)}%</div>
                            </div>
                        </div>
                        <div className={`futuresM-top ${darkname}`}>
                            <div className='futuresM-top-box'>
                                <div className={Mtab == 0 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(0)}>차트</div>
                                <div className={Mtab == 1 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(1)}>호가</div>
                                <div className={Mtab == 2 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(2)}>리스트</div>
                                <div className={Mtab == 3 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(3)}>보유</div>
                                <div className={Mtab == 4 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(4)}>내역</div>
                            </div>
                        </div>

                        <div className='futuresM-mid-1' style={{ display: Mtab == 0 ? "" : "none" }}>
                            <div style={{ width: "100%", height: "calc(100% - 60px)", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                <Chart exchange={"okx"} market={"spot"} />
                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/okx_logo_dark.png' : '/img/okx_logo.png'} style={{ maxWidth: "210px", width: "50%" }} /></div>
                            </div>
                            <div className='futuresM-mid-1-btn'>
                                <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-1`}>{t('futures_17')}</div>
                                </div>
                                <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-2`}>{t('futures_18')}</div>
                                </div>
                            </div>
                        </div>

                        <div className='futuresM-mid-2' style={{ display: Mtab == 1 ? "" : "none" }}>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <GoexOKXS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                                {
                                    Mordertab
                                        ?
                                        <ErrorBoundary>
                                            <GoexOKXS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} instrumentsData={instrumentsData} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn} />
                                        </ErrorBoundary>
                                        :
                                        ""
                                }
                            </div>
                            <div className='futures-M-mid-2-btn' onClick={() => setMordertab(e => !e)}>
                                {
                                    Mordertab
                                        ?
                                        <i class="fa-solid fa-circle-check" style={{ color: "#4285F4", marginRight: "7px" }}></i>
                                        :
                                        <i class="fa-regular fa-circle-check" style={{ color: "gray", marginRight: "7px" }}></i>
                                }
                                <span className="text">주문창</span>
                            </div>
                        </div>
                        {
                            Mtab === 2
                                ?
                                <GoexOKXS1_1 USDTSymbolS={USDTSymbolS} symbol={symbol} balance={balance} />
                                :
                                ""
                        }

                        <div style={{ display: Mtab == 3 ? "" : "none", width: "100%" }}>
                            <GoexOKXS8_1 symbol={symbol} balance={balanceD[0] ?? []} openorder={openorder} />
                        </div>

                        <div style={{ display: Mtab == 4 ? "" : "none", width: "100%" }}>
                            <GoexOKXS8_2 symbol={symbol} orderReload={orderReload} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default GOEXOKXSpot;