import { generateSymbol, makeApiRequest, parseFullSymbol, priceScale, makeBybitRequest } from "./helpers";
import SocketClient, { OKX_RESOLUSION } from "./streaming";

const configurationData: TradingView.DatafeedConfiguration = {
  supported_resolutions: ["1", "3", "5", "15", "30", "1H", "2H", "4H", "6H", "12H", "1D", "1W", "1M"] as TradingView.ResolutionString[],
  exchanges: [{ value: "Bybit", name: "Bybit", desc: "Bybit" }],
  symbols_types: [{ name: "crypto", value: "crypto" }],
};

export interface DataFeedOptions {
  SymbolInfo?: TradingView.LibrarySymbolInfo;
  DatafeedConfiguration?: TradingView.DatafeedConfiguration;
  getBars?: TradingView.IDatafeedChartApi["getBars"];
}

export default class DataFeed implements TradingView.IExternalDatafeed, TradingView.IDatafeedChartApi {
  private options: DataFeedOptions;
  private lastBarsCache: Map<string, TradingView.Bar>;
  private socket!: SocketClient;

  constructor(options: DataFeedOptions) {
    this.options = options;
    this.lastBarsCache = new Map();
    if (!options) {
      this.options.DatafeedConfiguration = configurationData;
    }
  }
  public async onReady(callback: TradingView.OnReadyCallback) {
    // console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
    this.socket = new SocketClient();
  }

  public async searchSymbols(userInput: string, exchange: string, symbolType: string, onResultReadyCallback: TradingView.SearchSymbolsCallback) {
    // console.log('[searchSymbols]: Method call');
    const symbols = await this.getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  }

  private async getAllSymbols() {
    const data = await makeBybitRequest("/v5/market/instruments-info?category=spot");
    let allSymbols: any[] = [];

    for (const exchange of configurationData.exchanges!) {
      const pairs = data.result.list;

      for (const pair of pairs) {
        const symbolInfo = generateSymbol("Bybit", pair.baseCoin, pair.quoteCoin);
        const symbol = {
          symbol: symbolInfo.short,
          full_name: symbolInfo.full,
          description: symbolInfo.short,
          exchange: exchange.value,
          type: "crypto",
          tickSize: pair.priceFilter.tickSize,
        };
        allSymbols = [...allSymbols, symbol];
      }
    }

    return allSymbols;
  }

  public async resolveSymbol(symbolName: string, onSymbolResolvedCallback: TradingView.ResolveCallback, onResolveErrorCallback: TradingView.ErrorCallback, extension: TradingView.SymbolResolveExtension) {
    const symbols = await this.getAllSymbols();
    const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
    if (!symbolItem) {
      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback("Cannot resolve symbol");
      return;
    }
    // Symbol information object
    const symbolInfo: Partial<TradingView.LibrarySymbolInfo> = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: priceScale(symbolItem.tickSize),
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      visible_plots_set: "ohlcv",
      supported_resolutions: configurationData.supported_resolutions!,
      volume_precision: 2,
      data_status: "streaming",
    };
    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo as TradingView.LibrarySymbolInfo);
  }

  private intervalCheck: string | null = null;
  private apiLimit: number = 1000;

  private dataCount: boolean = false;

  public async getBars(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, periodParams: TradingView.PeriodParams, onHistoryCallback: TradingView.HistoryCallback, onErrorCallback: TradingView.ErrorCallback) {
    let { from, to, countBack, firstDataRequest } = periodParams;

    // console.log("FROM", new Date(from * 1000));
    // console.log("TO", new Date(to * 1000));
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    // >>>>>>>>>>>>>>>>>> 분봉 변환 START
    // 데이터 없을 시 dataCount = true -> 데이터를 호출하지 않기 위함
    // 분봉 변경 시 새로 데이터 호출을 위해 dataCount = false 변환
    if (this.intervalCheck !== OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]) {
      this.dataCount = false;
    }
    this.intervalCheck = OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION];

    if (this.dataCount) {
      onHistoryCallback([], { noData: true });
      return;
    }
    // >>>>>>>>>>>>>>>>>> 분봉 변환 END

    if (parsedSymbol) {
      const urlParameters = {
        category: "spot",
        symbol: parsedSymbol.instId,
        interval: OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION],
        start: from * 1000,
        end: to * 1000,
        limit: this.apiLimit,
      };

      const query = Object.keys(urlParameters)
        .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
        .join("&");
      try {
        const data = await makeBybitRequest(`/v5/market/kline?${query}`);
        if (!data || data.result.list.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }

        let bars: {
          time: number;
          low: any;
          high: any;
          open: any;
          close: any;
          volume: any;
        }[] = [];

        if (countBack >= 1000 && data.result.list.length >= 1000) {
          data.result.list.reverse().forEach((bar: string[]) => {
            if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to * 1000) {
              bars = [
                ...bars,
                {
                  time: parseInt(bar[0]),
                  open: (bar[1]),
                  high: (bar[2]),
                  low: (bar[3]),
                  close: (bar[4]),
                  volume: (bar[5]),
                },
              ];
            }
          });

          to = data.result.list[0][0] - 1;

          while (true) {
            if(from * 1000 >= to) {
              break;
            }
            const urlParameters = {
              category: "spot",
              symbol: parsedSymbol.instId,
              interval: OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION],
              start: from * 1000,
              end: to,
              limit: this.apiLimit,
            };

            const query = Object.keys(urlParameters)
              .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
              .join("&");

            try {
              const data = await makeBybitRequest(`/v5/market/kline?${query}`);
              if (!data || data.result.list.length === 0) {
                break;
              }
              data.result.list.forEach((bar: string[]) => {
                if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to) {
                  bars = [
                    {
                      time: parseInt(bar[0]),
                      open: parseFloat(bar[1]),
                      high: parseFloat(bar[2]),
                      low: parseFloat(bar[3]),
                      close: parseFloat(bar[4]),
                      volume: parseFloat(bar[5]),
                    },
                    ...bars,
                  ];
                }
              });
              to = bars[0].time - 1;
              if(data.result.list.length < 1000) {
                break;
              }
            } catch (error) {
              console.error("The chart data didn't load correctly. Proceed to refresh.");
              // alert("The chart data didn't load correctly. Proceed to refresh.");
              // window.location.reload();
              break;
            }
          }
          console.log(`[getBars]: returned ${bars.length} bar(s)`);
          if (bars.length === 0) {
            this.dataCount = true;
          }
          onHistoryCallback(bars, { noData: false });
        } else {
          data.result.list.reverse().forEach((bar: string[]) => {
            if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to * 1000) {
              bars = [
                ...bars,
                {
                  time: parseInt(bar[0]),
                  open: parseFloat(bar[1]),
                  high: parseFloat(bar[2]),
                  low: parseFloat(bar[3]),
                  close: parseFloat(bar[4]),
                  volume: parseFloat(bar[5]),
                },
              ];
            }
          });

          if (firstDataRequest) {
            this.lastBarsCache.set(symbolInfo.full_name, {
              ...bars[bars.length - 1],
            });
          }
          // console.log(`[getBars]: returned ${bars.length} bar(s)`);
          if (bars.length === 0) {
            this.dataCount = true;
          }
          onHistoryCallback(bars, { noData: false });
        }
      } catch (error) {
        console.error("[getBars]: Get error", error);
        onErrorCallback(error as string);
        // alert("The chart data didn't load correctly. Proceed to refresh.");
        console.log("여기3");
        // window.location.reload();
      } finally {
        return;
      }
    }
  }

  public async subscribeBars(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, onRealtimeCallback: TradingView.SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void) {
    // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    this.socket.subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, this.lastBarsCache.get(symbolInfo.full_name));
  }

  public async unsubscribeBars(subscriberUID: string) {
    // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    this.socket.unsubscribeFromStream(subscriberUID);
  }
}
