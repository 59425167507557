import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { changeOKX_Message, checkSelectType, changeWalletPage } from '../../../store';
import { changeNum, RoundDown } from "../function";
import { useTranslation } from "react-i18next";
import Page from '../../Page';
import { SyncLoader } from "react-spinners";

import { Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Message from "../../message/message";
import Wallet_Message from '../../message/wallet_message';
import Modal from "../../message/modal";
import Namu_Message from "../../message/namu_message";
import IMG from "../../img"
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from "react-copy-to-clipboard/src";

function C_Wallet_Sidebar({ tab, setTab }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    const { t } = useTranslation();
    let dispatch = useDispatch();
    const [slidesPerView, setSlidesPerView] = useState(0);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1080) {
                setSlidesPerView(1);
            }
            else {
                setSlidesPerView(0);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 화면 크기에 따른 슬라이드 수 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='history-left'>
            {
                slidesPerView === 0
                    ?
                    <ul>
                        <li>
                            <Link to={`/wallet`} state={{ num: 0 }} >
                                <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(0); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/wallet_overview.png' style={{ width: "14px" }} />
                                        {t('header_29')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 1 }} >
                                <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(1); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/deposit.png' style={{ width: "14px" }} />
                                        {t('header_31')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 2 }} >
                                <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(2); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/withdraw.png' style={{ width: "14px" }} />
                                        {t('header_33')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 3 }} >
                                <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(3); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/convert.png' style={{ width: "14px" }} />
                                        {t('header_35')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 4 }} >
                                <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(4); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/transfer.png' style={{ width: "14px" }} />
                                        {t('header_37')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 5 }} >
                                <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(5); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/exchange.png' style={{ width: "14px" }} />
                                        {t('header_41')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/wallet`} state={{ num: 6 }} >
                                <div className={tab === 6 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(6); dispatch(changeWalletPage(-1)) }}>
                                    <div className='history-tit-box'>
                                        <img src='/img/icon/gift.png' style={{ width: "14px" }} />
                                        {t('header_39')}
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    :
                    <div style={{ position: "relative" }}>
                        <div className={`box-pre ${darkname}`}></div>
                        <div className={`box-next ${darkname}`}></div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={10}
                            initialSlide={tab}
                            freeMode={true}
                            centeredSlides={true}
                            slideToClickedSlide={true}
                            centeredSlidesBounds={true}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 0 }} >
                                    <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(0); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/wallet_overview.png' style={{ width: "14px" }} />
                                            {t('header_29')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 1 }} >
                                    <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(1); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/deposit.png' style={{ width: "14px" }} />
                                            {t('header_31')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 2 }} >
                                    <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(2); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/withdraw.png' style={{ width: "14px" }} />
                                            {t('header_33')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 3 }} >
                                    <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(3); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/convert.png' style={{ width: "14px" }} />
                                            {t('header_35')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 4 }} >
                                    <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(4); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/transfer.png' style={{ width: "14px" }} />
                                            {t('header_37')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-big'>
                                <Link to={`/wallet`} state={{ num: 5 }} >
                                    <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(5); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/exchange.png' style={{ width: "14px" }} />
                                            {t('header_41')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide className='wallet-small'>
                                <Link to={`/wallet`} state={{ num: 6 }} >
                                    <div className={tab === 6 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(6); dispatch(changeWalletPage(-1)) }}>
                                        <div className='history-tit-box'>
                                            <img src='/img/icon/gift.png' style={{ width: "14px" }} />
                                            {t('header_39')}
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                    </div>
            }
        </div>
    )
}

function C_Deposit({ message, setMessage, buyModal, setBuyModal, search, setSearch, onChangeSearch, setSelectSearch, BselectCoin, selectedOption, handleSelectChange, currency, dhistory, BS_filterSymbol, okxuid }) {
    const { t } = useTranslation();

    let SelectType = useSelector((state) => { return state.SelectType.value })
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })

    const [w_confirm, setW_confirm] = useState(false)

    const [isToggled1, setIsToggled1] = useState(false);
    const [modalbtn, setModalbtn] = useState(false)
    const [tab, setTab] = useState(false)

    const [page, setPage] = useState(1)

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    let filter_dhistory = dhistory.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='history-right'>
            {
                API_Address == "dev"
                    ?
                    <Modal message={message} />
                    :
                    <Namu_Message message={message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} res={0} />
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_31')}</div>
                <p className='history-right-top-2'>{t('header_32')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_20')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        1. {t('wallet_21')}<br />
                                        2. {t('wallet_22')}<br />
                                        3. {t('wallet_23')}<br />
                                        4. {t('wallet_24')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_26')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_27')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_28')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_29')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_30')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_31')} <br />
                                        1. {t('wallet_32')}<br />
                                        2. {t('wallet_33')}<br /><br />

                                        {t('wallet_34')}<br />
                                        - {t('wallet_35')}<br />
                                        - {t('wallet_36')}<br />
                                        - {t('wallet_37')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_38')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_39')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_40')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_41')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_42')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_43')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_44')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_45')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_46')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_47')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_48')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_49')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_50')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_51')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_52')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {SelectType == 1 ? t('exchange_0') : SelectType == 0 ? t('exchange_1') : t('exchange_2')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        {
                                            OKX_Mode
                                                ?
                                                <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                                :
                                                <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => dispatch(changeOKX_Message())}>{t('exchange_1')}</div>
                                        }
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                {
                    SelectType == 0
                        ?
                        <p className="wallet-right-form-5">
                            {t("wallet_239")}
                            <br />
                            {t("wallet_240")} <span style={{ fontWeight: "bold" }}>{okxuid}</span>
                        </p>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_53')}</div>
                    <div className='wallet-right-form-4-2' onClick={() => { setModalbtn(true); setSearch("") }}>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t('wallet_54')}`} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className='wallet-right-bottom1-modal'>
                                <div className='wallet-right-bottom1-modal-1'>
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='wallet-right-bottom1-modal-1-1-tit'>{t('wallet_55')}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} /></div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t('wallet_75')}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className='wallet-right-bottom1-modal-1-2'>
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true
                                                        if (IMG && !IMG.includes(a.symbol)) {
                                                            imgcheck = false
                                                        }

                                                        return (
                                                            <div className='wallet-right-bottom1-modal-1-2-1' onClick={() => { setSelectSearch(a.symbol); setSearch(a.symbol); setModalbtn(false) }} key={i}>
                                                                <div className='wallet-right-bottom1-modal-1-2-2'>
                                                                    <div className='wallet-right-bottom1-modal-1-2-img'>
                                                                        <img src={imgcheck ? `/img/coin/${a.symbol}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className='wallet-right-bottom1-modal-1-2-name'>
                                                                        <p>{a.symbol}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        API_Address == "dev"
                            ?
                            <>
                                <br />
                                <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                                <div className='wallet-right-form-4-4'>
                                    <div className='wallet-right-form-4-5'>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("USDT"); setSelectSearch("USDT") }}>USDT</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                                    </div>
                                    <div className='wallet-right-form-4-5'>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                                    </div>
                                </div>
                            </>
                            :
                            ""
                    }
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_57')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select
                                value={selectedOption}
                                onChange={handleSelectChange}
                            >
                                <option value="">{t('wallet_58')}</option>
                                {
                                    currency.map((option, index) => (
                                        <option key={index} value={option.chain}>
                                            {option.chainType} ({option.chain})
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <p className='wallet-right-form-5'>
                    {t('wallet_59')}<br />
                    {t('wallet_60')}<br />
                    {t('wallet_60-1')}
                </p>
                <div className='wallet-right-form-6'>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_61')}</div>
                        {

                            BselectCoin.address
                                ?
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <QRCodeCanvas value={BselectCoin.address} />
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div>{BselectCoin.address}</div>
                                        <CopyToClipboard text={BselectCoin.address}>
                                            <text className='wallet-right-form-4-8-1' onClick={() => {
                                                setMessage({ type: "binance", code: 0, msg: `${t('wallet_62_1')}` }); setBuyModal(API_Address == "rf" ? true : false)
                                            }}>{t('wallet_62')}</text>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <div style={{ height: "128px" }}></div>
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                                    </div>
                                </>
                        }
                    </div>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_63')}</div>
                        {

                            BselectCoin.tag
                                ?
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <QRCodeCanvas value={BselectCoin.tag} />
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div>{BselectCoin.tag}</div>
                                        <CopyToClipboard text={BselectCoin.tag}>
                                            <text className='wallet-right-form-4-8-1' onClick={() => {
                                                setMessage({ type: "binance", code: 0, msg: `${t('wallet_62_1')}` }); setBuyModal(API_Address == "rf" ? true : false)
                                            }}>{t('wallet_62')}</text>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='wallet-right-form-4-7-no'>
                                        <div className='wallet-right-form-4-7-1-no'>
                                            <div>{t('wallet_64')}</div>
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_65')}</div>
                    {
                        currency.map(function (a, i) {
                            if (a.chain == selectedOption) {
                                return (
                                    <div>{a.confirmation}</div>
                                )
                            }
                        })
                    }
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_67')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} onClick={() => DepositHistory(1)} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                dhistory && dhistory.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_dhistory.map(function (a, i) {
                                                return (
                                                    <DepositItem key={i} a={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={dhistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

let deposition_state = ""
function DepositItem({ a, darkname }) {
    let SelectType = useSelector((state) => { return state.SelectType.value })
    const { t } = useTranslation();

    const [mouseState, setMouseState] = useState(false)
    const [mouseState1, setMouseState1] = useState(false)

    const onMouseEnter = () => {
        setMouseState(true)
    }
    const onMouseLeave = () => {
        setMouseState(false)
    }
    const onMouseEnter1 = () => {
        setMouseState1(true)
    }
    const onMouseLeave1 = () => {
        setMouseState1(false)
    }

    if (SelectType == 1) {
        deposition_state = a.status
    }
    else if (SelectType == 0) {
        let tmp = a.status;
        if (tmp) {
            if (Number(tmp) === 0) deposition_state = "waiting for confirmation";
            else if (Number(tmp) === 1) deposition_state = "deposit credited";
            else if (Number(tmp) === 2) deposition_state = "Completed";
            else if (Number(tmp) === 8) deposition_state = "pending due to temporary deposit suspension on this crypto currency";
            else if (Number(tmp) === 11) deposition_state = "match the address blacklist";
            else if (Number(tmp) === 12) deposition_state = "account or deposit is frozen";
            else if (Number(tmp) === 13) deposition_state = "sub-account deposit interception";
            else if (Number(tmp) === 14) deposition_state = "KYC limit";
        }
    }
    else if (SelectType == 2) {
        if (a.status == "0") {
            deposition_state = "unknown"
        } else if (a.status == "1") {
            deposition_state = "toBeConfirmed"
        } else if (a.status == "2") {
            deposition_state = "processing"
        } else if (a.status == "3") {
            deposition_state = "success"
        } else if (a.status == "4") {
            deposition_state = "deposit failed"
        } else if (a.status == "10011") {
            deposition_state = "pending to be credited to funding pool"
        } else if (a.status == "10012") {
            deposition_state = "Credited to funding pool successfully"
        }
    }

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.time}</div>
                <div className='wallet-right-form-7-3-bottom-3'>
                    {SelectType == 1 ? t('exchange_0') : SelectType == 0 ? t('exchange_1') : t('exchange_2')}
                </div>
            </div>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txId}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txId}</div>
                            :
                            ""
                    }
                </div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.amount}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-4'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.coin}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.chain}</div>
            </div>
            <div className='wallet-right-form-7-3-top-5 wallet-display-none-800' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1} style={{ cursor: "pointer" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{deposition_state}</div>
                {
                    mouseState1
                        ?
                        <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{deposition_state}</div>
                        :
                        ""
                }
            </div>
        </div>
    )
}

function C_Exchange({ search, setSearch, onChangeSearch, selectSearch, setSelectSearch, amount, setAmount, onChangeAmount, balanceAmount, direction, setDirection, markprice, calAmount, spinner, message, qtypoint, buyModal, setBuyModal, setW_confirm, spotBalance, BS_filterSymbol }) {
    const { t } = useTranslation();
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [modalbtn, setModalbtn] = useState(false)
    const [tab, setTab] = useState(false)

    const [w_message, setW_message] = useState([])

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const Check_Modal = () => {
        let mes = []
        // mes.push(`Exchange: Binance`)
        // mes.push(`From: ${direction ? "USDT" : selectSearch}`)
        // mes.push(`To: ${direction ? selectSearch : "USDT"}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    return (
        <div className='history-right'>
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_35')}</div>
                <p className='history-right-top-2'>{t('header_36')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_123')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_124')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_125')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_126')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_127')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_128')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_129')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {SelectType == 1 ? t('exchange_0') : SelectType == 0 ? t('exchange_1') : t('exchange_2')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        {
                                            OKX_Mode
                                                ?
                                                <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                                :
                                                <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => dispatch(changeOKX_Message())}>{t('exchange_1')}</div>
                                        }
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_130')}</div>
                    <div className='wallet-right-form-4-2' onClick={() => { setModalbtn(true); setSearch("") }}>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={t('wallet_54')} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className='wallet-right-bottom1-modal'>
                                <div className='wallet-right-bottom1-modal-1'>
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='wallet-right-bottom1-modal-1-1-tit'>{t('wallet_55')}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} /></div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t('wallet_75')}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className='wallet-right-bottom1-modal-1-2'>
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true
                                                        if (IMG && !IMG.includes(a.ccy)) {
                                                            imgcheck = false
                                                        }
                                                        return (
                                                            <div className='wallet-right-bottom1-modal-1-2-1' onClick={() => { setSelectSearch(a.ccy); setSearch(a.ccy); setModalbtn(false) }}>
                                                                <div className='wallet-right-bottom1-modal-1-2-2'>
                                                                    <div className='wallet-right-bottom1-modal-1-2-img'>
                                                                        <img src={imgcheck ? `/img/coin/${a.ccy}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className='wallet-right-bottom1-modal-1-2-name'>
                                                                        <p>{a.ccy}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        API_Address == "dev"
                            ?
                            <>
                                <br />
                                <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                                <div className='wallet-right-form-4-4'>
                                    <div className='wallet-right-form-4-5'>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETC"); setSelectSearch("ETC") }}>ETC</div>
                                    </div>
                                    <div className='wallet-right-form-4-5'>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                                        <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                                    </div>
                                </div>
                            </>
                            :
                            ""
                    }
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')}<br /> <span>{changeNum(balanceAmount)}</span></p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')}<br /> <span>{spotBalance.free ? changeNum(RoundDown(spotBalance.free, 6)) : "0.00000"}</span></p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>{selectSearch ? selectSearch : '-'}</p>
                        <div className='wallet-right-form-10-5' onClick={() => { setDirection(e => !e); setAmount("") }}>
                            {
                                direction
                                    ?
                                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                                    :
                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                            }
                        </div>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>USDT</p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className='wallet-right-form-10-3'>{t('wallet_132')}</p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_133')}</div>
                    <div className='wallet-right-form-4-1-1'>{changeNum(markprice)} USDT</div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_134')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input type='number' className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>{direction ? "USDT" : selectSearch}</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() => setAmount(direction ? spotBalance.free ? RoundDown(spotBalance.free, 6) : 0 : balanceAmount ? RoundDown(balanceAmount, qtypoint) : 0)}>{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_135')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>{calAmount}</div>
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_35')}</div>
                </div>
            </div>
        </div >
    )
}

function C_QuickWithdrawal({ amount, setAmount, onChangeAmount, selectedSend, handleSelectSend, selectedReci, handleSelectReci, Bn_selectCoin, okx_selectCoin, By_selectCoin, quickHistory, spinner, message, modal_message, modal_buyModal, setModal_BuyModal, code, handleInputChange, buyModal, setBuyModal, setW_confirm, coinListReci, coinList }) {
    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt; });
    let Bybit_wallet_usdt = useSelector((state) => { return state.Bybit_wallet_usdt });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Bybit_Mode = useSelector((state) => { return state.Bybit_Mode.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [w_message, setW_message] = useState([])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`From: ${selectedSend}`)
        // mes.push(`To: ${selectedReci}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const [page, setPage] = useState(1)
    let filter_quickHistory = quickHistory.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='history-right'>
            {
                API_Address == "dev"
                    ?
                    <Modal message={modal_message} />
                    :
                    <Namu_Message message={modal_message} buyModal={modal_buyModal} setBuyModal={setModal_BuyModal} res={0} />
            }
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_41')}</div>
                <p className='history-right-top-2'>{t('header_42')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_197')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_198')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_199')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_200')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_201')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_202')}<br />
                                        {t('wallet_203')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_204')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_205')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_206')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ cursor: "auto" }} >
                            USDT
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-1-1' style={{ justifyContent: "flex-start" }}>
                            <img src='img/coin/USDT.png' />{t('wallet_207')}
                        </p>
                        <p className='wallet-right-form-10-1-1' style={{ justifyContent: "end" }}>
                            <img src='img/coin/USDT.png' />{t('wallet_208')}
                        </p>
                    </div>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')}<br />
                            {
                                selectedSend == "Binance"
                                    ?
                                    <span>{Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"}</span>
                                    :
                                    selectedSend == "OKX"
                                        ?
                                        <span>{OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"}</span>
                                        :
                                        <span>{Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"}</span>
                            }
                        </p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')}<br />
                            {
                                selectedReci == "Binance"
                                    ?
                                    <span>{Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"}</span>
                                    :
                                    selectedReci == "OKX"
                                        ?
                                        <span>{OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"}</span>
                                        :
                                        <span>{Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"}</span>
                            }
                        </p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <select
                            value={selectedSend}
                            onChange={handleSelectSend}
                            className={`wallet-right-form-10-4 ${darkname}`}
                        >
                            {
                                coinList.map((option, index) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>
                        <div className='wallet-right-form-10-5' style={{ cursor: "auto" }}>
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                        </div>
                        <select
                            value={selectedReci}
                            onChange={handleSelectReci}
                            className={`wallet-right-form-10-4 ${darkname}`}
                        >
                            {
                                coinListReci.map((option, index) => (
                                    option !== selectedSend && (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    )
                                ))
                            }
                        </select>
                    </div>
                    <div className='wallet-right-form-10-1 display-none-600'>
                        <p className='wallet-right-form-10-2'>
                            <span>{selectedSend == "Binance" ? Bn_selectCoin ? Bn_selectCoin : "-" : selectedSend == "OKX" ? okx_selectCoin ? okx_selectCoin : "-" : By_selectCoin ? By_selectCoin : "-"}</span>
                        </p>
                        <p className='wallet-right-form-10-3'>
                            <span>{selectedReci == "Binance" ? Bn_selectCoin ? Bn_selectCoin : "-" : selectedReci == "OKX" ? okx_selectCoin ? okx_selectCoin : "-" : By_selectCoin ? By_selectCoin : "-"}</span>
                        </p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_209')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input type='number' className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>USDT</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() =>
                                setAmount(
                                    selectedSend == "Binance"
                                        ?
                                        Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"
                                        :
                                        selectedSend == "OKX"
                                            ?
                                            OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"
                                            :
                                            Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"
                                )}
                            >{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_210')}</div>
                        <div className='wallet-right-form-4-1-1'>1 USDT</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_211')}</div>
                        <div className='wallet-right-form-4-1-1' >10 USDT</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_212')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_213')}
                    </div>
                    <div className='wallet-right-form-8'>
                        {code.map((digit, index) => (
                            <div className={`wallet-right-form-8-1 ${darkname}`}>
                                <input
                                    key={index}
                                    type="number"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`wallet-right-form-8-2 ${darkname}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_41')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_214')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} onClick={() => QuickWithdrawalHistory(1)} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                quickHistory && quickHistory.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_quickHistory.map(function (a, i) {
                                                return (
                                                    <QuickWithdrawItem key={i} a={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={quickHistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function QuickWithdrawItem({ a, darkname }) {
    const [mouseState, setMouseState] = useState(false)

    const onMouseEnter = () => {
        setMouseState(true)
    }
    const onMouseLeave = () => {
        setMouseState(false)
    }

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.time}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.exchange}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txId}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txId}</div>
                            :
                            ""
                    }
                </div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.amount}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-4'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.coin}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.network}</div>
            </div>
            <div className='wallet-right-form-7-3-top-5 wallet-display-none-800'>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{a.status}</div>
            </div>
        </div>
    )
}

export { C_Wallet_Sidebar, C_Deposit, C_Exchange, C_QuickWithdrawal };