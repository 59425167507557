import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faComputerMouse, faCircle } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { RoundDown, changeUnits, findSmallest, changeNum } from "../../common/function"
import { ClipLoader } from "react-spinners";
import { check_Error } from '../../../store';

let last = 0;

function GoexBnS4({ data, lastBuy, lastSell, symbol, setCheckordertab, checkordertab, exinfo, max, currentPrice, balance, currentUSDT, btnbtn, setBtnbtn }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Alert = useSelector((state) => { return state.Alert });
    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();

    const [ordertab, setOrdertab] = useState(0);
    const [autotab, setAutotab] = useState(true);
    const [quaninput, setQuaninput] = useState('');
    const [quaninput1, setQuaninput1] = useState('');
    const [btnpercent, setBtnpercent] = useState([50]);
    const [buyorderprice, setBuyorderprice] = useState();
    const [sellorderprice, setSellorderprice] = useState();
    const [message, setMessage] = useState([])
    const [qtypoint, setQtypoint] = useState(2)
    const [minCoin, setMinCoin] = useState(0)
    const [minvalue, setMinValue] = useState("Loding")
    const [minQty, setMinQty] = useState("Loding")
    const [current, setCurrent] = useState([])

    const [delay, setDelay] = useState(true)

    const [isPlaying, setIsPlaying] = useState(false);

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    useEffect(() => {
        if (balance) {
            for (let i = 0; i < balance.length; i++) {
                if (balance[i].asset === data.ccy) {
                    setCurrent(balance[i])
                }
            }
        }
    }, [symbol, data])
    
    useEffect(() => {
        if (exinfo[1]) {
            let point = findSmallest(Number(exinfo[1]?.minQty ?? 0))
            setMinValue(Number(exinfo[6]?.minNotional ?? 0))
            setMinCoin((Number(exinfo[1]?.minQty ?? 0)))
            setMinQty(Number(exinfo[1]?.minQty ?? 0))
            setQtypoint(point)
        }
    }, [exinfo])

    useEffect(() => {
        setQuaninput('')
        setQuaninput1('')
    }, [symbol])

    useEffect(() => {
        setBuyorderprice(Number(lastBuy).toFixed(max))
        if (lastBuy != 0) setAutotab(false)
    }, [lastBuy])

    useEffect(() => {
        setSellorderprice(Number(lastSell).toFixed(max))
        if (lastSell != 0) setAutotab(false)
    }, [lastSell])

    useEffect(() => {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
    }, [ordertab])

    if (autotab) setCheckordertab(true)
    else setCheckordertab(false)

    if (last != currentPrice && autotab && checkordertab) {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice ? currentPrice : data.lastPrice
    }

    const onChangeQuan = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput(onlyNumber)
    }

    const onChangeQuan1 = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput1(onlyNumber)
    }

    const onChangePlusCoin = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            let num = Number(quaninput) + e
            setQuaninput(num.toFixed(qtypoint))
            setQuaninput1((num * currentPrice).toFixed(3))
        }
    }

    const onChangePlusUSDT = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            let num = Number(quaninput1) + e
            setQuaninput1(num.toFixed(qtypoint))
            setQuaninput((num / currentPrice).toFixed(qtypoint))
        }
    }

    const onChangePercent = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            setQuaninput1(RoundDown((Number(currentUSDT?.free ?? 0) * e) / 100, qtypoint))
            setQuaninput(RoundDown((Number(currentUSDT?.free ?? 0) / Number(currentPrice)) * e / 100, qtypoint))
        }
    }

    useEffect(() => {
        if (btnbtn === 0) {
            setQuaninput1(RoundDown(Number(quaninput) * currentPrice, 3))
        }
        else {
            setQuaninput(RoundDown(Number(quaninput1) / currentPrice, qtypoint))

        }
    }, [currentPrice, quaninput, quaninput1])

    const onChangeBuy = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setBuyorderprice(onlyNumber)
    }

    const onChangeSell = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSellorderprice(onlyNumber)
    }

    const onSubmitBuy = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order", {
                symbol: symbol,
                side: "BUY",
                type: ordertab === 0 ? 'LIMIT' : 'MARKET',
                quantity: quaninput,
                price: buyorderprice,
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setQuaninput("")
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg?.msg })
                }

                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 59, "msg": error }))
            })
        }
    }

    const onSubmitSell = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order", {
                symbol: symbol,
                side: "SELL",
                type: ordertab === 0 ? 'LIMIT' : 'MARKET',
                quantity: quaninput,
                price: sellorderprice,
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setQuaninput("")
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg?.msg })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 60, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-section12 ${darkname}`}>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.7.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            <div>
                {/* <div style={{ filter: check_KYC && check_KYC.etcKyc === 0 ? "blur(5px)" : "" }}> */}
                <Modal message={message} />
                <div className={`futures-section4-top ${darkname}`}></div>
                <div className='futures-section4-top-1'>
                    <div className={ordertab === 0 ? `futures-section4-top-1-btn active ${darkname}` : 'futures-section4-top-1-btn'} onClick={() => { setOrdertab(0); setAutotab(true) }}>{t('futures_6')}</div>
                    <div className={ordertab === 1 ? `futures-section4-top-1-btn active ${darkname}` : 'futures-section4-top-1-btn'} onClick={() => { setOrdertab(1); setAutotab(true) }}>{t('futures_7')}</div>
                </div>
                <div className={`futures-section4-top-2 ${darkname}`}>
                    <FontAwesomeIcon icon={faWallet} className='futures-section4-top-2-ico' />
                    <div className='futures-section4-top-2-1'>{t('futures_0')}</div>
                    <div className={`futures-section4-top-2-2 ${darkname} red`}>
                        <span>{changeNum((Number(currentUSDT?.free ?? 0) + Number(currentUSDT?.locked ?? 0)).toFixed(3))}</span> <span style={{ fontSize: "11px" }}>USDT</span>
                    </div>
                </div>
                {
                    ordertab === 0
                        ?
                        autotab
                            ?
                            <div className={`futures-section4-top-3-1 ${darkname}`} onClick={() => { setAutotab(false) }}>
                                <span>{t('futures_10')}</span>&nbsp;<span className='color' style={{ fontWeight: "bold" }}>{t('futures_11')}</span>
                                <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                            </div>
                            :
                            <div className={`futures-section4-top-3-2 ${darkname}`} onClick={() => { setAutotab(true) }}>
                                <span>{t('futures_10')}</span>&nbsp;<span style={{ fontWeight: "bold", color: "#c1c1c1" }}>{t('futures_12')}</span>
                                <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                            </div>
                        :
                        <div className={`futures-section4-top-3-2 ${darkname}`}>
                            <span>{t('futures_10')}</span>&nbsp;<span style={{ fontWeight: "bold", color: "#c1c1c1" }}>{t('futures_12')}</span>
                            <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                        </div>
                }
                <div className='futures-section4-top-4'>
                    <div className={`futures-section4-top-4-1 ${darkname}`}>
                        <div className='futures-section4-top-4-3'>
                            <div className='futures-section4-top-4-1-1'>{t('futures_13')}</div>
                            {
                                ordertab === 1
                                    ?
                                    <input type='number' className={`futures-section4-top-4-1-2 ${darkname}`} value={buyorderprice} onChange={onChangeBuy} disabled />
                                    :
                                    <input type='number' className={`futures-section4-top-4-1-2 ${darkname}`} value={buyorderprice} onChange={onChangeBuy} onClick={() => { setAutotab(false); setCheckordertab(false) }} />
                            }
                        </div>
                    </div>
                    <div className={`futures-section4-top-4-2 ${darkname}`}>
                        <div className='futures-section4-top-4-3'>
                            <div className='futures-section4-top-4-1-1'>{t('futures_14')}</div>
                            {
                                ordertab === 1
                                    ?
                                    <input type='number' className={`futures-section4-top-4-1-3 ${darkname}`} value={sellorderprice} onChange={onChangeSell} disabled />
                                    :
                                    <input type='number' className={`futures-section4-top-4-1-3 ${darkname}`} value={sellorderprice} onChange={onChangeSell} onClick={() => { setAutotab(false); setCheckordertab(false) }} />
                            }
                        </div>
                    </div>
                </div>
                <div className='futures-section4-top-5'>
                    {
                        btnbtn === 0
                            ?
                            <>
                                <div style={{ padding: "5px 0", width: "100%" }}>
                                    <div className='futures-section4-top-5-1'>
                                        <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                        <div className='futures-section-top-5-1-2'>{data.ccy}</div>
                                        <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan} placeholder={0} value={quaninput} />
                                    </div>
                                    <div className='futures-section-top-5-1-4'>
                                        <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minQty}</span> <span>{data.ccy}</span></div>
                                        <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput1}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>USDT</span></div>
                                    </div>
                                    <div className='futures-section-top-5-1-4'>
                                        <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minvalue}</span> <span>USDT</span></div>
                                    </div>
                                </div>
                                <div className='futures-section4-top-5-2'>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin))}>{isNaN(minCoin) ? 0 : changeUnits(minCoin)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 5)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 5)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 10)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 10)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 50)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 50)}</div>
                                </div>
                                <div className='futures-section4-top-5-2'>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 100)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 100)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 500)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 500)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 1000)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 1000)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(0)}>{t('futures_16')}</div>
                                </div>
                            </>
                            :
                            btnbtn === 1
                                ?
                                <>
                                    <div style={{ padding: "5px 0", width: "100%" }}>
                                        <div className='futures-section4-top-5-1'>
                                            <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                            <div className='futures-section-top-5-1-2'>USDT</div>
                                            <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan1} placeholder={`0`} value={quaninput1} />
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minCoin}</span> <span>{data.ccy}</span></div>
                                            <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>{data.ccy}</span></div>
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minvalue}</span> <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='futures-section4-top-5-2'>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(1)}>1</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(5)}>5</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(10)}>10</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(50)}>50</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(100)}>100</div>
                                    </div>
                                    <div className='futures-section4-top-5-2'>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(500)}>500</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(1000)}>1K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(5000)}>5K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(10000)}>10K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(0)}>{t('futures_16')}</div>
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ padding: "5px 0", width: "100%" }}>
                                        <div className='futures-section4-top-5-1'>
                                            <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                            <div className='futures-section-top-5-1-2'>USDT</div>
                                            <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan1} placeholder={`0`} value={quaninput1} />
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minCoin}</span> <span>{data.ccy}</span></div>
                                            <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>{data.ccy}</span></div>
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minvalue}</span> <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='futures-section4-top-5-4' >
                                        <output futures-margin-output id="output"><span>{btnpercent[0]}</span> <span>%</span></output>
                                        <Range
                                            values={btnpercent}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={e => { setBtnpercent(e); onChangePercent(e) }}
                                            renderTrack={({ props, children }) => (
                                                <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "5px", width: "100%", borderRadius: "4px",
                                                            background: getTrackBackground({
                                                                values: btnpercent,
                                                                colors: [`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`, "#ccc"],
                                                                min: 0,
                                                                max: 100
                                                            }),
                                                            alignSelf: "center"
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div {...props} style={{ ...props.style, height: "20px", width: "20px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                                    <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? `${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}` : "#CCC" }} />
                                                </div>
                                            )}
                                        />
                                        <div className='futures-section4-top-5-4-1'>
                                            <div className='futures-section4-top-5-4-2'>0%</div>
                                            <div className='futures-section4-top-5-4-3'>25%</div>
                                            <div className='futures-section4-top-5-4-2'>50%</div>
                                            <div className='futures-section4-top-5-4-3'>75%</div>
                                            <div className='futures-section4-top-5-4-2'>100%</div>
                                        </div>
                                    </div>
                                </>
                    }
                    {
                        login
                            ?
                            ordertab === 0
                                ?
                                <div className='futures-section4-top-5-3'>
                                    <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => onSubmitBuy()}>
                                        <div className={`futures-section4-top-5-3-1-1`}>{
                                            delay
                                                ?
                                                t('futures_17')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }
                                        </div>
                                    </div>
                                    <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => onSubmitSell()}>
                                        <div className={`futures-section4-top-5-3-1-2`}>{
                                            delay
                                                ?
                                                t('futures_18')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='futures-section4-top-5-3'>
                                    <div className={`futures-section4-top-5-3-1 ${darkname}`}>
                                        <div className={`futures-section4-top-5-3-1-1`} onClick={() => onSubmitBuy()}>{
                                            delay
                                                ?
                                                t('futures_19')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }</div>
                                    </div>
                                    <div className={`futures-section4-top-5-3-2 ${darkname}`}>
                                        <div className={`futures-section4-top-5-3-1-2`} onClick={() => onSubmitSell()}>{
                                            delay
                                                ?
                                                t('futures_20')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }</div>
                                    </div>
                                </div>
                            :
                            <div className='futures-section4-top-5-3'>
                                <Link to={`/login`} className={`futures-section4-top-5-3-1 ${darkname}`}>
                                    <div className={`futures-section4-top-5-3-1-1`}>{t('login_0')}</div>
                                </Link>
                                <Link to={`/login`} className={`futures-section4-top-5-3-2 ${darkname}`}>
                                    <div className={`futures-section4-top-5-3-1-2`}>{t('login_0')}</div>
                                </Link>
                            </div>
                    }

                </div >
                <div className='futures-section4-top-6'>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(0) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 0 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_23')}</div>
                        </div>
                    </div>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(1) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 1 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_24')}</div>
                        </div>
                    </div>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(2) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 2 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_25')}</div>
                        </div>
                    </div>
                </div>
                <div className='futures-section4-top-10'>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>{changeNum((Number(currentUSDT?.free ?? 0) + Number(currentUSDT?.locked ?? 0)).toFixed(3))}</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>USDT</div>
                    </div>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>{changeNum((Number(currentUSDT?.free ?? 0)).toFixed(3))}</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>USDT</div>
                    </div>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{data.ccy} Balance</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>{changeNum((Number(current?.free ?? 0) + Number(current?.locked ?? 0)))}</div>
                        <div className={`futures-section4-top-10-1-2 ${darkname}`}>{data.ccy}</div>
                    </div>
                </div>
            </div>
            {/* {
                check_KYC && check_KYC.etcKyc === 0
                    ?
                    <div className='spot-kyc-modal' style={{ position: "absolute" }}>
                        <div className={`spot-kyc-modal-1 ${darkname}`}>
                            <div className='spot-kyc-modal-text'>{t('message_4')}<br />{t('message_5')}</div>
                            <Link to={`/dashboard`} state={{ num: 1 }}>
                                <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            } */}
        </div >
    )
}

export default GoexBnS4;