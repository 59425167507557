import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";

import { BinanceFutures } from '../../common/C_spot/M_goex1';

function GoexByS1_1({ USDTSymbolS, symbol, balance }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let dispatch = useDispatch()
    const darkname = dark ? 'dark' : "";

    const [search, setSearch] = useState("");
    const [message, setMessage] = useState([])
    const [balanceList, setBalanceList] = useState([])

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    let filterSymbol = USDTSymbolS.filter((p) => {
        return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    })

    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth - 40,
                    height: containerRef.current.offsetHeight - 130,
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef]);

    useEffect(() => {
        if (balance) {
            let arr = []
            for (let i = 0; i < balance.length; i++) {
                if (balance[i].coin != "USDT") {
                    let fav = USDTSymbolS.filter((item) => item.ccy == balance[i].coin)
                    arr.push({
                        ccy: balance[i].coin,
                        symbol: balance[i].coin + "USDT",
                        available: balance[i].availableToWithdraw,
                        eq: balance[i].equity,
                        value: balance[i].usdValue,
                        locked: balance[i].locked,
                        favorite: fav[0].favorite
                    })
                }
            }
            setBalanceList(arr)
        }
    }, [balance, USDTSymbolS])

    return (
        <div className={`futures-section18-1 ${darkname}`}>
            <Modal message={message} />
            <div className='futures-section1-box' ref={containerRef}>
                <div className={`futures-section1-box-position ${darkname}`}>
                    <div className={`futures-section1-box-1 ${darkname}`}>
                        <div className='futures-section1-box-1-1'>
                            <img src={dark ? '/img/bybit_dark.png' : '/img/bybit_logo.png'} className='futures-section1-box-1-img' style={{ width: "35px" }}></img>
                            <div className='futures-section1-box-1-3'>{t('header_3')}</div>
                        </div>
                    </div>
                    <div className={`futures-section1-box-2 ${darkname}`}>
                        <input type='text' placeholder={`${t('market_0')}`} value={search} onChange={onChange} />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='futures-section1-box-2-ico' />
                    </div>
                </div>
                <BinanceFutures filterSymbol={filterSymbol} darkname={darkname} symbol={symbol} dimensions={dimensions} balanceList={balanceList} exchange={"bybit"} />
            </div>
        </div>
    )
}

export default GoexByS1_1;