import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { changeTime, changeDate, RoundDown } from "../../common/function"
import { ClipLoader } from "react-spinners";

import { Alarm, OpenOrder, OrderHistory, TradeHistory, Balance } from '../../common/C_spot/W_goex8.js';

function GoexBnS8({ tab5, setTab5, symbol, setTradeVolume, tradeVolume, orderReload, openorder, balance, BinanceS }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const [message, setMessage] = useState([])
    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [openorderList, setOpenorderList] = useState([])
    const [balanceList, setBalanceList] = useState([])

    useEffect(() => {
        let arr = []
        if (balance) {
            for (let i = 0; i < balance.length; i++) {
                let v = BinanceS.filter((item) => item.ccy == "BTC")
                if (balance[i].asset != "USDT" && BinanceS[0]) {
                    arr.push({
                        ccy: balance[i].asset,
                        symbol: balance[i].asset + "USDT",
                        available: balance[i].free,
                        eq: Number(balance[i].btcValuation) * Number(v[0].lastPrice),
                        value: Number(balance[i].btcValuation) * Number(v[0].lastPrice),
                        locked: balance[i].freeze
                    })
                }
            }
        }
        setBalanceList(arr)
    }, [balance, BinanceS])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].symbol.split('USDT')
                arr.push({
                    ccy: sym[0],
                    symbol: openorder[i].symbol,
                    orderId: openorder[i].orderId,
                    type: openorder[i].type,
                    side: openorder[i].side == "BUY" ? "Buy" : "Sell",
                    px: openorder[i].price,
                    sz: openorder[i].origQty
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = () => {
        let list = []

        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i].symbol
                if (!list.includes(s)) {
                    list.push(s)
                }
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/all-order/cancel", {
                symbol: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 61, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order/cancel", {
                symbol: item.symbol,
                orderId: item.orderId,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setDelay(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 62, "msg": error }))
                })
        }
    }

    return (
        <div className={`futures-section15 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('spot_9')}</div>
                    <div className={tab5 === 1 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(3)}>{t('futures_45')}</div>
                    <div className={tab5 === 4 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(4)}>{t('futures_46')}</div>
                    <div className={tab5 === 5 ? `futures-section8-top-1-1 display-none-1000 active ${darkname}` : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(5)}>{t('futures_82')}</div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className={`futures-section8-top-1-3 ${darkname}`} onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className={`futures-section8-top-1-3 ${darkname}`}><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Balance balance={balanceList} exchange={"binance"} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder openorder={openorderList} symbol={symbol} all={false} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                            :
                            tab5 === 2
                                ?
                                <OpenOrder openorder={openorderList} symbol={symbol} all={true} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                :
                                tab5 === 3
                                    ?
                                    <FuturesOrderHistory orderReload={orderReload} />
                                    :
                                    tab5 === 4
                                        ?
                                        <FuturesTradeHistory orderReload={orderReload} />
                                        :
                                        <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                }
            </div>
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/history", {
                params: {
                    reqId: o_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && o_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                }
                setSpinner(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 72, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')
                let date = orderlist[i].transaction_time.split('T')
                let date1 = date[1].split('.000Z')
                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].last_executed_price) * Number(orderlist[i].last_executed_quantity),
                    fee: Number(orderlist[i].commission_amount) * (orderlist[i].side == "BUY" ? Number(orderlist[i].last_executed_price) : 1),
                    time: `${date[0]} ${date1[0]}`,
                    reward: 0,
                    side: orderlist[i].side,
                    price: orderlist[i].last_executed_price,
                    size: orderlist[i].last_executed_quantity,
                })
            }
        }
        setOrderData(summary)
    }, [orderlist])

    return (
        <OrderHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderdata} spinner={spinner} />
    )
}

let t_id = 0
function FuturesTradeHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderlistSum, setOrderlistSum] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [personal_fee, setPersonal_fee] = useState(0.25)
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/history", {
                params: {
                    reqId: t_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && t_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                }
                setSpinner(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 73, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')
                let date = orderlist[i].transaction_time.split('T')
                let date1 = date[1].split('.000Z')
                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].last_executed_price) * Number(orderlist[i].last_executed_quantity),
                    fee: Number(orderlist[i].commission_amount) * (orderlist[i].side == "BUY" ? Number(orderlist[i].last_executed_price) : 1),
                    time: `${date[0]} ${date1[0]}`,
                    reward: 0,
                    side: orderlist[i].side,
                    price: orderlist[i].last_executed_price,
                    size: orderlist[i].last_executed_quantity,
                })
            }
        }
        setOrderlistSum(summary)
    }, [orderlist])

    return (
        <TradeHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderlistSum} spinner={spinner} />
    )
}

export default GoexBnS8;