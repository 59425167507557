import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import { useTranslation } from "react-i18next";
import {  padZero, UTCTimestamp } from "../../common/function"

import { C_Deposit } from '../../common/C_wallet/W_wallet';

function Binance_Deposit({ walletData, fastCoin }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const [message, setMessage] = useState([])
    const [buyModal, setBuyModal] = useState(false);

    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [BselectCoin, SetBSelectCoin] = useState([])
    const [selectedOption, setSelectedOption] = useState("");
    const [currency, setCurrency] = useState([])
    const [dhistory, setDhistory] = useState([])
    const [BselectCoinList, SetBSelectCoinList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [dhistoryList, setDhistoryList] = useState([])

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    let BS_filterSymbol = []
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositCurrency(token)
        }
    }, [selectSearch])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            B_DepositLink(token)
        }
    }, [selectedOption])

    useEffect(() => {
        DepositHistory(0)
    }, [])

    const B_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/address`, {
            params: {
                coin: selectSearch,
                network: selectedOption,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response?.data?.data) {
                SetBSelectCoin(response.data.data)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 80, "msg": error }))
        })
    }

    const DepositCurrency = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/network`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp) {
                for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].coin == search) {
                        setCurrency(tmp[i].networkList)
                    }
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 81, "msg": error }))
        })
    }

    const DepositHistory = async (n) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/deposit`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    if (n === 1) setMessage({ type: "binance", code: response?.data?.code, msg: "Refresh successful" })
                    setDhistory(response.data.data)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: "Refresh Failed" })
                }
                if (API_Address == "rf" && n == 1) setBuyModal(true)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 82, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (BselectCoin) {
            SetBSelectCoinList({
                address: BselectCoin.address,
                tag: BselectCoin.tag
            })
        }
    }, [BselectCoin])

    useEffect(() => {
        if (currency) {
            let arr = []
            for (let i = 0; i < currency.length; i++) {
                arr.push({
                    chain: currency[i].network,
                    chainType: currency[i].name,
                    confirmation: currency[i].unLockConfirm
                })
            }
            setCurrencyList(arr)
        }
    }, [currency])

    useEffect(() => {
        if (dhistory) {
            let arr = []
            for (let i = 0; i < dhistory.length; i++) {
                const date = UTCTimestamp(dhistory[i].insertTime);
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                arr.push({
                    time: formattedDate,
                    status: dhistory[i].status,
                    txId: dhistory[i].txId,
                    amount: dhistory[i].amount,
                    coin: dhistory[i].coin,
                    chain: dhistory[i].network
                })
            }
            setDhistoryList(arr)
        }
    }, [dhistory])

    return (
        <C_Deposit message={message} setMessage={setMessage} buyModal={buyModal} setBuyModal={setBuyModal} search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} setSelectSearch={setSelectSearch} BselectCoin={BselectCoinList} selectedOption={selectedOption} handleSelectChange={handleSelectChange} currency={currencyList} dhistory={dhistoryList} BS_filterSymbol={BS_filterSymbol} />
    )
}

export default Binance_Deposit;