import { configureStore, createSlice } from '@reduxjs/toolkit'

let API_Address = createSlice({
    name: 'API_Address',
    initialState: { value: "" },
    reducers: {
        checkAPI_Address(state, action) {
            let va = ""
            if (action.payload == "namu") va = "rf"
            else if (action.payload == "goex") va = "dev"
            state.value = va
        }
    }
})

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let check_KYC = createSlice({
    name: 'check_KYC',
    initialState: {},
    reducers: {
        changeKYC(state, action) {
            state.value = action.payload
        }
    }
})

let SelectType = createSlice({
    name: 'SelectType',
    initialState: { value: 0 },
    reducers: {
        checkSelectType(state, action) {
            const objString = localStorage.getItem('token');
            if (objString) {
                const obj = JSON.parse(objString);
                const newobj = {
                    value: obj.value,
                    expire: obj.expire,
                    type: action.payload === 0 ? "okx" : action.payload === 1 ? "binance" : "bybit",
                    login: obj.login
                }
                const newobjString = JSON.stringify(newobj);
                localStorage.setItem('token', newobjString)
            }
            state.value = action.payload
        }
    }
})

let Wallet_Page = createSlice({
    name: 'Wallet_Page',
    initialState: { value: -1 },
    reducers: {
        changeWalletPage(state, action) {
            state.value = action.payload
        }
    }
})

let Binance_coinS = createSlice({
    name: 'Binance_coinS',
    initialState: [],
    reducers: {
        Binance_addCoinS(state, action) {
            let num = 0;
            state.map(function (a, i) {
                if (a.symbol == action.payload.symbol) {
                    state[i] = action.payload
                    num = 1
                    // if (a.lastPrice != action.payload.lastPrice) state[i].check = true
                }
            })
            if (num == 0) state.push(action.payload)
            num = 0
        }
    }
})

let Binance_coinF = createSlice({
    name: 'Binance_coinF',
    initialState: [],
    reducers: {
        Binance_addCoinF(state, action) {
            let num = 0;
            state.map(function (a, i) {
                if (a.symbol == action.payload.symbol) {
                    state[i] = action.payload
                    num = 1
                    // if (a.lastPrice != action.payload.lastPrice) state[i].check = true
                }
            })
            if (num == 0) state.push(action.payload)
            num = 0
        }
    }
})

let OKX_coinS = createSlice({
    name: 'OKX_coinS',
    initialState: [],
    reducers: {
        OKX_addCoinS(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let OKX_coinF = createSlice({
    name: 'OKX_coinF',
    initialState: [],
    reducers: {
        OKX_addCoinF(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let Bybit_coinS = createSlice({
    name: 'Bybit_coinS',
    initialState: [],
    reducers: {
        Bybit_addCoinS(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let Bybit_coinF = createSlice({
    name: 'Bybit_coinF',
    initialState: [],
    reducers: {
        Bybit_addCoinF(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let OKX_S_favorite = createSlice({
    name: 'OKX_S_favorite',
    initialState: [],
    reducers: {
        addCoin_OKX_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_OKX_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let OKX_F_favorite = createSlice({
    name: 'OKX_F_favorite',
    initialState: [],
    reducers: {
        addCoin_OKX_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_OKX_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_S_favorite = createSlice({
    name: 'Binance_S_favorite',
    initialState: [],
    reducers: {
        addCoin_Binance_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Binance_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_F_favorite = createSlice({
    name: 'Binance_F_favorite',
    initialState: [],
    reducers: {
        addCoin_Binance_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Binance_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})


let Bybit_S_favorite = createSlice({
    name: 'Bybit_S_favorite',
    initialState: [],
    reducers: {
        addCoin_Bybit_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Bybit_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Bybit_F_favorite = createSlice({
    name: 'Bybit_F_favorite',
    initialState: [],
    reducers: {
        addCoin_Bybit_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Bybit_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_position = createSlice({
    name: 'Binance_position',
    initialState: [],
    reducers: {
        change_Binance_position(state, action) {
            return action.payload;
        }
    }
})

let OKX_position = createSlice({
    name: 'OKX_position',
    initialState: [],
    reducers: {
        change_OKX_position(state, action) {
            return action.payload;
        }
    }
})

let Bybit_position = createSlice({
    name: 'Bybit_position',
    initialState: [],
    reducers: {
        change_Bybit_position(state, action) {
            return action.payload;
        }
    }
})

let OKX_liquidation = createSlice({
    name: 'OKX_liquidation',
    initialState: false,
    reducers: {
        change_OKX_liquidation(state, action) {
            let v = state
            return !v;
        }
    }
})
let OKX_Instruments = createSlice({
    name: 'OKX_Instruments',
    initialState: [],
    reducers: {
        check_OKX_Instruments(state, action) {
            return action.payload;
        }
    }
})

let Binance_ListenKey = createSlice({
    name: 'Binance_ListenKey',
    initialState: [],
    reducers: {
        change_Binance_ListenKey(state, action) {
            state.push(action.payload)
        }
    }
})

let Binance_wallet_spot = createSlice({
    name: 'Binance_wallet_spot',
    initialState: [],
    reducers: {
        change_Binance_wallet_spot(state, action) {
            return action.payload;
        }
    }
})

let Binance_wallet_futures = createSlice({
    name: 'Binance_wallet_futures',
    initialState: [],
    reducers: {
        change_Binance_wallet_futures(state, action) {
            return action.payload;
        }
    }
})
let Binance_openorder_futures = createSlice({
    name: 'Binance_openorder_futures',
    initialState: [],
    reducers: {
        change_Binance_openorder_futures(state, action) {
            return action.payload;
        }
    }
})
let OKX_wallet = createSlice({
    name: 'OKX_wallet',
    initialState: [],
    reducers: {
        change_OKX_wallet(state, action) {
            return action.payload;
        }
    }
})
let OKX_wallet_usdt = createSlice({
    name: 'OKX_wallet_usdt',
    initialState: [],
    reducers: {
        change_OKX_wallet_usdt(state, action) {
            return action.payload;
        }
    }
})



let Bybit_wallet = createSlice({
    name: 'Bybit_wallet',
    initialState: [],
    reducers: {
        change_Bybit_wallet(state, action) {
            return action.payload;
        }
    }
})
let Bybit_wallet_usdt = createSlice({
    name: 'Bybit_wallet_usdt',
    initialState: [],
    reducers: {
        change_Bybit_wallet_usdt(state, action) {
            return action.payload;
        }
    }
})


let ImgCheck = createSlice({
    name: 'ImgCheck',
    initialState: [],
    reducers: {
        addImg(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        }
    }
})

let checkspot = createSlice({
    name: 'checkspot',
    initialState: { value: false },
    reducers: {
        checkSpot(state, action) {
            state.value = action.payload
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: true },
    reducers: {
        checkMode(state, action) {
            localStorage.setItem('mode', action.payload ? "dark" : "light")
            state.value = action.payload
        }
    }
})

let Languages = createSlice({
    name: 'Languages',
    initialState: { value: "en-US" },
    reducers: {
        changeLanguages(state, action) {
            localStorage.setItem('language', action.payload)
            state.value = action.payload
        }
    }
})

let Alert = createSlice({
    name: 'Alert',
    initialState: ["OFF", "OFF", "OFF", "OFF"],
    reducers: {
        checkAlert(state, action) {
            let n = action.payload
            state[n.num] = n.value
            localStorage.setItem('alert', state)
        }
    }
})

let Error = createSlice({
    name: 'Error',
    initialState: { value: false },
    reducers: {
        check_Error(state, action) {
            state.value = action.payload
        }
    }
})

let Guide = createSlice({
    name: 'Guide',
    initialState: { value: false },
    reducers: {
        checkGuide(state, action) {
            state.value = action.payload
        }
    }
})

let OKX_timestamp = createSlice({
    name: 'OKX_timestamp',
    initialState: { value: "" },
    reducers: {
        checkOKX_timestamp(state, action) {
            state.value = action.payload
        }
    }
})

let Reload = createSlice({
    name: 'Reload',
    initialState: { value: false },
    reducers: {
        changeReload(state, action) {
            state.value = state.value ? false : true
        }
    }
})

let Bn_markPrice = createSlice({
    name: 'Bn_markPrice',
    initialState: [],
    reducers: {
        changeBn_markPrice(state, action) {
            return action.payload;
        }
    }
})

let OKX_markPrice = createSlice({
    name: 'OKX_markPrice',
    initialState: [],
    reducers: {
        changeOKX_markPrice(state, action) {
            return action.payload;
        }
    }
})

let Bybit_markPrice = createSlice({
    name: 'Bybit_markPrice',
    initialState: [],
    reducers: {
        changeBybit_markPrice(state, action) {
            return action.payload;
        }
    }
})

let OKX_openorder = createSlice({
    name: 'OKX_openorder',
    initialState: [],
    reducers: {
        change_OKX_openorder(state, action) {
            return action.payload;
        }
    }
})

let Bn_openorder = createSlice({
    name: 'Bn_openorder',
    initialState: [],
    reducers: {
        change_Bn_openorder(state, action) {
            return action.payload;
        }
    }
})

let Bybit_openorder = createSlice({
    name: 'Bybit_openorder',
    initialState: [],
    reducers: {
        change_Bybit_openorder(state, action) {
            return action.payload;
        }
    }
})
let By_apiKey = createSlice({
    name: 'By_apiKey',
    initialState: {
        "S_api": "",
        "S_sec": ""
    },
    reducers: {
        change_By_apiKey(state, action) {
            state.S_api = action.payload.S_api
            state.S_sec = action.payload.S_sec
        }
    }
})

let OKX_Message = createSlice({
    name: 'OKX_Message',
    initialState: { value: 0 },
    reducers: {
        changeOKX_Message(state, action) {
            state.value += 1
        }
    }
})


let Bybit_Mode = createSlice({
    name: 'Bybit_Mode',
    initialState: { value: true },
    reducers: {
        changeBybit_Mode(state, action) {
            state.value = action.payload
        }
    }
})

let OKX_Mode = createSlice({
    name: 'OKX_Mode',
    initialState: { value: true },
    reducers: {
        changeOKX_Mode(state, action) {
            state.value = action.payload
        }
    }
})

export let { checkAPI_Address } = API_Address.actions
export let { checkLogin } = login.actions
export let { changeKYC } = check_KYC.actions
export let { checkSelectType } = SelectType.actions
export let { changeWalletPage } = Wallet_Page.actions
export let { Binance_addCoinS } = Binance_coinS.actions
export let { Binance_addCoinF } = Binance_coinF.actions
export let { OKX_addCoinS } = OKX_coinS.actions
export let { OKX_addCoinF } = OKX_coinF.actions
export let { Bybit_addCoinS } = Bybit_coinS.actions
export let { Bybit_addCoinF } = Bybit_coinF.actions
export let { addCoin_OKX_S_Favorite, change_OKX_S_Favorite } = OKX_S_favorite.actions
export let { addCoin_OKX_F_Favorite, change_OKX_F_Favorite } = OKX_F_favorite.actions
export let { addCoin_Binance_S_Favorite, change_Binance_S_Favorite } = Binance_S_favorite.actions
export let { addCoin_Binance_F_Favorite, change_Binance_F_Favorite } = Binance_F_favorite.actions
export let { addCoin_Bybit_S_Favorite, change_Bybit_S_Favorite } = Bybit_S_favorite.actions
export let { addCoin_Bybit_F_Favorite, change_Bybit_F_Favorite } = Bybit_F_favorite.actions
export let { addImg } = ImgCheck.actions
export let { checkSpot } = checkspot.actions
export let { checkMode } = Darkmode.actions
export let { changeLanguages } = Languages.actions
export let { checkAlert } = Alert.actions
export let { change_Binance_position } = Binance_position.actions
export let { change_OKX_position } = OKX_position.actions
export let { change_Bybit_position } = Bybit_position.actions
export let { change_OKX_liquidation } = OKX_liquidation.actions
export let { change_Binance_ListenKey } = Binance_ListenKey.actions
export let { change_Binance_wallet_spot } = Binance_wallet_spot.actions
export let { change_Binance_wallet_futures } = Binance_wallet_futures.actions
export let { change_Binance_openorder_futures } = Binance_openorder_futures.actions
export let { change_OKX_wallet } = OKX_wallet.actions
export let { change_OKX_wallet_usdt } = OKX_wallet_usdt.actions
export let { change_Bybit_wallet } = Bybit_wallet.actions
export let { change_Bybit_wallet_usdt } = Bybit_wallet_usdt.actions
export let { check_OKX_Instruments } = OKX_Instruments.actions
export let { check_Error } = Error.actions
export let { checkGuide } = Guide.actions
export let { checkOKX_timestamp } = OKX_timestamp.actions
export let { changeReload } = Reload.actions
export let { changeBn_markPrice } = Bn_markPrice.actions
export let { changeOKX_markPrice } = OKX_markPrice.actions
export let { changeBybit_markPrice } = Bybit_markPrice.actions
export let { change_OKX_openorder } = OKX_openorder.actions
export let { change_Bn_openorder } = Bn_openorder.actions
export let { change_Bybit_openorder } = Bybit_openorder.actions
export let { change_By_apiKey } = By_apiKey.actions
export let { changeBybit_Mode } = Bybit_Mode.actions
export let { changeOKX_Mode } = OKX_Mode.actions
export let { changeOKX_Message } = OKX_Message.actions

export default configureStore({
    reducer: {
        API_Address: API_Address.reducer,
        login: login.reducer,
        check_KYC: check_KYC.reducer,
        SelectType: SelectType.reducer,
        Wallet_Page: Wallet_Page.reducer,
        Binance_coinS: Binance_coinS.reducer,
        Binance_coinF: Binance_coinF.reducer,
        OKX_coinS: OKX_coinS.reducer,
        OKX_coinF: OKX_coinF.reducer,
        Bybit_coinS: Bybit_coinS.reducer,
        Bybit_coinF: Bybit_coinF.reducer,
        OKX_S_favorite: OKX_S_favorite.reducer,
        OKX_F_favorite: OKX_F_favorite.reducer,
        Binance_S_favorite: Binance_S_favorite.reducer,
        Binance_F_favorite: Binance_F_favorite.reducer,
        Bybit_S_favorite: Bybit_S_favorite.reducer,
        Bybit_F_favorite: Bybit_F_favorite.reducer,
        ImgCheck: ImgCheck.reducer,
        checkspot: checkspot.reducer,
        Darkmode: Darkmode.reducer,
        Languages: Languages.reducer,
        Alert: Alert.reducer,
        Binance_position: Binance_position.reducer,
        OKX_position: OKX_position.reducer,
        Bybit_position: Bybit_position.reducer,
        OKX_liquidation: OKX_liquidation.reducer,
        Binance_ListenKey: Binance_ListenKey.reducer,
        Binance_wallet_spot: Binance_wallet_spot.reducer,
        Binance_wallet_futures: Binance_wallet_futures.reducer,
        Binance_openorder_futures: Binance_openorder_futures.reducer,
        OKX_wallet: OKX_wallet.reducer,
        OKX_wallet_usdt: OKX_wallet_usdt.reducer,
        Bybit_wallet: Bybit_wallet.reducer,
        Bybit_wallet_usdt: Bybit_wallet_usdt.reducer,
        OKX_Instruments: OKX_Instruments.reducer,
        Error: Error.reducer,
        Guide: Guide.reducer,
        OKX_timestamp: OKX_timestamp.reducer,
        Reload: Reload.reducer,
        Bn_markPrice: Bn_markPrice.reducer,
        OKX_markPrice: OKX_markPrice.reducer,
        Bybit_markPrice: Bybit_markPrice.reducer,
        OKX_openorder: OKX_openorder.reducer,
        Bn_openorder: Bn_openorder.reducer,
        Bybit_openorder: Bybit_openorder.reducer,
        By_apiKey: By_apiKey.reducer,
        Bybit_Mode: Bybit_Mode.reducer,
        OKX_Mode: OKX_Mode.reducer,
        OKX_Message: OKX_Message.reducer
    }
}) 