import './nav.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useRef, useState } from 'react';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType } from '../store';
import i18n from '../lang/i18n';
import { useTranslation } from "react-i18next";

function Sidebar() {
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    const [loca, setLoca] = useState("main")

    useEffect(() => {
        if (location.pathname == '/') setLoca("main")
        else if (location.pathname.includes("market")) setLoca("market")
        else if (location.pathname.includes("spot")) setLoca("spot")
        else if (location.pathname.includes("futures")) setLoca("futures")
        else if (location.pathname.includes("wallet")) setLoca("wallet")
        else setLoca("else")
    }, [location])

    const onChangeSelectType = (e) => {
        if (loca == "spot") {
            if (e === 1) navigate("/spot/binance/BTCUSDT")
            else navigate("/spot/okx/BTC-USDT")
        }
        else if (loca == "futures") {
            if (e === 1) navigate("/futures/binance/BTCUSDT")
            else navigate("/futures/okx/BTC-USDT-SWAP")
        }
        dispatch(checkSelectType(e))
    }

    return (
        <div className={`sidebar ${darkname}`}>
            <div className='sidebar-box'>
                <div className={`sidebar-box-1 ${darkname} ${SelectType === 1 ? "bn current" : ""}`} onClick={() => onChangeSelectType(1)}>
                    <img src='/img/binance_logo.png' />
                </div>
                <div className={`sidebar-box-1 ${darkname} ${SelectType === 1 ? "" : "okx current"}`} onClick={() => onChangeSelectType(0)}>
                    <img src='/img/okx_logo.png' />
                </div>
            </div>
        </div>
    )
}

export default Sidebar;