import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import axios from 'axios';
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";

import { BinanceFutures } from '../../common/C_futures/M_goex1';

function GoexOKXF1_1({ USDTSymbolF, symbol, positionData }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let dispatch = useDispatch()
    const darkname = dark ? 'dark' : "";

    const [search, setSearch] = useState("");
    const [message, setMessage] = useState([])
    const [positionList, setPositionList] = useState([])

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    let filterSymbol = USDTSymbolF.filter((p) => {
        return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    })

    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth - 40,
                    height: containerRef.current.offsetHeight - 100,
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef]);

    useEffect(() => {
        if (positionData) {
            let arr = []
            for (let i = 0; i < positionData.length; i++) {
                let sym = positionData[i].symbol.split('-')
                arr.push({
                    entryPrice: positionData[i].avgPx,
                    ccy: sym[0],
                    symbol: positionData[i].symbol,
                    pnl: positionData[i].upl,
                    ex: "okx",
                    instId: positionData[i].instId,
                    mgnMode: positionData[i].mgnMode
                })
            }
            setPositionList(arr)
        }
    }, [positionData])

    const FuturesPositionClose = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/close/position", {
                instId: p.instId,
                mgnMode: p.mgnMode,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: response?.data?.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 132, "msg": error }))
                })
        }
    }

    return (
        <div className={`futures-section18-1 ${darkname}`}>
            <Modal message={message} />
            <div className='futures-section1-box' ref={containerRef}>
                <div className={`futures-section1-box-position ${darkname}`}>
                    <div className={`futures-section1-box-1 ${darkname}`}>
                        <div className='futures-section1-box-1-1'>
                            <img src='/img/okx_logo.png' className='futures-section1-box-1-img'></img>
                            <div className='futures-section1-box-1-3'>{t('header_8')}</div>
                        </div>
                    </div>
                    <div className={`futures-section1-box-2 ${darkname}`}>
                        <input type='text' placeholder={`${t('market_0')}`} value={search} onChange={onChange} />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='futures-section1-box-2-ico' />
                    </div>
                </div>
                <BinanceFutures filterSymbol={filterSymbol} darkname={darkname} symbol={symbol} dimensions={dimensions} positionData={positionList} FuturesPositionClose={FuturesPositionClose} exchange={"okx"} />
            </div>
        </div>
    )
}

export default GoexOKXF1_1;