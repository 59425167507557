import '../../css/newMarket.css'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faStar, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin } from '../../../store';
import IMG from "../../img"
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../function"

let filter_spot = []
let filter_futures = []

function NewMarketTop({ onSelectType, USDTSymbolS, USDTSymbolF, windowtype1, Type }) {
    const { t } = useTranslation();
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        if (window.innerWidth > 1300) {
            // 스크립트가 이미 추가되었는지 확인
            let script = document.querySelector('script[src="https://static.coinstats.app/widgets/v3/cs-widget.js"]');

            const createWidget = () => {
                // 기존 위젯이 있는지 확인하고 제거
                const existingWidget = document.querySelector('cs-widget');
                if (existingWidget) {
                    existingWidget.remove();
                }

                // 새로운 위젯 생성 및 추가
                const widget = document.createElement('cs-widget');
                widget.setAttribute('type', 'fear-and-greed');
                widget.setAttribute('background', dark ? '#101014' : '#FFFFFF');
                widget.setAttribute('is-market-sentiment-visible', 'true');
                widget.setAttribute('is-last-updated-visible', 'false');
                widget.setAttribute('title-color', dark ? '#fff' : '#000000');
                widget.setAttribute('chart-indicator-one-color', '#F02934');
                widget.setAttribute('chart-indicator-two-color', '#F07D29');
                widget.setAttribute('chart-indicator-three-color', '#9ACB82');
                widget.setAttribute('chart-indicator-four-color', '#34B349');
                widget.setAttribute('subtitle-color', '#666666');
                widget.setAttribute('last-updated-color', '#666666');
                widget.setAttribute('arrow-color', '#D9D9D9');
                document.getElementById('widget-container').appendChild(widget);
            };

            if (!script) {
                // 스크립트를 동적으로 생성하여 문서에 추가
                script = document.createElement('script');
                script.async = true;
                script.src = 'https://static.coinstats.app/widgets/v3/cs-widget.js';
                document.body.appendChild(script);

                // 스크립트가 로드된 후에 위젯을 생성
                script.onload = () => {
                    createWidget();
                };
            } else {
                // 스크립트가 이미 로드된 경우 직접 위젯 생성
                createWidget();
            }

            // 컴포넌트가 언마운트될 때 스크립트를 제거하여 메모리 누수 방지
            return () => {
                const existingWidget = document.querySelector('cs-widget');
                if (existingWidget) {
                    existingWidget.remove();
                }
                // 스크립트를 제거하지 않음 (한 번 로드된 후 계속 사용)
            };
        }
    }, [windowtype1, dark]);

    filter_spot = [...USDTSymbolS].sort((a, b) => {
        if (a.priceChangePercent < b.priceChangePercent) return 1;
        if (a.priceChangePercent > b.priceChangePercent) return -1;
    });

    filter_futures = [...USDTSymbolF].sort((a, b) => {
        if (a.priceChangePercent < b.priceChangePercent) return 1;
        if (a.priceChangePercent > b.priceChangePercent) return -1;
    })

    return (
        <div className='newmarket'>
            <div className={`newmarket_top ${darkname}`}
            //  style={{ background: SelectType === 1 ? "rgba(252, 213, 53)" : SelectType === 0 ? "rgb(0, 0, 0)" : "#f7a600" }}
            >
                <div className='newmarket-top-box'>
                    <div className={SelectType === 1 ? `newmarket-top-box-1 current ${darkname}` : `newmarket-top-box-1 ${darkname}`} onClick={() => onSelectType(1)}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `newmarket-top-box-1 current ${darkname}` : `newmarket-top-box-1 ${darkname}`} onClick={() => onSelectType(0)}>{t('exchange_1')}</div>
                    <div className={SelectType === 2 ? `newmarket-top-box-1 current ${darkname}` : `newmarket-top-box-1 ${darkname}`} onClick={() => onSelectType(2)}>{t('exchange_2')}</div>
                </div>
            </div>
            {
                windowtype1
                    ?
                    <div className='newmarket-mid'>
                        <div className='newmarket-mid-box'>
                            <div style={{ position: "relative" }}>
                                <div id="widget-container" className='widget' style={{ width: "410px" }}></div>
                                <div className={`newmarket-mid-box-3 ${darkname}`}>
                                    <p>Crypto Fear and Greed Indicator</p>
                                </div>
                            </div>
                            <div className={`newmarket-mid-box-1 ${darkname}`}>
                                <div className={`newmarket-mid-box-2 ${darkname}`}>
                                    <p>Spot Top Gainners</p>
                                </div>
                                <div className='newmarket-mid-box-4'>
                                    <div className='newmarket-mid-box-4-1'>
                                        <div className='newmarket-mid-box-4-2'>Pair</div>
                                        <div className='newmarket-mid-box-4-3'>Price</div>
                                        <div className='newmarket-mid-box-4-4'>24H Change</div>
                                    </div>
                                    {
                                        filter_spot && filter_spot.length > 0
                                            ?
                                            filter_spot.map(function (a, i) {
                                                if (i < 6) {
                                                    return (
                                                        <NewMarketTopItem key={i} item={a} darkname={darkname} type={"spot"} Type={Type} />
                                                    )
                                                }
                                            })
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className={`newmarket-mid-box-1 ${darkname}`}>
                                <div className={`newmarket-mid-box-2 ${darkname}`}>
                                    <p>Futures Top Gainners</p>
                                </div>
                                <div className='newmarket-mid-box-4'>
                                    <div className='newmarket-mid-box-4-1'>
                                        <div className='newmarket-mid-box-4-2'>Pair</div>
                                        <div className='newmarket-mid-box-4-3'>Price</div>
                                        <div className='newmarket-mid-box-4-4'>24H Change</div>
                                    </div>
                                    {
                                        filter_futures && filter_futures.length > 0
                                            ?
                                            filter_futures.map(function (a, i) {
                                                if (i < 6) {
                                                    return (
                                                        <NewMarketTopItem key={i} item={a} darkname={darkname} type={"futures"} Type={Type} />
                                                    )
                                                }
                                            })
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    )
}

function NewMarketTopItem({ item, darkname, type, Type }) {
    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    return (
        <Link to={`/${type}/${Type}/${item.symbol}`} className="tr" style={{ textDecoration: "none" }} >
            <div className='newmarket-mid-box-4-5'>
                <div className='newmarket-mid-box-4-2'>
                    <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className={`${darkname}`}></img>
                    <span>{item.ccy}{type == "spot" ? "/USDT" : "USDT"}</span>
                </div>
                <div className='newmarket-mid-box-4-3'>{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                <div className={`newmarket-mid-box-4-4 ${Number(item.priceChangePercent) >= 0 ? "green" : "red"}`}>
                    {Number(item.priceChangePercent).toFixed(2)}%</div>
            </div >
        </Link>
    )
}

export default NewMarketTop;