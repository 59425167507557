import './css/dashboard.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash, faCaretUp, faXmark, faAngleRight, faGift } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { changeOKX_Message, checkLogin, checkSelectType, check_Error } from '../store';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import base64 from 'base-64';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import SumsubWebSdk from '@sumsub/websdk-react'
import Country from './country'
import Page from './Page';
import Message from './message/message';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "./common/function"
import Modal from './message/modal';
import Namu_Message from './message/namu_message';

import { Pagination, FreeMode, Scrollbar, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Dashboard() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })

    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [mydata, setMydata] = useState([])
    const [resetMydata, setResetMydata] = useState(false)
    const [refcode, setRefcode] = useState("")
    const [check_bnKyc, setCheck_bnKyc] = useState(false)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else {
            if (location.state.num === 3 && check_KYC && check_KYC.bnKyc === 0 && check_KYC.etcKyc === 0) {
                setCheck_bnKyc(true)
                setTab(3)
            }
            else {
                setCheck_bnKyc(false)
                setTab(location.state.num)
            }
        }
    }, [location, check_KYC])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 1080) {
                setSlidesPerView(1);
            }
            else {
                setSlidesPerView(0);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 화면 크기에 따른 슬라이드 수 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            dispatch(checkLogin(true));
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
                setRefcode(JSON.parse(dec).referralCode)
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    useEffect(() => {
        MyProfile()
    }, [resetMydata])

    const MyProfile = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/info", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setMydata(response.data.data)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 28, "msg": error }))
            })
        }
    }

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, [API_Address]);

    return (
        <div className={`dashboard ${darkname}`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            <div className='history-container' style={{ filter: check_bnKyc ? "blur(5px)" : "" }}>
                <div className='history-wrap'>
                    <div className='history-left'>
                        {
                            slidesPerView === 0
                                ?
                                <ul>
                                    <li>
                                        <Link to={`/dashboard`} state={{ num: 0 }} >
                                            <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(0)}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/profile.png' style={{ width: "14px" }} />
                                                    {t('header_44')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/dashboard`} state={{ num: 1 }} >
                                            <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(1)}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/security.png' style={{ width: "14px" }} />
                                                    {t('header_46')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/dashboard`} state={{ num: 2 }} >
                                            <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(2)}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/self_referral.png' style={{ width: "14px" }} />
                                                    {t('header_48')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        {
                                            check_KYC && check_KYC.bnKyc === 0 && check_KYC.etcKyc === 0
                                                ?
                                                <Link to={`/dashboard`} state={{ num: 3 }} >
                                                    <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setCheck_bnKyc(true); setTab(3) }}>
                                                        <div className='history-tit-box'>
                                                            <img src='/img/icon/referral_program.png' style={{ width: "14px" }} />
                                                            {t('header_50')}
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                <Link to={`/dashboard`} state={{ num: 3 }} >
                                                    <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(3)}>
                                                        <div className='history-tit-box'>
                                                            <img src='/img/icon/referral_program.png' style={{ width: "14px" }} />
                                                            {t('header_50')}
                                                        </div>
                                                    </div>
                                                </Link>
                                        }
                                    </li> */}
                                    {/* <li>
                                        <Link to={`/dashboard`} state={{ num: 4 }} >
                                            <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(4)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 25 25">
                                                        <g>
                                                            <path d="M14.8,8.35l1.94-1.94c.21-.21.57-.14.69.13l.8,1.94c-1.27-.21-2.42-.23-3.43-.13Z" />
                                                            <path d="M14.43,15.59c-2.37,1.15-4.3,2.1-5.62,2.97l-2.47-1.02c-.24-.1-.3-.41-.12-.6l5.3-5.29,1.96-1.96c1.37-.32,3.13-.43,5.27.01l1.24,2.97c-1.55.97-3.81,2.07-5.55,2.92Z" />
                                                            <path d="M17.5,19.33c-1.14.33-2.63.76-4.1,1.14l-3.33-1.38c1.5-.88,3.49-1.84,4.84-2.49,2.27-1.1,4.13-2.03,5.49-2.87l.8,1.95.99,2.38c-1.28.3-2.84.75-4.7,1.28Z" />
                                                            <path d="M23.75,21.8c-.67.69-1.38,1.44-1.74,2.23l-6.48-2.68-.42-.18c.85-.23,1.75-.49,2.71-.77,1.45-.42,3.27-.95,4.81-1.31l1.12,2.72Z" />
                                                            <path d="M23.98,24.84l-.96-.39c.21-.45.63-.95,1.19-1.53l.49,1.19c.19.46-.27.92-.72.72Z" />
                                                        </g>
                                                        <path d="M2.43,10.36l.29.1c.32.11.68.05.96-.15l.25-.18c.69-.52,1.68-.02,1.67.85v.31c0,.34.16.67.44.86l.25.18c.71.5.53,1.59-.29,1.85l-.29.09c-.33.1-.58.36-.69.69l-.09.29c-.26.82-1.35,1-1.85.29l-.18-.25c-.2-.28-.52-.44-.86-.44h-.31c-.86.01-1.37-.97-.85-1.66l.18-.25c.21-.27.26-.63.15-.96l-.1-.29c-.28-.82.5-1.6,1.32-1.32Z" />
                                                        <path d="M6.58,1.73l.07.3c.07.34.31.61.62.74l.28.12c.8.33.88,1.43.13,1.86l-.27.16c-.3.17-.49.48-.51.82l-.02.31c-.06.86-1.09,1.28-1.73.7l-.23-.2c-.26-.23-.61-.32-.94-.23l-.3.07c-.84.21-1.55-.64-1.2-1.43l.12-.28c.14-.31.11-.68-.07-.97l-.16-.26c-.45-.73.13-1.67.99-1.59l.31.03c.34.03.68-.1.9-.36l.2-.23c.56-.66,1.63-.39,1.81.45Z" />
                                                        <path d="M11.31,1.68l.3-.06c.34-.07.62-.29.76-.61l.12-.28c.34-.79,1.45-.85,1.87-.09l.15.27c.17.3.47.5.81.53l.31.03c.86.08,1.25,1.11.67,1.75l-.21.23c-.23.25-.33.6-.25.94l.07.3c.19.84-.67,1.54-1.46,1.17l-.28-.13c-.31-.14-.67-.13-.97.05l-.26.16c-.74.44-1.67-.16-1.57-1.02l.04-.31c.04-.34-.09-.68-.35-.91l-.23-.2c-.65-.57-.36-1.64.49-1.8Z" />
                                                        <path d="M12.37,10.27l-.87-.87-.09-.08s-.05-.04-.08-.06c-.2-.27-.05-.6.09-.79l.82-.82-1.85-1.85-.87.87-.08.09c-.36.43-.67,1.07-.78,1.77-.56.09-1.13.34-1.65.86l-1,1,1.85,1.85,1-1c.22-.22.49-.15.7-.04l.96.96,1.85-1.85-.02-.02.03-.03Z" />
                                                    </svg>
                                                    {t('header_52')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                        <div className='history-txt history-tit-coming'>
                                            <div className='history-tit-box'>
                                                <svg viewBox="0 0 343.9 343.9">
                                                    <path d="M343.8,77.5c0-5.2-0.3-10.5-1.3-15.6c-7.4-37-37.8-61.7-75.7-61.8C203.6,0,140.3,0,77.1,0.1c-5.2,0-10.5,0.3-15.6,1.4
                            C24.6,9,0.1,39.3,0,77.1C0,140.3,0,203.6,0.1,266.8c0,5.2,0.3,10.5,1.4,15.6c7.6,36.8,37.8,61.3,75.6,61.4c31.6,0.1,63.2,0,94.9,0
                            c31.2,0,62.5,0.1,93.7-0.1c5.5,0,11.1-0.2,16.4-1.3c37.1-7.4,61.8-37.8,61.8-76.1C343.9,203.4,343.9,140.5,343.8,77.5z M110,209.2
                            c7.4,7.5,5.9,19-2.9,24c-5.8,3.3-12.8,2.5-17.7-2.3c-16.2-16.1-32.4-32.2-48.5-48.5c-5.9-6-5.9-14.7-0.1-20.7
                            c16-16.3,32.2-32.4,48.5-48.5c6.1-6,15.3-5.7,21.1,0.1c5.8,5.8,6,15,0,21.1c-12.3,12.5-24.8,24.7-37.7,37.6
                            C85.5,184.7,97.8,196.8,110,209.2z M209.7,105.9c-15.7,47.3-31.4,94.5-47.1,141.7c-2.4,7.3-8.1,11.6-14.8,11.6
                            c-10.4-0.1-17.3-10.1-13.9-20.6c4.1-12.8,8.5-25.5,12.7-38.2c11.6-34.8,23.2-69.6,34.8-104.4c2.5-7.5,9-12,15.9-11.3
                            c7.2,0.7,12.9,6.1,13.5,13.4C211,100.7,210.5,103.5,209.7,105.9z M303.2,182c-16.2,16.5-32.6,32.9-49.1,49.1
                            c-5.9,5.8-15.1,5.2-20.7-0.6c-5.8-5.9-5.8-15,0.1-21.1c11.2-11.4,22.5-22.6,33.8-33.9c1.1-1.1,2.4-1.9,4.1-3.2
                            c-12.9-12.9-25-25-37.1-37c-4.5-4.5-6.4-9.7-4.7-15.8c2.9-10.3,16-14.7,23.7-7.3c17.1,16.3,33.7,33.2,50.2,50.1
                            C308.9,167.8,308.7,176.5,303.2,182z"></path>
                                                </svg>
                                                API Management
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='history-txt history-tit-coming'>
                                            <div className='history-tit-box'>
                                                <svg viewBox="0 0 425.3 426">
                                                    <path d="M133.3,0c3.3,0,6.7,0,10,0c1.5,0.5,3,1,4.5,1.4c12.9,3.9,21.6,14.8,22.7,28.7c1,12.4-6.5,25-18.2,30.7
                                c-1.2,0.6-2.9,1.8-2.9,2.7c-0.2,7.1-0.1,14.3-0.1,21.6c42.8,0,85.1,0,127.9,0c0-6.6-0.1-12.9,0.1-19.2c0.1-2.7-0.8-4-3.3-5.3
                                c-11.8-5.9-18.2-15.8-18-29c0.1-13.2,6.7-22.8,18.6-28.7c2.5-1.2,5.3-2,7.9-3c3.3,0,6.7,0,10,0c2.8,0.9,5.7,1.5,8.4,2.8
                                c11.9,5.7,18.6,15.3,18.8,28.5c0.3,13.4-6.2,23.2-18,29.5c-1.3,0.7-3,2-3.1,3.1c-0.3,7-0.1,14-0.1,21.6c4.5,0,8.7-0.2,12.8,0
                                c29,1.4,51.1,24.3,51.1,53.4c0.1,60,0.1,120.1,0,180.1c0,30.5-23.6,53.8-54.1,53.8c-18.6,0-37.2-0.1-55.7,0.1
                                c-2.6,0-5.4,0.9-7.6,2.2c-9.7,5.8-19.3,11.9-28.9,17.9c-16.4,10.3-32.8,20.5-49.3,30.8c-5.7,3.5-11.6,2.9-14.7-2
                                c-1.5-2.3-2.3-5.4-2.3-8.1c-0.2-13.5-0.1-27.1-0.1-41c-10.3,0-20.2,0-30.2,0c-31.7,0-54.8-23-54.9-54.7c0-59.3,0-118.7,0-178
                                c0-31.7,23-54.6,54.8-54.7c2.9,0,5.7,0,9,0c0-6.8-0.1-13.2,0.1-19.5c0.1-2.7-0.8-4-3.3-5.2c-10.8-5.3-17-14.2-17.9-26.1
                                c-1-12.5,4.1-22.5,14.6-29.3C124.9,2.9,129.3,1.7,133.3,0z"></path>
                                                    <path d="M0,245.4c0-14.4,0-28.8,0-43.3c1.9-22.4,17.1-33.8,42.4-31.4c0,35.2,0,70.5,0,105.7C19.3,280.4,2,267.7,0,245.4z"></path>
                                                    <path d="M383.6,277.3c0-35.9,0-71.1,0-106.3c20.1-3.3,35.7,5.1,41,22.6c0.5,1.7,0.7,3.5,0.7,5.3c0.1,15.8,0,31.6,0,47.4
                                c0,14.8-10.6,27.6-25.2,29.9C394.8,277.1,389.4,276.9,383.6,277.3z"></path>
                                                </svg>
                                                My AI Management
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                                :
                                <div style={{ position: "relative" }}>
                                    <div className={`box-pre ${darkname}`}></div>
                                    <div className={`box-next ${darkname}`}></div>
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={10}
                                        initialSlide={tab}
                                        freeMode={true}
                                        centeredSlides={true}
                                        slideToClickedSlide={true}
                                        centeredSlidesBounds={true}
                                        modules={[FreeMode, Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <Link to={`/dashboard`} state={{ num: 0 }} >
                                                <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(0)}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/profile.png' style={{ width: "14px" }} />
                                                        {t('header_44')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/dashboard`} state={{ num: 1 }} >
                                                <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(1)}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/security.png' style={{ width: "14px" }} />
                                                        {t('header_46')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/dashboard`} state={{ num: 2 }} >
                                                <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(2)}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/self_referral.png' style={{ width: "14px" }} />
                                                        {t('header_48')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            {
                                                check_KYC && check_KYC.bnKyc === 0 && check_KYC.etcKyc === 0
                                                    ?
                                                    <Link to={`/dashboard`} state={{ num: 3 }} >
                                                        <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setCheck_bnKyc(true); setTab(3) }}>
                                                            <div className='history-tit-box'>
                                                                <img src='/img/icon/referral_program.png' style={{ width: "14px" }} />
                                                                {t('header_50')}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link to={`/dashboard`} state={{ num: 3 }} >
                                                        <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(3)}>
                                                            <div className='history-tit-box'>
                                                                <img src='/img/icon/referral_program.png' style={{ width: "14px" }} />
                                                                {t('header_50')}
                                                            </div>
                                                        </div>
                                                    </Link>
                                            }
                                        </SwiperSlide> */}
                                        {/* <SwiperSlide>
                                            <Link to={`/dashboard`} state={{ num: 4 }} >
                                                <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(4)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 25 25">
                                                            <g>
                                                                <path d="M14.8,8.35l1.94-1.94c.21-.21.57-.14.69.13l.8,1.94c-1.27-.21-2.42-.23-3.43-.13Z" />
                                                                <path d="M14.43,15.59c-2.37,1.15-4.3,2.1-5.62,2.97l-2.47-1.02c-.24-.1-.3-.41-.12-.6l5.3-5.29,1.96-1.96c1.37-.32,3.13-.43,5.27.01l1.24,2.97c-1.55.97-3.81,2.07-5.55,2.92Z" />
                                                                <path d="M17.5,19.33c-1.14.33-2.63.76-4.1,1.14l-3.33-1.38c1.5-.88,3.49-1.84,4.84-2.49,2.27-1.1,4.13-2.03,5.49-2.87l.8,1.95.99,2.38c-1.28.3-2.84.75-4.7,1.28Z" />
                                                                <path d="M23.75,21.8c-.67.69-1.38,1.44-1.74,2.23l-6.48-2.68-.42-.18c.85-.23,1.75-.49,2.71-.77,1.45-.42,3.27-.95,4.81-1.31l1.12,2.72Z" />
                                                                <path d="M23.98,24.84l-.96-.39c.21-.45.63-.95,1.19-1.53l.49,1.19c.19.46-.27.92-.72.72Z" />
                                                            </g>
                                                            <path d="M2.43,10.36l.29.1c.32.11.68.05.96-.15l.25-.18c.69-.52,1.68-.02,1.67.85v.31c0,.34.16.67.44.86l.25.18c.71.5.53,1.59-.29,1.85l-.29.09c-.33.1-.58.36-.69.69l-.09.29c-.26.82-1.35,1-1.85.29l-.18-.25c-.2-.28-.52-.44-.86-.44h-.31c-.86.01-1.37-.97-.85-1.66l.18-.25c.21-.27.26-.63.15-.96l-.1-.29c-.28-.82.5-1.6,1.32-1.32Z" />
                                                            <path d="M6.58,1.73l.07.3c.07.34.31.61.62.74l.28.12c.8.33.88,1.43.13,1.86l-.27.16c-.3.17-.49.48-.51.82l-.02.31c-.06.86-1.09,1.28-1.73.7l-.23-.2c-.26-.23-.61-.32-.94-.23l-.3.07c-.84.21-1.55-.64-1.2-1.43l.12-.28c.14-.31.11-.68-.07-.97l-.16-.26c-.45-.73.13-1.67.99-1.59l.31.03c.34.03.68-.1.9-.36l.2-.23c.56-.66,1.63-.39,1.81.45Z" />
                                                            <path d="M11.31,1.68l.3-.06c.34-.07.62-.29.76-.61l.12-.28c.34-.79,1.45-.85,1.87-.09l.15.27c.17.3.47.5.81.53l.31.03c.86.08,1.25,1.11.67,1.75l-.21.23c-.23.25-.33.6-.25.94l.07.3c.19.84-.67,1.54-1.46,1.17l-.28-.13c-.31-.14-.67-.13-.97.05l-.26.16c-.74.44-1.67-.16-1.57-1.02l.04-.31c.04-.34-.09-.68-.35-.91l-.23-.2c-.65-.57-.36-1.64.49-1.8Z" />
                                                            <path d="M12.37,10.27l-.87-.87-.09-.08s-.05-.04-.08-.06c-.2-.27-.05-.6.09-.79l.82-.82-1.85-1.85-.87.87-.08.09c-.36.43-.67,1.07-.78,1.77-.56.09-1.13.34-1.65.86l-1,1,1.85,1.85,1-1c.22-.22.49-.15.7-.04l.96.96,1.85-1.85-.02-.02.03-.03Z" />
                                                        </svg>
                                                        {t('header_52')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide> */}
                                        {/* <SwiperSlide>
                                            <div className='history-txt history-tit-coming'>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 343.9 343.9">
                                                        <path d="M343.8,77.5c0-5.2-0.3-10.5-1.3-15.6c-7.4-37-37.8-61.7-75.7-61.8C203.6,0,140.3,0,77.1,0.1c-5.2,0-10.5,0.3-15.6,1.4
                            C24.6,9,0.1,39.3,0,77.1C0,140.3,0,203.6,0.1,266.8c0,5.2,0.3,10.5,1.4,15.6c7.6,36.8,37.8,61.3,75.6,61.4c31.6,0.1,63.2,0,94.9,0
                            c31.2,0,62.5,0.1,93.7-0.1c5.5,0,11.1-0.2,16.4-1.3c37.1-7.4,61.8-37.8,61.8-76.1C343.9,203.4,343.9,140.5,343.8,77.5z M110,209.2
                            c7.4,7.5,5.9,19-2.9,24c-5.8,3.3-12.8,2.5-17.7-2.3c-16.2-16.1-32.4-32.2-48.5-48.5c-5.9-6-5.9-14.7-0.1-20.7
                            c16-16.3,32.2-32.4,48.5-48.5c6.1-6,15.3-5.7,21.1,0.1c5.8,5.8,6,15,0,21.1c-12.3,12.5-24.8,24.7-37.7,37.6
                            C85.5,184.7,97.8,196.8,110,209.2z M209.7,105.9c-15.7,47.3-31.4,94.5-47.1,141.7c-2.4,7.3-8.1,11.6-14.8,11.6
                            c-10.4-0.1-17.3-10.1-13.9-20.6c4.1-12.8,8.5-25.5,12.7-38.2c11.6-34.8,23.2-69.6,34.8-104.4c2.5-7.5,9-12,15.9-11.3
                            c7.2,0.7,12.9,6.1,13.5,13.4C211,100.7,210.5,103.5,209.7,105.9z M303.2,182c-16.2,16.5-32.6,32.9-49.1,49.1
                            c-5.9,5.8-15.1,5.2-20.7-0.6c-5.8-5.9-5.8-15,0.1-21.1c11.2-11.4,22.5-22.6,33.8-33.9c1.1-1.1,2.4-1.9,4.1-3.2
                            c-12.9-12.9-25-25-37.1-37c-4.5-4.5-6.4-9.7-4.7-15.8c2.9-10.3,16-14.7,23.7-7.3c17.1,16.3,33.7,33.2,50.2,50.1
                            C308.9,167.8,308.7,176.5,303.2,182z"></path>
                                                    </svg>
                                                    API Management
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='history-txt history-tit-coming'>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 425.3 426">
                                                        <path d="M133.3,0c3.3,0,6.7,0,10,0c1.5,0.5,3,1,4.5,1.4c12.9,3.9,21.6,14.8,22.7,28.7c1,12.4-6.5,25-18.2,30.7
                                c-1.2,0.6-2.9,1.8-2.9,2.7c-0.2,7.1-0.1,14.3-0.1,21.6c42.8,0,85.1,0,127.9,0c0-6.6-0.1-12.9,0.1-19.2c0.1-2.7-0.8-4-3.3-5.3
                                c-11.8-5.9-18.2-15.8-18-29c0.1-13.2,6.7-22.8,18.6-28.7c2.5-1.2,5.3-2,7.9-3c3.3,0,6.7,0,10,0c2.8,0.9,5.7,1.5,8.4,2.8
                                c11.9,5.7,18.6,15.3,18.8,28.5c0.3,13.4-6.2,23.2-18,29.5c-1.3,0.7-3,2-3.1,3.1c-0.3,7-0.1,14-0.1,21.6c4.5,0,8.7-0.2,12.8,0
                                c29,1.4,51.1,24.3,51.1,53.4c0.1,60,0.1,120.1,0,180.1c0,30.5-23.6,53.8-54.1,53.8c-18.6,0-37.2-0.1-55.7,0.1
                                c-2.6,0-5.4,0.9-7.6,2.2c-9.7,5.8-19.3,11.9-28.9,17.9c-16.4,10.3-32.8,20.5-49.3,30.8c-5.7,3.5-11.6,2.9-14.7-2
                                c-1.5-2.3-2.3-5.4-2.3-8.1c-0.2-13.5-0.1-27.1-0.1-41c-10.3,0-20.2,0-30.2,0c-31.7,0-54.8-23-54.9-54.7c0-59.3,0-118.7,0-178
                                c0-31.7,23-54.6,54.8-54.7c2.9,0,5.7,0,9,0c0-6.8-0.1-13.2,0.1-19.5c0.1-2.7-0.8-4-3.3-5.2c-10.8-5.3-17-14.2-17.9-26.1
                                c-1-12.5,4.1-22.5,14.6-29.3C124.9,2.9,129.3,1.7,133.3,0z"></path>
                                                        <path d="M0,245.4c0-14.4,0-28.8,0-43.3c1.9-22.4,17.1-33.8,42.4-31.4c0,35.2,0,70.5,0,105.7C19.3,280.4,2,267.7,0,245.4z"></path>
                                                        <path d="M383.6,277.3c0-35.9,0-71.1,0-106.3c20.1-3.3,35.7,5.1,41,22.6c0.5,1.7,0.7,3.5,0.7,5.3c0.1,15.8,0,31.6,0,47.4
                                c0,14.8-10.6,27.6-25.2,29.9C394.8,277.1,389.4,276.9,383.6,277.3z"></path>
                                                    </svg>
                                                    My AI Management
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                        }
                    </div>
                    {
                        tab === 0
                            ?
                            <Profile darkname={darkname} dark={dark} mydata={mydata} setResetMydata={setResetMydata} />
                            :
                            tab === 1
                                ?
                                <Security darkname={darkname} dark={dark} mydata={mydata} setResetMydata={setResetMydata} />
                                :
                                tab === 2
                                    ?
                                    <RewardProgram darkname={darkname} dark={dark} SelectType={SelectType} />
                                    :
                                    tab === 3
                                        ?
                                        check_bnKyc
                                            ?
                                            <Profile darkname={darkname} dark={dark} mydata={mydata} setResetMydata={setResetMydata} />
                                            :
                                            <FiendInvitation darkname={darkname} dark={dark} refcode={refcode} />
                                        :
                                        <Event darkname={darkname} dark={dark} />
                    }
                </div>
            </div>
            {
                check_bnKyc
                    ?
                    <div className='spot-kyc-modal'>
                        <div className={`spot-kyc-modal-1 ${darkname}`}>
                            <div className='spot-kyc-modal-text'>{t('message_4')}<br />{t('message_5')}</div>
                            <Link to={`/dashboard`} state={{ num: 1 }}>
                                <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    )
}

function Profile({ darkname, dark, mydata, setResetMydata }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [btn, setBtn] = useState(false)
    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)
    const [name, setName] = useState('')
    const [nameOK, setNameOK] = useState(false);
    const [passwordOK, setPasswordOK] = useState(false);
    const [nameCharacters, setNameCharacters] = useState(false);
    const [namespecialCharacter, setNamespecialCharacter] = useState(false);
    const [prePassword, setPrePassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [googleOTP, setGoogleOTP] = useState("")
    const [logindate, setLogindate] = useState("")

    const [minCharacters, setMinCharacters] = useState(false);
    const [leastLetter, setLeastLetter] = useState(false);
    const [upperLetter, setUpperLetter] = useState(false);
    const [leastNumber, setleastNumber] = useState(false);
    const [specialCharacter, setspecialCharacter] = useState(false);

    const [isToggled, setIsToggled] = useState(false);
    const [isToggled1, setIsToggled1] = useState(false);
    const [eye, setEye] = useState(false)
    const [eye1, setEye1] = useState(false)

    const [phone, setPhone] = useState('82');
    const [tel, setTel] = useState('')
    const [email, setEmail] = useState('')
    const [digit, setDigit] = useState('')
    const [type, setType] = useState(false)

    const [check, setCheck] = useState(false)
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)

    const [checkname, setCheckname] = useState(false)
    const [checkemail, setCheckEmail] = useState(false);
    const [checktel, setCheckTel] = useState(false);
    const [checkpassword, setCheckPassword] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])
    const [buyModal, setBuyModal] = useState(false);

    const toggleButton = () => {
        setIsToggled(!isToggled);
    };

    const toggleButton1 = () => {
        setIsToggled1(!isToggled1);
    };

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const passwordRegEx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?'":{}|<>`;/])[A-Za-z\d!@#$%^&*(),.?'":{}|<>`;/]{6,15}$/;
    const nameRegEx = /^[a-zA-Z0-9]{2,10}$/;
    const regex = /[!@#$%^&*(),.?'":{}|<>`;/]/;
    const regex1 = /[A-Z]/;
    const regex2 = /^[1-9]\d*$/;
    const regex3 = /[a-z]/;
    const regex4 = /[0-9]/;
    const regex5 = /^[1-9][0-9]{8,10}$/;

    const onChangePrePassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, "")
        setPrePassword(onlyNumber)
    }

    const onChangeNewPassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, "")
        setNewPassword(onlyNumber)

        if (onlyNumber && onlyNumber.length >= 6 && onlyNumber.length <= 15) setMinCharacters(true)
        else setMinCharacters(false)

        if (regex3.test(onlyNumber)) setLeastLetter(true)
        else setLeastLetter(false)

        if (regex1.test(onlyNumber)) setUpperLetter(true)
        else setUpperLetter(false)

        if (regex4.test(onlyNumber)) setleastNumber(true)
        else setleastNumber(false)

        if (regex.test(onlyNumber)) setspecialCharacter(true)
        else setspecialCharacter(false)

        passwordCheck(onlyNumber)
    }

    const onChangeGoogelOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setGoogleOTP(onlyNumber)
    }

    const onChangeName = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setName(onlyNumber)

        if (onlyNumber && onlyNumber.length >= 2 && onlyNumber.length <= 10) setNameCharacters(true)
        else setNameCharacters(false)

        if (regex.test(onlyNumber)) setNamespecialCharacter(false)
        else setNamespecialCharacter(true)

        if (onlyNumber == "") setNamespecialCharacter(false)

        nameCheck(onlyNumber)
    }

    const nameCheck = (username) => {
        if (nameRegEx.test(username)) setCheckname(true)
        else setCheckname(false)
    }

    const passwordCheck = (username) => {
        if (passwordRegEx.test(username)) setCheckPassword(true)
        else setCheckPassword(false)
    }

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
        emailCheck(onlyNumber)
    }

    const emailCheck = (username) => {
        if (emailRegEx.test(username)) setCheckEmail(true)
        else setCheckEmail(false);
    }

    const onChangeDigit = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setDigit(onlyNumber);
    }

    const onChangeTel = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setTel(onlyNumber);
        telCheck(onlyNumber)
    }

    const telCheck = (username) => {
        if (regex5.test(username)) setCheckTel(true)
        else setCheckTel(false);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const nameMouseEnter = () => {
        setNameOK(true);
    };

    const nameMouseLeave = () => {
        setNameOK(false);
    };

    const passwordMouseEnter = () => {
        setPasswordOK(true);
    };

    const passwordMouseLeave = () => {
        setPasswordOK(false);
    };


    const onSubmitNicName = async () => {
        setSpinner(true)
        if (checkname) {
            let objString = localStorage.getItem('token');
            if (objString) {
                let obj = JSON.parse(objString)
                let jwt = obj.value;
                await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/change/info", {
                    type: '01',
                    nickname: name
                }, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setResetMydata(e => !e)
                        setBtn(false)
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_44') })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                    if (API_Address == "rf") setBuyModal(true)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 29, "msg": error }))
                })
            }
        }
        else {
            setMessage({ type: "binance", code: -1, msg: `${t('message_22')}` })
            if (API_Address == "rf") setBuyModal(true)
        }
        setSpinner(false)
    }

    const onSubmitPassword = async () => {
        if (checkpassword) {
            let objString = localStorage.getItem('token');
            if (objString) {
                let obj = JSON.parse(objString)
                let jwt = obj.value;
                await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/change/info", {
                    type: '02',
                    password: newPassword,
                    oldPassword: prePassword
                }, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setResetMydata(e => !e)
                        setBtn1(false)
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_45') })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                    if (API_Address == "rf") setBuyModal(true)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 30, "msg": error }))
                })
            }
        }
        else {
            setMessage({ type: "binance", code: -1, msg: `${t('message_23')}` })
            if (API_Address == "rf") setBuyModal(true)
        }
        setSpinner(false)
    }

    const onSubmitMobile = async () => {
        if (checktel) {
            let objString = localStorage.getItem('token');
            if (objString) {
                let obj = JSON.parse(objString)
                let jwt = obj.value;
                await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/mobile", {
                    mobile: tel,
                    countryCode: phone,
                }, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setResetMydata(e => !e)
                        setBtn3(false)
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_46') })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                    if (API_Address == "rf") setBuyModal(true)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 31, "msg": error }))
                })
            }
        }
        else {
            setMessage({ type: "binance", code: -1, msg: `${t('message_24')}` })
            if (API_Address == "rf") setBuyModal(true)
        }
        setSpinner(false)
    }

    const onSubmitEmail = async () => {
        if (checkemail) {
            let objString = localStorage.getItem('token');
            if (objString) {
                let obj = JSON.parse(objString)
                let jwt = obj.value;
                await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/email", {
                    email: email
                }, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setResetMydata(e => !e)
                        setBtn2(false)
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_47') })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                    if (API_Address == "rf") setBuyModal(true)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 32, "msg": error }))
                })
            }
        }
        else {
            setMessage({ type: "binance", code: -1, msg: `${t('message_25')}` })
            if (API_Address == "rf") setBuyModal(true)
        }
        setSpinner(false)
    }

    const onSendCertifyCheck = () => {
        if (type) {
            if (checkemail) {
                onSendCertify()
            }
            else {
                setMessage({ type: "binance", code: -1, msg: `${t('message_25')}` })
                if (API_Address == "rf") setBuyModal(true)
            }
        }
        else {
            if (checktel) {
                onSendCertify()
            }
            else {
                setMessage({ type: "binance", code: -1, msg: `${t('message_24')}` })
                if (API_Address == "rf") setBuyModal(true)
            }
        }
    }

    const onSendCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/get/certify", {
                params: {
                    type: type ? "email" : "mobile",
                    id: mydata.uid,
                    email: type ? email : "",
                    mobile: type ? "" : phone + tel,
                    form: type ? "email" : "mobile",
                },
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                setMessage({ type: "binance", code: 0, msg: `${t('message_26')}` })
                if (API_Address == "rf") setBuyModal(true)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 33, "msg": error }))
            })
        }
    }

    const onSubmitCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/check/certify", {
                params: {
                    id: mydata.uid,
                    number: digit,
                    form: type ? "email" : "mobile",
                    email: type ? email : "",
                    mobile: type ? "" : phone + tel,
                },
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    setCheck1(true)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    if (API_Address == "rf") setBuyModal(true)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 34, "msg": error }))
            })
        }
    }

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                headers: { Authorization: `Bearer ${jwt}` },
                params: { otp: googleOTP }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    setCheck(true)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_42') })
                    if (API_Address == "rf") setBuyModal(true)
                    setCheck(false)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 35, "msg": error }))
            })
        }
    }

    const CheckGoogleOTpassword = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                headers: { Authorization: `Bearer ${jwt}` },
                params: { otp: googleOTP }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    setCheck2(true)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_42') })
                    if (API_Address == "rf") setBuyModal(true)
                    setCheck2(false)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 36, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (check) onSubmitCertify()
    }, [check])

    useEffect(() => {
        if (check && check1) {
            if (type) onSubmitEmail()
            else onSubmitMobile()
            setCheck(false)
            setCheck1(false)
        }
    }, [check1])

    useEffect(() => {
        if (check2) {
            onSubmitPassword()
            setCheck2(false)
        }
    }, [check2])

    let arr = [1, 2, 3, 4, 5]

    useEffect(() => {
        if (mydata.last_access_date) {
            const date = UTCTimestamp(new Date(mydata.last_access_date))
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
            setLogindate(formattedDate)
        }
    }, [mydata])

    return (
        <div className='history-right'>
            {
                API_Address == "dev"
                    ?
                    <Modal message={message} />
                    :
                    <Namu_Message message={message} buyModal={buyModal} setBuyModal={setBuyModal} res={0} />
            }
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal' style={{ zIndex: "10001" }}>
                        <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_44')}</div>
                <p className='history-right-top-2'>{t('header_45')}</p>
            </div>
            <div className='dashboard-right-bottom'>
                <div className={`dashboard-right-bottom-1 ${darkname}`}>
                    <div className='dashboard-right-bottom-1-1'>
                        <div className='dashboard-right-bottom-1-2'>
                            <div className='dashboard-right-bottom-1-3'>
                                <div className='dashboard-right-bottom-1-4'></div>
                            </div>
                        </div>
                        <div className='dashboard-right-bottom-1-2'>
                            <div className='dashboard-right-bottom-1-5'>
                                <div>{t('dashboard_0')}</div>
                                <div>{mydata.nickname}</div>
                            </div>
                            <div className='dashboard-right-bottom-1-5' style={{ marginTop: "20px" }}>
                                <div>{t('dashboard_1')}</div>
                                <div>{mydata.uid}</div>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom-2'>
                        <div className='dashboard-right-bottom-2-1'>
                            <div className='dashboard-right-bottom-2-2'>{t('dashboard_2')}</div>
                            <div className='dashboard-right-bottom-2-3'>{mydata.access_ip}</div>
                        </div>
                        <div className='dashboard-right-bottom-2-1'>
                            <div className='dashboard-right-bottom-2-2'>{t('dashboard_3')}
                            </div>
                            <div className='dashboard-right-bottom-2-3'>
                                <div>{mydata.last_access_ip}</div>
                                <div className='dashboard-right-bottom-2-4'>{logindate}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`dashboard-right-bottom1 ${darkname}`}>
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/name.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_4')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_5')}</div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom1-1-2'>
                        <div className='dashboard-right-bottom1-1-2-1'>
                            <img src='/img/certified.svg' />
                            <div className='dashboard-right-bottom1-1-2-2'>{mydata.nickname}</div>
                        </div>
                        <div className='dashboard-right-bottom1-1-3'>
                            <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => setBtn(true)}>{t('dashboard_6')}</div>
                            {
                                btn
                                    ?
                                    <div className='dashboard-right-bottom1-modal'>
                                        <div className='dashboard-right-bottom1-modal-1'>
                                            <div className={`dashboard-right-bottom1-modal-1-1 ${darkname}`}>
                                                <div className='dashboard-right-bottom1-modal-1-1-tit'>{t('dashboard_7')}</div>
                                                <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`} onMouseEnter={nameMouseEnter} onMouseLeave={nameMouseLeave}>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                        <input type='text' value={name} onChange={onChangeName} placeholder={`${t('dashboard_8')}`} />
                                                        {
                                                            nameOK
                                                                ?
                                                                <div className={`dashboard-right-bottom1-modal-1-1-box ${darkname}`}>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={nameCharacters ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('regist_15')}</div>
                                                                    </div>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={namespecialCharacter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('regist_16')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </div>
                                                <div className='dashboard-right-bottom1-modal-1-1-btn'>
                                                    <div className='dashboard-right-bottom1-modal-1-1-btn-1' onClick={() => onSubmitNicName()}>{t('dashboard_11')}</div>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-btn-2 ${darkname}`} onClick={() => setBtn(false)}>{t('dashboard_12')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/email.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_13')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_14')}</div>
                        </div>
                    </div>
                    {
                        mydata.email
                            ?
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    <img src='/img/certified.svg' />
                                    <div className='dashboard-right-bottom1-1-2-2'>{mydata.email}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 dashboard-coming ${darkname}`}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                            :
                                            <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_15')}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => { setBtn2(true); setType(true) }}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                    }
                    {
                        btn2
                            ?
                            <div className='dashboard-right-bottom1-modal'>
                                <div className='dashboard-right-bottom1-modal-2'>
                                    <div className={`dashboard-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='dashboard-right-bottom1-modal-1-1-tit'>{t('dashboard_16')}</div>
                                        <div className='dashboard-modal-2-form'>
                                            <div className={`dashboard-modal-2-tit ${darkname}`}>
                                                <input className='regist-box-left-form-1-input' type='text' value={email} onChange={onChangeEmail} placeholder={`${t('dashboard_18')}`} />
                                            </div>
                                        </div>
                                        <div className={`dashboard-modal-2-btn ${darkname}`} onClick={() => onSendCertifyCheck()}>{t('dashboard_17')}</div>
                                        <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input type='number' value={digit} onChange={onChangeDigit} maxLength={6} placeholder={`${t('dashboard_19')}`} />
                                            </div>
                                        </div>
                                        <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input type='number' value={googleOTP} onChange={onChangeGoogelOTP} maxLength={6} placeholder={`${t('dashboard_20')}`} />
                                            </div>
                                        </div>
                                        <div className='dashboard-right-bottom1-modal-1-1-btn'>
                                            <div className='dashboard-right-bottom1-modal-1-1-btn-1' onClick={() => CheckGoogleOTP()}>{t('dashboard_21')}</div>
                                            <div className={`dashboard-right-bottom1-modal-1-1-btn-2 ${darkname}`} onClick={() => setBtn2(false)}>{t('dashboard_12')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/mobile.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_22')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_23')}</div>
                        </div>
                    </div>
                    {
                        mydata.mobile
                            ?
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    <img src='/img/certified.svg' />
                                    <div className='dashboard-right-bottom1-1-2-2'>{mydata.mobile}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 dashboard-coming ${darkname}`}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                            :
                                            <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_15')}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => { setBtn3(true); setType(false) }}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                    }
                    {
                        btn3
                            ?
                            <div className='dashboard-right-bottom1-modal'>
                                <div className='dashboard-right-bottom1-modal-2'>
                                    <div className={`dashboard-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='dashboard-right-bottom1-modal-1-1-tit'>{t('dashboard_24')}</div>
                                        <div className='dashboard-modal-2-form'>
                                            <div className={`dashboard-modal-2-tit ${darkname}`}>
                                                <div className='regist-box-left-form-country'>
                                                    <input type="search" className='regist-box-left-form-country-input' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                                    <datalist id="list">
                                                        {
                                                            Country.map(function (a, i) {
                                                                return (
                                                                    <option value={a.value}>{a.name} ({a.value})</option>
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                </div>
                                                <input className='regist-box-left-form-1-input' type='text' value={tel} onChange={onChangeTel} placeholder={`${t('dashboard_25')}`} />
                                            </div>
                                        </div>
                                        <div className={`dashboard-modal-2-btn ${darkname}`} onClick={() => onSendCertifyCheck()}>{t('dashboard_26')}</div>
                                        <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input type='number' value={digit} onChange={onChangeDigit} maxLength={6} placeholder={`${t('dashboard_27')}`} />
                                            </div>
                                        </div>
                                        <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input type='number' value={googleOTP} onChange={onChangeGoogelOTP} maxLength={6} placeholder={`${t('dashboard_20')}`} />
                                            </div>
                                        </div>
                                        <div className='dashboard-right-bottom1-modal-1-1-btn'>
                                            <div className='dashboard-right-bottom1-modal-1-1-btn-1' onClick={() => CheckGoogleOTP()}>{t('dashboard_21')}</div>
                                            <div className={`dashboard-right-bottom1-modal-1-1-btn-2 ${darkname}`} onClick={() => setBtn3(false)}>{t('dashboard_12')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/password.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_28')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_29')}</div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom1-1-2'>
                        <div className='dashboard-right-bottom1-1-2-1'>
                            <img src='/img/certified.svg' />
                            <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_83')}</div>
                        </div>
                        <div className='dashboard-right-bottom1-1-3'>
                            <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => setBtn1(true)}>{t('dashboard_6')}</div>
                            {
                                btn1
                                    ?
                                    <div className='dashboard-right-bottom1-modal'>
                                        <div className='dashboard-right-bottom1-modal-2'>
                                            <div className={`dashboard-right-bottom1-modal-1-1 ${darkname}`}>
                                                <div className='dashboard-right-bottom1-modal-1-1-tit'>{t('dashboard_30')}</div>
                                                <div style={{ marginTop: "5px" }}>{t('dashboard_31')}</div>
                                                <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                        {
                                                            eye
                                                                ?
                                                                <input type='text' value={prePassword} onChange={onChangePrePassword} placeholder={`${t('dashboard_32')}`} />
                                                                :

                                                                <input type='password' value={prePassword} onChange={onChangePrePassword} placeholder={`${t('dashboard_32')}`} />
                                                        }
                                                        {
                                                            eye
                                                                ?
                                                                <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                                                :
                                                                <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`} onMouseEnter={passwordMouseEnter} onMouseLeave={passwordMouseLeave}>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                        {
                                                            eye1
                                                                ?
                                                                <input type='text' value={newPassword} onChange={onChangeNewPassword} placeholder={`${t('dashboard_33')}`} />
                                                                :
                                                                <input type='password' value={newPassword} onChange={onChangeNewPassword} placeholder={`${t('dashboard_33')}`} />
                                                        }
                                                        {
                                                            passwordOK
                                                                ?
                                                                <div className={`dashboard-right-bottom1-modal-1-1-box ${darkname}`}>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={minCharacters ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('dashboard_34')}</div>
                                                                    </div>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={leastNumber ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('dashboard_35')}</div>
                                                                    </div>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={leastLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('dashboard_36')}</div>
                                                                    </div>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={upperLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('dashboard_37')}</div>
                                                                    </div>
                                                                    <div className="signup_password_condition">
                                                                        <FontAwesomeIcon icon={faCheck} className={specialCharacter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                                        <div className="signup_password_condition_txt">{t('dashboard_38')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            eye1
                                                                ?
                                                                <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye1(eye1 => !eye1)} />
                                                                :
                                                                <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye1(eye1 => !eye1)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className={`dashboard-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                        <input type='number' value={googleOTP} onChange={onChangeGoogelOTP} maxLength={6} placeholder={`${t('dashboard_20')}`} />
                                                    </div>
                                                </div>
                                                <div className='dashboard-right-bottom1-modal-1-1-btn'>
                                                    <div className='dashboard-right-bottom1-modal-1-1-btn-1' onClick={() => CheckGoogleOTpassword()}>{t('dashboard_11')}</div>
                                                    <div className={`dashboard-right-bottom1-modal-1-1-btn-2 ${darkname}`} onClick={() => setBtn1(false)}>{t('dashboard_12')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                {/* <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/board.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_39')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_40')}</div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom1-1-3'>
                        <div className={`dashboard-right-form-1-box-1-label ${isToggled ? 'toggled' : ''} ${darkname}`} onClick={toggleButton}>
                            {
                                isToggled
                                    ?
                                    <span className={`dashboard-right-bottom1-1-3-on ${darkname}`}>{t('futures_11')}</span>
                                    :
                                    <span className={`dashboard-right-bottom1-1-3-off ${darkname}`}>{t('futures_12')}</span>
                            }
                            <div className="dashboard-right-form-1-box-1-btn slider"></div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/marketing.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_41')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_42')}</div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom1-1-3'>
                        <div className={`dashboard-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                            {
                                isToggled1
                                    ?
                                    <span className={`dashboard-right-bottom1-1-3-on ${darkname}`}>{t('futures_11')}</span>
                                    :
                                    <span className={`dashboard-right-bottom1-1-3-off ${darkname}`}>{t('futures_12')}</span>
                            }
                            <div className="dashboard-right-form-1-box-1-btn slider"></div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <div className={`dashboard-right-bottom2 ${darkname}`}>
                <Link to={`/dashboard`} state={{ num: 4 }} >
                    <div className='dashboard-right-bottom2-1'>
                        Event Notifications <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </Link>
                <div className='dashboard-right-bottom2-2'>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={30}
                        freeMode={true}
                        scrollbar={true}
                        modules={[FreeMode, Scrollbar]}
                        className="mySwiper"
                    >
                        {
                            arr.map(function (a, i) {
                                return (
                                    <SwiperSlide>
                                        <EventItem key={i} item={a} darkname={darkname} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div> */}
        </div>
    )
}

function EventItem({ item, darkname }) {
    return (
        <div className={`dashboard-right-bottom2-2-1 ${darkname}`}>
            <img src={`/img/event/event${item}.jpg`} />
            <div className={`dashboard-right-bottom2-2-2 ${darkname}`}>
                <div className='dashboard-right-bottom2-2-2-1'>New Listing ~ Welcom</div>
                <div className='dashboard-right-bottom2-2-2-2'>New Listing ~ Welcom New Listing ~ Welcom</div>
                <div className='dashboard-right-bottom2-2-2-3'>
                    <div className='dashboard-right-bottom2-2-2-4'>2023.11.11</div>
                    <div className='dashboard-right-bottom2-2-2-5'>-1 day</div>
                </div>
            </div>
        </div>
    )
}

function Security({ darkname, dark, mydata, setResetMydata }) {
    let checkkyc = useSelector((state) => { return state.check_KYC.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Languages = useSelector((state) => { return state.Languages.value; });

    let lang = Languages.split("-");

    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [btn, setBtn] = useState(false)
    const [btn1, setBtn1] = useState(false)
    const [current, setCurrent] = useState(0)
    const [input, setInput] = useState("")
    const [input1, setInput1] = useState("")
    const [otp, setOtp] = useState([])
    const [num, setNum] = useState(0)
    const [check, setCheck] = useState(false)
    const [check1, setCheck1] = useState(false)
    const [binancekyc, setBinancekyc] = useState([])
    const [etckyc, setEtckyc] = useState([])
    const [reload, setReload] = useState(false)
    const [message, setMessage] = useState([])
    const [buyModal, setBuyModal] = useState(false);

    const onChangeInput = (event) => {
        setInput(event.target.value)
    }

    const onChangeInput1 = (event) => {
        setInput1(event.target.value)
    }

    useEffect(() => {
        let nu = 0
        if (mydata.otp) nu += 1
        if (checkkyc && checkkyc.etcKyc === 1) nu += 1
        setNum(nu)
    }, [mydata, checkkyc])

    useEffect(() => {
        BinanceKYCLink()
        KYCLink()
    }, [])

    const BinanceKYCLink = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/bn/verify/kyc", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                let code = response?.data?.code
                if (code && code > 0) {
                    let tmp = response.data.data.data
                    if (tmp) {
                        setBinancekyc(response.data.data.data)
                    }
                }
                else if (code && code === -1100) {
                    setBinancekyc({ "code": -1100 })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 37, "msg": error }))
            })
        }
    }

    const KYCLink = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/kyc/token", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setEtckyc(tmp.accessToken)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 38, "msg": error }))
            })
        }
    }

    const onKYCID = async (e) => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/kyc/app-id", {
                appId: e
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 39, "msg": error }))
            })
        }
    }



    const GoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/create/otp", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setOtp(response.data.data)
                    setCurrent(e => e + 1);
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    if (API_Address == "rf") setBuyModal(true)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 40, "msg": error }))
            })
        }
    }

    const sendDigit = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/get/certify", {
                headers: { Authorization: `Bearer ${jwt}` },
                params: {
                    type: obj.login,
                    id: mydata.uid,
                    form: "otp"
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    if (API_Address == "rf") setBuyModal(true)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 41, "msg": error }))
            })
        }
    }

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                headers: { Authorization: `Bearer ${jwt}` },
                params: { otp: input }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    setCheck(true)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_42') })
                    if (API_Address == "rf") setBuyModal(true)
                    setCheck(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 42, "msg": error }))
            })
        }
    }

    const CheckDigit = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/check/certify", {
                headers: { Authorization: `Bearer ${jwt}` },
                params: {
                    number: input1,
                    id: mydata.uid,
                    form: "otp",
                    type: obj.login
                }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    setCheck1(true)
                    setMessage({ type: "binance", code: response?.data?.code, msg: "Google OTP registration successful" })
                    if (API_Address == "rf") setBuyModal(true)
                    // const obj = {
                    //     value: response.data.data.jwt,
                    //     expire: Date.now() + 1000 * 60 * 60 * 24 * 5
                    // }
                    // const newobjString = JSON.stringify(obj);
                    // localStorage.setItem('token', newobjString)
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    if (API_Address == "rf") setBuyModal(true)
                    setCheck1(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 43, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (btn1) {
            document.body.style.cssText = `
            position: fixed; 
            top: -${window.scrollY}px;
            overflow-y: scroll;
            width: 100%;`;
            return () => {
                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }
    }, [btn1]);

    useEffect(() => {
        if (check) CheckDigit()
    }, [check])

    useEffect(() => {
        if (check && check1) {
            setCurrent(e => e + 1);
            setCheck(false)
            setCheck1(false)
        }
    }, [check1])

    return (
        <div className='history-right'>
            {
                API_Address == "dev"
                    ?
                    <Modal message={message} />
                    :
                    <Namu_Message message={message} buyModal={buyModal} setBuyModal={setBuyModal} res={0} />
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_46')}</div>
                <p className='history-right-top-2'>{t('header_47')}</p>
            </div>
            <div className='dashboard-right-bottom'>
                <div className='dashboard-right-bottom-3'>
                    <p>{t('dashboard_44')}</p>
                    <div className='dashboard-right-bottom-3-1'>
                        <span className={num === 0 ? `${t('dashboard_45')}` : num === 1 ? `${t('dashboard_46')}` : `${t('dashboard_47')}`}>{num === 0 ? `${t('dashboard_45')}` : num === 1 ? `${t('dashboard_46')}` : `${t('dashboard_47')}`}</span>
                        <div className='dashboard-right-bottom-3-2'>
                            <div className='dashboard-right-bottom-3-2-low'></div>
                            <div className={num >= 1 ? 'dashboard-right-bottom-3-2-middle' : 'dashboard-right-bottom-3-2-middle none'}></div>
                            <div className={num >= 2 ? 'dashboard-right-bottom-3-2-high' : 'dashboard-right-bottom-3-2-high none'}></div>
                        </div>
                    </div>
                </div>
                <div className='dashboard-right-bottom-3-3'>
                    <div className='dashboard-right-bottom-3-4'>
                        <div className='dashboard-right-bottom-3-4-1'>
                            <div className={`dashoboard-right-bottom-3-4-2 ${darkname}`}>
                                <div className='dashboard-right-bottom-3-4-3'>
                                    {
                                        mydata.email
                                            ?
                                            <img src='/img/certified.svg' />
                                            :
                                            dark
                                                ?
                                                <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                                :
                                                <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                </div>
                                <p>{t('dashboard_48')}</p>
                            </div>
                            <div className={`dashoboard-right-bottom-3-4-2 ${darkname}`}>
                                <div className='dashboard-right-bottom-3-4-3'>
                                    {
                                        mydata.mobile
                                            ?
                                            <img src='/img/certified.svg' />
                                            :
                                            dark
                                                ?
                                                <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                                :
                                                <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                </div>
                                <p>{t('dashboard_49')}</p>
                            </div>
                        </div>
                        <div className='dashboard-right-bottom-3-4-1'>
                            <div className={`dashoboard-right-bottom-3-4-2 ${darkname}`}>
                                <div className='dashboard-right-bottom-3-4-3'>
                                    {
                                        mydata.otp
                                            ?
                                            <img src='/img/certified.svg' />
                                            :
                                            dark
                                                ?
                                                <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                                :
                                                <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                </div>
                                <p>{t('dashboard_50')}</p>
                            </div>
                            <div className={`dashoboard-right-bottom-3-4-2 ${darkname}`}>
                                <div className={`dashboard-right-bottom-3-4-3 ${darkname}`}>
                                    {
                                        checkkyc && checkkyc.etcKyc === 1
                                            ?
                                            <img src='/img/certified.svg' />
                                            :
                                            dark
                                                ?
                                                <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                                :
                                                <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                </div>
                                <p>{t('dashboard_51')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashboard-right-bottom1'>
                <p>{t('dashboard_52')}</p>
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/authenticator.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_53')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_54')}</div>
                        </div>
                    </div>
                    {
                        mydata.otp
                            ?
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    <img src='/img/certified.svg' />
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_83')}</div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                            :
                                            <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_15')}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => { setBtn(true) }}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                    }
                    {
                        btn
                            ?
                            <div className='dashboard-modal-security-1'>
                                <div className='dashboard-modal-security-1-box'>
                                    <div className={`dashboard-modal-security-1-box-1 ${darkname}`}>
                                        <div className='dashboard-modal-security-1-box-2' onClick={() => setBtn(false)}><FontAwesomeIcon icon={faXmark} /></div>
                                        <span className='dashboard-modal-security-1-box-1-tit'>{t('dashboard_55')}</span>
                                        <div className={`dashboard-modal-security-1-box-3 ${darkname}`}>
                                            <div className='dashboard-modal-security-1-box-3-hr'></div>
                                            <ul>
                                                <li className={current >= 0 ? 'active' : ""}>
                                                    <div className={`dashboard-modal-security-1-box-3-1 ${darkname}`}>
                                                        <img src='/img/ico_Google_Authenticator_down.svg' />
                                                    </div>
                                                    <p className='dashboard-modal-security-1-box-3-2'>{t('dashboard_56')}</p>
                                                </li>
                                                <li className={current >= 1 ? 'active' : ""}>
                                                    <div className={`dashboard-modal-security-1-box-3-1 ${darkname}`}>
                                                        <img src='/img/ico_Google_Authenticator_qr.svg' />
                                                    </div>
                                                    <p className='dashboard-modal-security-1-box-3-2'>{t('dashboard_57')}</p>
                                                </li>
                                                <li className={current >= 2 ? 'active' : ""}>
                                                    <div className={`dashboard-modal-security-1-box-3-1 ${darkname}`}>
                                                        <img src='/img/ico_Google_Authenticator_back.svg' />
                                                    </div>
                                                    <p className='dashboard-modal-security-1-box-3-2'>{t('dashboard_58')}</p>
                                                </li>
                                                <li className={current >= 3 ? 'active' : ""}>
                                                    <div className={`dashboard-modal-security-1-box-3-1 ${darkname}`}>
                                                        <img src='/img/ico_Google_Authenticator_mail.svg' />
                                                    </div>
                                                    <p className='dashboard-modal-security-1-box-3-2'>{t('dashboard_59')}</p>
                                                </li>
                                                <li className={current >= 4 ? 'active' : ""}>
                                                    <div className={`dashboard-modal-security-1-box-3-1 ${darkname}`}>
                                                        <img src='/img/ico_Google_Authenticator_comp.svg' />
                                                    </div>
                                                    <p className='dashboard-modal-security-1-box-3-2'>{t('dashboard_60')}</p>
                                                </li>
                                            </ul>
                                            {
                                                current === 0
                                                    ?
                                                    <div className='dashboard-modal-security-1-box-4'>
                                                        <div className='dashboard-modal-security-1-box-4-1'>
                                                            <div className='dashboard-modal-securtiy-1-box-4-2'>
                                                                <p className='dashboard-modal-securtiy-1-box-4-2-p'>{t('dashboard_61')}</p>
                                                                <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_62')}</p>
                                                            </div>
                                                            <div className='dashboard-modal-security-1-box-4-3'>
                                                                <div className={`dashboard-modal-security-1-box-4-3-1 ${darkname}`}>
                                                                    <div className='dashboard-modal-security-1-box-4-3-1-img'>
                                                                        <img src='/img/appstore.svg' />
                                                                        <img src='/img/appstore_dark.svg' style={{ display: "none" }} />
                                                                    </div>
                                                                    <div className='dashboard-modal-security-1-box-4-3-1-span'>
                                                                        <span>{t('dashboard_63')}</span><br />
                                                                        {t('dashboard_64')}
                                                                    </div>
                                                                    <div className={`dashboard-modal-security-1-box-4-3-1-btn ${darkname}`} onClick={() => window.open('https://apps.apple.com/us/app/google-authenticator/id388497605')}>{t('dashboard_65')}</div>
                                                                </div>
                                                                <div className={`dashboard-modal-security-1-box-4-3-1 ${darkname}`}>
                                                                    <div className='dashboard-modal-security-1-box-4-3-1-img'>
                                                                        <img src='/img/google.svg' />
                                                                    </div>
                                                                    <div className='dashboard-modal-security-1-box-4-3-1-span'>
                                                                        <span>{t('dashboard_63')}</span><br />
                                                                        {t('dashboard_64')}
                                                                    </div>
                                                                    <div className={`dashboard-modal-security-1-box-4-3-1-btn ${darkname}`} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko&gl=US&pli=1')}>{t('dashboard_65')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    current === 1
                                                        ?
                                                        <div className='dashboard-modal-security-1-box-4'>
                                                            <div className='dashboard-modal-security-1-box-4-1'>
                                                                <div className='dashboard-modal-securtiy-1-box-4-2'>
                                                                    <p className='dashboard-modal-securtiy-1-box-4-2-p'>{t('dashboard_66')}</p>
                                                                    <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_67')}</p>
                                                                </div>
                                                                <div className='dashboard-modal-security-1-box-4-3'>
                                                                    <div className={`dashboard-modal-security-1-box-4-4 ${darkname}`}>
                                                                        <img src={otp.imageData} />
                                                                    </div>
                                                                </div>
                                                                <div className='dashboard-modal-security-1-box-4-6-1'>{otp.secret}</div>
                                                                <span>{t('dashboard_69')}</span>
                                                                <div className={`dashboard-modal-security-1-box-4-5 ${darkname}`}>
                                                                    <input type='number' value={input1} onChange={onChangeInput1} maxLength={6} />
                                                                </div>
                                                                <span>{t('dashboard_68')}</span>
                                                                <div className={`dashboard-modal-security-1-box-4-5 ${darkname}`}>
                                                                    <input type='number' value={input} onChange={onChangeInput} maxLength={6} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        current === 2
                                                            ?
                                                            <div className='dashboard-modal-security-1-box-4'>
                                                                <div className='dashboard-modal-security-1-box-4-1'>
                                                                    <div className='dashboard-modal-securtiy-1-box-4-2'>
                                                                        <p className='dashboard-modal-securtiy-1-box-4-2-p'>{t('dashboard_70')}</p>
                                                                        <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_71')}<br />{t('dashboard_71_1')}</p>
                                                                    </div>
                                                                    <div className={`dashboard-modal-security-1-box-4-6 ${darkname}`}>{otp.secret}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            current === 3
                                                                ?
                                                                <div className='dashboard-modal-security-1-box-4'>
                                                                    <div className='dashboard-modal-security-1-box-4-1'>
                                                                        <div className='dashboard-modal-securtiy-1-box-4-2'>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_72')}</p>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_73')}</p>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_74')}</p>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_75')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='dashboard-modal-security-1-box-4'>
                                                                    <div className='dashboard-modal-security-1-box-4-1'>
                                                                        <div className='dashboard-modal-securtiy-1-box-4-2'>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p'>{t('dashboard_76')}</p>
                                                                            <p className='dashboard-modal-securtiy-1-box-4-2-p1'>{t('dashboard_77')}</p>
                                                                        </div>
                                                                        <img src='/img/ico_GoogleAuthenticator_finish.svg' className='dashboard-modal-securtiy-1-box-4-2-img' />
                                                                    </div>
                                                                </div>
                                            }
                                            {/* <div className='dashboard-modal-security-1-box-5-1' onClick={() => setCurrent(e => e - 1)}>이전</div>
                                            <div className='dashboard-modal-security-1-box-5-1' style={{ right: "0px", left: "auto" }} onClick={() => setCurrent(e => e + 1)}>다음</div> */}
                                            {
                                                current === 3
                                                    ?
                                                    <div className='dashboard-modal-security-1-box-5-1' onClick={() => setCurrent(e => e - 1)}>{t('dashboard_78')}</div>
                                                    :
                                                    ""
                                            }
                                            {
                                                current === 0
                                                    ?
                                                    <div className='dashboard-modal-security-1-box-5' onClick={() => { GoogleOTP(); sendDigit() }}>{t('dashboard_79')}</div>
                                                    :
                                                    current === 1
                                                        ?
                                                        <div className='dashboard-modal-security-1-box-5' onClick={() => { CheckGoogleOTP() }}>{t('dashboard_79')}</div>
                                                        :
                                                        current <= 3
                                                            ?
                                                            <div className='dashboard-modal-security-1-box-5' onClick={() => setCurrent(e => e + 1)}>{t('dashboard_79')}</div>
                                                            :
                                                            <div className='dashboard-modal-security-1-box-5' onClick={() => { setBtn(false); setResetMydata(e => !e) }}>{t('dashboard_80')}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/verification.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_84')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_85')}</div>
                        </div>
                    </div>
                    {
                        checkkyc && checkkyc.etcKyc === 1
                            ?
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    <img src='/img/certified.svg' />
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_83')}</div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                            :
                                            <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                    <div className='dashboard-right-bottom1-1-2-2'>{checkkyc && checkkyc.etcKycFailReason ? checkkyc.etcKycFailReason : t('dashboard_15')}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => { setBtn1(true) }}>{t('dashboard_6')}</div>
                                </div>
                            </div>
                    }
                    {
                        btn1
                            ?
                            <div className='dashboard-right-bottom1-modal'>
                                <div className='dashboard-right-bottom1-modal-2'>
                                    <div className={`dashboard-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='dashboard-right-bottom1-modal-1-1-tit'>{t('dashboard_86')}
                                            <FontAwesomeIcon icon={faXmark} style={{ cursor: "pointer" }} onClick={() => setBtn1(false)} /></div>
                                        <SumsubWebSdk
                                            accessToken={etckyc}
                                            expirationHandler={() => { }}
                                            config={{
                                                lang: lang[0] ? lang[0] : "en"
                                            }}
                                            options={{}}
                                            onMessage={(type, payload) => {
                                                if (type === "idCheck.onApplicantLoaded") {
                                                    if (payload.applicantId != undefined) onKYCID(payload.applicantId);
                                                }
                                            }}
                                            onError={() => { }}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/icon/verification.png' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>{t('dashboard_81')}</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>{t('dashboard_82')}</div>
                        </div>
                    </div>
                    {
                        checkkyc && checkkyc.bnKyc === 1
                            ?
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    <img src='/img/certified.svg' />
                                    <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_83')}</div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-right-bottom1-1-2'>
                                <div className='dashboard-right-bottom1-1-2-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                            :
                                            <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                                    }
                                    <div className='dashboard-right-bottom1-1-2-2'>{checkkyc && checkkyc.bnKycFailReason ? checkkyc.bnKycFailReason : t('dashboard_15')}</div>
                                </div>
                                <div className='dashboard-right-bottom1-1-3'>
                                    <div className={`dashboard-right-bottom1-1-3-1 ${darkname}`} onClick={() => {
                                        binancekyc && !binancekyc.code ? window.open(binancekyc.pageUrl) : alert("Please register your email.")
                                    }}>
                                        {t('dashboard_6')}</div>
                                </div>
                            </div>
                    }
                </div>
                {/* <hr />
                <div className='dashboard-right-bottom1-1'>
                    <div className='dashboard-right-bottom1-1-1'>
                        <div className='dashboard-right-bottom1-1-1-ico'>
                            <img src='/img/ico_antiphishing.svg' />
                        </div>
                        <div className='dashboard-right-bottom1-1-1-wrap'>
                            <div className='dashboard-right-bottom1-1-1-tit'>Anti-Phishing</div>
                            <div className='dashboard-right-bottom1-1-1-desc'>Turn on email Anti-Phishing to protect your account more securely.</div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom1-1-2'>
                        <div className='dashboard-right-bottom1-1-2-1'>
                            {
                                dark
                                    ?
                                    <img src='/img/certified_not_dark.svg' style={{ opacity: ".3" }} />
                                    :
                                    <img src='/img/certified_not.svg' style={{ opacity: ".3" }} />
                            }
                            <div className='dashboard-right-bottom1-1-2-2'>{t('dashboard_15')}</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

function RewardProgram({ darkname, dark, SelectType }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    let dispatch = useDispatch();
    const [referral_bn, setReferral_bn] = useState([])
    const [referral_okx, setReferral_okx] = useState([])
    const [referral_by, setReferral_by] = useState([])

    const [volume, setVolume] = useState(0)
    const [reward, setReward] = useState(0)

    const [bn_page, setBn_Page] = useState(1)
    const [okx_page, setOKX_Page] = useState(1)
    const [by_page, setBy_Page] = useState(1)

    useEffect(() => {
        ReferralData()
        dispatch(checkSelectType(1))
    }, [])

    const ReferralData = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/wallet/cm/selferral", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setReferral_bn(tmp.bn)
                    setReferral_okx(tmp.okx)
                    setReferral_by(tmp.bybit)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 44, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let data = SelectType === 1 ? referral_bn : SelectType === 0 ? referral_okx : referral_by
        if (data) {
            let v = 0
            let r = 0
            for (let i = 0; i < data.length; i++) {
                v += Number(data[i].volume)
                r += Number(data[i].selferral)
            }
            setVolume(v)
            setReward(r)
        }
    }, [referral_bn, referral_okx, referral_by, SelectType])

    let filter_bn_referral = referral_bn.slice((bn_page - 1) * 20, (bn_page) * 20)
    let filter_okx_referral = referral_okx.slice((okx_page - 1) * 20, (okx_page) * 20)
    let filter_by_referral = referral_by.slice((by_page - 1) * 20, (by_page) * 20)

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_48')}</div>
                <p className='history-right-top-2'>{t('header_49')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`}
                        onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`}
                        onClick={() => OKX_Mode ? dispatch(checkSelectType(0)) : dispatch(changeOKX_Message())}>{t('exchange_1')}</div>
                    <div className={SelectType === 2 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`}
                        onClick={() => dispatch(checkSelectType(2))}>{t('exchange_2')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    {/* <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <label className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                />
                            </label>
                        </div>
                        <div>
                            <div className='history-right-bottom-2-2-search'>{t('market_0')}</div>
                        </div>
                    </div> */}
                    <div className='dashboard-right-bottom-5'>
                        <div className='dashboard-right-bottom-5-1'>
                            <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                                <div className='dashboard-right-bottom-5-3'>
                                    <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                                    <div>{SelectType === 1 ? t('dashboard_98') : SelectType === 0 ? t('dashboard_98_1') : t('dashboard_98_2')}</div>
                                </div>
                                <div className='dashboard-right-bottom-5-4'>
                                    <FontAwesomeIcon icon={faCaretUp} />
                                    <span>{SelectType === 1 ? "25" : SelectType === 0 ? "50" : "0"} %</span>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-right-bottom-5-1'>
                            <div className='dashboard-right-bottom-5-5'>
                                <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                                    <div className='dashboard-right-bottom-5-3'>
                                        <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                                            <g>
                                                <path className="fill-color" d="M503.2,212.9c-15.3-81.3-60-141.3-132.7-180.3C341,16.8,309.2,8.3,275.8,5.8c-1-0.1-2.1-0.5-3.1-0.8
		c-11.1,0-22.2,0-33.3,0c-8.8,1.2-17.7,2-26.4,3.8C113,29.2,47.1,88.2,15.5,184.9c-5.3,16.2-6.5,33.8-9.6,50.8
		c-0.2,1.2-0.6,2.4-0.9,3.7c0,11.1,0,22.2,0,33.3c1.3,9,2.1,18,3.9,26.9C26.6,389.1,77.7,451.8,161,488.3
		c23.7,10.4,48.9,15.9,74.7,17.8c0.9,0.1,1.7,0.6,2.6,0.9c11.1,0,22.2,0,33.3,0c9.2-1.3,18.4-2.1,27.4-3.8
		c87.6-16.9,149.7-66.5,187.1-147.1c11.7-25.4,17.9-52.4,20-80.3c0.1-1.1,0.5-2.1,0.8-3.2c0-10.8,0-21.6,0-32.4
		C505.7,231.2,504.9,222,503.2,212.9z M338.3,308.7c-4.2,27.7-22.1,45.3-51.8,51.1c-4,0.8-8.1,1.4-12.6,2.1c0,13.6,0,27.1,0,40.8
		c-12,0-23.5,0-35.4,0c0-14,0-27.7,0-41.6c-24.6-3.9-47.4-11.7-67.7-26.3c6.1-11.9,12-23.3,18.1-35.3c14,10.1,28.9,17.6,45.2,22
		c14.4,3.9,29,5.4,43.7,1.7c8.8-2.2,15.4-6.8,16.8-16.7c1.4-9.9-2.9-17.4-11.4-21.2c-11.2-5-23-8.8-34.6-12.8
		c-11.1-3.8-22.6-6.7-33.5-11c-38.9-15.1-41.6-49.1-29.6-77.3c8.7-20.5,26.3-30.3,46.9-35.8c1.9-0.5,3.8-0.9,6.2-1.5
		c0-12.6,0-25.3,0-38.2c11.8,0,23.2,0,35.1,0c0,12.4,0,24.8,0,37.8c21.5,3.1,40,11.3,57.8,22.7c-6.1,11.2-12,22.1-18.2,33.4
		c-13-10.1-27.4-16.1-42.9-19.3c-10.3-2.1-20.7-2.3-30.9,0.6c-10,2.8-16.2,9.6-17.2,18.7c-1.1,10.1,2.5,18,13,22
		c13.8,5.4,28.3,9,42.5,13.6c10.2,3.4,20.7,6.4,30.3,11.1C331.9,261.1,342.3,282.2,338.3,308.7z"/>
                                            </g>
                                        </svg>
                                        <div>{t('dashboard_99')}</div>
                                    </div>
                                    <div className='dashboard-right-bottom-5-6'>
                                        <div className='dashboard-right-bottom-5-7'>
                                            <span>{volume.toFixed(8)}</span>
                                            <span style={{ fontSize: "14px", fontWeight: "400" }}>USDT</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='dashboard-right-bottom-5-5'>
                                <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                                    <div className='dashboard-right-bottom-5-3'>
                                        <img src='/img/icon/reward.png' />
                                        <div>{t('dashboard_100')}</div>
                                    </div>
                                    <div className='dashboard-right-bottom-5-6'>
                                        <div className='dashboard-right-bottom-5-7'>
                                            <span>{reward.toFixed(8)}</span>
                                            <span style={{ fontSize: "14px", fontWeight: "400" }}>USDT</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1'>{t('spot_45')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('dashboard_101')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('dashboard_102')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('dashboard_103')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                SelectType === 1
                                    ?
                                    filter_bn_referral && filter_bn_referral.length === 0
                                        ?
                                        <div className='history-right-bottom-4-bottom-1'>
                                            <img src='/img/img_nofound.png'></img>
                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                            <p>{t('message_0')}</p>
                                        </div>
                                        :
                                        <>
                                            {
                                                filter_bn_referral.map(function (a, i) {
                                                    return (
                                                        <RewardProgramItem key={i} item={a} darkname={darkname} />
                                                    )
                                                })
                                            }
                                            <Page initialData={referral_bn} page={bn_page} setPage={setBn_Page} maxFirstPage={20} />
                                        </>
                                    :
                                    SelectType === 0
                                        ?
                                        filter_okx_referral && filter_okx_referral.length === 0
                                            ?
                                            <div className='history-right-bottom-4-bottom-1'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>{t('message_0')}</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    filter_okx_referral.map(function (a, i) {
                                                        return (
                                                            <RewardProgramItem key={i} item={a} darkname={darkname} />
                                                        )
                                                    })
                                                }
                                                <Page initialData={referral_okx} page={okx_page} setPage={setOKX_Page} maxFirstPage={20} />
                                            </>
                                        :
                                        filter_by_referral && filter_by_referral.length === 0
                                            ?
                                            <div className='history-right-bottom-4-bottom-1'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>{t('message_0')}</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    filter_by_referral.map(function (a, i) {
                                                        return (
                                                            <RewardProgramItem key={i} item={a} darkname={darkname} />
                                                        )
                                                    })
                                                }
                                                <Page initialData={referral_by} page={by_page} setPage={setBy_Page} maxFirstPage={20} />
                                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RewardProgramItem({ item, darkname }) {
    let arr = item.date.split("T")

    return (
        <div className={`history-right-bottom-4-bottom-2-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1'>{arr && arr[0] ? arr[0] : item.date}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.symbol}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{Number(item.volume).toFixed(8)}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{Number(item.selferral).toFixed(8)}</div>
        </div>
    )
}

function FiendInvitation({ darkname, dark, refcode }) {
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [tab1, setTab1] = useState(0)
    const [referralData, setReferralData] = useState([])

    useEffect(() => {
        navigate(`/`)
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            Referral(token)
        }
    }, [])

    const Referral = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/account/user/get/referral`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setReferralData(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 45, "msg": error }))
        })
    }

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_50')}</div>
                <p className='history-right-top-2'>{t('header_51')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={tab1 === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => setTab1(0)}>{t('dashboard_105')}</div>
                    <div className={tab1 === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => OKX_Mode ? setTab1(1) : dispatch(changeOKX_Message())}>{t('dashboard_107')}</div>
                    {/* <div className={tab1 === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => setTab1(1)}>{t('dashboard_107')}</div> */}
                    {/* <div className={tab1 === 2 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => setTab1(2)}>{t('dashboard_106')}</div> */}
                    <div className={tab1 === 2 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => alert("Coming Soon")}>{t('dashboard_106')}</div>
                    <div className={tab1 === 3 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => alert("Coming Soon")}>{t('dashboard_125')}</div>
                </div>
                {
                    tab1 === 0
                        ?
                        <MyFriends darkname={darkname} dark={dark} refcode={refcode} referralData={referralData} />
                        :
                        tab1 === 1
                            ?
                            <OKXFriends darkname={darkname} dark={dark} />
                            :
                            <BinanceFriends darkname={darkname} dark={dark} />
                }
            </div>
        </div>
    )
}

function MyFriends({ darkname, dark, refcode, referralData }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    const [referral, setReferral] = useState("")
    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (refcode) {
            setReferral(`https://${API_Address == "rf" ? "NAMUBIT.com" : "dev.goex.kr"}/regist?ref=${refcode}`)
        }
    }, [refcode])

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className='history-right-bottom-2'>
            {
                modal
                    ?
                    <div className={`buy-modal copy ${darkname}`}>
                        <div>{t('wallet_62')}</div>
                    </div>
                    :
                    ""
            }
            <div className='dashboard-right-bottom-5'>
                <div className='dashboard-right-bottom-5-1'>
                    <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                        <div className='dashboard-right-bottom-5-3'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_108')}</div>
                        </div>
                        <div>
                            <div className={`dashboard-right-bottom-5-8 ${darkname}`}>
                                {referral}
                                <CopyToClipboard text={referral}>
                                    <text className={`dashboard-right-bottom-5-9 ${darkname}`} onClick={() => onChangeModal()}>{t('wallet_62')}</text>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dashboard-right-bottom-5-1'>
                    <div className='dashboard-right-bottom-5-5'>
                        <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                            <div className='dashboard-right-bottom-5-3'>
                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                                    <g>
                                        <path className="fill-color" d="M503.2,212.9c-15.3-81.3-60-141.3-132.7-180.3C341,16.8,309.2,8.3,275.8,5.8c-1-0.1-2.1-0.5-3.1-0.8
		c-11.1,0-22.2,0-33.3,0c-8.8,1.2-17.7,2-26.4,3.8C113,29.2,47.1,88.2,15.5,184.9c-5.3,16.2-6.5,33.8-9.6,50.8
		c-0.2,1.2-0.6,2.4-0.9,3.7c0,11.1,0,22.2,0,33.3c1.3,9,2.1,18,3.9,26.9C26.6,389.1,77.7,451.8,161,488.3
		c23.7,10.4,48.9,15.9,74.7,17.8c0.9,0.1,1.7,0.6,2.6,0.9c11.1,0,22.2,0,33.3,0c9.2-1.3,18.4-2.1,27.4-3.8
		c87.6-16.9,149.7-66.5,187.1-147.1c11.7-25.4,17.9-52.4,20-80.3c0.1-1.1,0.5-2.1,0.8-3.2c0-10.8,0-21.6,0-32.4
		C505.7,231.2,504.9,222,503.2,212.9z M338.3,308.7c-4.2,27.7-22.1,45.3-51.8,51.1c-4,0.8-8.1,1.4-12.6,2.1c0,13.6,0,27.1,0,40.8
		c-12,0-23.5,0-35.4,0c0-14,0-27.7,0-41.6c-24.6-3.9-47.4-11.7-67.7-26.3c6.1-11.9,12-23.3,18.1-35.3c14,10.1,28.9,17.6,45.2,22
		c14.4,3.9,29,5.4,43.7,1.7c8.8-2.2,15.4-6.8,16.8-16.7c1.4-9.9-2.9-17.4-11.4-21.2c-11.2-5-23-8.8-34.6-12.8
		c-11.1-3.8-22.6-6.7-33.5-11c-38.9-15.1-41.6-49.1-29.6-77.3c8.7-20.5,26.3-30.3,46.9-35.8c1.9-0.5,3.8-0.9,6.2-1.5
		c0-12.6,0-25.3,0-38.2c11.8,0,23.2,0,35.1,0c0,12.4,0,24.8,0,37.8c21.5,3.1,40,11.3,57.8,22.7c-6.1,11.2-12,22.1-18.2,33.4
		c-13-10.1-27.4-16.1-42.9-19.3c-10.3-2.1-20.7-2.3-30.9,0.6c-10,2.8-16.2,9.6-17.2,18.7c-1.1,10.1,2.5,18,13,22
		c13.8,5.4,28.3,9,42.5,13.6c10.2,3.4,20.7,6.4,30.3,11.1C331.9,261.1,342.3,282.2,338.3,308.7z"/>
                                    </g>
                                </svg>
                                <div>{t('dashboard_109')}</div>
                            </div>
                            <div className='dashboard-right-bottom-5-6'>
                                <div className='dashboard-right-bottom-5-7' style={{ opacity: ".3" }}>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-right-bottom-5-5'>
                        <div className={`dashboard-right-bottom-5-2 ${darkname}`}>
                            <div className='dashboard-right-bottom-5-3'>
                                <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                                <div>{t('dashboard_111')}</div>
                            </div>
                            <div className='dashboard-right-bottom-5-6'>
                                <div className='dashboard-right-bottom-5-7' style={{ opacity: ".3", color: "#72cf8f" }}>
                                    <FontAwesomeIcon icon={faCaretUp} style={{ paddingTop: "6px", marginRight: "7px" }} />
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='history-right-bottom-4'>
                <div className={`history-right-bottom-4-top ${darkname}`}>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_126')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_112')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_113')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_127')}</div>
                    <div className='history-right-bottom-4-top-1'><FontAwesomeIcon icon={faGift} /></div>
                    {/* <div className='history-right-bottom-4-top-1'>{t('dashboard_114')}</div> */}
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`}>
                    {
                        referralData && referralData.length === 0
                            ?
                            <div className='history-right-bottom-4-bottom-1'>
                                <img src='/img/img_nofound.png'></img>
                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                <p>{t('message_0')}</p>
                            </div>
                            :
                            referralData.map(function (a, i) {
                                return (
                                    <ReferralItem key={i} item={a} darkname={darkname} />
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}

function ReferralItem({ item, darkname }) {
    let date = item.regist_date.split("T")
    return (
        <div className={`history-right-bottom-4-bottom-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1'>{date && date[0] ? date[0] : item.regist_date}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.id}</div>
            <div className='history-right-bottom-4-bottom-2-1'>0</div>
            <div className='history-right-bottom-4-bottom-2-1'>0</div>
            {/* <div className='history-right-bottom-4-bottom-2-1'>{(Number(item.total_binance_trading) + Number(item.total_okx_trading)).toFixed(5)}</div> */}
            <div className='history-right-bottom-4-bottom-2-1'>-</div>
        </div>
    )
}

function OKXFriends({ darkname, dark }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    return (
        <div className='history-right-bottom-2'>
            <div className='history-right-bottom-2-1'>
                <div className='history-right-bottom-2-1-tit'>{t('dashboard_115')}</div>
            </div>
            <div className='history-right-bottom-2-2'>
                <div className={`history-right-bottom-2-2-date ${darkname}`}>
                    <div className='history-right-bottom-2-2-data-1'>
                        {
                            dark
                                ?
                                <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                :
                                <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                        }
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                    </div>
                    <p>-</p>
                    <div className='history-right-bottom-2-2-data-1'>
                        {
                            dark
                                ?
                                <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                :
                                <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                        }
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
                <div>
                    <div className='history-right-bottom-2-2-search'>{t('market_0')}</div>
                </div>
            </div>
            <div className='history-right-bottom-3'>
                <div className='history-right-bottom-3-1'>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_116')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt-1'>
                            <span>0</span>
                        </div>
                    </div>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_118')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt'>
                            <span>0.0000</span>
                            <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                        </div>
                    </div>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_119')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt'>
                            <span>0.0000</span>
                            <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='history-right-bottom-4'>
                <div className={`history-right-bottom-4-top ${darkname}`}>
                    <div className='history-right-bottom-4-top-1'>Date</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_120')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_121')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_122')}</div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`}>
                    <div className='history-right-bottom-4-bottom-1'>
                        <img src='/img/img_nofound.png'></img>
                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                        <p>{t('message_0')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function BinanceFriends({ darkname, dark }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    return (
        <div className='history-right-bottom-2'>
            <div className='history-right-bottom-2-1'>
                <div className='history-right-bottom-2-1-tit'>{t('dashboard_123')}</div>
            </div>
            <div className='history-right-bottom-2-2'>
                <div className={`history-right-bottom-2-2-date ${darkname}`}>
                    <div className='history-right-bottom-2-2-data-1'>
                        {
                            dark
                                ?
                                <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                :
                                <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                        }
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                    </div>
                    <p>-</p>
                    <div className='history-right-bottom-2-2-data-1'>
                        {
                            dark
                                ?
                                <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                :
                                <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                        }
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
                <div>
                    <div className='history-right-bottom-2-2-search'>{t('market_0')}</div>
                </div>
            </div>
            <div className='history-right-bottom-3'>
                <div className='history-right-bottom-3-1'>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_116')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt-1'>
                            <span>10</span>
                        </div>
                    </div>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_117')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt-1'>
                            <span>10</span>
                        </div>
                    </div>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_118')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt'>
                            <span>0.0000</span>
                            <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                        </div>
                    </div>
                    <div className={`history-right-bottom-3-1-box ${darkname}`}>
                        <div className='history-right-bottom-3-1-box-tit'>
                            <img src={`${API_Address == "rf" ? "/img/icon/star-blue.png" : "/img/icon/star-orange.png"}`} />
                            <div>{t('dashboard_119')}</div>
                        </div>
                        <div className='history-right-bottom-3-1-box-txt'>
                            <span>0.0000</span>
                            <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='history-right-bottom-4'>
                <div className={`history-right-bottom-4-top ${darkname}`}>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_120')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_121')}</div>
                    <div className='history-right-bottom-4-top-1'>{t('dashboard_122')}</div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`}>
                    <div className='history-right-bottom-4-bottom-1'>
                        <img src='/img/img_nofound.png'></img>
                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                        <p>{t('message_0')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


function Event({ darkname, dark }) {
    const { t } = useTranslation();
    const [tab1, setTab1] = useState(0)
    let arr = [1, 2, 3, 4, 5]
    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_52')}</div>
                <p className='history-right-top-2'>{t('header_53')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={tab1 === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => setTab1(0)}>{t('dashboard_124')}</div>
                    <div className={tab1 === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => setTab1(1)}>{t('dashboard_83')}</div>
                </div>
                {
                    tab1 === 0
                        ?
                        <div className='dashboard-right-event'>
                            {
                                arr.map(function (a, i) {
                                    return (
                                        <EventItem key={i} item={a} darkname={darkname} />
                                    )
                                })
                            }
                        </div>
                        :
                        <div className='dashboard-right-event'>
                            {
                                arr.map(function (a, i) {
                                    return (
                                        <EventItem key={i} item={a} darkname={darkname} />
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default Dashboard;