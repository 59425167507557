import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';

import { Position } from '../../common/C_futures/M_goex8';

function GoexOKXF8_1({ symbol, USDTSymbolF, positionData, openorder, FuturesOpenOrder }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((p) => p.instId === symbol)

    let myData1 = USDTSymbolF.filter((item) => { return item.symbol === symbol })
    let myData = myData1[0] ?? []

    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [delay, setDelay] = useState(true)
    const [message, setMessage] = useState([])
    const [positionList, setPositionList] = useState([])
    const [openorderList, setOpenorderList] = useState([])

    useEffect(() => {
        let arr = {}
        if (positionData?.instId) {
            let sym = positionData.instId.split('-')
            arr = {
                entryPrice: myData?.point ? Number(positionData.entryPrice).toFixed(myData.point) : Number(positionData.entryPrice),
                value: positionData.notionalUsd,
                ccy: sym[0],
                symbol: positionData.instId,
                side: Number(positionData.pos) >= 0 ? 'Buy' : 'Sell',
                sz: Number(positionData.pos)* Number(ctVal[0].ctVal),
                mark: myData?.point ? Number(positionData.markPx).toFixed(myData.point) : Number(positionData.markPx),
                mode: positionData.mgnMode == "cross" ? "Cross" : "Isolated",
                liq: myData?.point ? Number(positionData.liqPx).toFixed(myData.point) : Number(positionData.liqPx),
                pnl: positionData.upl,
                leverage: positionData.lever
            }
        }
        setPositionList(arr)
    }, [positionData])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].instId.split('-')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].instId,
                    orderId: openorder[i].ordId,
                    algoId: openorder[i].algoId,
                    type: openorder[i].ordType,
                    side: openorder[i].side,
                    px: openorder[i].px,
                    sz: Number(openorder[i].sz) * Number(ctVal[0].ctVal),
                    fillSz: Number(openorder[i].fillSz) * Number(ctVal[0].ctVal)
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = (item) => {
        let list = []
        let algo = []
        if (item) {
            for (let i = 0; i < item.length; i++) {
                let s = item[i]
                if (s.type == "conditional" || s.type == "trigger") {
                    if (s.symbol == symbol) algo.push({ algoId: s.algoId, instId: s.symbol })
                }
                else {
                    if (s.symbol == symbol) list.push({ ordId: s.ordId, instId: s.symbol })
                }
            }
        }

        if (list && list.length > 0) {
            FuturesAllOpenOrderCancel(list)
        }
        if (algo && algo.length > 0) {
            FuturesAllOpenOrderCancel_Algo(algo)
        }
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel", {
                ordArr: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 130, "msg": error }))
            })
        }
    }
    const FuturesAllOpenOrderCancel_Algo = async (algo) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel/algo", {
                ordArr: algo
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    FuturesOpenOrder()
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 131, "msg": error }))
            })
        }
    }
    const positionClose = (p, z) => {
        if (z === 0) {
            FuturesPositionHalfClose(p)
        }
        else {
            FuturesPositionClose(p)
        }
    }

    const FuturesPositionClose = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/close/position", {
                instId: p.instId,
                mgnMode: p.mode,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: response?.data?.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 132, "msg": error }))
                })
        }
    }

    const createPostData = (p) => {
        const timestamp1 = '' + Date.now();
        return {
            instId: p.symbol,
            tdMode: p.mode,
            lever: p.leverage,
            clOrdId: timestamp1,
            side: Number(p.sz) >= 0 ? "sell" : "buy",
            ordType: "market",
            px: "",
            sz: Math.abs(p.sz) / 2,
            // sz: Math.floor(Math.abs(p.pos) / 2) > 0 ? Math.floor(Math.abs(p.pos) / 2) : Math.abs(p.pos),
            reduceOnly: true,
            tag: "ff5b6baef008SCDE",
            tgtCcy: Number(p.sz) >= 0 ? "base_ccy" : "quote_ccy",
        };
    };

    const onSubmitFutures = async (postData, url, p) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (Number(response?.data?.code) === 0) {
                    setMessage({ type: "okx", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                }
                else {
                    if (Number(response.data.data[0].sCode == "51121")) {
                        FuturesPositionClose(p)
                    }
                    else {
                        setMessage({ type: "okx", code: Number(response?.data?.code), msg: response.data.data[0].sMsg })
                    }
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 133, "msg": error }))
            })
        }
    }

    const FuturesPositionHalfClose = (p) => {
        let url = process.env.REACT_APP_API + '/api/v1/trade/okx/swap/order'
        let postData = createPostData(p);
        onSubmitFutures(postData, url, p);
    };

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/cancel", {
                ordId: item.orderId,
                instId: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 134, "msg": error }))
                })
        }
    }

    return (
        <>
            <Modal message={message} />
            <Position positionList={positionList} openorderList={openorderList} FuturesPositionClose={positionClose} FuturesAllOpenOrderCancel={CancelAll} FuturesOpenOrderCancel={FuturesOpenOrderCancel} openorder_cancel={openorder_cancel} delay={delay} symbol={symbol} exchange={"okx"} />
        </>
    )
}

export default GoexOKXF8_1;