import '../../css/futures.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { findSmallest, changeNum } from "../function"
import { checkAlert } from '../../../store';
import { ClipLoader } from "react-spinners";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Page from '../../Page'


function Position({ setPositionAmount, positionList, symbol, currentPrice, FuturesPositionClose, exchange }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    
    const positionCancel = (list, z) => {
        if (exchange == "binance") {
            FuturesPositionClose(list, z, findSmallest(Number(list.minSize)))
        } else if (exchange == "okx") {
            FuturesPositionClose(list, z)
        } else if (exchange == "bybit") {
            FuturesPositionClose(list, z, findSmallest(Number(list.minSize)))
        }
    }
    return (
        <>
            {
                <div className='position-box'>
                    <div className={`position ${darkname}`}>
                        <div className='position-1'>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_84')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_85')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_86')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_87')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_88')}</div>
                            </div>
                        </div>
                        <div className='position-1'>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_89')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_90')}</div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'><span>{t('futures_91')}</span><span>(USDT)</span></div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_92')}(ROI) <FontAwesomeIcon icon={faCrown} /></div>
                            </div>
                            <div className='position-2'>
                                <div className='position-3'>{t('futures_93')}</div>
                            </div>
                        </div>
                    </div>
                    {
                        positionList
                            ?
                            positionList.map(function (a, i) {
                                return (
                                    <PositionWebItem key={i} positionList={a} darkname={darkname} positionCancel={positionCancel} setPositionAmount={setPositionAmount} symbol={symbol} currentPrice={currentPrice} exchange={exchange} />
                                )
                            })
                            :
                            ""
                    }
                </div>
            }
        </>
    )
}

function PositionWebItem({ positionList, darkname, positionCancel, setPositionAmount, symbol, currentPrice, exchange }) {
    const { t } = useTranslation();

    return (
        <div className={`position ${positionList.side == "Buy" ? "bl-green" : "bl-red"}`}>
            <div className='position-1'>
                <div className='position-2'>
                    <Link to={`/futures/${exchange}/${positionList.symbol}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{`${positionList.ccy}USDT`}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    {
                        positionList.side
                            ?
                            positionList.side == "Buy"
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionList?.entryPrice ?? "-"}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionList?.sz ? `${Number(Math.abs(positionList?.sz))}` : ""])} style={{ cursor: "pointer" }}>
                    <div className={`position-4 ${darkname}`}>{positionList?.sz ? changeNum(Math.abs(positionList?.sz)) : '-'}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionList?.mark ?? "-"}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionList?.mode ?? '-'}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionList?.liq ?? "-"}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{changeNum(Math.abs(positionList?.value ?? 0).toFixed(4, '0'))}</div>
                </div>
                <div className='position-2'>
                    {
                        symbol === positionList.symbol ?
                            Number((currentPrice - Number(positionList.entryPrice)) * Number(positionList.sz) * (positionList.side == "Buy" ? 1 : -1)) >= 0 ?
                                <div className={`position-4 ${darkname} green`}>
                                    {changeNum(Number((currentPrice - Number(positionList.entryPrice)) * Number(positionList.sz) * (positionList.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(positionList.entryPrice)) * Number(positionList.sz) * (positionList.side == "Buy" ? 1 : -1) * 100 / ((Number(positionList.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`position-4 ${darkname} red`}>
                                    {changeNum(Number((currentPrice - Number(positionList.entryPrice)) * Number(positionList.sz) * (positionList.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(positionList.entryPrice)) * Number(positionList.sz) * (positionList.side == "Buy" ? 1 : -1) * 100 / ((Number(positionList.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                            :
                            Number(positionList?.pnl) >= 0 ?
                                <div className={`${darkname} green`}>
                                    {changeNum(Number((positionList?.pnl)).toFixed(3))}
                                    ({(Number(positionList?.pnl) * 100 / ((Number(positionList?.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`${darkname} red`}>
                                    {changeNum(Number((positionList?.pnl)).toFixed(3))}
                                    ({(Number(positionList?.pnl) * 100 / ((Number(positionList?.entryPrice) * Math.abs(positionList.sz) * (1 / positionList.leverage)))).toFixed(2)}%)
                                </div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-4-1'>
                        <div className={`position-table-bg-1-1 ${darkname}`} onClick={() => positionCancel(positionList, 0)}>{t('futures_94')}</div>
                        <div className={`position-table-bg-1-1 ${darkname}`} onClick={() => positionCancel(positionList, 1)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OrderHistory({ setSelectedDate, selectedDate, setSelectedDate1, selectedDate1, orderdata, spinner }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const darkname = dark ? 'dark' : "";

    const [page, setPage] = useState(1)

    let ordertop = orderdata.slice((page - 1) * 4, (page) * 4)

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src={darkname == "dark" ? `/img/ico_calendar_dark.svg` : `/img/ico_calendar.svg`} className='history-right-bottom-2-2-data-img'></img>
                    {/* <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img' style={{ display: "none" }}></img> */}
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
            </div>
            <div className='position-box'>
                <div className={`position ${darkname}`}>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_117')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_118')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_119')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_120')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_121')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_122')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_123')}</div>
                    </div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`} style={{ fontSize: "12px" }}>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            orderdata && orderdata.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p><span>{t('message_0')}</span></p>
                                </div>
                                :
                                <>{
                                    ordertop.map(function (a, i) {
                                        return (
                                            <OrderDetailItem key={i} item={a} darkname={darkname} />
                                        )
                                    })}
                                    <Page initialData={orderdata} page={page} setPage={setPage} maxFirstPage={12} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function OrderDetailItem({ item, darkname }) {
    return (
        <div className={`position ${item.side == "Buy" ? "bl-green" : "bl-red"}`}>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.time ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.ccy ?? '-'}USDT</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.type ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.side ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.size ?? 0}/{(Number(item?.volume ?? 0)).toFixed(4)}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.price ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.status ?? '-'}</div>
            </div>
        </div >
    )
}

function TradeHistory({ setSelectedDate, selectedDate, setSelectedDate1, selectedDate1, orderdata, spinner }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const darkname = dark ? 'dark' : "";

    const [page, setPage] = useState(1)

    let ordertop = orderdata.slice((page - 1) * 4, (page) * 4)

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src={darkname == "dark" ? `/img/ico_calendar_dark.svg` : `/img/ico_calendar.svg`} className='history-right-bottom-2-2-data-img'></img>
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
            </div>
            <div className='position-box'>
                <div className={`position ${darkname}`}>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_49')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_50')} <span>(USDT)</span></div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_56')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_57')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_58')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_51')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_52')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_53')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_54')}</div>
                    </div>
                    <div className='position-2'>
                        <div className='position-3'>{t('futures_55')}</div>
                    </div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`}>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            orderdata && orderdata.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>{t('message_0')}</p>
                                </div>
                                :
                                <>{
                                    ordertop.map(function (a, i) {
                                        return (
                                            <TradeDetailItem key={i} item={a} darkname={darkname} />
                                        )
                                    })}
                                    <Page initialData={orderdata} page={page} setPage={setPage} maxFirstPage={4} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function TradeDetailItem({ item, darkname }) {
    return (
        <div className={`position ${item.side == "Buy" ? "bl-green" : "bl-red"}`}>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.ccy ?? "-"}USDT</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum(Number(item?.volume ?? 0).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.side ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum(item?.price ?? 0)}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum(Number(item?.size ?? 0))}/{changeNum((Number(item?.size ?? 0) * Number(item?.price ?? 0)).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname} ${Number(item.pnl) >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(item?.pnl ?? 0).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum(Math.abs(item?.fee ?? 0).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname} green`}>{changeNum((item?.reward ?? 0).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum((item?.pnl - item?.fee + item?.reward ?? 0).toFixed(4))}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.time ?? '-'}</div>
            </div>
        </div >
    )
}

function OpenOrder({ openorder, symbol, all, darkname, delay, openorder_cancel, FuturesOpenOrderCancel }) {
    const { t } = useTranslation();

    return (
        <>
            {
                <div className='position-box'>
                    <div className={`position ${darkname}`}>
                        <div className='position-2'>
                            <div className='position-3'>{t('futures_97')}</div>
                        </div>
                        <div className='position-2'>
                            <div className='position-3'>{t('futures_98')}</div>
                        </div>
                        <div className='position-2'>
                            <div className='position-3'>Side</div>
                        </div>
                        <div className='position-2'>
                            <div className='position-3'>{t('futures_99')}</div>
                        </div>
                        <div className='position-2'>
                            <div className='position-3'>{t('futures_100')}/{t('futures_101')}</div>
                        </div>
                        <div className='position-2'>
                            <div className='position-3'>{t('futures_102')}</div>
                        </div>
                    </div>
                    {
                        openorder.map(function (a, i) {
                            if (all) {
                                return (
                                    <OpenOrderItem key={i} item={a} delay={delay} openorder_cancel={openorder_cancel} darkname={darkname} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                )
                            }
                            else {
                                if (a.symbol === symbol) {
                                    return (
                                        <OpenOrderItem key={i} item={a} delay={delay} openorder_cancel={openorder_cancel} darkname={darkname} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                    )
                                }
                            }
                        })
                    }
                </div>
            }
        </>
    )
}

function OpenOrderItem({ item, delay, openorder_cancel, darkname, FuturesOpenOrderCancel }) {
    const { t } = useTranslation();

    return (
        <div className={`position ${item.side == "Buy" ? "bl-green" : "bl-red"}`}>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item.ccy}USDT</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.type ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.side ?? '-'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{item?.px ?? '0'}</div>
            </div>
            <div className='position-2'>
                <div className={`position-4 ${darkname}`}>{changeNum(0)}/{changeNum(item?.sz ?? 0)}</div>
            </div>
            <div className='position-2'>
                <div className='position-4-1'>
                    {
                        openorder_cancel && delay
                            ?
                            <td className={`position-table-bg-1-1 ${darkname}`} style={{ cursor: "pointer" }} onClick={() => FuturesOpenOrderCancel(item)}>
                                {t('futures_102')}
                            </td>
                            :
                            <td className={`position-table-bg-1-1 ${darkname}`} style={{ cursor: "pointer" }}>
                                <ClipLoader color="#595959" size='14px' />
                            </td>
                    }
                </div>
            </div>
        </div>
    )
}

function Multi() {
    const { t } = useTranslation();
    return (
        <div className='futures-multi'>
            <div className='futures-multi-1'>
                <div>{t('futures_104')}</div>
                <div>{t('futures_105')}</div>
            </div>
            <div className='futures-multi-2'>
                <div className='futures-multi-2-1'>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_107')}</div>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_108')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>1</span></option>
                            <option><span>2</span></option>
                            <option><span>3</span></option>
                            <option><span>4</span></option>
                            <option><span>5</span></option>
                        </select>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_109')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>X</span> <span>1</span></option>
                            <option><span>X</span> <span>2</span></option>
                            <option><span>X</span> <span>3</span></option>
                            <option><span>X</span> <span>4</span></option>
                            <option><span>X</span> <span>5</span></option>
                        </select>
                    </div>
                </div>
                <div className='futures-multi-2-2'>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 1</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 2</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 3</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 4</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 5</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Alarm({ setTradeVolume, tradeVolume }) {
    const { t } = useTranslation();
    let Alert = useSelector((state) => { return state.Alert });
    let dispatch = useDispatch();

    const onChangeTradeAlert = (e) => {
        dispatch(checkAlert({ num: 0, value: e.target.value }))
    }
    const onChangeFilledAlert = (e) => {
        dispatch(checkAlert({ num: 1, value: e.target.value }))
    }
    const onChangeOrderAlert = (e) => {
        dispatch(checkAlert({ num: 2, value: e.target.value }))
    }
    const onChangeCancelAlert = (e) => {
        dispatch(checkAlert({ num: 3, value: e.target.value }))
    }
    const onChangeTradeVolume = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setTradeVolume(onlyNumber)
        localStorage.setItem('TradeVolume', onlyNumber)
    }

    return (
        <div className='futures-alarm'>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_111')}</div>
                <select value={Alert[0]} onChange={onChangeTradeAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_112')}</div>
                <input type='number' placeholder='0' value={tradeVolume} onChange={onChangeTradeVolume} />
            </div>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_113')}</div>
                <select value={Alert[1]} onChange={onChangeFilledAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_147')}</div>
                <select value={Alert[2]} onChange={onChangeOrderAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                {/* <div className='futures-alarm-1'>Order Cancel Alert</div>
                <select value={Alert[3]} onChange={onChangeCancelAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select> */}
            </div>
        </div>
    )
}

export { Multi, Alarm, OpenOrder, OrderHistory, TradeHistory, Position }