import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';

import { Position } from '../../common/C_futures/M_goex8';
import { RoundDown } from '../../common/function';

function GoexByF8_1({ symbol, USDTSymbolF, positionData, marginbtn, openorder, exinfo }) {
    const { t } = useTranslation();
    let myData1 = USDTSymbolF.filter((item) => { return item.symbol === symbol })
    let myData = myData1[0]
    let entryPrice = positionData?.avgPrice ? positionData?.avgPrice : positionData?.entryPrice ? positionData?.entryPrice : ""
    let minSize = exinfo?.lotSizeFilter?.minOrderQty ?? 0

    let dispatch = useDispatch()
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [delay, setDelay] = useState(true)
    const [message, setMessage] = useState([])
    const [positionList, setPositionList] = useState([])
    const [openorderList, setOpenorderList] = useState([])

    useEffect(() => {
        let arr = {}
        if (positionData?.symbol) {
            let sym = positionData.symbol.split('USDT')
            arr = {
                entryPrice: myData?.point ? Number(entryPrice).toFixed(myData.point) : Number(entryPrice),
                value: positionData.positionValue,
                ccy: sym[0],
                symbol: positionData.symbol,
                side: positionData.side,
                sz: positionData.size,
                mark: myData?.point ? Number(positionData.markPrice).toFixed(myData.point) : Number(positionData.markPrice),
                mode: positionData?.markPrice ? marginbtn == 0 ? "Cross" : "Isolated" : "-",
                liq: myData?.point ? Number(positionData.liqPrice).toFixed(myData.point) : Number(positionData.liqPrice),
                pnl: positionData.unrealisedPnl,
                leverage: positionData.leverage,
                minSize: minSize
            }
        }
        setPositionList(arr)
    }, [positionData])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].symbol.split('USDT')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].symbol,
                    orderId: openorder[i].orderId,
                    type: openorder[i].orderType,
                    side: openorder[i].side,
                    px: openorder[i].px,
                    sz: openorder[i].sz
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const FuturesPositionClose = async (p, z, m) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order", {
                category: "linear",
                symbol: p.symbol,
                side: p.side == "Buy" ? "Sell" : "Buy",
                orderType: 'Market',
                qty: z === 0 ? String(RoundDown(Number(p.sz) / 2, m)) : p.sz,
                closeOnTrigger: true
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) == 1000) {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: response?.data?.msg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 78, "msg": error }))
            })
        }
    }

    const FuturesAllOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order/cancel/all", {
                category: "linear",
                symbol: symbol
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) == 1000) {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 77, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order/cancel", {
                category: "linear",
                orderId: item.orderId,
                symbol: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) == 1000) {
                        setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "bybit", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 117, "msg": error }))
                })
        }
    }

    return (
        <>
            <Modal message={message} />
            <Position positionList={positionList} openorderList={openorderList} FuturesPositionClose={FuturesPositionClose} FuturesAllOpenOrderCancel={FuturesAllOpenOrderCancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} openorder_cancel={openorder_cancel} delay={delay} symbol={symbol} exchange={"bybit"} />
        </>
    )
}

export default GoexByF8_1;