import '../css/futures.css'
import '../css/goexF.css'
import Chart from '../spotchart'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, check_Error, changeBn_markPrice, change_Bn_openorder } from '../../store';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../ErrorBoundary'
import isEqual from 'lodash/isEqual';
import { changeNum, padZero } from "../common/function"
import '@fortawesome/fontawesome-free/css/all.css';

import BFsection1 from './Bfutures/BFsection1';
import BFsection4 from './Bfutures/BFsection4';
import BFsection8 from './Bfutures/BFsection8';
import BFsection7 from './Bfutures/BFsection7';
import BFsection6 from './Bfutures/BFsection6';

import GoexBnF1 from './goexBnF/goexBnF1';
import GoexBnF1_1 from './goexBnF/goexBnF1-1';
import GoexBnF4 from './goexBnF/goexBnF4';
import GoexBnF7 from './goexBnF/goexBnF7';
import GoexBnF8 from './goexBnF/goexBnF8';
import GoexBnF8_1 from './goexBnF/goexBnF8-1';
import GoexBnF8_2 from './goexBnF/goexBnF8-2';

import { W_goex6 } from '../common/C_futures/W_goex6';

let data = []
let preArr = []
let arr = []
let pre_symbol = ""
let prePrice = 0

function BFutures() {
    const { t } = useTranslation();
    let BinanceF = useSelector((state) => { return state.Binance_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Binance_position = useSelector((state) => { return state.Binance_position });
    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let login = useSelector((state) => { return state.login.value });
    let Alert = useSelector((state) => { return state.Alert });
    let Binance_openorder_futures = useSelector((state) => { return state.Binance_openorder_futures });

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab3, setTab3] = useState(true)
    const [tab4, setTab4] = useState(1)
    const [tab5, setTab5] = useState(0)
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)
    const [speedorder, setSpeedorder] = useState([0, 0])
    const [fwallet, setFwallet] = useState([])
    const [current, setCurrent] = useState('0');
    const [orderReload, setOrderReload] = useState(false)

    const [exinfo, setExinfo] = useState([])
    const [totalexinfo, setTotalExinfo] = useState([])
    const [positionAmount, setPositionAmount] = useState([false, ""])
    const [positionData, setPositionData] = useState([]);

    const [max, setMax] = useState(4)
    const [maxCnt, setMaxCnt] = useState(true)
    const [firstmargin, setFirstmargin] = useState([])
    const [maxPosition, setMaxPosition] = useState(0)
    const [marginbtn, setMarginbtn] = useState(0)
    const [marginbtn1, setMarginbtn1] = useState(1)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [checkCurrentPosition, setCheckCurrentPosition] = useState(false)
    const [btnbtn, setBtnbtn] = useState(0);

    const [markPrice, setMarkPrice] = useState([])
    const [nextTime, setNextTime] = useState("00:00:00")
    const [close, setClose] = useState(false)
    const [fundingFee, setFundingFee] = useState(0)
    const [positionDirect, setPositionDirect] = useState(0)
    const [risk_balance, setRisk_balance] = useState(0)
    const [risk_position, setRisk_position] = useState(0)
    const [margin_type, setMargin_type] = useState("")

    const [tmm, setTMM] = useState(0)
    const [upnl, setUPNL] = useState(0)
    const [pBoth, setPBoth] = useState([0, 0, 1])

    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)
    const [openorder, setOpenorder] = useState([])

    let USDTSymbolF = BinanceF ? BinanceF : [];
    dispatch(checkSelectType(1))

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    useEffect(() => {
        setMaxCnt(true)
        setMtab(0)
    }, [symbol])

    USDTSymbolF.map(function (a, i) {
        if (a.symbol === symbol) {
            if (maxCnt) {
                setMax(a.point)
                setMaxCnt(false)
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0.00000000001) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "USDT" : ""} | Binance`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "USDT" : ""} | Binance`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        setCurrentPrice(0.00000000001)
    }, [symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesInstruments(token)
        }
    }, [symbol, marginbtn])

    useEffect(() => {
        if (login) {
            FuturesAllOpenOrder();
        }
    }, [login])

    const Bn_OpenOrder = (arr) => {
        dispatch(change_Bn_openorder(arr))
    }

    useEffect(() => {
        if (Binance_openorder_futures && Binance_openorder_futures.o) {
            let arr = [...openorder];
            let tmp = Binance_openorder_futures.o

            if (tmp.X == "NEW") {
                let fil = arr.filter((item) => item.orderId != tmp.i)
                fil.push({
                    orderId: tmp.i, symbol: tmp.s, type: tmp.o, price: tmp.o.toUpperCase() != "LIMIT" && tmp.o.toUpperCase() != "MARKET" ? tmp.sp : tmp.p,
                    executedQty: tmp.z, origQty: tmp.q, side: tmp.S, reduceOnly: tmp.R, timeInForce: tmp.f
                })
                setOpenorder(fil)
                Bn_OpenOrder(fil)
            }
            else if (tmp.X == "PARTIALLY_FILLED") {
                let arr1 = []
                let fil = arr.filter((item) => item.orderId != tmp.i)

                fil.map(function (a, i) {
                    arr1.push({
                        orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                        executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                    })
                })
                arr1.push({
                    orderId: tmp.i, symbol: tmp.s, type: tmp.o, price: tmp.o.toUpperCase() != "LIMIT" && tmp.o.toUpperCase() != "MARKET" ? tmp.sp : tmp.p,
                    executedQty: tmp.z, origQty: tmp.q, side: tmp.S, reduceOnly: tmp.R, timeInForce: tmp.f
                })

                setOpenorder(arr1)
                Bn_OpenOrder(arr1)
            }
            else if (tmp.X == "CANCELED" || tmp.X == "EXPIRED") {
                let fil = arr.filter((item) => item.symbol != tmp.s)
                FuturesOpenOrder(fil, tmp.s);
            }
            else {
                let fil = arr.filter((item) => item.orderId != tmp.i)
                setOpenorder(fil)
                Bn_OpenOrder(fil)
            }
            setOrderReload(e => !e)
        }
    }, [Binance_openorder_futures])

    const FuturesOpenOrder = async (fil, sym) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/open/orders", {
                params: {
                    symbol: sym
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    tmp.map(function (a, i) {
                        arr.push({
                            orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                            executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                        })
                    })
                    setOpenorder([...fil, ...arr])
                    Bn_OpenOrder([...fil, ...arr])
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 75, "msg": error }))
            })
        }
    }

    const FuturesAllOpenOrder = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/open/orders", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    tmp.map(function (a, i) {
                        arr.push({
                            orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                            executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                        })
                    })
                    setOpenorder(arr)
                    Bn_OpenOrder(arr)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 76, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let nextArr = []
        let cnt = false
        let t_tmm = 0
        let t_upnl = 0
        let t_price = 0
        let t_quantity = 0
        let t_leverage = 1

        const objString = localStorage.getItem('token');
        if (objString && Binance_position) {
            arr = Binance_position.filter(i => Number(i.positionAmt) != 0)

            arr.map(function (a, i) {
                if (symbol == a.symbol) {
                    t_price = Number(a.entryPrice)
                    t_quantity = Number(a.positionAmt)
                    t_leverage = Number(a.leverage)
                    cnt = true
                }
                else {
                    t_tmm += Number(a.notional)
                    t_upnl += Number(a.unRealizedProfit)
                }
                nextArr.push({ symbol: a.symbol, size: a.positionAmt })
            })

            setCheckCurrentPosition(cnt)

            let ArraysEqual = isEqual(preArr, nextArr);

            if (!ArraysEqual) {
                setOrderReload(e => !e)
            }

            preArr = nextArr
            setTMM(t_tmm)
            setUPNL(t_upnl)
            setPBoth([t_price, t_quantity, t_leverage])
            setPositionData(arr)
        }
    }, [Binance_position, symbol])

    useEffect(() => {
        let cnt = 0
        let marginType = ""
        let risk_cross = 0
        let risk_isolated = 0
        let risk_cross_WB = 0
        let risk_isolated_WB = 0

        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let tmp = positionData[i]
                if (symbol == tmp.symbol) {
                    cnt = 1
                    setFundingFee(Math.abs(tmp.notional))
                    setPositionDirect(Number(tmp.positionAmt))

                    marginType = tmp.marginType
                    risk_isolated = Math.abs(Number(tmp.notional))
                    risk_isolated_WB = Math.abs(Number(tmp.notional) / Number(tmp.leverage))
                }
                if (tmp.marginType == "CROSSED") {
                    risk_cross += Math.abs(Number(tmp.notional))
                }
                else {
                    risk_cross_WB += Math.abs(Number(tmp.notional) / Number(tmp.leverage))
                }
            }
            if (cnt === 0) {
                setFundingFee(0)
                setPositionDirect(0)
                setRisk_balance(0)
                setRisk_position(0)
                setMargin_type("0")
            }
            else {
                setMargin_type(marginType)
                if (marginType == "ISOLATED") {
                    setRisk_position(risk_isolated)
                    setRisk_balance(risk_isolated_WB)
                }
                else {
                    setRisk_position(risk_cross)
                    setRisk_balance(risk_cross_WB)
                }
            }
        }
    }, [positionData])

    useEffect(() => {
        pre_symbol = symbol
        let socket = new WebSocket(`wss://fstream.binance.com/stream?streams=${symbol.toLowerCase()}@markPrice@1s`);
        socket.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp) {
                    setMarkPrice(tmp.data)
                    dispatch(changeBn_markPrice(tmp.data))

                    let date = Math.floor((tmp.data.T - new Date()) / 1000)
                    let formattedDate = `${padZero(Math.floor(date / (60 * 60)))}:${padZero(Math.floor((date / 60) % 60))}:${padZero(Math.floor(date % 60))}`;
                    setNextTime(formattedDate)
                }
            }
        }
        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };
        socket.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket.close();
        }
    }, [symbol, close])

    useEffect(() => {
        if (Binance_wallet_futures.length > 0) {
            setCurrent(Binance_wallet_futures[0].availableBalance)
            setFwallet(Binance_wallet_futures[0])
        }
    }, [Binance_wallet_futures])

    useEffect(() => {
        ExchangeInformation()
    }, [symbol])

    const ExchangeInformation = async () => {
        await axios.get('https://fapi.binance.com/fapi/v1/exchangeInfo'
        ).then((response) => {
            let tmp = response?.data?.symbols
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) {
                        setExinfo(a.filters)
                    }
                })
                setTotalExinfo(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesLever(token)
        }
    }, [symbol])

    const FuturesLever = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/leverage`, {
            params: {
                symbol: symbol
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp[0]) {
                if (tmp[0].brackets && tmp[0].brackets.length > 0) {
                    setFirstmargin(response.data.data[0].brackets)
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 47, "msg": error }))
        })
    }

    const FuturesInstruments = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/position`, {
            params: {
                symbol: symbol
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data?.symbolData
            let tmp1 = response?.data?.data?.positionData

            if (tmp && tmp[0]) {
                setMarginbtn(tmp[0].marginType == "cross" ? 0 : 1)
                setMarginbtn1(tmp[0].leverage)
            }
            if (tmp1) {
                let cnt = false
                for (let i = 0; i < tmp1.length; i++) {
                    if (symbol == tmp1[i].symbol) {
                        cnt = true
                    }
                }
                setCheckCurrentPosition(cnt)
                setPositionData(tmp1)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 48, "msg": error }))
        })
    }

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };
    let positionD = positionData.filter((item) => item.symbol == symbol)

    return (
        <div className={`futures ${darkname}`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            {
                API_Address == "rf"
                    ?
                    windowtype
                        ?
                        <div className='futures-box'>
                            <ErrorBoundary>
                                <BFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                            </ErrorBoundary>
                            <div className={tab ? 'futures-section2' : 'futures-section2-check'}>
                                <div className='futures-section3'>
                                    <div className='futures-section5'>
                                        <ErrorBoundary>
                                            <BFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                                        </ErrorBoundary>
                                        <ErrorBoundary>
                                            <BFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} />
                                        </ErrorBoundary>
                                    </div>
                                    <ErrorBoundary>
                                        <BFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} totalexinfo={totalexinfo} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} setOrderReload={setOrderReload} />
                                    </ErrorBoundary>
                                </div>
                                <ErrorBoundary>
                                    <BFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                                </ErrorBoundary>
                            </div>
                        </div>
                        :
                        <div className='futures-box'>
                            <ErrorBoundary>
                                <BFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                            </ErrorBoundary>
                            <div className='futures-section9'>
                                <ErrorBoundary>
                                    <BFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <BFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} totalexinfo={totalexinfo} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} setOrderReload={setOrderReload} />
                                </ErrorBoundary>
                                <div className='futures-section10'>
                                    <ErrorBoundary>
                                        <BFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <BFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                                    </ErrorBoundary>
                                </div>

                                <div style={{ padding: "5px 5px 0" }}>
                                    <div className='futures-section4-top-9 display-none-700-ok'>
                                        <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                                        <div className='futures-section4-top-9-2 green'>{fwallet.balance ? changeNum((risk_balance * 100 / (Number(fwallet.balance) + Number(fwallet.crossUnPnl))).toFixed(2)) : "0.00"}%</div>
                                        <div className='futures-section4-top-9-2'>%</div>
                                    </div>
                                    <div className='futures-section4-top-10 display-none-700-block'>
                                        <div className='futures-section4-top-10-1'>
                                            <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                                            <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum((Number(fwallet.balance) + Number(fwallet.crossUnPnl)).toFixed(3)) : "0.000"}</div>
                                            <div className='futures-section4-top-10-1-2'>USDT</div>
                                        </div>
                                        <div className='futures-section4-top-10-1'>
                                            <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                                            <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum(Number(fwallet.balance).toFixed(3)) : "0.000"}</div>
                                            <div className='futures-section4-top-10-1-2'>USDT</div>
                                        </div>
                                        <div className='futures-section4-top-10-1'>
                                            <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                                            <div className='futures-section4-top-10-1-2'>{fwallet.availableBalance ? changeNum(Number(fwallet.availableBalance).toFixed(3)) : "0.000"}</div>
                                            <div className='futures-section4-top-10-1-2'>USDT</div>
                                        </div>
                                        {/* <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>Maintenance Margin</div>
                                        <div className='futures-section4-top-10-1-2'>-</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    windowtypeM
                        ?
                        <div className='futures-box font-family'>
                            <div className='futures-section11'>
                                <W_goex6 isBlinking={isBlinking} handleButtonClick={handleButtonClick} changeTab={changeTab} tab={tab} data={data} currentPrice={currentPrice} priceDirect={priceDirect} max={max} mark={markPrice?.p ?? 0} index={markPrice?.i ?? 0} MOrder={exinfo[2]?.maxQty ?? 0} MPosition={maxPosition} funding={markPrice?.r ?? 0} nextTime={nextTime} feeAmount={fundingFee} positionDirect={positionDirect} />

                                <div className='futures-section14'>
                                    <ErrorBoundary>
                                        <GoexBnF1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <div className={tab1 ? `futures-section16 ${darkname}` : `futures-section16-check ${darkname}`}>
                                            <div style={{ width: "100%", height: "880px", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                                <Chart exchange={"Binance"} market={"futures"} />
                                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "500px", width: "50%" }} /></div>
                                            </div>
                                        </div>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <GoexBnF7 tab1={tab1} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} maxPosition={maxPosition} fundingFee={fundingFee} nextTime={nextTime} positionDirect={positionDirect} />
                                    </ErrorBoundary>
                                </div>
                                <div className={`futures-section15 ${darkname}`}>
                                    <ErrorBoundary>
                                        <GoexBnF8 tab5={tab5} setTab5={setTab5} symbol={symbol} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} totalexinfo={totalexinfo} currentPrice={currentPrice} marginbtn={marginbtn} orderReload={orderReload} openorder={openorder} />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <ErrorBoundary>
                                <GoexBnF4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                            </ErrorBoundary>
                        </div>
                        :
                        <div className={`futuresM font-family ${darkname}`}>
                            <div className='futuresM-list' onClick={() => setMtab(2)}>
                                <div className={`futuresM-list-1 ${darkname}`}>{symbol}</div>
                                <div className={`futuresM-list-2 ${data?.priceChangePercent >= 0 ? "green" : "red"}`}>
                                    <div className='futuresM-list-2-1'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                    <div className='futuresM-list-2-2'>{Number(data?.priceChangePercent ?? 0).toFixed(2)}%</div>
                                </div>
                            </div>
                            <div className={`futuresM-top ${darkname}`}>
                                <div className='futuresM-top-box'>
                                    <div className={Mtab == 0 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(0)}>차트</div>
                                    <div className={Mtab == 1 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(1)}>호가</div>
                                    <div className={Mtab == 2 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(2)}>리스트</div>
                                    <div className={Mtab == 3 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(3)}>포지션</div>
                                    <div className={Mtab == 4 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(4)}>내역</div>
                                </div>
                            </div>

                            <div className='futuresM-mid-1' style={{ display: Mtab == 0 ? "" : "none" }}>
                                <div style={{ width: "100%", height: "calc(100% - 60px)", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                    <Chart exchange={"Binance"} market={"futures"} />
                                    <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "280px", width: "50%" }} /></div>
                                </div>
                                <div className='futuresM-mid-1-btn'>
                                    <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => setMtab(1)}>
                                        <div className={`futures-section4-top-5-3-1-1`}>{t('futures_17')}</div>
                                    </div>
                                    <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => setMtab(1)}>
                                        <div className={`futures-section4-top-5-3-1-2`}>{t('futures_18')}</div>
                                    </div>
                                </div>
                            </div>


                            <div className='futuresM-mid-2' style={{ display: Mtab == 1 ? "" : "none" }}>
                                <div className='futures-section10'>
                                    <ErrorBoundary>
                                        <GoexBnF7 tab1={tab1} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} maxPosition={maxPosition} fundingFee={fundingFee} nextTime={nextTime} positionDirect={positionDirect} />
                                    </ErrorBoundary>
                                    {
                                        Mordertab
                                            ?
                                            <ErrorBoundary>
                                                <GoexBnF4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                                            </ErrorBoundary>
                                            :
                                            ""
                                    }
                                </div>
                                <div className='futures-M-mid-2-btn' onClick={() => setMordertab(e => !e)}>
                                    {
                                        Mordertab
                                            ?
                                            <i class="fa-solid fa-circle-check" style={{ color: "#4285F4", marginRight: "7px" }}></i>
                                            :
                                            <i class="fa-regular fa-circle-check" style={{ color: "gray", marginRight: "7px" }}></i>
                                    }
                                    <span className="text">주문창</span>
                                </div>
                            </div>
                            {
                                Mtab === 2
                                    ?
                                    <GoexBnF1_1 tab={tab} USDTSymbolF={USDTSymbolF} symbol={symbol} positionData={positionData} Mtab={Mtab} />
                                    :
                                    ""
                            }

                            <div style={{ display: Mtab == 3 ? "" : "none", width: "100%", height: "100%" }}>
                                <GoexBnF8_1 symbol={symbol} USDTSymbolF={USDTSymbolF} positionData={positionD && positionD[0] ? positionD[0] : []} totalexinfo={totalexinfo} openorder={openorder} />
                            </div>

                            <div style={{ display: Mtab == 4 ? "" : "none", width: "100%", height: "100%" }}>
                                <GoexBnF8_2 symbol={symbol} orderReload={orderReload} />
                            </div>
                        </div>
            }
        </div>
    )
}

export default BFutures;