import { useEffect, useState } from 'react';
import './css/market.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAnglesRight, faAnglesLeft, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from 'react-redux'

const createArr = (n) => {
    const iArr = new Array(n);
    for (var i = 0; i < n; i++)  iArr[i] = i + 1;
    return iArr;
}

function Page({ initialData, page, setPage, maxFirstPage, loca }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        if (page === 1) setBlockNum(0)
    }, [page])

    let len = 0
    if (initialData) {
        len = initialData.length;
    }
    let maxPage = 0
    if (parseInt(Number(len) % maxFirstPage) === 0) {
        maxPage = parseInt(Number(len) / maxFirstPage)
    }
    else {
        maxPage = parseInt(Number(len) / maxFirstPage) + 1;
    }
    let pageLimit = Math.min(parseInt(Number(len) / maxFirstPage) + 1, 5);

    const [blockNum, setBlockNum] = useState(Math.floor((page - 1) / 5));
    let lastBlock = Math.ceil(maxPage / 5) - 1

    const v = Number(blockNum * pageLimit);
    const iArr = createArr(maxPage);
    let pArr = iArr.slice(v, Number(pageLimit) + v);

    const firstPage = () => {
        setPage(1)
        setBlockNum(0)
    }

    const lastPage = () => {
        setPage(maxPage)
        setBlockNum(Math.ceil(maxPage / pageLimit) - 1)
    }

    const prevPage = () => {
        setBlockNum(n => n - 1);
        setPage((blockNum) * pageLimit);
    }
    const nextPage = () => {
        setBlockNum(n => n + 1);
        setPage((blockNum + 1) * pageLimit + 1);
    }

    const nowPage = (n) => {
        setPage(n)
    }

    return (
        <>
            <div className={`market-paging ${loca == "market" ? "market-paging-mobile" : ""}`}>
                <div className={`market-paging-pages ${darkname}`}>
                    {
                        page <= 5
                            ?
                            ""
                            :
                            <>
                                <div className={`market-paging-btn ${darkname}`} onClick={firstPage}>
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </div>
                                <div className={`market-paging-btn ${darkname}`} onClick={prevPage}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                            </>
                    }
                    {pArr.map(num => (
                        page == num ?
                            (<span key={num} className='active'>
                                {num}
                            </span>) :
                            (<span key={num} className='' onClick={() => {
                                nowPage(num);
                            }}>
                                {num}
                            </span>)
                    ))}
                    {
                        blockNum === lastBlock
                            ?
                            ""
                            :
                            <>
                                <div className={`market-paging-btn ${darkname}`} onClick={nextPage}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                                <div className={`market-paging-btn ${darkname}`} onClick={lastPage}>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    );
}

export default Page;