import '../../css/futures.css'
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Range, getTrackBackground } from 'react-range';


function Margin({ setMargintab, marginbtn1, FuturesChangeLever, firstmargin }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let dark = useSelector((state) => { return state.Darkmode.value });
    const { t } = useTranslation();
    const darkname = dark ? 'dark' : "";

    const [btnpercent, setBtnpercent] = useState([marginbtn1]);

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const onChangePercent = (e) => {
        const { value } = e.target;
        if (Number(value) <= Number(firstmargin)) {
            let onlyNumber = value.replace(/[^0-9]/g, '');
            if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
                onlyNumber = onlyNumber.substring(1);
            }
            setBtnpercent([onlyNumber])
        }
    }

    return (
        <div className='futures-margin-modal'>
            <div className='futures-margin-modal-1'>
                <div className={`futures-margin-modal-1-1 ${darkname}`}>
                    <div className='futures-margin-modal-1-1-tit' style={{ textAlign: "center" }}>{t('futures_35')}</div>
                    <div className={`futures-margin-modal-1-1-wrap ${darkname}`}>
                        <div className={`futures-margin-modal-1-1-txt ${darkname}`}>
                            <div className='futures-section4-top-5-4' >
                                <div className='futures-reverage-input'>
                                    <input type='number' className='futures-margin-output' id="output" value={btnpercent[0]} onChange={onChangePercent} style={{ width: "35px", marginTop: "0" }} ref={inputRef} />
                                    <div style={{ opacity: ".5" }}>x</div>
                                </div>
                                {/* <output className='futures-margin-output' id="output"><span>{btnpercent[0]}</span> x</output> */}
                                <Range
                                    values={btnpercent}
                                    step={1}
                                    min={1}
                                    max={Number(firstmargin)}
                                    onChange={e => { setBtnpercent(e) }}
                                    renderTrack={({ props, children }) => (
                                        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "30px", display: "flex", width: "93%" }}>
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px", width: "100%", borderRadius: "4px",
                                                    background: getTrackBackground({
                                                        values: btnpercent,
                                                        colors: [`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`, "#ccc"],
                                                        min: 1,
                                                        max: Number(firstmargin),
                                                    }),
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div {...props} style={{ ...props.style, height: "25px", width: "25px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                            <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? `${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}` : "#CCC" }} />
                                        </div>
                                    )}
                                />
                                <div className='futures-section4-top-5-4-1'>
                                    <div className='futures-section4-top-5-4-2'>0 x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor(firstmargin / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{Math.floor(firstmargin / 2)} x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor((firstmargin * 3) / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{firstmargin} x</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='futures-margin-modal-2'>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([1]) }}>1x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([5]) }} style={{ display: firstmargin >= 5 ? "" : "none" }}>5x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([10]) }} style={{ display: firstmargin >= 10 ? "" : "none" }}>10x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([20]) }} style={{ display: firstmargin >= 20 ? "" : "none" }}>20x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([50]) }} style={{ display: firstmargin >= 50 ? "" : "none" }}>50x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([75]) }} style={{ display: firstmargin >= 75 ? "" : "none" }}>75x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([100]) }} style={{ display: firstmargin >= 100 ? "" : "none" }}>100x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([125]) }} style={{ display: firstmargin >= 125 ? "" : "none" }}>125x</div>
                    </div>
                    <div className='futures-margin-modal-1-1-btn'>
                        <div className='futures-margin-modal-1-1-btn-1' onClick={() => { setMargintab(false); FuturesChangeLever(btnpercent[0]) }}>{t('futures_36')}</div>
                        <div className='futures-margin-modal-1-1-btn-2' onClick={() => setMargintab(false)}>{t('futures_37')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Margin }