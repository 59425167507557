import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndent, faOutdent } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { changeNum } from "../function"
import IMG from '../../img';

function W_goex6({ isBlinking, handleButtonClick, changeTab, tab, data, currentPrice, priceDirect, max }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let darkname = dark ? "dark" : ""

    let imgcheck = true
    if (IMG && !IMG.includes(data.ccy)) {
        imgcheck = false
    }
    
    return (
        <div className={`futures-section13 ${darkname}`}>
            <div className={`futures-mid-top-1 ${darkname}`}>
                <table>
                    <thead>
                        <tr>
                            <th>
                                {
                                    tab
                                        ?
                                        <FontAwesomeIcon icon={faOutdent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'}
                                            onClick={() => { handleButtonClick(); changeTab(tab ? false : true) }} style={{ cursor: "pointer" }} />
                                        :
                                        <FontAwesomeIcon icon={faIndent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'}
                                            onClick={() => { handleButtonClick(); changeTab(tab ? false : true) }} style={{ cursor: "pointer" }} />
                                }
                                <div style={{ paddingLeft: "22px", fontSize: "18px", display: "flex", alignItems: "center", paddingTop: "4px" }}>
                                    <img src={imgcheck ? `/img/coin/${data.ccy}.png` : `/img/coin/origin.png`} style={{ width: "22px", paddingRight: "8px" }} />
                                    <span>{data.ccy}</span><span>/USDT</span>
                                </div>
                                <div className='futures-mid-top-1-1'>
                                    <div className={`futures-orderbook-2-4 ${priceDirect ? "green" : "red"}`}>
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                    <div className={`futures-mid-top-1-2 ${Number(data.priceChangePercent) >= 0 ? "green" : "red"}`}>
                                        <div><span>{Number(data.priceChangePercent) >= 0 ? "+" : ""}</span><span>{data.priceChangePercent ? Number(data.priceChangePercent).toFixed(2) : 0}</span><span>%</span></div>
                                        <div>{data.priceChange ? Number(data.priceChange).toFixed(max) : 0}</div>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className='futures-mid-top-1-4 display-none-1150'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('market_6')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{data.highPrice === undefined ? "-" : changeNum(Number(data.highPrice).toFixed(max))}</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-4 display-none-1150'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('market_7')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{data.lowPrice === undefined ? "-" : changeNum(Number(data.lowPrice).toFixed(max))}</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-3 display-none-1400'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('spot_0')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{data.volume === undefined ? "-" : changeNum((Number(data.volume) / 1000000).toFixed(3))}</span><span>M</span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    )
}

export { W_goex6 }