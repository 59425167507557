import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { RoundDown } from "../../common/function"

import { Position } from '../../common/C_futures/M_goex8';

function GoexBnF8_1({ symbol, USDTSymbolF, positionData, totalexinfo, openorder }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    let myData1 = USDTSymbolF.filter((item) => { return item.symbol === symbol })
    let myData = myData1[0]
    let minSize1 = totalexinfo.filter((item) => { return item.symbol === symbol })
    let minSize = minSize1[0]?.filters[2]?.minQty ?? 0
    
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [delay, setDelay] = useState(true)
    const [message, setMessage] = useState([])
    const [positionList, setPositionList] = useState([])
    const [openorderList, setOpenorderList] = useState([])

    useEffect(() => {
        let arr = {}
        if (positionData?.symbol) {
            let sym = positionData.symbol.split('USDT')
            arr = {
                entryPrice: myData?.point ? Number(positionData.entryPrice).toFixed(myData.point) : Number(positionData.entryPrice),
                value: positionData.notional,
                ccy: sym[0],
                symbol: positionData.symbol,
                side: Number(positionData.positionAmt) >= 0 ? 'Buy' : 'Sell',
                sz: positionData.positionAmt,
                mark: myData?.point ? Number(positionData.markPrice).toFixed(myData.point) : Number(positionData.markPrice),
                mode: positionData.marginType == "cross" ? "Cross" : "Isolated",
                liq: myData?.point ? Number(positionData.liquidationPrice).toFixed(myData.point) : Number(positionData.liquidationPrice),
                pnl: positionData.unRealizedProfit,
                leverage: positionData.leverage,
                minSize: minSize
            }
        }
        setPositionList(arr)
    }, [positionData])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].symbol.split('USDT')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].symbol,
                    orderId: openorder[i].orderId,
                    type: openorder[i].type,
                    side: openorder[i].side,
                    px: openorder[i].price,
                    sz: openorder[i].origQty,
                    executedQty: openorder[i].executedQty
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const FuturesPositionClose = async (p, z, m) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order', {
                symbol: p.symbol,
                side: Number(p.sz) >= 0 ? "SELL" : "BUY",
                type: 'MARKET',
                quantity: z === 0 ? Number(RoundDown(Math.abs(p.sz) / 2, m)) > 0 ? Number(RoundDown(Math.abs(p.sz) / 2, m)) : Math.abs(p.sz) : Math.abs(p.sz),
                price: "",
                timeInForce: "",
                reduceOnly: true,
                stopPrice: "",
                closePosition: "true"
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }
            ).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 78, "msg": error }))
            })
        }
    }

    const CancelAll = () => {
        let list = []

        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i].symbol
                if (s == symbol && !list.includes(s)) {
                    list.push(s)
                }
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/all-order/cancel", {
                symbol: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 77, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            setOpenorder_cancel(false)
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/order/cancel", {
                orderId: item.orderId,
                symbol: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 79, "msg": error }))
                })
        }
    }

    return (
        <>
            <Modal message={message} />
            <Position positionList={positionList} openorderList={openorderList} FuturesPositionClose={FuturesPositionClose} FuturesAllOpenOrderCancel={CancelAll} FuturesOpenOrderCancel={FuturesOpenOrderCancel} openorder_cancel={openorder_cancel} delay={delay} symbol={symbol} exchange={"binance"} />
        </>
    )
}


export default GoexBnF8_1;