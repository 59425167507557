import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';
import langJa from './lang.ja.json';
import langVi from './lang.vi.json';
import langTest from './lange.test.json'

import goex_langEn from './goex/lang.en.json';
import goex_langKo from './goex/lang.ko.json';
import goex_langJa from './goex/lang.ja.json';
import goex_langVi from './goex/lang.vi.json';

const resource = {
    'en-US': {
        translation: process.env.REACT_APP_API == "https://api-test.namubit.com" || process.env.REACT_APP_API == "https://api.namubit.com" ? langEn : goex_langEn
    },
    'ko-KR': {
        translation: process.env.REACT_APP_API == "https://api-test.namubit.com" || process.env.REACT_APP_API == "https://api.namubit.com" ? langKo : goex_langKo
    },
    'ja-JP': {
        translation: process.env.REACT_APP_API == "https://api-test.namubit.com" || process.env.REACT_APP_API == "https://api.namubit.com" ? langJa : goex_langJa
    },
    'vi-VN': {
        translation: process.env.REACT_APP_API == "https://api-test.namubit.com" || process.env.REACT_APP_API == "https://api.namubit.com" ? langVi : goex_langVi
    },
    'test-KR': {
        translation: langTest
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        // 초기 설정 언어
        lng: 'en-US',
        fallbackLng: {
            'en-US': ['en-US'],
            'ja-JP': ['ja-JP'],
            'vi-VN': ['vi-VN'],
            'ko-RK': ['ko-KR'],
            default: ['test-KR']
        },
        debug: false,
        defaultNS: 'translation',
        ns: 'translation',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    })

export default i18n;