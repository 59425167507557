import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { changeTime, changeDate, RoundDown } from "../../common/function"
import { ClipLoader } from "react-spinners";

import { Alarm, OpenOrder, OrderHistory, TradeHistory, Balance } from '../../common/C_spot/W_goex8.js';

function GoexOKXS8({ tab5, setTab5, symbol, setTradeVolume, tradeVolume, orderReload, openorder, balance }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const [message, setMessage] = useState([])
    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [openorderList, setOpenorderList] = useState([])
    const [balanceList, setBalanceList] = useState([])

    useEffect(() => {
        let arr = []
        if (balance) {
            for (let i = 0; i < balance.length; i++) {
                if (balance[i].coin != "USDT") {
                    arr.push({
                        ccy: balance[i].coin,
                        symbol: balance[i].coin + "USDT",
                        available: balance[i].availableToWithdraw,
                        eq: balance[i].equity,
                        value: balance[i].usdValue,
                        locked: balance[i].locked
                    })
                }
            }
        }
        setBalanceList(arr)
    }, [balance])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].instId.split('USDT')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].instId,
                    orderId: openorder[i].ordId,
                    type: openorder[i].orderType,
                    side: openorder[i].side == "sell" ? "Sell" : "Buy",
                    px: openorder[i].px,
                    sz: openorder[i].sz,
                    instId: openorder[i].instId
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = () => {
        let list = []
        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i]
                list.push({ ordId: s.ordId, instId: s.instId })
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel", {
                ordArr: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 116, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/cancel", {
                ordId: item.ordId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setDelay(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 117, "msg": error }))
                })
        }
    }

    return (
        <div className={`futures-section15 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('spot_9')}</div>
                    <div className={tab5 === 1 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(3)}>{t('futures_45')}</div>
                    <div className={tab5 === 4 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(4)}>{t('futures_46')}</div>
                    <div className={tab5 === 5 ? `futures-section8-top-1-1 display-none-1000 active ${darkname}` : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(5)}>{t('futures_82')}</div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className={`futures-section8-top-1-3 ${darkname}`} onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className={`futures-section8-top-1-3 ${darkname}`}><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Balance balance={balanceList} exchange={"okx"} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder openorder={openorderList} symbol={symbol} all={false} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                            :
                            tab5 === 2
                                ?
                                <OpenOrder openorder={openorderList} symbol={symbol} all={true} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                :
                                tab5 === 3
                                    ?
                                    <FuturesOrderHistory orderReload={orderReload} />
                                    :
                                    tab5 === 4
                                        ?
                                        <FuturesTradeHistory orderReload={orderReload} />
                                        :
                                        <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                }
            </div>
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/spot/trade-history", {
                params: {
                    reqId: o_id++,
                    begin: changeDate(changeTime(selectedDate)),
                    end: changeDate(changeTime(selectedDate1) + 86400000)
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                // let reqId = response?.data?.reqId
                let tmp = response.data.data
                // if (tmp && o_id - 1 === Number(reqId)) {
                setOrderlist(tmp)
                setSpinner(false)
                // }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 72, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].instId.split('USDT')
                summary.push({
                    symbol: orderlist[i].instId,
                    ccy: arr[0],
                    volume: Number(orderlist[i].fillPx) * Number(orderlist[i].fillSz),
                    type: orderlist[i].order_type,
                    time: orderlist[i].ts,
                    side: orderlist[i].side == "sell" ? "Sell" : "Buy",
                    price: orderlist[i].fillPx,
                    size: orderlist[i].fillSz,
                    status: orderlist[i].exec_type
                })
            }
        }
        setOrderData(summary)
    }, [orderlist])

    return (
        <OrderHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderdata} spinner={spinner} />
    )
}

let t_id = 0
function FuturesTradeHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderlistSum, setOrderlistSum] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [personal_fee, setPersonal_fee] = useState(0.25)
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/spot/trade-history", {
                params: {
                    reqId: t_id++,
                    begin: changeDate(changeTime(selectedDate)),
                    end: changeDate(changeTime(selectedDate1) + 86400000)
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                // let reqId = response?.data?.reqId
                let tmp = response.data.data
                // if (tmp && t_id - 1 === Number(reqId)) {
                setOrderlist(tmp)
                setSpinner(false)
                // }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 73, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].instId.split('USDT')
                summary.push({
                    symbol: orderlist[i].instId,
                    ccy: arr[0],
                    volume: Number(orderlist[i].fillPx) * Number(orderlist[i].fillSz),
                    fee: orderlist[i].fee,
                    time: orderlist[i].ts,
                    side: orderlist[i].side == "sell" ? "Sell" : "Buy",
                    price: orderlist[i].fillPx,
                    size: orderlist[i].fillSz,
                    status: orderlist[i].exec_type
                })
            }
        }
        setOrderlistSum(summary)
    }, [orderlist])

    return (
        <TradeHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderlistSum} spinner={spinner} />
    )
}

export default GoexOKXS8;