import '../../css/futures.css'
import '../../css/modal.css'
import axios from 'axios';
import base64 from 'base-64';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faSquareCaretDown, faCircleXmark, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { change_Bn_openorder, change_Bybit_openorder, checkAlert, check_Error } from '../../../store';
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"
import { ClipLoader } from "react-spinners";
import { previousDay } from 'date-fns/fp';

let openorder_data = []
let postOnly_data = []

function ByFsection8({ tab5, setTab5, symbol, windowtypeM, USDTSymbolF, setPositionAmount, exinfo, positionData, setTradeVolume, tradeVolume, totalexinfo, currentPrice, checkCurrentPosition, setOrderReload, marginbtn }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const CryptoJS = require('crypto-js');

    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value });
    let Alert = useSelector((state) => { return state.Alert });

    const darkname = dark ? 'dark' : "";

    const [positionbtn, setPositionbtn] = useState(true)
    const [openorder, setOpenorder] = useState([])
    const [message, setMessage] = useState([])
    const [qtypoint, setQtypoint] = useState(2)

    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    useEffect(() => {
        if (exinfo[1]) {
            let point = findSmallest(Number(exinfo[1].minQty))
            setQtypoint(point)
        }
    }, [exinfo])

    useEffect(() => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (login && by_timestamp && By_apiKey?.S_api?.length > 0) {
            let socket = new WebSocket(`wss://stream.bybit.com/v5/private`);

            const generateSignature = (apiSecret, expires) => {
                const paramStr = `GET/realtime${expires}`;
                return CryptoJS.HmacSHA256(paramStr, apiSecret).toString(CryptoJS.enc.Hex);
            };

            const expires = Number(by_timestamp) + 100000;
            const signature = generateSignature(By_apiKey.S_sec, expires);

            const loginMessage = {
                op: "auth",
                args: [By_apiKey.S_api, expires.toString(), signature]
            }

            const subscribeMessage = {
                op: "subscribe",
                args: ["order"]
            }

            socket.onopen = () => {
                socket.send(JSON.stringify(loginMessage));
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({ "op": "ping" }));
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            };

            socket.onmessage = function (event) {
                let tmp = JSON.parse(event.data);
                if (tmp?.op != "pong") {
                    if (tmp?.op == "auth" && tmp.success) {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    else if (tmp.topic === "order") {
                        let list = tmp?.data ?? []
                        let arr = []
                        setOrderReload(e => !e)

                        for (let i = 0; i < list.length; i++) {
                            let data = list[i]
                            if (data.orderStatus == "New") {
                                let fil = openorder_data.filter((item) => item.orderId != data.orderId)
                                arr = [{ orderId: data.orderId, symbol: data.symbol, orderType: data.orderType, px: data.price, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce }]
                                setOpenorder([...fil, ...arr])
                                dispatch(change_Bybit_openorder([...fil, ...arr]))
                                openorder_data = [...fil, ...arr]
                            }
                            else if (data.orderStatus == "Untriggered") {
                                let fil = openorder_data.filter((item) => item.orderId != data.orderId)
                                arr = [{ orderId: data.orderId, symbol: data.symbol, orderType: data.stopOrderType, px: data.triggerPrice, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce }]
                                setOpenorder([...fil, ...arr])
                                dispatch(change_Bybit_openorder([...fil, ...arr]))
                                openorder_data = [...fil, ...arr]
                            }
                            else {
                                arr = openorder_data.filter((item) => item.orderId != data.orderId)
                                setOpenorder(arr)
                                dispatch(change_Bybit_openorder(arr))
                                openorder_data = arr
                            }
                        }
                    }
                }
            }
            socket.onclose = function (event) {
                console.log("Bybit Order Close")
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, By_apiKey])

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            FuturesAllOpenOrder()
        }
    }, [By_apiKey])

    const FuturesAllOpenOrder = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}category=linear&settleCoin=USDT`
            await axios.get(`https://api.bybit.com/v5/order/realtime?category=linear&settleCoin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    FuturesAllOpenOrder()
                }
                else if (tmp.retCode == 0) {
                    let arr = []
                    let list = tmp?.result?.list ?? []
                    for (let i = 0; i < list.length; i++) {
                        let data = list[i]
                        if (data.orderStatus == "Untriggered") arr.push({ orderId: data.orderId, symbol: data.symbol, orderType: data.stopOrderType, px: data.triggerPrice, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce })
                        else arr.push({ orderId: data.orderId, symbol: data.symbol, orderType: data.orderType, px: data.price, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce })
                    }
                    setOpenorder(arr)
                    dispatch(change_Bybit_openorder(arr))
                    openorder_data = arr
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 76, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                FuturesAllOpenOrder()
            }, 1000)
        }
    }

    const FuturesAllOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order/cancel/all", {
                category: "linear",
                symbol: symbol
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) == 1000) {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 77, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-section8 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('futures_79')}</div>
                    <div className={tab5 === 1 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(3)}>{t('futures_82')}</div>
                    {/* <div className={tab5 === 4 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(4)} style={{ color: "#F5AC1D" }}>{t('futures_83')}</div> */}
                    <div className='futures-section8-top-1-2 display-none-1000'>
                        <input type='checkbox' disabled={true} />
                    </div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className='futures-section8-top-1-3' onClick={() => FuturesAllOpenOrderCancel()}>{t('spot_46')}</div>
                            :
                            <div className='futures-section8-top-1-3'><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <FontAwesomeIcon icon={positionbtn ? faSquareCaretDown : faSquareCaretUp} className='display-none-700-block' style={{ color: "gray", fontSize: "18px", paddingRight: "5px" }} onClick={() => setPositionbtn(btn => !btn)} />
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Position positionbtn={positionbtn} darkname={darkname} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setMessage={setMessage} setPositionAmount={setPositionAmount} qtypoint={qtypoint} positionData={positionData} totalexinfo={totalexinfo} symbol={symbol} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} marginbtn={marginbtn} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder positionbtn={positionbtn} openorder={openorder} symbol={symbol} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                            :
                            tab5 === 2
                                ?
                                <DiffOrder openorder={openorder} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                :
                                tab5 === 3
                                    ?
                                    <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                                    :
                                    <Multi />
                }
            </div>
        </div>
    )
}

function Position({ positionbtn, darkname, windowtypeM, USDTSymbolF, setMessage, setPositionAmount, qtypoint, positionData, totalexinfo, symbol, currentPrice, checkCurrentPosition, marginbtn }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const FuturesPositionClose = async (p, z, m, min) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            
            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order", {
                category: "linear",
                symbol: p.symbol,
                side: p.side == "Buy" ? "Sell" : "Buy",
                orderType: 'Market',
                qty: z === 0 ? Number(p.size) / 2 >= Number(min) ? String(RoundDown(Number(p.size) / 2, m)) : p.size : p.size,
                closeOnTrigger: true
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) == 1000) {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "bybit", code: Number(response?.data?.code), msg: response?.data?.msg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 78, "msg": error }))
            })
        }
    }

    return (
        <>
            {
                windowtypeM
                    ?
                    <div className='position-box'>
                        <div className='position'>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_84')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_85')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_86')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_87')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_88')}</div>
                                </div>
                            </div>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_89')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_90')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'><span>{t('futures_91')}</span><span>(USDT)</span></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_92')}(ROI) <FontAwesomeIcon icon={faCrown} /></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_93')}</div>
                                </div>
                            </div>
                        </div>
                        {
                            checkCurrentPosition
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                    let minSize1 = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                    let minSize = minSize1[0]?.lotSizeFilter?.minOrderQty ?? 0
                                    if (a.symbol == symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize} symbol={symbol} currentPrice={currentPrice} marginbtn={marginbtn} />
                                        )
                                    }
                                })
                                :
                                <PositionNoneItem darkname={darkname} />
                        }
                        {
                            positionData
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                    let minSize1 = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                    let minSize = minSize1[0]?.lotSizeFilter?.minOrderQty ?? 0
                                    if (a.symbol != symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize} symbol={symbol} currentPrice={currentPrice} marginbtn={marginbtn} />
                                        )
                                    }
                                })
                                :
                                ""
                        }
                    </div>
                    :
                    positionbtn
                        ?
                        <>
                            {
                                checkCurrentPosition
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                        let minSize1 = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                        let minSize = minSize1[0]?.lotSizeFilter?.minOrderQty ?? 0
                                        if (a.symbol == symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize} symbol={symbol} currentPrice={currentPrice} marginbtn={marginbtn} />
                                            )
                                        }
                                    })
                                    :
                                    <PositionNoneItem darkname={darkname} />
                            }
                            {
                                positionData
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                        let minSize1 = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                        let minSize = minSize1[0]?.lotSizeFilter?.minOrderQty ?? 0
                                        if (a.symbol != symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize} symbol={symbol} currentPrice={currentPrice} marginbtn={marginbtn} />
                                            )
                                        }
                                    })
                                    :
                                    ""
                            }
                        </>
                        :
                        ""
            }
        </>
    )
}

function PositionNoneItem({ darkname }) {
    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                </div>
            </div>
        </div >
    )
}

function PositionWebItem({ positionData, darkname, FuturesPositionClose, myData, setPositionAmount, minSize, symbol, currentPrice, marginbtn }) {
    const { t } = useTranslation();
    let arr = positionData.symbol.split('USDT')

    let entryPrice = positionData?.avgPrice ? positionData?.avgPrice : positionData?.entryPrice ? positionData?.entryPrice : ""

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <Link to={`/futures/bybit/${positionData.symbol ? positionData.symbol : "BTCUSDT"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.symbol ? `${arr[0]}USDT` : ""}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    {
                        positionData.side
                            ?
                            positionData.side == "Buy"
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{entryPrice ? myData && myData.point ? changeNum(Number(entryPrice).toFixed(myData.point)) : changeNum(Number(entryPrice)) : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.size ? `${Number(Math.abs(positionData.size))}` : ""])} style={{ cursor: "pointer" }}>
                    <div className={`position-4 ${darkname}`}>{positionData.size ? changeNum(Number(Math.abs(positionData.size))) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.markPrice ? myData && myData.point ? changeNum(Number(positionData.markPrice).toFixed(myData.point)) : changeNum(Number(positionData.markPrice)) : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{marginbtn == 0 ? "Cross" : "Isolated" ?? "Cross"}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.liqPrice && Number(positionData.liqPrice) > 0 ? myData && myData.point ? Number(positionData.liqPrice).toFixed(myData.point) : Number(positionData.liqPrice) : "-"}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.positionValue ? changeNum(Number(Math.abs(positionData.positionValue)).toFixed(4, '0')) : ""}</div>
                </div>
                <div className='position-2'>
                    {
                        symbol === positionData.symbol ?
                            Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)) >= 0 ?
                                <div className={`position-4 ${darkname} green`}>
                                    {changeNum(Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`position-4 ${darkname} red`}>
                                    {changeNum(Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                            :
                            Number((Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)) >= 0 ?
                                <div className={`position-4 ${darkname} green`}>
                                    {changeNum(Number((Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`position-4 ${darkname} red`}>
                                    {changeNum(Number((Number(entryPrice) - Number(myData?.lastPrice ?? entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(Number(entryPrice) - Number(myData?.lastPrice ?? entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionClose(positionData, 0, findSmallest(Number(minSize)), minSize)}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData, 1)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PositionMobileItem({ positionData, darkname, FuturesPositionClose, myData, setPositionAmount, minSize, symbol, currentPrice, marginbtn }) {
    const { t } = useTranslation();
    let arr = positionData.symbol.split('USDT')

    let entryPrice = positionData?.avgPrice ? positionData?.avgPrice : positionData?.entryPrice ? positionData?.entryPrice : ""

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_84')}</div>
                    <Link to={`/futures/bybit/${positionData.symbol ? positionData.symbol : "BTCUSDT"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.symbol ? `${arr[0]}USDT` : ""}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_85')}</div>
                    {
                        positionData.side
                            ?
                            positionData.side == "Buy"
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_86')}</div>
                    <div className={`position-4 ${darkname}`}>{entryPrice ? myData && myData.point ? changeNum(Number(entryPrice).toFixed(myData.point)) : changeNum(Number(entryPrice)) : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.size ? `${Number(Math.abs(positionData.size))}` : ""])} style={{ cursor: "pointer" }}>
                    <div className='position-3'>{t('futures_87')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.size ? changeNum(Number(Math.abs(positionData.size))) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_88')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.markPrice ? myData && myData.point ? changeNum(Number(positionData.markPrice).toFixed(myData.point)) : changeNum(Number(positionData.markPrice)) : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_89')}</div>
                    <div className={`position-4 ${darkname}`}>{marginbtn == 0 ? "Cross" : "Isolated" ?? "Cross"}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_90')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.liqPrice && Number(positionData.liqPrice) > 0 ? myData && myData.point ? Number(positionData.liqPrice).toFixed(myData.point) : Number(positionData.liqPrice) : "-"}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_91')}(USDT)</div>
                    <div className={`position-4 ${darkname}`}>{positionData.positionValue ? changeNum(Number(Math.abs(positionData.positionValue)).toFixed(4, '0')) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_92')}(ROI) <FontAwesomeIcon icon={faCrown} /></div>
                    {
                        symbol === positionData.symbol ?
                            Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)) >= 0 ?
                                <div className={`position-4 ${darkname} green`}>
                                    {changeNum(Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`position-4 ${darkname} red`}>
                                    {changeNum(Number((currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(currentPrice - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                            :
                            Number((Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)) >= 0 ?
                                <div className={`position-4 ${darkname} green`}>
                                    {changeNum(Number((Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(Number(myData?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                                :
                                <div className={`position-4 ${darkname} red`}>
                                    {changeNum(Number((Number(entryPrice) - Number(myData?.lastPrice ?? entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1)).toFixed(3))}
                                    ({(Number(Number(entryPrice) - Number(myData?.lastPrice ?? entryPrice)) * Number(positionData.size) * (positionData.side == "Buy" ? 1 : -1) * 100 / ((Number(entryPrice) * Math.abs(positionData.size) * (1 / positionData.leverage)))).toFixed(2)}%)
                                </div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_93')}</div>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionClose(positionData, 0, findSmallest(Number(minSize)),minSize)}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData, 1)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OpenOrder({ positionbtn, openorder, symbol, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();

    return (
        <>
            {
                positionbtn
                    ?
                    <div className='futures-order'>
                        <div className='futures-order1'>
                            <div className='futures-order-1'>
                                <div className={`futures-order-1-top ${darkname}`}><span>{t('futures_96')}</span></div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "Buy") {
                                                        if (a.symbol === symbol) {
                                                            return (
                                                                <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='futures-order-1'>
                                <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "Sell") {
                                                        if (a.symbol === symbol) {
                                                            return (
                                                                <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    ""
            }
        </>
    )
}

function OpenOrderItem({ item, side, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let arr = item.symbol.split('USDT')

    const FuturesOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order/cancel", {
                category: "linear",
                orderId: item.orderId,
                symbol: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) == 1000) {
                        setMessage({ type: "bybit", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "bybit", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 117, "msg": error }))
                })
        }
    }

    return (
        <tr>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{arr[0]}USDT<span></span></span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{item?.orderType ?? '-'}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{item?.px ?? '0'}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{changeNum(0)}</span><span>/</span><span>{changeNum(item.sz)}</span></td>
            {
                openorder_cancel && delay
                    ?
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }} onClick={() => FuturesOpenOrderCancel()}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </td>
                    :
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }}>
                        <ClipLoader color="#595959" size='14px' />
                    </td>
            }
        </tr>
    )
}

function DiffOrder({ openorder, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    return (
        <div className='futures-order'>
            <div className='futures-order1'>
                <div className='futures-order-1'>
                    <div className={`futures-order-1-top ${darkname}`}>{t('futures_96')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "Buy") {
                                            return (
                                                <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='futures-order-1'>
                    <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "Sell") {
                                            return (
                                                <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Multi() {
    const { t } = useTranslation();
    return (
        <div className='futures-multi'>
            <div className='futures-multi-1'>
                <div>{t('futures_104')}</div>
                <div>{t('futures_105')}</div>
            </div>
            <div className='futures-multi-2'>
                <div className='futures-multi-2-1'>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_107')}</div>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_108')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>1</span></option>
                            <option><span>2</span></option>
                            <option><span>3</span></option>
                            <option><span>4</span></option>
                            <option><span>5</span></option>
                        </select>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_109')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>X</span> <span>1</span></option>
                            <option><span>X</span> <span>2</span></option>
                            <option><span>X</span> <span>3</span></option>
                            <option><span>X</span> <span>4</span></option>
                            <option><span>X</span> <span>5</span></option>
                        </select>
                    </div>
                </div>
                <div className='futures-multi-2-2'>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 1</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 2</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 3</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 4</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 5</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Alarm({ setTradeVolume, tradeVolume }) {
    const { t } = useTranslation();
    let Alert = useSelector((state) => { return state.Alert });
    let dispatch = useDispatch();

    const onChangeTradeAlert = (e) => {
        dispatch(checkAlert({ num: 0, value: e.target.value }))
    }
    const onChangeFilledAlert = (e) => {
        dispatch(checkAlert({ num: 1, value: e.target.value }))
    }
    const onChangeOrderAlert = (e) => {
        dispatch(checkAlert({ num: 2, value: e.target.value }))
    }
    const onChangeCancelAlert = (e) => {
        dispatch(checkAlert({ num: 3, value: e.target.value }))
    }
    const onChangeTradeVolume = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setTradeVolume(onlyNumber)
        localStorage.setItem('TradeVolume', onlyNumber)
    }

    return (
        <div className='futures-alarm'>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_111')}</div>
                <select value={Alert[0]} onChange={onChangeTradeAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_112')}</div>
                <input type='number' placeholder='0' value={tradeVolume} onChange={onChangeTradeVolume} />
            </div>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_113')}</div>
                <select value={Alert[1]} onChange={onChangeFilledAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_147')}</div>
                <select value={Alert[2]} onChange={onChangeOrderAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                {/* <div className='futures-alarm-1'>Order Cancel Alert</div>
                <select value={Alert[3]} onChange={onChangeCancelAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select> */}
            </div>
        </div>
    )
}

export default ByFsection8;