import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndent, faOutdent } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { changeNum } from "../function"
import IMG from '../../img';

function W_goex6({ isBlinking, handleButtonClick, changeTab, tab, data, currentPrice, priceDirect, max, mark, index, MOrder, MPosition, funding, nextTime, feeAmount, positionDirect }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let darkname = dark ? "dark" : ""

    let imgcheck = true
    if (IMG && !IMG.includes(data.ccy)) {
        imgcheck = false
    }

    return (
        <div className={`futures-section13 ${darkname}`}>
            <div className={`futures-mid-top-1 ${darkname}`}>
                <table>
                    <thead>
                        <tr>
                            <th>
                                {
                                    tab
                                        ?
                                        <FontAwesomeIcon icon={faOutdent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'}
                                            onClick={() => { handleButtonClick(); changeTab(tab ? false : true) }} style={{ cursor: "pointer" }} />
                                        :
                                        <FontAwesomeIcon icon={faIndent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'}
                                            onClick={() => { handleButtonClick(); changeTab(tab ? false : true) }} style={{ cursor: "pointer" }} />
                                }
                                <div style={{ paddingLeft: "22px", fontSize: "18px", display: "flex", alignItems: "center", paddingTop: "4px" }}>
                                    <img src={imgcheck ? `/img/coin/${data.ccy}.png` : `/img/coin/origin.png`} style={{ width: "22px", paddingRight: "8px" }} />
                                    <span>{data.ccy}</span><span>USDT</span>
                                </div>
                                <div className='futures-mid-top-1-1'>
                                    <div className={`futures-orderbook-2-4 ${priceDirect ? "green" : "red"}`}>
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                    <div className={`futures-mid-top-1-2 ${Number(data.priceChangePercent) >= 0 ? "green" : "red"}`}>
                                        <div><span>{Number(data.priceChangePercent) >= 0 ? "+" : ""}</span><span>{data.priceChangePercent ? Number(data.priceChangePercent).toFixed(2) : 0}</span><span>%</span></div>
                                        <div>{data.priceChange ? Number(data.priceChange).toFixed(max) : 0}</div>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className='futures-mid-top-1-4 display-none-1150'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_88')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{changeNum(Number(mark ?? 0).toFixed(max))}</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-4 display-none-1150'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_127')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{changeNum(Number(index ?? 0).toFixed(max))}</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-3 display-none-1400'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_38')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{changeNum(Number(MOrder)) ?? "0.000"}</span> <span>Qty</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-3 display-none-1400'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_39')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}>{changeNum(MPosition) ?? "0.000"}</span> <span>USDT</span>
                                    </div>
                                </div>
                                <div className='futures-mid-top-1-3'>
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_40')}</div>
                                    <div className='futures-mid-top-table-th-2' style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span className={`futures-mid-top-table-th-span ${darkname}`}><span>{((Number(funding ?? 0)) * 100).toFixed(5)}</span> <span>%</span></span>
                                        <span className={`futures-mid-top-table-th-span-1 ${darkname}`}>{nextTime ?? "00:00:00"}</span>
                                    </div>
                                </div>
                                <div className="futures-mid-top-1-3">
                                    <div className={`futures-mid-top-table-th-1 ${darkname}`}>{t('futures_41')}</div>
                                    <div className='futures-mid-top-table-th-2'>
                                        <span className={`futures-mid-top-table-th-span ${darkname}
                                                    ${positionDirect > 0 ? Number(funding) >= 0 ? "red" : "green"
                                                : positionDirect < 0 ? Number(funding) >= 0 ? "green" : "red"
                                                    : ""}
                                                    `}>{Math.abs(Number(funding) * feeAmount ?? 0).toFixed(5)}</span> <span>USDT</span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    )
}

export { W_goex6 }