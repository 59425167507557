import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import { useTranslation } from "react-i18next";
import { RoundDown } from "../../common/function"

import { C_Exchange } from '../../common/C_wallet/W_wallet';

function OKX_Exchange({ walletData, currentUSDT, setReload, fastCoin }) {
    const { t } = useTranslation();
    const timestamp1 = '' + Date.now();

    let OKXS = useSelector((state) => { return state.OKX_coinS });

    let dispatch = useDispatch();

    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [amount, setAmount] = useState("")
    const [balanceAmount, setBalanceAmount] = useState(0)
    const [direction, setDirection] = useState(false)
    const [markprice, setMarkprice] = useState(0)
    const [calAmount, setCalAmount] = useState("")
    const [sym, setSym] = useState("")
    
    const [qtypoint, setQtypoint] = useState(0)

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    let BS_filterSymbol = []
    if (OKXS) {
        BS_filterSymbol = OKXS.filter((p) => {
            return (p.ccy.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    BS_filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));

    useEffect(() => {
        if (walletData) {
            for (let i = 0; i < walletData.length; i++) {
                if (walletData[i].symbol === selectSearch) {
                    setBalanceAmount(Number(walletData[i].availBal))
                    setMarkprice(Number(walletData[i].lastPrice))
                    setSym(`${walletData[i].symbol}-USDT`)
                }
            }
        }
    }, [selectSearch])

    useEffect(() => {
        setCalAmount(direction ? (Number(amount) / Number(markprice)) : RoundDown(Number(markprice) * Number(amount), 8))
    }, [amount, selectSearch])

    const createPostData = (side, sz) => {
        return {
            instId: sym,
            tdMode: "cash",
            clOrdId: timestamp1,
            side: side,
            ordType: 'market',
            sz: sz,
            tag: "ff5b6baef008SCDE",
            tgtCcy: "base_ccy",
        };
    };

    const onSubmit = async (postData, url) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (Number(response?.data?.code) === 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload(e => !e)
                        setSearch("")
                        setAmount("")
                        setSelectSearch("")
                        setBalanceAmount(0)
                        setMessage({ type: 1, code: Number(response?.data?.code), msg: `${t('message_16')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 1, code: Number(response?.data?.code), msg: response.data.data[0].sMsg })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 139, "msg": error }))
            })
        }
    }

    const onSubmitBuy = () => {
        setSpinner(true)

        let url = process.env.REACT_APP_API + '/api/v1/trade/okx/swap/order'
        let postData = direction ? createPostData("buy", calAmount) : createPostData("sell", amount);
        onSubmit(postData, url);
    };

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            setW_confirm(false)
            onSubmitBuy()
        }
    }, [w_confirm])

    return (
        <C_Exchange search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} selectSearch={selectSearch} setSelectSearch={setSelectSearch} amount={amount} setAmount={setAmount} onChangeAmount={onChangeAmount} balanceAmount={balanceAmount} direction={direction} setDirection={setDirection} markprice={markprice} calAmount={calAmount} spinner={spinner} message={message} qtypoint={qtypoint} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} spotBalance={currentUSDT} BS_filterSymbol={BS_filterSymbol}/>
    )
}

export default OKX_Exchange;