import '../../css/futures.css'
import Chart from '../../spotchart.js'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndent, faCircleChevronRight, faOutdent, faCircleChevronLeft, faComputerMouse, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Range, getTrackBackground } from 'react-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Page from '../../Page.js'
import Modal from '../../message/modal'
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp, Decimalization } from "../../common/function"
import { changeOKX_markPrice, changeOKX_Message, check_Error } from '../../../store.js';

let prePrice = 0
let cnt = 0
let pre_symbol = ""

function Fsection6({ tab, tab1, changeTab, change_OrderbookTab, data, symbol, instrumentsData, max, positionData, currentPrice, firstmargin, marginbtn, setMarginbtn, marginbtn1, setMarginbtn1, markPrice, setMarkPrice, maxPosition, fundingFee, positionDirect, orderReload }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    let dark = useSelector((state) => { return state.Darkmode.value });
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";

    const [windowtype, setWindowtype] = useState(true);
    const [margintab, setMargintab] = useState(false)
    const [isBlinking, setIsBlinking] = useState(false);
    const [marginUpdate, setMarginUpdate] = useState(false)
    const [tab2, setTab2] = useState(0)
    const [indexPrice, setIndexPrice] = useState([])
    const [fundingRate, setFundingRate] = useState("")
    const [nextTime, setNextTime] = useState("00:00:00")
    const [priceDirect, setPriceDirect] = useState(false)
    const [message, setMessage] = useState([])
    const [close, setClose] = useState(false)
    const [close1, setClose1] = useState(false)

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 200);
    };

    useEffect(() => {
        if (window.innerWidth >= 700) setWindowtype(true)
        else setWindowtype(false)

        const handleResize = () => {
            if (window.innerWidth >= 700) setWindowtype(true)
            else setWindowtype(false)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setTab2(0)
    }, [symbol])

    useEffect(() => {
        pre_symbol = symbol
        let date = 0
        let id;

        let socket = new WebSocket(`wss://ws.okx.com:8443/ws/v5/public`);
        const subscribeMessage = JSON.stringify({
            op: "subscribe",
            args: [
                {
                    channel: "funding-rate",
                    instId: symbol
                }
            ]
        });

        socket.onopen = () => {
            socket.send(subscribeMessage);
        };

        socket.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp.data && tmp.data[0]) {
                    let tmp1 = tmp.data[0]
                    setFundingRate(Number(tmp1.fundingRate))

                    clearInterval(id);
                    date = Math.floor((Number(tmp1.fundingTime) - new Date().getTime()) / 1000)
                    id = setInterval(() => {
                        date -= 1
                        let formattedDate = `${padZero(Math.floor(date / (60 * 60)))}:${padZero(Math.floor((date / 60) % 60))}:${padZero(Math.floor(date % 60))}`;
                        setNextTime(formattedDate)
                    }, 1000)
                }
            }
        }
        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };
        socket.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket.close();
            clearInterval(id);
        }
    }, [symbol, close])

    useEffect(() => {
        pre_symbol = symbol
        let ccy = symbol ? symbol.split("-SWAP")[0] : ""

        let socket1 = new WebSocket(`wss://ws.okx.com:8443/ws/v5/public`);
        const subscribeMessage1 = JSON.stringify({
            op: "subscribe",
            args: [
                {
                    channel: "mark-price",
                    instId: symbol
                },
                {
                    channel: "index-tickers",
                    instId: ccy
                }
            ]
        });

        socket1.onopen = () => {
            socket1.send(subscribeMessage1);
        };

        socket1.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp.arg && tmp.arg.channel && tmp.arg.channel == 'mark-price') {
                    if (tmp.data && tmp.data[0]) {
                        setMarkPrice(tmp.data[0].markPx)
                        dispatch(changeOKX_markPrice(tmp.data[0].markPx))
                    }
                }
                else if (tmp.arg && tmp.arg.channel && tmp.arg.channel == 'index-tickers')
                    if (tmp.data && tmp.data[0]) {
                        setIndexPrice(tmp.data[0].idxPx)
                    }
            }
        }
        socket1.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose1(e => !e)
            }
        };
        socket1.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket1.close();
        }
    }, [symbol, close1])

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const [chartwidth, setChartwidth] = useState("100%")

    useEffect(() => {
        if (chartwidth != "100%") {
            setTimeout(() => {
                setChartwidth("100%")
            }, 1000)
        }
        else {
            setTimeout(() => {
                setChartwidth("99.99%")
            }, 1000)
        }
    }, [chartwidth])

    const FuturesChangeLever = async (m) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/set/leverage`, {
                instId: symbol,
                lever: m,
                mgnMode: marginbtn === 0 ? "cross" : "isolated",
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_29')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_33')}` })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 123, "msg": error }))
            })
        }
    }

    return (
        <div className={tab1 ? `futures-section6 ${darkname}` : `futures-section6-check ${darkname}`}>
            <Modal message={message} />
            <div className='futures-mid-top'>
                {
                    windowtype
                        ?
                        <div className={`futures-mid-top-1 ${darkname}`}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ cursor: "pointer" }} onClick={() => changeTab(tab ? false : true)}>
                                            {
                                                tab
                                                    ?
                                                    <FontAwesomeIcon icon={faOutdent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'} onClick={handleButtonClick} />
                                                    :
                                                    <FontAwesomeIcon icon={faIndent} className={isBlinking ? 'futures-section6-ico current' : 'futures-section6-ico'} onClick={handleButtonClick} />
                                            }
                                            <div style={{ paddingLeft: "5px", fontSize: "18px" }}><span>{data.ccy}</span><span>USDT</span></div>
                                            {/* <div className='futures-mid-top-1-1'>
                                                <div className={`futures-orderbook-2-4 ${priceDirect ? "green" : "red"}`}>
                                                    {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                                <div className={`futures-mid-top-1-2 ${Number(data.priceChangePercent) >= 0 ? "green" : "red"}`}>
                                                    <div><span>{data.priceChangePercent ? Number(data.priceChangePercent).toFixed(2) : 0}</span><span>%</span></div>
                                                    <div>{data.priceChange ? Number(data.priceChange).toFixed(max) : 0}</div>
                                                </div>
                                            </div> */}
                                        </th>
                                        <th>
                                            <div className='futures-mid-top-1-4'>
                                                <div className='futures-mid-top-table-th-1'>
                                                    <div className={`futures-orderbook-2-4 ${priceDirect ? "green" : "red"}`}>
                                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                                </div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{markPrice ? changeNum(Number(markPrice).toFixed(max)) : "0"}</span>
                                                </div>
                                            </div>
                                            {/* <div className='futures-mid-top-1-4 display-none-1000'>
                                                <div className='futures-mid-top-table-th-1'>{t('futures_88')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{markPrice ? changeNum(Number(markPrice).toFixed(max)) : "0"}</span>
                                                </div>
                                            </div> */}
                                            <div className='futures-mid-top-1-4 display-none-1800'>
                                                <div className='futures-mid-top-table-th-1'>{t('futures_127')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{indexPrice ? changeNum(Number(indexPrice).toFixed(max)) : "0"}</span>
                                                </div>
                                            </div>
                                            <div className='futures-mid-top-1-4'>
                                                <div className='futures-mid-top-table-th-1'>24H {t('spot_17')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname} ${Number(data.priceChangePercent) >= 0 ? "green" : "red"}`}>
                                                        <span>{data.priceChangePercent ? Number(data.priceChangePercent).toFixed(2) : 0}</span><span>%</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='futures-mid-top-1-3 display-none-1800'>
                                                <div className='futures-mid-top-table-th-1'>{t('futures_38')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{instrumentsData ? changeNum(Number(instrumentsData.maxMktSz) * Number(instrumentsData.ctVal)) : "0"}</span> <span>Qty</span>
                                                </div>
                                            </div>
                                            <div className='futures-mid-top-1-3 display-none-1800'>
                                                <div className='futures-mid-top-table-th-1'>{t('futures_39')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{maxPosition ? changeNum(Number(maxPosition)) : "0"}</span> <span>USDT</span>
                                                </div>
                                            </div>
                                            <div className='futures-mid-top-1-3'>
                                                <div className='futures-mid-top-table-th-1'>{t('futures_40')}</div>
                                                <div className='futures-mid-top-table-th-2' style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}><span>{fundingRate ? Number(fundingRate * 100).toFixed(5) : "0"}</span> <span>%</span></span>
                                                    <span className={`futures-mid-top-table-th-span-1 ${darkname}`}>{nextTime}</span>
                                                </div>
                                            </div>
                                            <div className="futures-mid-top-1-3">
                                                <div className='futures-mid-top-table-th-1'>{t('futures_41')}</div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span 
                                                    ${positionDirect > 0 ? Number(fundingRate) >= 0 ? "red" : "green"
                                                            : positionDirect < 0 ? Number(fundingRate) >= 0 ? "green" : "red"
                                                                : ""}
                                                    ${darkname}`}>{fundingRate ? Math.abs(fundingRate * fundingFee).toFixed(5) : "0"}</span> <span>USDT</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="display-none-1300" style={{ cursor: "pointer" }} onClick={() => change_OrderbookTab(tab1 ? false : true)}>
                                            {
                                                tab1
                                                    ?
                                                    <FontAwesomeIcon icon={faCircleChevronRight} />
                                                    :
                                                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                                            }
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        :
                        <div className='futures-mid-top-11'>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ cursor: "pointer" }} onClick={() => changeTab(tab ? false : true)}>
                                            <FontAwesomeIcon icon={faIndent} style={{ fontSize: "16px", paddingTop: "1px" }} />
                                            <div style={{ paddingLeft: "5px", fontSize: "18px" }}>{data.ccy}</div>
                                        </th>
                                        <th>
                                            <div className={`futures-section4-top ${darkname}`}>
                                                <div className={marginbtn === 0 ? `futures-section4-top-btn active ${darkname}` : `futures-section4-top-btn ${darkname}`} onClick={() => setMarginbtn(0)}>{t('futures_4')}</div>
                                                <div className={marginbtn === 1 ? `futures-section4-top-btn active ${darkname}` : `futures-section4-top-btn ${darkname}`} onClick={() => setMarginbtn(1)}>{t('futures_5')}</div>
                                                <div className={`futures-section4-top-btn-1 active ${darkname}`} onClick={() => setMargintab(true)}><span>{marginbtn1}</span> x</div>
                                                {
                                                    margintab
                                                        ?
                                                        <Margin setMargintab={setMargintab} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} darkname={darkname} FuturesChangeLever={FuturesChangeLever} firstmargin={firstmargin ? firstmargin : "100"} />
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className='futures-mid-top-1-4'>
                                                <div className='futures-mid-top-table-th-1'>
                                                    <div className={`futures-orderbook-2-4 ${priceDirect ? "green" : "red"}`}>
                                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                                </div>
                                                <div className='futures-mid-top-table-th-2'>
                                                    <span className={`futures-mid-top-table-th-span ${darkname}`}>{markPrice ? changeNum(Number(markPrice).toFixed(max)) : "0"}</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='futures-mid-top-table-th-1'>{t('futures_40')}</div>
                                            <div className='futures-mid-top-table-th-2' style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span className={`futures-mid-top-table-th-span ${darkname}`}><span>{fundingRate ? Number(fundingRate).toFixed(5) : "0"}</span> <span>%</span></span>
                                                <span className={`futures-mid-top-table-th-span-1 ${darkname}`}>{nextTime}</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='futures-mid-top-table-th-1'>{t('futures_41')}</div>
                                            <div className='futures-mid-top-table-th-2'>
                                                <span className={`futures-mid-top-table-th-span 
                                                    ${positionDirect > 0 ? Number(fundingRate) >= 0 ? "red" : "green"
                                                        : positionDirect < 0 ? Number(fundingRate) >= 0 ? "green" : "red"
                                                            : ""}
                                                    ${darkname}`}>{fundingRate ? Math.abs(fundingRate * fundingFee).toFixed(5) : "0"}</span> <span>USDT</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                }

                <div className='futures-mid-top-2 display-none-1000'>
                    <div className={tab2 === 0 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => setTab2(0)}>{t('futures_42')}</div>
                    {/* <div className={tab2 === 1 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => alert("Coming Soon")}>{t('futures_43')}</div> */}
                    {/* <div className={tab2 === 2 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => alert("Coming Soon")}>{t('futures_44')}</div> */}
                    <div className={tab2 === 3 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => OKX_Mode ? setTab2(3) : dispatch(changeOKX_Message())}>{t('futures_45')}</div>
                    <div className={tab2 === 4 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => OKX_Mode ? setTab2(4) : dispatch(changeOKX_Message())}>{t('futures_46')}</div>
                    {/* <div className={tab2 === 5 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => alert("Coming Soon")}>Spot/Futures Transfer</div> */}
                    {/* <div className={tab2 === 6 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => alert("Coming Soon")}>{t('futures_47')}</div> */}
                    {/* <div className={tab2 === 6 ? `futures-mid-top-2-btn active ${darkname}` : `futures-mid-top-2-btn ${darkname}`} onClick={() => setMarginUpdate(true)}>Isolated Margin Update</div> */}
                </div>
            </div>
            {
                tab2 === 0
                    ?
                    <div style={{ width: "100%", height: windowtype ? "610px" : "400px", border: `1px solid ${dark ? API_Address == "rf" ? "#2f2f2f" : "none" : API_Address == "rf" ? "#e2e2e2" : "none"}`, boxSizing: "border-box" }}>
                        <Chart exchange={"okx"} market={"futures"} />
                    </div>
                    :
                    tab2 === 3
                        ?
                        <FuturesOrderHistory darkname={darkname} orderReload={orderReload} />
                        :
                        <FuturesTradeHistory darkname={darkname} orderReload={orderReload} />
            }
            {
                marginUpdate
                    ?
                    <FuturesMarginUpdate setMarginUpdate={setMarginUpdate} darkname={darkname} symbol={symbol} />
                    :
                    ""
            }
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ darkname, orderReload }) {
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/order-history", {
                params: {
                    reqId: o_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response.data.reqId
                let tmp = response.data.data
                if (tmp && o_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 124, "msg": error }))
            })
        }
    }

    let ordertop = orderlist.slice((page - 1) * 12, (page) * 12)

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                    <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img' style={{ display: "none" }}></img>
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
                {/* <div className='f-trade-history-1-btn' onClick={() => FuturesOrderHistory()}>{t('market_0')}</div> */}
            </div>
            <div className={`f-trade-history-2 ${darkname}`}>
                <div className={`f-trade-history-2-1 ${darkname}`}>
                    <div className='f-trade-history-2-1-1'>{t('futures_117')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_118')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_119')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_120')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_121')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_122')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_123')}</div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`} style={{ fontSize: "12px" }}>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            orderlist && orderlist.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p><span>{t('message_0')}</span></p>
                                </div>
                                :
                                <>{
                                    ordertop.map(function (a, i) {
                                        return (
                                            <FuturesOrderDetailItem key={i} item={a} darkname={darkname} />
                                        )
                                    })}
                                    <Page initialData={orderlist} page={page} setPage={setPage} maxFirstPage={12} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function FuturesOrderDetailItem({ item, darkname }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((p) => p.instId === item.instId)

    let arr = item.instId.split('-')
    const date = UTCTimestamp(item.uTime);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <div className={`history-right-bottom-4-bottom-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1'>{formattedDate}</div>
            <div className='history-right-bottom-4-bottom-2-1'><span>{arr[0]}</span><span>USDT</span></div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.ordType}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.side}</div>
            <div className='history-right-bottom-4-bottom-2-1'><span>{changeNum(Number(item.fillSz) * ctVal[0].ctVal)}</span><span>/</span><span>{changeNum((item.sz * item.px * ctVal[0].ctVal).toFixed(4))}</span></div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(Decimalization(item.px))}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.state}</div>
        </div >
    )
}

let t_id = 0
function FuturesTradeHistory({ darkname, orderReload }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let API_Address = useSelector((state) => { return state.API_Address.value });
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderlistSum, setOrderlistSum] = useState([])
    const [orderdetail, setOrderdetail] = useState([])
    const [selectsym, setSelectsym] = useState("")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [checksym, setChecksym] = useState([false, false, false, false])
    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const [num, setNum] = useState(0)
    const [spinner, setSpinner] = useState(true)
    const [personal_fee, setPersonal_fee] = useState(0.5)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/trade-history`, {
                params: {
                    reqId: t_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response.data.reqId
                let tmp = response.data.data
                if (tmp && t_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 125, "msg": error }))
            })
        }
    }

    useEffect(() => {
        setChecksym([false, false, false, false])
    }, [page, selectedDate, selectedDate1, orderReload])

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let check = true

                let ctVal = OKX_Instruments.filter((p) => p.instId === orderlist[i].instId)

                for (let j = 0; j < summary.length; j++) {
                    if (orderlist[i].instId === summary[j].instId) {
                        check = false
                        summary[j].volume += Number(orderlist[i].fillPx) * Number(orderlist[i].fillSz) * ctVal[0].ctVal
                        summary[j].pnl += Number(orderlist[i].fillPnl)
                        summary[j].fee += Number(orderlist[i].fee)
                        summary[j].reward += Number(orderlist[i].reward)
                    }
                }
                if (check) {
                    summary.push({
                        instId: orderlist[i].instId, volume: Number(orderlist[i].fillPx) * Number(orderlist[i].fillSz) * ctVal[0].ctVal,
                        pnl: Number(orderlist[i].fillPnl), fee: Number(orderlist[i].fee), reward: Number(orderlist[i].reward),
                        side: orderlist[i].side, fillPx: orderlist[i].fillPx, fillSz: orderlist[i].fillSz
                    })
                }
            }
        }
        setOrderlistSum(summary)
        setPage1(1)
    }, [orderlist])

    useEffect(() => {
        let detail = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                if (orderlist[i].instId === selectsym) {
                    detail.push({
                        instId: orderlist[i].instId, side: orderlist[i].side, fillPx: orderlist[i].fillPx, fillSz: orderlist[i].fillSz,
                        fee: Number(orderlist[i].fee), time: orderlist[i].ts, pnl: Number(orderlist[i].fillPnl), reward: Number(orderlist[i].reward)
                    })
                }
            }
        }
        setOrderdetail(detail)
        setPage1(1)
    }, [orderlist, selectsym])

    const handleButtonClick = (e) => {
        setChecksym(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    let ordertop = orderlistSum.slice((page - 1) * 4, (page) * 4)
    let orderbottom = orderdetail.slice((page1 - 1) * 4, (page1) * 4)

    return (
        <div className={`f-trade-history ${darkname}`}>
            <div className='f-trade-history-1'>
                <div className='f-trade-history-1-img'>
                    <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                    <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img' style={{ display: "none" }}></img>
                    <span>{t('futures_48')}</span>
                </div>
                <div className={`f-trade-history-1-date ${darkname}`}>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                    // excludeDates={disabledDates}
                    />
                    <p>-</p>
                    <DatePicker
                        dateFormat='yyyy-MM-dd' // 날짜 형태
                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                        selected={selectedDate1}
                        onChange={(date) => setSelectedDate1(date)}
                    />
                </div>
                {/* <div className='f-trade-history-1-btn' onClick={() => FuturesOrderHistory()}>{t('market_0')}</div> */}
            </div>
            <div className={`f-trade-history-2 ${darkname}`}>
                <div className={`f-trade-history-2-1 ${darkname}`}>
                    <div className='f-trade-history-2-1-1'>{t('futures_49')}</div>
                    <div className='f-trade-history-2-1-1'><span>{t('futures_50')}</span> <span>(USDT)</span></div>
                    <div className='f-trade-history-2-1-1'>{t('futures_51')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_52')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_53')}</div>
                    <div className='f-trade-history-2-1-1'>{t('futures_54')}</div>
                </div>
                <div className={`history-right-bottom-4-bottom ${darkname}`}>
                    {
                        spinner
                            ?
                            <div className='spot-loading' style={{ height: "200px" }}>
                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            orderlistSum && orderlistSum.length === 0
                                ?
                                <div className='history-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p><span>{t('message_0')}</span></p>
                                </div>
                                :
                                <>{
                                    ordertop.map(function (a, i) {
                                        return (
                                            <FuturesDetailItem key={i} num={i} setNum={setNum} item={a} setSelectsym={setSelectsym} darkname={darkname} selectsym={selectsym} checksym={checksym} handleButtonClick={handleButtonClick} personal_fee={personal_fee} />
                                        )
                                    })}
                                    <Page initialData={orderlistSum} page={page} setPage={setPage} maxFirstPage={4} />
                                </>
                    }
                </div>
                {
                    checksym[num]
                        ?
                        <div className={`history-right-bottom-4-bottom-3 ${darkname}`}>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th><span>{t('futures_55')}</span></th>
                                        <th><span>{t('futures_56')}</span></th>
                                        <th><span>{t('futures_57')}</span></th>
                                        <th><span>{t('futures_58')}</span></th>
                                        <th><span>{t('futures_51')}</span></th>
                                        <th><span>{t('futures_59')}</span></th>
                                        <th><span>{t('futures_60')}</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orderbottom.map(function (a, i) {
                                            return (
                                                <FuturesDetailTable key={i} item={a} state={false} personal_fee={personal_fee} />
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <Page initialData={orderdetail} page={page1} setPage={setPage1} maxFirstPage={4} />
                        </div>
                        :
                        ""
                }
            </div>
        </div>
    )
}

function FuturesDetailItem({ num, setNum, item, setSelectsym, darkname, selectsym, checksym, handleButtonClick, personal_fee }) {
    let arr = item.instId.split('-')

    return (
        <div className={selectsym === item.instId && checksym[num] ? `history-right-bottom-4-bottom-2 on ${darkname}` : `history-right-bottom-4-bottom-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1' onClick={() => { setSelectsym(item.instId); setNum(num); handleButtonClick(num) }} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faComputerMouse} style={{ color: "#979797" }} />
                <span style={{ marginLeft: "5px" }}><span>{arr[0]}</span><span>USDT</span></span>
            </div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(item.volume.toFixed(5))}</div>
            <div className={`history-right-bottom-4-bottom-2-1 ${Number(item.pnl) >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(item.pnl).toFixed(5))}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(Math.abs(item.fee).toFixed(5))}</div>
            <div className='history-right-bottom-4-bottom-2-1 green'>{changeNum((item.reward).toFixed(5))}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum((item.pnl + item.fee + item.reward).toFixed(5))}</div>
        </div>
    )
}

function FuturesDetailTable({ item, state, personal_fee }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((p) => p.instId === item.instId)

    const date = UTCTimestamp(new Date(Number(item.time)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <tr>
            <td><span>{formattedDate}</span></td>
            <td><span>{state ? `${item.side} / ${item.state}` : item.side}</span></td>
            <td><span>{changeNum(Decimalization(item.fillPx))}</span></td>
            <td><span>{changeNum(Number((Number(item.fillSz) * ctVal[0].ctVal).toFixed(10)))}</span><span>/</span><span>{changeNum((Number(item.fillSz) * Number(item.fillPx) * ctVal[0].ctVal).toFixed(4))}</span></td>
            <td className={`${item.pnl >= 0 ? "green" : "red"}`}><span>{changeNum(Math.abs(item.pnl).toFixed(5))}</span></td>
            <td><span>{changeNum(Math.abs(item.fee).toFixed(5))}</span></td>
            <td className='green'><span>{changeNum((item.reward).toFixed(5))}</span></td>
        </tr>
    )
}

function FuturesMarginUpdate({ setMarginUpdate, symbol, darkname }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [margininput, setMargininput] = useState("")
    const [buysell, setBuysell] = useState(false)

    const onChangeMarginInput = (e) => {
        setMargininput(e.target.value)
    }

    const MarginChange = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/margin/balance", {
                instId: symbol,
                posSide: "net",
                type: buysell ? "add" : "reduce",
                amt: margininput,
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 126, "msg": error }))
            })
        }
    }

    return (
        <div className='futures-margin-modal'>
            <div className='futures-margin-modal-1'>
                <div className={`futures-margin-modal-1-2 ${darkname}`}>
                    <div className='futures-margin-modal-1-1-tit' style={{ textAlign: "center" }}>{t('futures_61')}</div>
                    <div className={`futures-margin-modal-1-1-wrap ${darkname}`}>
                        <div className={`futures-margin-modal-1-2-txt ${darkname}`}>1</div>
                        <div className={`futures-margin-modal-1-2-txt ${darkname}`}>
                            <input type='number' value={margininput} onChange={onChangeMarginInput} placeholder='Amount' />
                        </div>
                    </div>
                    <div className='futures-margin-modal-1-1-btn'>
                        <div className='futures-margin-modal-1-2-btn-1' onClick={() => { MarginChange(); setBuysell(true); setMarginUpdate(false) }}>{t('futures_62')}</div>
                        <div className='futures-margin-modal-1-2-btn-1' onClick={() => { MarginChange(); setBuysell(false); setMarginUpdate(false) }}>{t('futures_63')}</div>
                        <div className='futures-margin-modal-1-2-btn-2' onClick={() => setMarginUpdate(false)}>{t('futures_115')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Margin({ setMargintab, marginbtn1, setMarginbtn1, darkname, FuturesChangeLever, firstmargin }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    const [btnpercent, setBtnpercent] = useState([marginbtn1]);

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const onChangePercent = (e) => {
        const { value } = e.target;

        if (Number(value) <= firstmargin) {
            let onlyNumber = value.replace(/[^0-9]/g, '');
            if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
                onlyNumber = onlyNumber.substring(1);
            }
            setBtnpercent([onlyNumber])
        }
    }

    return (
        <div className='futures-margin-modal'>
            <div className='futures-margin-modal-1'>
                <div className={`futures-margin-modal-1-1 ${darkname}`}>
                    <div className='futures-margin-modal-1-1-tit' style={{ textAlign: "center" }}>{t('futures_35')}</div>
                    <div className={`futures-margin-modal-1-1-wrap ${darkname}`}>
                        <div className={`futures-margin-modal-1-1-txt ${darkname}`}>
                            <div className='futures-section4-top-5-4' >
                                <div className='futures-reverage-input'>
                                    <input type='number' className='futures-margin-output' id="output" value={btnpercent[0]} onChange={onChangePercent} style={{ width: "35px", marginTop: "0" }} ref={inputRef} />
                                    <div style={{ opacity: ".5" }}>x</div>
                                </div>
                                <Range
                                    values={btnpercent}
                                    step={1}
                                    min={1}
                                    max={firstmargin}
                                    onChange={e => { setBtnpercent(e) }}
                                    renderTrack={({ props, children }) => (
                                        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px", width: "100%", borderRadius: "4px",
                                                    background: getTrackBackground({
                                                        values: btnpercent,
                                                        colors: [`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`, "#ccc"],
                                                        min: 1,
                                                        max: firstmargin
                                                    }),
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div {...props} style={{ ...props.style, height: "25px", width: "25px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                            <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? `${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}` : "#CCC" }} />
                                        </div>
                                    )}
                                />
                                <div className='futures-section4-top-5-4-1'>
                                    <div className='futures-section4-top-5-4-2'>0 x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor(firstmargin / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{Math.floor(firstmargin / 2)} x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor((firstmargin * 3) / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{firstmargin} x</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='futures-margin-modal-2'>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([1]) }}>1x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([5]) }} style={{ display: firstmargin >= 5 ? "" : "none" }}>5x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([10]) }} style={{ display: firstmargin >= 10 ? "" : "none" }}>10x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([20]) }} style={{ display: firstmargin >= 20 ? "" : "none" }}>20x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([50]) }} style={{ display: firstmargin >= 50 ? "" : "none" }}>50x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([75]) }} style={{ display: firstmargin >= 75 ? "" : "none" }}>75x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([100]) }} style={{ display: firstmargin >= 100 ? "" : "none" }}>100x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([125]) }} style={{ display: firstmargin >= 125 ? "" : "none" }}>125x</div>
                    </div>
                    <div className='futures-margin-modal-1-1-btn'>
                        <div className='futures-margin-modal-1-1-btn-1' onClick={() => { setMargintab(false); setMarginbtn1(btnpercent[0]); FuturesChangeLever(btnpercent[0]) }}>{t('futures_36')}</div>
                        <div className='futures-margin-modal-1-1-btn-2' onClick={() => setMargintab(false)}>{t('futures_37')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fsection6;