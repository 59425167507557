import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { RoundDown } from '../../common/function';

import { Position } from '../../common/C_spot/M_goex8';

function GoexOKXS8_1({ symbol, balance, openorder }) {
    const { t } = useTranslation();

    let dispatch = useDispatch()
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [delay, setDelay] = useState(true)
    const [message, setMessage] = useState([])
    const [balanceList, setBalanceist] = useState([])
    const [openorderList, setOpenorderList] = useState([])

    useEffect(() => {
        let arr = {}
        if (balance?.coin) {
            if (balance.coin != "USDT") {
                arr = {
                    ccy: balance.coin,
                    symbol: balance.coin + "USDT",
                    available: balance.availableToWithdraw,
                    eq: balance.equity,
                    value: balance.usdValue,
                    locked: balance.locked
                }
            }
        }
        setBalanceist(arr)
    }, [balance])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].symbol.split('USDT')
                arr.push({
                    ccy: sym[0],
                    symbol: openorder[i].symbol,
                    orderId: openorder[i].orderId,
                    type: openorder[i].orderType,
                    side: openorder[i].side,
                    px: openorder[i].price,
                    sz: openorder[i].qty
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = () => {
        let list = []
        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i]
                list.push({ ordId: s.ordId, instId: s.instId })
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel", {
                ordArr: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 116, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/cancel", {
                ordId: item.ordId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setDelay(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 117, "msg": error }))
                })
        }
    }

    return (
        <>
            <Modal message={message} />
            <Position balanceList={balanceList} openorderList={openorderList} FuturesAllOpenOrderCancel={CancelAll} FuturesOpenOrderCancel={FuturesOpenOrderCancel} openorder_cancel={openorder_cancel} delay={delay} symbol={symbol} exchange={"bybit"} />
        </>
    )
}

export default GoexOKXS8_1;