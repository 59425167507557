import { useSelector } from 'react-redux';
import '../css/modal.css'
import { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

function Modal({ message }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";
    const [buyModal, setBuyModal] = useState(false);

    useEffect(() => {
        if (message.type) {
            setBuyModal(true)
            setTimeout(() => {
                setBuyModal(false);
            }, 2500);
        }
    }, [message]);

    return (
        <>
            {
                API_Address == "dev"
                    ?
                    message.type == "okx"
                        ?
                        <div className={`new-modal ${buyModal ? 'show' : 'hide'} ${darkname}`}>
                            <div className='new-modal-1'><i className={`${Number(message.code) === 0 ? "fa-regular fa-circle-check new-modal-green" : "fa-regular fa-circle-xmark new-modal-red"} `}></i></div>
                            <div className='new-modal-2'>{message.msg}</div>
                        </div>
                        :
                        <div className={`new-modal ${buyModal ? 'show' : 'hide'} ${darkname}`} style={{ zIndex: Number(message.code) === -123 ? "100000" : "" }}>
                            <div className='new-modal-1'><i className={`${Number(message.code) >= 0 ? "fa-regular fa-circle-check new-modal-green" : "fa-regular fa-circle-xmark new-modal-red"} `}></i></div>
                            <div className='new-modal-2'>{message.msg}</div>
                        </div>
                    :
                    message.type == "okx"
                        ?
                        <div className={`buy-modal ${buyModal ? 'show' : 'hide'} ${Number(message.code) === 0 ? "modal-green" : "modal-red"} ${darkname}`}>
                            <div>{message.msg}</div>
                        </div>
                        :
                        <div className={`buy-modal ${buyModal ? 'show' : 'hide'} ${Number(message.code) >= 0 ? "modal-green" : "modal-red"} ${darkname}`} style={{ zIndex: Number(message.code) === -123 ? "100000" : "" }}>
                            <div>{message.msg}</div>
                        </div>
            }
        </>
    )
}

export default Modal;