import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import { useTranslation } from "react-i18next";
import { findSmallest, RoundDown } from "../../common/function"

import { C_Exchange } from '../../common/C_wallet/W_wallet';

function Bybit_Exchange({ walletData, spotBalance, setReload, fastCoin }) {
    const { t } = useTranslation();
    let BybitS = useSelector((state) => { return state.Bybit_coinS });
    let dispatch = useDispatch();

    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [amount, setAmount] = useState("")
    const [balanceAmount, setBalanceAmount] = useState(0)
    const [direction, setDirection] = useState(false)
    const [markprice, setMarkprice] = useState(0)
    const [calAmount, setCalAmount] = useState(0)
    const [sym, setSym] = useState("")

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const [exinfo, setExinfo] = useState([])
    const [qtypoint, setQtypoint] = useState(0)

    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    let BS_filterSymbol = []
    if (BybitS) {
        BS_filterSymbol = BybitS.filter((p) => {
            return (p.ccy.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    BS_filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));

    useEffect(() => {
        if (walletData) {
            for (let i = 0; i < walletData.length; i++) {
                if (walletData[i].symbol === selectSearch) {
                    setBalanceAmount(Number(walletData[i].availBal))
                    setMarkprice(Number(walletData[i].lastPrice))
                    setSym(`${walletData[i].symbol}USDT`)
                }
            }
        }
    }, [selectSearch, walletData])

    useEffect(() => {
        setCalAmount(direction ? RoundDown((Number(amount) / Number(markprice)), qtypoint) : (RoundDown(Number(markprice) * Number(amount), 8)))
    }, [amount, selectSearch])

    useEffect(() => {
        ExchangeInformation()
    }, [selectSearch])

    useEffect(() => {
        if (exinfo?.basePrecision) {
            let point = findSmallest(Number(exinfo.basePrecision))
            setQtypoint(point)
        }
    }, [exinfo])

    const ExchangeInformation = async () => {
        await axios.get('https://api.bybit.com/v5/market/instruments-info?category=spot'
        ).then((response) => {
            let tmp = response?.data?.result?.list
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == `${selectSearch}USDT`) {
                        setExinfo(a?.lotSizeFilter)
                    }
                })
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    const createPostData = (side, sz) => {
        return {
            category: "spot",
            symbol: sym,
            side: side,
            orderType: 'MARKET',
            price: "",
            qty: sz,
        };
    };

    const onSubmit = async (postData, token, url) => {
        await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
            if (response?.data?.code >= 0) {
                setTimeout(() => {
                    setSpinner(false);
                    setReload(e => !e)
                    setSearch("")
                    setAmount("")
                    setSelectSearch("")
                    setBalanceAmount(0)
                    setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_16')}` })
                }, 2000);
            }
            else {
                setTimeout(() => {
                    setSpinner(false);
                    setMessage({ type: 0, code: Number(response?.data?.code), msg: response?.data?.msg })
                }, 1000);
            }
            setW_confirm(false)
        }).catch((error) => {
            alert("Error, please refresh")
            console.log(error)
        })
    }

    const onSubmitBuy = () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            setSpinner(true)

            let url = process.env.REACT_APP_API + "/api/v1" + '/trade/bybit/spot/order'
            let postData = direction ? createPostData("Buy", calAmount) : createPostData("Sell", amount);
            onSubmit(postData, token, url);
        }
    };

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            setW_confirm(false)
            onSubmitBuy()
        }
    }, [w_confirm])

    return (
        <C_Exchange search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} selectSearch={selectSearch} setSelectSearch={setSelectSearch} amount={amount} setAmount={setAmount} onChangeAmount={onChangeAmount} balanceAmount={balanceAmount} direction={direction} setDirection={setDirection} markprice={markprice} calAmount={calAmount} spinner={spinner} message={message} qtypoint={qtypoint} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} spotBalance={spotBalance} BS_filterSymbol={BS_filterSymbol}/>
    )
}

export default Bybit_Exchange;