import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faFlag } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { changeNum, changeUnits } from "../function"

function Trades({ tradeData, qtypoint, max, darkname }) {
    const { t } = useTranslation();
    return (
        <div className='futures-section17-trade'>
            <div className='futures-section17-trade-1'>
                <div className='futures-section17-trade-1-1'>{t('futures_77')}</div>
                <div className='futures-section17-trade-1-2'>{t('futures_76')}</div>
                <div className='futures-section17-trade-1-2'>{t('futures_117')}</div>
            </div>
            {
                tradeData.map(function (a, i) {
                    return (
                        < TradingTab key={i} item={a} qtypoint={qtypoint} max={max} darkname={darkname} />
                    )
                })}
        </div>
    )
}

function TradingTab({ item, qtypoint, max, darkname }) {
    const date = new Date(Number(item.t));

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}:${seconds}`;

    return (
        <>
            {
                item.side
                    ?
                    <div className='futures-section17-trade-1'>
                        <div className='futures-section17-trade-1-1 red'>{changeNum(Number(item.px).toFixed(max))}</div>
                        <div className={`futures-section17-trade-1-2 ${darkname}`}>{changeUnits(Number(item.sz).toFixed(qtypoint))}</div>
                        <div className={`futures-section17-trade-1-2 ${darkname}`}>{timeString}</div>
                    </div>
                    :
                    <div className='futures-section17-trade-1'>
                        <div className='futures-section17-trade-1-1 green'>{changeNum(Number(item.px).toFixed(max))}</div>
                        <div className={`futures-section17-trade-1-2 ${darkname}`}>{changeUnits(Number(item.sz).toFixed(qtypoint))}</div>
                        <div className={`futures-section17-trade-1-2 ${darkname}`}>{timeString}</div>
                    </div>
            }
        </>
    )
}


function Orderbook({ up, down, setLastBuy, setLastSell, data, setCheckordertab, darkname, max, qtypoint, priceDirect, currentPrice, btnbtn, mark, windowtypeM }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? windowtypeM ? up.slice(0, 13) : up.slice(0, 11) : [];
    const down8 = down ? windowtypeM ? down.slice(0, 13) : down.slice(0, 11) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum4 = sum1 + sum2
    let sum5 = 0;
    let sum6 = 0;


    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const divElement = divRef.current;
            const divHeight = divElement.clientHeight;
            const windowHeight = window.innerHeight - 104;
            const scrollY = (divHeight - windowHeight) / 2;

            window.scrollTo(0, scrollY);
        }
    }, []);

    return (
        <div className='futures-mid-1' ref={divRef}>
            <div className='futures-mid-3' style={{ height: "800px" }}>
                <div className={`futures-orderbook ${darkname}`}>
                    <div className='futures-orderbook-2-1'>
                        <div className='futures-orderbook-2-3'>{t('futures_77')}</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_76')}{windowtypeM ? ((btnbtn === 0 ? `(${t('futures_150')})` : "(USDT)")) : ""}</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_76')}{windowtypeM ? ((btnbtn === 0 ? `(${t('futures_150')})` : "(USDT)")) : ""}</div>
                    </div>
                    <div className='futures-orderbook-1'>
                        <div style={{ width: "100%" }}>
                            {
                                [...up8].reverse().map(function (a, i) {
                                    sum1 -= sum5
                                    sum5 = btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookUp key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={`futures-orderbook-2 ${darkname}`}>
                        <div className='futures-orderbook-2-2'>
                            {
                                priceDirect
                                    ?
                                    <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                                    :
                                    <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                            }
                            <div className='futures-orderbook-2-4-1'><FontAwesomeIcon icon={faFlag} style={{ marginRight: "5px", fontSize: "11px" }} />
                                <span>{changeNum(Number(mark ?? 0).toFixed(max))}</span>
                            </div>
                        </div>
                    </div>
                    <div className='futures-orderbook-3'>
                        <div style={{ width: "100%" }}>
                            {
                                down8.map(function (a, i) {
                                    sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookDown key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='futures-section17-order-sum'>
                    <div className='futures-section17-order-sum-1'>
                        <div className={`futures-section17-order-sum-2 bg-green ${darkname}`}>
                            <span>B</span>
                            <i>{Math.floor(sum2 * 100 / sum4)}%</i>
                        </div>
                        <div className='futures-section17-order-sum-3'>
                            <div className={`futures-section17-order-sum-3-1 ${darkname}`} style={{ width: `${Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-1-1 ${darkname}`}></div>
                            </div>
                            <div className={`futures-section17-order-sum-3-2 ${darkname}`} style={{ width: `${100 - Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-2-1 ${darkname}`}></div>
                            </div>
                        </div>
                        <div className={`futures-section17-order-sum-4 bg-red ${darkname}`}>
                            <i>{100 - Math.floor(sum2 * 100 / sum4)}%</i>
                            <span>S</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OrderbookUp({ price, setLastSell, sum1, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-red ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 red'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(sum1.toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>

    )
}

function OrderbookDown({ price, setLastBuy, sum6, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-green ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 green'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(sum6.toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>

    )
}


function Dual({ up, down, setLastBuy, setLastSell, data, setCheckordertab, darkname, max, qtypoint, currentPrice, btnbtn, priceDirect, mark }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 28) : [];
    const down8 = down ? down.slice(0, 28) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum4 = sum1 + sum2
    let sum5 = 0;
    let sum6 = 0;

    return (
        <div className='futures-dual'>
            <div className={`futures-orderbook-2 ${darkname}`}>
                <div className='futures-orderbook-2-2'>
                    {
                        priceDirect
                            ?
                            <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                            </div>
                            :
                            <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                            </div>
                    }
                    <div className='futures-orderbook-2-4-1'><FontAwesomeIcon icon={faFlag} style={{ marginRight: "5px", fontSize: "11px" }} />
                        <span>{changeNum(Number(mark ?? 0).toFixed(max))}</span>
                    </div>
                </div>
            </div>
            <div className={`futures-dual-bottom ${darkname}`}>
                <div className='futures-dual-bottom-1'>
                    <div className='futures-dual-bottom-top green'>
                        <div className='futures-dual-bottom-top-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                        <div className='futures-dual-bottom-top-2'>{t('futures_77')}</div>
                    </div>
                    <div className='futures-dual-bottom-bottom'>
                        {
                            down8.map(function (a, i) {
                                sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                return (
                                    <DualDown key={i} price={a} setLastBuy={setLastBuy} setLastSell={setLastSell} sum6={sum6} sum3={sum3} setCheckordertab={setCheckordertab} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                )
                            })
                        }
                    </div>
                </div>
                <div className='futures-dual-bottom-1'>
                    <div className='futures-dual-bottom-top red'>
                        <div className='futures-dual-bottom-top-1'>{t('futures_77')}</div>
                        <div className='futures-dual-bottom-top-2'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    </div>
                    <div className='futures-dual-bottom-bottom'>
                        {
                            up8.map(function (a, i) {
                                sum5 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                return (
                                    <DualUp key={i} price={a} setLastBuy={setLastBuy} setLastSell={setLastSell} sum5={sum5} sum3={sum3} setCheckordertab={setCheckordertab} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                )
                            })}
                    </div>
                </div>
            </div>
            <div className='futures-section17-order-sum'>
                <div className='futures-section17-order-sum-1'>
                    <div className={`futures-section17-order-sum-2 bg-green ${darkname}`}>
                        <span>B</span>
                        <i>{Math.floor(sum2 * 100 / sum4)}%</i>
                    </div>
                    <div className='futures-section17-order-sum-3'>
                        <div className={`futures-section17-order-sum-3-1 ${darkname}`} style={{ width: `${Math.floor(sum2 * 100 / sum4)}%` }}>
                            <div className={`futures-section17-order-sum-3-1-1 ${darkname}`}></div>
                        </div>
                        <div className={`futures-section17-order-sum-3-2 ${darkname}`} style={{ width: `${100 - Math.floor(sum2 * 100 / sum4)}%` }}>
                            <div className={`futures-section17-order-sum-3-2-1 ${darkname}`}></div>
                        </div>
                    </div>
                    <div className={`futures-section17-order-sum-4 bg-red ${darkname}`}>
                        <i>{100 - Math.floor(sum2 * 100 / sum4)}%</i>
                        <span>S</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DualDown({ price, setLastBuy, sum6, sum3, setCheckordertab, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1]).toFixed(qtypoint)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <div className='futures-dualup' onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
            <div className={`futures-dualup-bg bg-green`} style={{ width: `${wid}%` }}></div>
            <div className='futures-dualup-1'>{changeUnits(sum6.toFixed(btnbtn === 0 ? qtypoint : 2))}</div>
            <div className='futures-dualup-2 green'>{changeNum(str)}</div>
        </div>
    )
}

function DualUp({ price, setLastSell, sum5, sum3, setCheckordertab, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1]).toFixed(qtypoint)
    let wid = sum3 > 0 ? sum5 * 100 / sum3 : 0;

    return (
        <div className='futures-dualup1' onClick={() => { setLastSell(str); setCheckordertab(false) }}>
            <div className={`futures-dualup-bg bg-red`} style={{ width: `${wid}%` }}></div>
            <div className='futures-dualup-1 red'>{changeNum(str)}</div>
            <div className='futures-dualup-2'>{changeUnits(sum5.toFixed(btnbtn === 0 ? qtypoint : 2))}</div>
        </div>
    )
}

function OrderbookBuy({ up, down, setLastBuy, data, setCheckordertab, darkname, max, qtypoint, priceDirect, currentPrice, btnbtn, mark }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 26) : [];
    const down8 = down ? down.slice(0, 26) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum4 = sum1 + sum2
    let sum5 = 0;
    let sum6 = 0;

    return (
        <div className='futures-mid-1'>
            <div className='futures-mid-3'>
                <div className={`futures-orderbook ${darkname}`}>
                    <div className={`futures-orderbook-2 ${darkname}`}>
                        <div className='futures-orderbook-2-2'>
                            {
                                priceDirect
                                    ?
                                    <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                                    :
                                    <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                            }
                            <div className='futures-orderbook-2-4-1'><FontAwesomeIcon icon={faFlag} style={{ marginRight: "5px", fontSize: "11px" }} />
                                <span>{changeNum(Number(mark ?? 0).toFixed(max))}</span>
                            </div>
                        </div>
                    </div>
                    <div className='futures-orderbook-2-1'>
                        <div className='futures-orderbook-2-3'>{t('futures_77')}</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_76')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    </div>
                    <div className='futures-orderbook-5'>
                        <div style={{ width: "100%" }}>
                            {
                                down8.map(function (a, i) {
                                    sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookBuyDown key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='futures-section17-order-sum'>
                    <div className='futures-section17-order-sum-1'>
                        <div className={`futures-section17-order-sum-2 bg-green ${darkname}`}>
                            <span>B</span>
                            <i>{Math.floor(sum2 * 100 / sum4)}%</i>
                        </div>
                        <div className='futures-section17-order-sum-3'>
                            <div className={`futures-section17-order-sum-3-1 ${darkname}`} style={{ width: `${Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-1-1 ${darkname}`}></div>
                            </div>
                            <div className={`futures-section17-order-sum-3-2 ${darkname}`} style={{ width: `${100 - Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-2-1 ${darkname}`}></div>
                            </div>
                        </div>
                        <div className={`futures-section17-order-sum-4 bg-red ${darkname}`}>
                            <i>{100 - Math.floor(sum2 * 100 / sum4)}%</i>
                            <span>S</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OrderbookBuyDown({ price, setLastBuy, sum6, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-green ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 green'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(sum6.toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>
    )
}


function OrderbookSell({ up, down, setLastSell, data, setCheckordertab, darkname, max, qtypoint, priceDirect, currentPrice, btnbtn, mark }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 26) : [];
    const down8 = down ? down.slice(0, 26) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum4 = sum1 + sum2
    let sum5 = 0;
    let sum6 = 0;

    return (
        <div className='futures-mid-1'>
            <div className='futures-mid-3'>
                <div className={`futures-orderbook ${darkname}`}>
                    <div className={`futures-orderbook-2 ${darkname}`}>
                        <div className='futures-orderbook-2-2'>
                            {
                                priceDirect
                                    ?
                                    <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                                    :
                                    <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                        {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                    </div>
                            }
                            <div className='futures-orderbook-2-4-1'><FontAwesomeIcon icon={faFlag} style={{ marginRight: "5px", fontSize: "11px" }} />
                                <span>{changeNum(Number(mark ?? 0).toFixed(max))}</span>
                            </div>
                        </div>
                    </div>
                    <div className='futures-orderbook-2-1'>
                        <div className='futures-orderbook-2-3'>{t('futures_77')}</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_76')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                        <div className='futures-orderbook-2-3-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    </div>
                    <div className='futures-orderbook-4'>
                        <div style={{ width: "100%" }}>
                            {
                                [...up8].reverse().map(function (a, i) {
                                    sum1 -= sum5
                                    sum5 = btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookSellUp key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='futures-section17-order-sum'>
                    <div className='futures-section17-order-sum-1'>
                        <div className={`futures-section17-order-sum-2 bg-green ${darkname}`}>
                            <span>B</span>
                            <i>{Math.floor(sum2 * 100 / sum4)}%</i>
                        </div>
                        <div className='futures-section17-order-sum-3'>
                            <div className={`futures-section17-order-sum-3-1 ${darkname}`} style={{ width: `${Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-1-1 ${darkname}`}></div>
                            </div>
                            <div className={`futures-section17-order-sum-3-2 ${darkname}`} style={{ width: `${100 - Math.floor(sum2 * 100 / sum4)}%` }}>
                                <div className={`futures-section17-order-sum-3-2-1 ${darkname}`}></div>
                            </div>
                        </div>
                        <div className={`futures-section17-order-sum-4 bg-red ${darkname}`}>
                            <i>{100 - Math.floor(sum2 * 100 / sum4)}%</i>
                            <span>S</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OrderbookSellUp({ price, setLastSell, sum1, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-red ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 red'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(sum1.toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>

    )
}

function Orderbook_tab({ up, down, setLastBuy, setLastSell, data, setCheckordertab, darkname, max, qtypoint, priceDirect, currentPrice, btnbtn, tradeData, mark, index, MOrder, MPosition, funding, nextTime, feeAmount,  positionDirect }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 13) : [];
    const down8 = down ? down.slice(0, 13) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum4 = sum1 + sum2
    let sum5 = 0;
    let sum6 = 0;

    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const divElement = divRef.current;
            const divHeight = divElement.clientHeight;
            const windowHeight = window.innerHeight - 104;
            const scrollY = (divHeight - windowHeight) / 2;

            window.scrollTo(0, scrollY);
        }
    }, []);

    return (
        <div className='futures-mid-1' ref={divRef}>
            <div className='futures-mid-3'>
                <div className='spot-left-mid-2-mid'>
                    <div className='spot-left-mid-2-mid-1'>
                        <div style={{ width: "100%" }}>
                            {
                                [...up8].reverse().map(function (a, i) {
                                    sum1 -= sum5
                                    sum5 = btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookUp_tab key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                        <div className={`futures-section17-order-detail-1 ${darkname}`}>
                            <div className='futures-section17-order-detail-1-box'>
                                {/* <div className={`futures-section17-order-detail-1-box-1 ${darkname}`}>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>거래량</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>251.21M</div>
                                    </div>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>최고</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>72182</div>
                                    </div>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>최저</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>35446</div>
                                    </div>
                                </div> */}
                                <div className={`futures-section17-order-detail-1-box-1 ${darkname}`}>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>Mark</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>{changeNum(Number(mark ?? 0).toFixed(max))}</div>
                                    </div>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>Index</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>{changeNum(Number(index ?? 0).toFixed(max))}</div>
                                    </div>
                                </div>

                                <div className={`futures-section17-order-detail-1-box-1 ${darkname}`}>
                                    <div className='futures-section17-order-detail-1-box-1-list column'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1 tab'>Funding</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2 tab'>
                                            <div className='futures-mid-top-table-th-2' style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span className={`futures-section17-order-detail-1-box-1-list-1 ${darkname}`}><span>{((Number(funding ?? 0)) * 100).toFixed(5)}</span> <span>%</span></span>
                                                <span className={`futures-section17-order-detail-1-box-1-list-2 ${darkname}`}>{nextTime ?? "00:00:00"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='futures-section17-order-detail-1-box-1-list'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1'>Est.Fee</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2'>
                                            <span className={`futures-section17-order-detail-1-box-1-list-1 ${darkname}
                                                    ${positionDirect > 0 ? Number(funding) >= 0 ? "red" : "green"
                                                    : positionDirect < 0 ? Number(funding) >= 0 ? "green" : "red"
                                                        : ""}
                                                    `}>{funding ? Math.abs(Number(funding) * feeAmount ?? 0).toFixed(5) : "0"}</span> <span>USDT</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`futures-section17-order-detail-1-box-1 ${darkname}`}>
                                    <div className='futures-section17-order-detail-1-box-1-list column'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1 tab'>Max.Market</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2 tab'><span>{changeNum(Number(MOrder)) ?? "0.000"}</span> <span>Qty</span></div>
                                    </div>
                                    <div className='futures-section17-order-detail-1-box-1-list column'>
                                        <div className='futures-section17-order-detail-1-box-1-list-1 tab'>Max.Position</div>
                                        <div className='futures-section17-order-detail-1-box-1-list-2 tab'><span>{changeNum(MPosition) ?? "0.000"}</span> <span>USDT</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`spot-left-mid-2-mid-3 ${darkname}`}>
                        {
                            priceDirect
                                ?
                                <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                    {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                </div>
                                :
                                <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                    {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                                </div>
                        }
                    </div>
                    <div className='spot-left-mid-2-mid-2'>
                        <div style={{ width: "100%" }}>
                            {
                                down8.map(function (a, i) {
                                    sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                    return (
                                        <OrderbookDown_tab key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} btnbtn={btnbtn} />
                                    )
                                })
                            }
                        </div>
                        <div className={`futures-section17-order-detail-2 ${darkname}`}>
                            <div className='futures-section17-order-detail-2-box'>
                                <div className='futures-section17-order-detail-2-box-list'>
                                    {
                                        tradeData.map(function (a, i) {
                                            if (i < 16) {
                                                return (
                                                    < Trade_tab key={i} price={a} qtypoint={qtypoint} max={max} darkname={darkname} />
                                                )
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function Trade_tab({ price, qtypoint, max, darkname }) {
    return (
        <>
            {
                price.side
                    ?
                    <div className='futures-section17-trade-1 tab'>
                        <div className='futures-section17-trade-1-1 tab red'>{changeNum(Number(price.px).toFixed(max))}</div>
                        <div className={`futures-section17-trade-1-2 tab ${darkname}`}>{changeUnits(Number(price.sz).toFixed(qtypoint))}</div>
                    </div>
                    :
                    <div className='futures-section17-trade-1 tab'>
                        <div className='futures-section17-trade-1-1 tab green'>{changeNum(Number(price.px).toFixed(max))}</div>
                        <div className={`futures-section17-trade-1-2 tab ${darkname}`}>{changeUnits(Number(price.sz).toFixed(qtypoint))}</div>
                    </div>
            }
        </>
    )
}

function OrderbookUp_tab({ price, setLastSell, sum1, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className="orderbook-box" onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                    <div className='spot-left-mid-2-mid-1-1'>
                        <div className={`orderbook-bg bg-red ${darkname}`} style={{ width: `${wid}%` }}></div>
                        <div style={{ textAlign: "right", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                    </div>
                    <div className='spot-left-mid-2-mid-1-2 red'>
                        {changeNum(str)}
                    </div>
                    <div className='spot-left-mid-2-mid-1-3'>
                    </div>
                </div>
            }
        </>
    )
}

function OrderbookDown_tab({ price, setLastBuy, sum6, sum3, setCheckordertab, darkname, max, qtypoint, btnbtn }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? Number(price[1]).toFixed(qtypoint) : (Number(price[0]) * Number(price[1])).toFixed(2)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className="orderbook-box" onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                    <div className='spot-left-mid-2-mid-2-1'>

                    </div>
                    <div className='spot-left-mid-2-mid-2-2 green'>
                        {changeNum(str)}
                    </div>
                    <div className='spot-left-mid-2-mid-2-3'>
                        <div className={`orderbook-bg bg-green ${darkname}`} style={{ width: `${wid}%` }}></div>
                        <div style={{ textAlign: "left", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                    </div>
                </div>
            }
        </>
    )
}

export { Orderbook_tab, Trades, Orderbook, Dual, OrderbookBuy, OrderbookSell }