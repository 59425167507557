import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSmallest, changeNum, makeSamllest } from "../../common/function"

import { Orderbook_tab, Trades, Orderbook, Dual, OrderbookBuy, OrderbookSell } from '../../common/C_futures/W_goex7';

let arr = []
let prePrice = 0
let pre_symbol = ""

let current = 0
let bol = false
let bn_cnt = 0
let socket_close = true
let maxPoint = 0;

function GoexByF7({ tab1, setLastBuy, setLastSell, data, setCheckordertab, symbol, exinfo, setMax, max, tradeVolume, setCurrentPrice, currentPrice, btnbtn, markPrice, windowtypeM, Mordertab, indexPrice, maxPosition, fundingFee, nextTime, positionDirect, fundingFeeAmount }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Alert = useSelector((state) => { return state.Alert });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Reload = useSelector((state) => { return state.Reload.value })
    const darkname = dark ? 'dark' : "";

    const [orderTradeTab, setOrderTradeTab] = useState(0)
    const [orderBookTab, setOrderBookTab] = useState(0)

    const [selectVolume, setSelectVolume] = useState("1")
    const [smallNum, setSmallNum] = useState("1")
    const [checkup, setCheckup] = useState([]);
    const [checkdown, setCheckdown] = useState([]);
    const [up, setUp] = useState([]);
    const [down, setDown] = useState([]);
    const [tradePreData, setTradePreData] = useState([])
    const [tradeData, setTradeData] = useState([])
    const [isClicked1, setIsClicked1] = useState(true);
    const [qtypoint, setQtypoint] = useState(2)
    const [priceDirect, setPriceDirect] = useState(false)

    const [isPlaying, setIsPlaying] = useState(false);
    const [close, setClose] = useState(false)

    current = currentPrice ? Number(currentPrice) : Number(data.lastPrice)

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    useEffect(() => {
        if (!windowtypeM) {
            setOrderTradeTab(0)
            setOrderBookTab(0)
        }
    }, [windowtypeM])

    useEffect(() => {
        if (exinfo) {
            let point = findSmallest(Number(exinfo?.lotSizeFilter?.minOrderQty ?? 2))
            setQtypoint(point)
        }
    }, [exinfo])

    useEffect(() => {
        arr = []
        setTradeData([])
    }, [symbol])

    const onChangeVolume = (e) => {
        setSelectVolume(e.target.value)
    }

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    useEffect(() => {
        if (bn_cnt) {
            bol = false
            setClose(e => !e)
        }
        bn_cnt += 1
    }, [Reload])

    const change_socket_close = () => {
        socket_close = false
        setTimeout(() => {
            socket_close = true
        }, 3000)
    }

    useEffect(() => {
        let tradeA = []
        let tradeB = []
        let newtradeA = []
        let newtradeB = []
        maxPoint = 0;
        let cntVolume = true;
        // setIsClicked1(bol ? false : true)

        let socket = new WebSocket(`wss://stream.bybit.com/v5/public/linear`);

        socket.onopen = () => {
            const subscribeMessage = JSON.stringify({
                op: 'subscribe',
                args: [`orderbook.50.${symbol}`, `publicTrade.${symbol}`]
            });

            socket.send(subscribeMessage);
        };

        socket.onmessage = function (event) {
            let tmp = JSON.parse(event.data);
            if (tmp.data != undefined) {
                pre_symbol = symbol
                if (tmp.topic == `publicTrade.${symbol}`) {
                    setTradePreData(tmp.data)
                }
                else {
                    let tradeArr = tmp.data
                    if (tradeArr != undefined) {
                        let asks = tradeArr.a;
                        let bids = tradeArr.b;
                        if (asks) {
                            for (let i = 0; i < asks.length; i++) {
                                let cnt = false
                                for (let j = 0; j < tradeA.length; j++) {
                                    if (asks[i][0] === tradeA[j][0]) {
                                        tradeA[j] = asks[i]
                                        cnt = true
                                        break;
                                    }
                                }
                                if (!cnt) {
                                    tradeA.push(asks[i])
                                }
                                if (i >= 0 && i <= 3) {
                                    maxPoint = Math.max(maxPoint, findSmallest(asks[i][0]))
                                }
                            }
                        }

                        if (bids) {
                            for (let i = 0; i < bids.length; i++) {
                                let cnt = false
                                for (let j = 0; j < tradeB.length; j++) {
                                    if (bids[i][0] === tradeB[j][0]) {
                                        tradeB[j] = bids[i]
                                        cnt = true
                                        break;
                                    }
                                }
                                if (!cnt) {
                                    tradeB.push(bids[i])
                                }
                            }
                        }

                        newtradeA = tradeA ? tradeA.filter((p) => Number(p[1]) > 0 && Number(p[0]) >= current) : []
                        newtradeB = tradeB ? tradeB.filter((p) => Number(p[1]) > 0 && Number(p[0]) <= current) : []

                        newtradeA.sort((a, b) => Number(a[0]) - Number(b[0]))
                        newtradeB.sort((a, b) => Number(b[0]) - Number(a[0]))

                        newtradeA = newtradeA.splice(0, 500)
                        newtradeB = newtradeB.splice(0, 500)

                        setUp(newtradeA)
                        setDown(newtradeB)
                        tradeA = newtradeA
                        tradeB = newtradeB

                        setSmallNum(makeSamllest(maxPoint))
                        if (cntVolume) {
                            setSelectVolume(makeSamllest(maxPoint))
                            cntVolume = false
                        }
                        setMax(maxPoint)
                    }
                }
            }
        }

        socket.onclose = function (event) {
            if (bol) {
                console.log("By_orderbook close")
                bol = false
            }
            else {
                if (pre_symbol == symbol && socket_close) {
                    console.log("By_orderbook close")
                    change_socket_close()
                    setClose(e => !e)
                }
            }
        };

        socket.error = function (error) {
            window.location.reload()
        }

        return () => {
            socket.close();
        }
    }, [symbol, close])

    useEffect(() => {
        let newup = []
        let newdown = []

        if (up) {
            for (let i = 0; i < up.length; i++) {
                let num = 0
                let check = true

                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(up[i][0]) - (Number(up[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(up[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                    }
                }

                for (let j = 0; j < newup.length; j++) {
                    if (newup[j][0] === num) {
                        newup[j][1] += Number(up[i][1])
                        check = false
                    }
                }
                if (check) {
                    newup.push([num, Number(up[i][1])])
                }
            }
        }
        if (down) {
            for (let i = 0; i < down.length; i++) {
                let num = 0
                let check = true

                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(down[i][0]) - (Number(down[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(down[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                    }
                }

                for (let j = 0; j < newdown.length; j++) {
                    if (newdown[j][0] === num) {
                        newdown[j][1] += Number(down[i][1])
                        check = false
                    }
                }
                if (check) {
                    newdown.push([num, Number(down[i][1])])
                }
            }
        }

        newup.sort((a, b) => Number(a[0]) - Number(b[0]));
        newdown.sort((a, b) => Number(b[0]) - Number(a[0]));

        if (data.priceChangePercent >= 0) {
            newup = newup.filter((p) => p[0] >= current)
            newdown = newdown.filter((p) => p[0] < current)
        }
        else {
            newup = newup.filter((p) => p[0] > current)
            newdown = newdown.filter((p) => p[0] <= current)
        }

        if (newup && newdown && newup.length >= 13 && newdown.length >= 13) {
            setIsClicked1(false)

            setCheckup(newup)
            setCheckdown(newdown)
        }

    }, [selectVolume, up, down])

    useEffect(() => {
        if (tradePreData) {
            for (let i = 0; i < tradePreData.length; i++) {
                if (arr) {
                    arr.splice(33, 1)
                    arr.unshift({
                        t: tradePreData[i].T,
                        px: tradePreData[i].p,
                        sz: tradePreData[i].v,
                        side: tradePreData[i].S == "Sell" ? true : false
                    })
                }
                if (Alert[0] == "ON" && Number(tradePreData[i].v) * Number(tradePreData[i].p) >= Number(tradeVolume)) {
                    playAudio()
                }
                setCurrentPrice(tradePreData[i].p ? tradePreData[i].p : 0.00000000001)
                current = tradePreData[i].p ? tradePreData[i].p : 0.00000000001
                setTradeData(arr)
            }
        }
    }, [tradePreData])

    return (
        <div className={tab1 ? `futures-section17 ${darkname} ${Mordertab ? "" : "tab"}` : `futures-section17-check ${darkname}`} style={{ width: Mordertab ? "" : "100%" }}>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.1.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                windowtypeM
                    ?
                    <div className={`futures17-top ${darkname}`}>
                        <div className='futures17-top-1'>
                            <div className={`futures17-top-1-1 ${orderTradeTab === 0 ? "active" : ""} ${darkname}`} onClick={() => setOrderTradeTab(0)}>Order Book</div>
                            <div className={`futures17-top-1-1 ${orderTradeTab === 1 ? "active" : ""} ${darkname}`} onClick={() => setOrderTradeTab(1)}>Recent Trades</div>
                        </div>
                        {
                            orderTradeTab === 0
                                ?
                                <div className='futures17-top-2'>
                                    <div className='futures17-top-2-left'>
                                        <div className={`futures17-top-2-1 ${orderBookTab === 0 ? "active" : ""} ${darkname}`} onClick={() => setOrderBookTab(0)}></div>
                                        <div className={`futures17-top-2-2 ${orderBookTab === 1 ? "active" : ""} ${darkname}`} onClick={() => setOrderBookTab(1)}></div>
                                        <div className={`futures17-top-2-3 ${orderBookTab === 2 ? "active" : ""} ${darkname}`} onClick={() => setOrderBookTab(2)}></div>
                                        <div className={`futures17-top-2-4 ${orderBookTab === 3 ? "active" : ""} ${darkname}`} onClick={() => setOrderBookTab(3)}></div>
                                    </div>
                                    <div className='futures17-top-2-right'>
                                        <div className={`futures17-top-2-right-1 ${darkname}`}>
                                            <div className='futures-mid-top-3-box-1'>
                                                <select className={`futures-mid-top-3-box-select ${darkname}`} style={{ textAlignLast: "center", width: "100px", background: "inherit" }} value={selectVolume} onChange={onChangeVolume}>
                                                    <option value={Number(smallNum).toFixed(max >= 0 ? max : 0)}><span>{smallNum}</span></option>
                                                    <option value={(Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0)}><span>{changeNum((Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0))}</span></option>
                                                    <option value={(Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0)}><span>{changeNum((Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0))}</span></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                        }
                    </div>
                    :
                    ""
            }
            {
                isClicked1
                    ?
                    <div className='spot-loading'>
                        <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                    </div>
                    :
                    Mordertab
                        ?
                        orderTradeTab === 0
                            ?
                            orderBookTab === 0
                                ?
                                <Orderbook up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} priceDirect={priceDirect} currentPrice={currentPrice} btnbtn={btnbtn} mark={markPrice} windowtypeM={windowtypeM} />
                                :
                                orderBookTab === 1
                                    ?
                                    <Dual up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} priceDirect={priceDirect} currentPrice={currentPrice} btnbtn={btnbtn} mark={markPrice} />
                                    :
                                    orderBookTab === 2
                                        ?
                                        <OrderbookBuy up={checkup} down={checkdown} setLastBuy={setLastBuy} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} priceDirect={priceDirect} currentPrice={currentPrice} btnbtn={btnbtn} mark={markPrice} />
                                        :
                                        <OrderbookSell up={checkup} down={checkdown} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} priceDirect={priceDirect} currentPrice={currentPrice} btnbtn={btnbtn} mark={markPrice} />
                            :
                            <Trades tradeData={tradeData} qtypoint={qtypoint} max={max} darkname={darkname} />
                        :
                        <Orderbook_tab up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} qtypoint={qtypoint} priceDirect={priceDirect} currentPrice={currentPrice} btnbtn={btnbtn} tradeData={tradeData} mark={markPrice} index={indexPrice} MOrder={exinfo?.lotSizeFilter?.maxMktOrderQty ?? 0} MPosition={maxPosition} funding={fundingFee} nextTime={nextTime} feeAmount={fundingFeeAmount} positionDirect={positionDirect} />
            }
        </div >
    )
}


export default GoexByF7;