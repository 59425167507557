import { useSelector } from 'react-redux';
import '../css/modal.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

function Banner() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";

    const [tab, setTab] = useState(0)
    const [modal, setModal] = useState(false)
    const [banner, setBanner] = useState(true)

    const [isToggled1, setIsToggled1] = useState(false);
    const [isToggled2, setIsToggled2] = useState(false);
    const [isToggled3, setIsToggled3] = useState(false);
    const [isToggled4, setIsToggled4] = useState(false);

    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)
    const [btn4, setBtn4] = useState(false)

    const [tablebtn, setTablebtn] = useState(false)

    const toggleButton1 = () => {
        setIsToggled1(!isToggled1);
    };
    const toggleButton2 = () => {
        setIsToggled2(!isToggled2);
    };
    const toggleButton3 = () => {
        setIsToggled3(!isToggled3);
    };
    const toggleButton4 = () => {
        setIsToggled4(!isToggled4);
    };

    useEffect(() => {
        let objString = localStorage.getItem('banner')
        if (objString) {
            setBanner(false)
        }
        else {
            setBanner(true)
        }
    }, [])

    const check_banner = () => {
        localStorage.setItem('banner', true)
        setBanner(false)
    }

    useEffect(() => {
        if (banner) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }
    }, [banner])

    return (
        <>
            {
                banner
                    ?
                    <>
                        <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(3px)" }}></div>
                        {
                            modal
                                ?
                                <div className='banner'>
                                    <div className='banner-top'>
                                        <div className='banner-img'>
                                            <img src={API_Address == "rf" ? "/img/logo/01_01.png" : "/img/GOEX_logo_light.png"} width={80}></img>
                                        </div>
                                        <div className='banner-1'>
                                            <div className={`banner-1-1 ${tab === 0 ? "current" : ""}`} onClick={() => setTab(0)}>ABOUT COOKIES</div>
                                            <div className={`banner-1-1 ${tab === 1 ? "current" : ""}`} onClick={() => setTab(1)}>Manage Consent Preferences</div>
                                        </div>
                                        {
                                            tab === 0
                                                ?
                                                <>
                                                    <div className='banner-2'>
                                                        <div>
                                                            We use cookies to collect data which improves your experience while you navigate through the website. We use this information:<br />
                                                            1. to give you a <b>better experience</b> of our website (functional)<br />
                                                            2. to <b>count</b> the pages you visit (statistics)<br />
                                                            3. to serve you <b>relevant</b> promotions (marketing)
                                                        </div>
                                                    </div>
                                                    <div className='banner-2'>
                                                        <div>
                                                            Click <b>"Accept Cookies & Continue"</b> to give us your consent to use cookies for all these purposes.
                                                        </div>
                                                    </div>
                                                    <div className='banner-2'>
                                                        <div>
                                                            The cookies that are categorized as "Strictly Necessary" are on by default and are stored on your browser as they are essential for the working of basic functionalities and security of the website.
                                                        </div>
                                                    </div>
                                                    <div className='banner-2'>
                                                        <div>
                                                            Of course, because we respect your right to privacy, you can choose to enable or disable some or all of cookies, but disabling some of them may affect your browsing experience.
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='banner-4'>
                                                        <div className='banner-4-box'>
                                                            <div className='banner-4-1' onClick={() => setBtn1(e => !e)}>
                                                                <FontAwesomeIcon icon={btn1 ? faAngleUp : faAngleDown} style={{ paddingRight: "10px" }} />
                                                                <div>Strictly Necessary Cookies</div>
                                                            </div>
                                                            <div className='banner-4-2'>
                                                                <div className={`dashboard-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''}`} onClick={toggleButton1}>
                                                                    {
                                                                        isToggled1
                                                                            ?
                                                                            <span className={`dashboard-right-bottom1-1-3-on`}>{t('futures_11')}</span>
                                                                            :
                                                                            <span className={`dashboard-right-bottom1-1-3-off`}>{t('futures_12')}</span>
                                                                    }
                                                                    <div className="dashboard-right-form-1-box-1-btn slider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='banner-4-txt' style={{ display: btn1 ? "" : "none" }}>
                                                            These cookies are necessary for the website to function and cannot be switched off in our systems. They
                                                            are usually only set in response to actions made by you which amount to a request for services, such as
                                                            setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert
                                                            your about these cookies, but some parts of the site will not then work. These cookies do not store any
                                                            personally identifiable information.
                                                        </div>
                                                        <div className='banner-4-table' style={{ display: btn1 && tablebtn ? "" : "none" }}>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Host</th>
                                                                        <th>Duration</th>
                                                                        <th>Description</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>p20t</td>
                                                                        <td>NAMU.com</td>
                                                                        <td>session</td>
                                                                        <td>This cookies is an essential cookie which
                                                                            allows authentication of users</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>workerCurrentGroupid</td>
                                                                        <td>pool.NAMU.com</td>
                                                                        <td>365days</td>
                                                                        <td>This cookies is an essential cookie that
                                                                            reflects groupting info.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>locale</td>
                                                                        <td>NAMU.com</td>
                                                                        <td>session</td>
                                                                        <td>This cookie is essential to provide the user the
                                                                            service it requested in the language they
                                                                            prefer</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='banner-4-3' style={{ display: btn1 ? "" : "none" }} onClick={() => setTablebtn(e => !e)}>HIDE COOKIES</div>
                                                    </div>
                                                    <div className='banner-4'>
                                                        <div className='banner-4-box'>
                                                            <div className='banner-4-1' onClick={() => setBtn2(e => !e)}>
                                                                <FontAwesomeIcon icon={btn2 ? faAngleUp : faAngleDown} style={{ paddingRight: "10px" }} />
                                                                <div>Functional Cookies</div>
                                                            </div>
                                                            <div className='banner-4-2'>
                                                                <div className={`dashboard-right-form-1-box-1-label ${isToggled2 ? 'toggled' : ''}`} onClick={toggleButton2}>
                                                                    {
                                                                        isToggled2
                                                                            ?
                                                                            <span className={`dashboard-right-bottom1-1-3-on`}>{t('futures_11')}</span>
                                                                            :
                                                                            <span className={`dashboard-right-bottom1-1-3-off`}>{t('futures_12')}</span>
                                                                    }
                                                                    <div className="dashboard-right-form-1-box-1-btn slider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='banner-4-txt' style={{ display: btn2 ? "" : "none" }}>
                                                            These cookies enable the website to provide enhanced functionality and personalisation. They may be
                                                            set by us or by third party providers whose services we have added to our pages. If you do not allow
                                                            these cookies then some or all of these services may not function properly.
                                                        </div>
                                                    </div>
                                                    <div className='banner-4'>
                                                        <div className='banner-4-box'>
                                                            <div className='banner-4-1' onClick={() => setBtn3(e => !e)}>
                                                                <FontAwesomeIcon icon={btn3 ? faAngleUp : faAngleDown} style={{ paddingRight: "10px" }} />
                                                                <div>Targeting Cookies</div>
                                                            </div>
                                                            <div className='banner-4-2'>
                                                                <div className={`dashboard-right-form-1-box-1-label ${isToggled3 ? 'toggled' : ''}`} onClick={toggleButton3}>
                                                                    {
                                                                        isToggled3
                                                                            ?
                                                                            <span className={`dashboard-right-bottom1-1-3-on`}>{t('futures_11')}</span>
                                                                            :
                                                                            <span className={`dashboard-right-bottom1-1-3-off`}>{t('futures_12')}</span>
                                                                    }
                                                                    <div className="dashboard-right-form-1-box-1-btn slider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='banner-4-txt' style={{ display: btn3 ? "" : "none" }}>
                                                            Targeting cookies may be set through our site by ourselves and our advertising partners. First parties and
                                                            third parties will use them to build a profile of your interests based on the browsing information they
                                                            collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not
                                                            allow these cookies you will still see basic advertising on your browser that is generic and not based on
                                                            your interests
                                                        </div>
                                                    </div>
                                                    <div className='banner-4'>
                                                        <div className='banner-4-box'>
                                                            <div className='banner-4-1' onClick={() => setBtn4(e => !e)}>
                                                                <FontAwesomeIcon icon={btn4 ? faAngleUp : faAngleDown} style={{ paddingRight: "10px" }} />
                                                                <div>Performance Cookies</div>
                                                            </div>
                                                            <div className='banner-4-2'>
                                                                <div className={`dashboard-right-form-1-box-1-label ${isToggled4 ? 'toggled' : ''}`} onClick={toggleButton4}>
                                                                    {
                                                                        isToggled4
                                                                            ?
                                                                            <span className={`dashboard-right-bottom1-1-3-on`}>{t('futures_11')}</span>
                                                                            :
                                                                            <span className={`dashboard-right-bottom1-1-3-off`}>{t('futures_12')}</span>
                                                                    }
                                                                    <div className="dashboard-right-form-1-box-1-btn slider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='banner-4-txt' style={{ display: btn4 ? "" : "none" }}>
                                                            These cookies allow us to count visits and traffic sources so we can measure and improve the
                                                            performance of our site. They help us to know which pages are the most and least popular and see how
                                                            visitors move around the site. All information these cookies collect is aggregated and therefore
                                                            anonymous. If you do not allow these cookies we will not know when you have visited our site, and will
                                                            not be able to monitor its performance
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    <div className='banner-3'>
                                        <div className='banner-3-1' onClick={() => check_banner()}>Reject Additional Cookies</div>
                                        <div className='banner-3-1' onClick={() => check_banner()}>Accept Cookes</div>
                                    </div>
                                </div >
                                :
                                ""
                        }
                        <div className='banner1'>
                            <div className='banner1-box'>
                                <div className='banner1-1'>
                                    We use “Strictly Necessary”” cookies to keep our site reliable and secure. We’d like to set additional cookies to
                                    understand site usage, make site improvements, to remember your settings and to assist in our marketing efforts.
                                </div>
                                <div className='banner1-2'>
                                    <div className='banner1-2-1' onClick={() => setModal(true)}>Manage Cookies</div>
                                    <div className='banner1-2-2' onClick={() => check_banner()}>Reject Additional Cookies</div>
                                    <div className='banner1-2-2' onClick={() => check_banner()}>Accept Cookes</div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    ""
            }
        </>
    )
}

export default Banner;