import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import { changeTime, changeDate } from "../../common/function"

import { OrderHistory, TradeHistory } from '../../common/C_futures/M_goex8';

function GoexByF8_2({ symbol, orderReload }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    const [Mordertab, setMordertab] = useState(0)

    return (
        <div className='futures-section15-2'>
            <div className={`futures-section15-1-3 ${darkname}`}>
                <div className={`futures-section15-1-3-1 ${Mordertab === 0 ? "active" : ""} ${darkname}`} onClick={() => setMordertab(0)}>Open History</div>
                <div className={`futures-section15-1-3-1 ${Mordertab === 1 ? "active" : ""} ${darkname}`} onClick={() => setMordertab(1)}>Trade History</div>
            </div>
            <div className='futures-section15-2-1'>
                {
                    Mordertab === 0
                        ?
                        <FuturesOrderHistory orderReload={orderReload} symbol={symbol} />
                        :
                        <FuturesTradeHistory orderReload={orderReload} symbol={symbol} />
                }
            </div>
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ orderReload, symbol }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1" + '/trade/bybit/linear/order/history', {
                params: {
                    reqId: o_id++,
                    begin: changeDate(changeTime(selectedDate)),
                    end: changeDate(changeTime(selectedDate1) + 86400000)
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response.data.data
                if (tmp && o_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 72, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')
                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].price) * Number(orderlist[i].qty),
                    type: orderlist[i].order_type,
                    time: orderlist[i].order_time,
                    side: orderlist[i].side,
                    price: orderlist[i].price,
                    size: orderlist[i].qty,
                    status: orderlist[i].order_status
                })
            }
        }
        setOrderData(summary)
    }, [orderlist])

    let order_symbol = orderdata.filter((item) => item.symbol == symbol)

    return (
        <OrderHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} order_symbol={order_symbol} spinner={spinner} />
    )
}


let t_id = 0
function FuturesTradeHistory({ orderReload, symbol }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderdata] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [personal_fee, setPersonal_fee] = useState(0.25)
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1" + '/trade/bybit/linear/trade/history', {
                params: {
                    reqId: t_id++,
                    begin: changeDate(changeTime(selectedDate)),
                    end: changeDate(changeTime(selectedDate1) + 86400000)
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response.data.data
                if (tmp && t_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 73, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')
                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].exec_price) * Number(orderlist[i].exec_qty),
                    pnl: Number(orderlist[i].pnl),
                    fee: orderlist[i].exec_fee ? Number(orderlist[i].exec_fee) : 0,
                    time: orderlist[i].trade_time,
                    reward: Number(orderlist[i].exec_fee) * personal_fee,
                    side: orderlist[i].side,
                    price: orderlist[i].exec_price,
                    size: orderlist[i].exec_qty,
                })
            }
        }
        setOrderdata(summary)
    }, [orderlist])

    let order_symbol = orderdata.filter((item) => item.symbol == symbol)

    return (
        <TradeHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} order_symbol={order_symbol} spinner={spinner} />
    )
}

export default GoexByF8_2;