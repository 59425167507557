import { Mark } from "../../charting_library/datafeed-api";
import { generateSymbol, makeApiRequest, parseFullSymbol, priceScale } from "./helpers";
import SocketClient, { BINANCE_RESOLUSION } from "./streaming";

const configurationData: TradingView.DatafeedConfiguration = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ["1", "3", "5", "15", "30", "1H", "2H", "4H", "6H", "8H", "12H", "1D", "3D", "1W", "1M"] as TradingView.ResolutionString[],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [{ value: "Binance", name: "Binance", desc: "Binance" }],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: "crypto", value: "crypto" }],
  // supports_marks: true,
};

export interface DataFeedOptions {
  SymbolInfo?: TradingView.LibrarySymbolInfo;
  DatafeedConfiguration?: TradingView.DatafeedConfiguration;
  getBars?: TradingView.IDatafeedChartApi["getBars"];
  getMarks?: TradingView.IDatafeedChartApi["getMarks"];
}

export default class DataFeed implements TradingView.IExternalDatafeed, TradingView.IDatafeedChartApi {
  private options: DataFeedOptions;
  private lastBarsCache: Map<string, TradingView.Bar>;
  private socket!: SocketClient;

  constructor(options: DataFeedOptions) {
    this.options = options;
    this.lastBarsCache = new Map();
    if (!options) {
      this.options.DatafeedConfiguration = configurationData;
    }
  }
  public async onReady(callback: TradingView.OnReadyCallback) {
    // console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
    this.socket = new SocketClient();
  }

  public async searchSymbols(userInput: string, exchange: string, symbolType: string, onResultReadyCallback: TradingView.SearchSymbolsCallback) {
    // console.log('[searchSymbols]: Method call');
    const symbols = await this.getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  }

  private async getAllSymbols() {
    const data = await makeApiRequest("api/v3/exchangeInfo");

    let allSymbols: any[] = [];

    for (const exchange of configurationData.exchanges!) {
      const pairs = data.symbols;

      for (const pair of pairs) {
        const symbolInfo = generateSymbol(exchange.value, pair.baseAsset, pair.quoteAsset);
        const symbol = {
          symbol: symbolInfo.short,
          full_name: symbolInfo.full,
          description: symbolInfo.short,
          exchange: exchange.value,
          type: "crypto",
          tickSize: pair.filters[0].tickSize,
        };
        allSymbols = [...allSymbols, symbol];
      }
    }

    return allSymbols;
  }

  public async resolveSymbol(symbolName: string, onSymbolResolvedCallback: TradingView.ResolveCallback, onResolveErrorCallback: TradingView.ErrorCallback, extension: TradingView.SymbolResolveExtension) {
    const symbols = await this.getAllSymbols();
    const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
    if (!symbolItem) {
      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback("Cannot resolve symbol");
      return;
    }
    // Symbol information object
    const symbolInfo: Partial<TradingView.LibrarySymbolInfo> = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: priceScale(symbolItem.tickSize),
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      visible_plots_set: "ohlcv",
      supported_resolutions: configurationData.supported_resolutions!,
      volume_precision: 2,
      data_status: "streaming",
    };
    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo as TradingView.LibrarySymbolInfo);
  }

  // public async getBars(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, periodParams: TradingView.PeriodParams, onHistoryCallback: TradingView.HistoryCallback, onErrorCallback: TradingView.ErrorCallback) {
  //   let { from, to, firstDataRequest } = periodParams;
  //   console.log(periodParams)
  //   const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

  //   if (parsedSymbol) {
  //     const urlParameters = {
  //       symbol: parsedSymbol.symbol,
  //       interval: BINANCE_RESOLUSION[resolution as keyof typeof BINANCE_RESOLUSION],
  //       startTime: from * 1000,
  //       endTime: to * 1000,
  //       limit: 1000,
  //     };

  //     const query = Object.keys(urlParameters)
  //       .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
  //       .join("&");
  //     try {
  //       const data = await makeApiRequest(`api/v3/klines?${query}`);
  //       if (!data || data.length === 0) {
  //         // "noData" should be set if there is no data in the requested period
  //         onHistoryCallback([], { noData: true });
  //         return;
  //       }

  //       let bars: {
  //         time: number;
  //         low: any;
  //         high: any;
  //         open: any;
  //         close: any;
  //         volume: any;
  //       }[] = [];
  //       data.forEach((bar: string[]) => {
  //         if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to * 1000) {
  //           bars = [
  //             ...bars,
  //             {
  //               time: parseInt(bar[0]),
  //               open: parseFloat(bar[1]),
  //               high: parseFloat(bar[2]),
  //               low: parseFloat(bar[3]),
  //               close: parseFloat(bar[4]),
  //               volume: parseFloat(bar[5]),
  //             },
  //           ];
  //         }
  //       });
  //       if (firstDataRequest) {
  //         this.lastBarsCache.set(symbolInfo.full_name, {
  //           ...bars[bars.length - 1],
  //         });
  //       }
  //       console.log(`[getBars]: returned ${bars.length} bar(s)`);
  //       onHistoryCallback(bars, { noData: false });
  //     } catch (error) {
  //       // console.log('[getBars]: Get error', error);
  //       onErrorCallback(error as string);
  //     }
  //   }
  // }

  private toDate: number | null = null;
  private fromDate: number | null = null;
  private dataCount: boolean = false;
  private intervalCheck: string | null = null;

  public async getBars(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, periodParams: TradingView.PeriodParams, onHistoryCallback: TradingView.HistoryCallback, onErrorCallback: TradingView.ErrorCallback) {
    await sleep(100);
    let { from, to, countBack, firstDataRequest } = periodParams;
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    if (firstDataRequest) {
      this.toDate = null;
    }

    // >>>>>>>>>>>>>>>>>> 분봉 변환 START
    // 데이터 없을 시 dataCount = true -> 데이터를 호출하지 않기 위함
    // 분봉 변경 시 새로 데이터 호출을 위해 dataCount = false 변환
    if (this.intervalCheck !== BINANCE_RESOLUSION[resolution as keyof typeof BINANCE_RESOLUSION]) {
      this.dataCount = false;
    }
    this.intervalCheck = BINANCE_RESOLUSION[resolution as keyof typeof BINANCE_RESOLUSION];

    if (this.dataCount) {
      onHistoryCallback([], { noData: true });
      return;
    }
    // >>>>>>>>>>>>>>>>>> 분봉 변환 END

    // if (this.fromDate === from) {
    //   // window.location.reload();
    //   onHistoryCallback([], { noData: true });
    //   return;
    // }
    
    if (parsedSymbol) {
      const urlParameters = {
        symbol: parsedSymbol.symbol,
        interval: BINANCE_RESOLUSION[resolution as keyof typeof BINANCE_RESOLUSION],
        startTime: from * 1000,
        endTime: to * 1000,
        limit: 1000,
      };

      if (urlParameters.startTime > Date.now() || urlParameters.startTime < 1489372195000) {
        // alert("check point 3");
        urlParameters.startTime = 1489372195000;
      }

      if (urlParameters.endTime > Date.now() + 1000 * 60 * 60 * 24 * 5 || urlParameters.endTime < 1489372195000) {
        // alert("check point 4");
        urlParameters.endTime = Date.now();
      }

      if (urlParameters.startTime > urlParameters.endTime) {
        // window.location.reload();
        onHistoryCallback([], { noData: true });
        return;
      }

      const query = Object.keys(urlParameters)
        .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
        .join("&");
      try {
        const data = await makeApiRequest(`api/v3/klines?${query}`);
        if (!data || data.length === 0) {
          // "noData" should be set if there is no data in the requested period
          onHistoryCallback([], { noData: true });
          return;
        }

        let bars: {
          time: number;
          low: any;
          high: any;
          open: any;
          close: any;
          volume: any;
        }[] = [];

        if (countBack >= 1000 && data.length >= 1000) {
          // if (countBack >= 1000) {
          data.forEach((bar: string[]) => {
            if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to * 1000) {
              bars = [
                ...bars,
                {
                  time: parseInt(bar[0]),
                  open: parseFloat(bar[1]),
                  high: parseFloat(bar[2]),
                  low: parseFloat(bar[3]),
                  close: parseFloat(bar[4]),
                  volume: parseFloat(bar[5]),
                },
              ];
            }
          });

          from = data[data.length - 1][0] + 1;
          to = this.toDate ?? to * 1000;

          while (true) {
            if (from == to + 1) {
              break;
            }
            const urlParameters = {
              symbol: parsedSymbol.symbol,
              interval: BINANCE_RESOLUSION[resolution as keyof typeof BINANCE_RESOLUSION],
              startTime: from,
              endTime: to,
              limit: 1000,
            };

            const query = Object.keys(urlParameters)
              .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
              .join("&");

            try {
              await sleep(100);
              const data = await makeApiRequest(`api/v3/klines?${query}`);
              if (!data || data.length === 0) {
                break;
              }
              data.forEach((bar: string[]) => {
                if (parseInt(bar[0]) >= from && parseInt(bar[0]) < to) {
                  bars = [
                    ...bars,
                    {
                      time: parseInt(bar[0]),
                      open: parseFloat(bar[1]),
                      high: parseFloat(bar[2]),
                      low: parseFloat(bar[3]),
                      close: parseFloat(bar[4]),
                      volume: parseFloat(bar[5]),
                    },
                  ];
                }
              });
              from = data[data.length - 1][0] + 1;
            } catch (error) {
              console.error("The chart data didn't load correctly. Proceed to refresh.");
              // alert("The chart data didn't load correctly. Proceed to refresh.");
              window.location.reload();
              break;
            }
          }
          this.toDate = data[0][0];
          // this.lastBarsCache.set(symbolInfo.full_name, {
          //   ...bars[bars.length - 1],
          // });
          console.log(`[getBars]: returned ${bars.length} bar(s)`);
          if (bars.length === 0) {
            this.dataCount = true;
          }
          onHistoryCallback(bars, { noData: false });
        } else {
          data.forEach((bar: string[]) => {
            if (parseInt(bar[0]) >= from * 1000 && parseInt(bar[0]) < to * 1000) {
              bars = [
                ...bars,
                {
                  time: parseInt(bar[0]),
                  open: parseFloat(bar[1]),
                  high: parseFloat(bar[2]),
                  low: parseFloat(bar[3]),
                  close: parseFloat(bar[4]),
                  volume: parseFloat(bar[5]),
                },
              ];
            }
          });
          if (firstDataRequest) {
            this.toDate = data[0][0];
            this.lastBarsCache.set(symbolInfo.full_name, {
              ...bars[bars.length - 1],
            });
          }
          this.fromDate = from;
          console.log(`[getBars]: returned ${bars.length} bar(s)`);
          if (bars.length === 0) {
            this.dataCount = true;
          }
          onHistoryCallback(bars, { noData: false });
        }
      } catch (error) {
        console.error("[getBars]: Get error", error);
        onErrorCallback(error as string);
        // alert("The chart data didn't load correctly. Proceed to refresh.");
        window.location.reload();
      } finally {
        return;
      }
    }
  }

  public async subscribeBars(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, onRealtimeCallback: TradingView.SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void) {
    // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    this.socket.subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, this.lastBarsCache.get(symbolInfo.full_name));
  }

  public async unsubscribeBars(subscriberUID: string) {
    // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    this.socket.unsubscribeFromStream(subscriberUID);
  }

  public async getMarks(symbolInfo: TradingView.LibrarySymbolInfo, startDate: number, endDate: number, onDataCallback: TradingView.GetMarksCallback<Mark>, resolution: TradingView.ResolutionString) {
    const arr: Mark[] = [
      // {
      //   id: 1,
      //   time: 1709792257000 / 1000,
      //   color: "yellow",
      //   text: "hello",
      //   label: "how",
      //   labelFontColor: "black",
      //   minSize: 20,
      // },
      // {
      //   id: 2,
      //   time: 1709722257000 / 1000,
      //   color: "yellow",
      //   text: "400",
      //   label: "c",
      //   labelFontColor: "black",
      //   minSize: 20,
      // },
      // {
      //   id: 3,
      //   time: 1709292257000 / 1000,
      //   color: "yellow",
      //   text: "500",
      //   label: "d",
      //   labelFontColor: "black",
      //   minSize: 10,
      // },
    ];

    onDataCallback(arr);
  }
}

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
