import { useEffect, useRef, useState } from 'react';

const findSmallest = (n) => {
    if (Number.isInteger(Number(n))) {
        return 0;
    }

    let trimmedStr = String(n).replace(/0+$/, '');
    let cnt = trimmedStr.length
    let cnt1 = trimmedStr.indexOf('.') + 1

    return cnt - cnt1;
}

const MakeNumber = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    if (arr[1]) {
        result = arr[1].length
    }
    else {
        result = 0
    }

    return result;
}

const changeNum = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    if (arr[1]) {
        result = Number(arr[0]).toLocaleString() + "." + arr[1]
    }
    else {
        result = Number(arr[0]).toLocaleString()
    }

    return result;
}

const RoundDown = (n, p) => {
    let num = Decimalization(n)
    let arr = num.split('.')
    let result;

    if (arr[1] && arr[1].length > p && p != 0) {
        let point = arr[1].slice(0, Number(p))
        result = arr[0] + "." + point
    }
    else if (arr[1] && arr[1].length <= p && p != 0) {
        let zero = ''
        let len = p - arr[1].length
        for (let i = 0; i < len; i++) {
            zero += '0'
        }
        result = arr[0] + "." + arr[1] + zero
    }
    else {
        result = Number(arr[0]).toFixed(p)
    }

    return result;
}

const changeTime = (d) => {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
}

const makeSamllest = (n) => {
    if (n === 0) {
        return 1;
    }

    let cn = ""
    for (let i = 0; i < n; i++) {
        if (n - i === 1) {
            cn += "1"
        }
        else {
            cn += "0"
        }
    }
    return `0.${cn}`;
}

const changeUnits = (n) => {
    let num = Number(n)
    let result;

    if (num >= 1000000000000) result = RoundDown(String(n / 1000000000000), 2) + ' T'
    else if (num >= 1000000000) result = RoundDown(String(n / 1000000000), 2) + ' B'
    else if (num >= 1000000) result = RoundDown(String(n / 1000000), 2) + ' M'
    else if (num >= 1000) result = RoundDown(String(n / 1000), 2) + ' K'
    else result = n

    return result;
}


const UTCTimestamp = (timestamp) => {
    // JavaScript Date 객체를 UTC+0으로 변환
    const date = new Date(Number(timestamp));
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return utcDate;
}

const Decimalization = (n) => {
    let str = String(n).toUpperCase()
    let arr = str.split('E-')
    let result;
    if (arr[1]) {
        result = (Number(arr[0]) / Math.pow(10, Number(arr[1]))).toFixed(Number(arr[1]) + arr[0].length - 2)
    }
    else {
        result = arr[0]
    }

    return result
}

const useCounter = (maxCount, p) => {
    const [count, setCount] = useState(0)
    const ref = useRef(0)

    const countEasingOut = (progressRate) => {
        const remainder = Math.pow(2, -10 * progressRate)
        const maxProgress = 1

        if (progressRate === maxProgress) {
            return maxProgress
        } else {
            return maxProgress - remainder
        }
    }

    useEffect(() => {
        // 0. 마운트 후 useEffect 실행
        const onCount = () => {
            const duration = 1000
            const frameTimeout = 1000 / 60
            const totalFrame = Math.round(duration / frameTimeout)
            const percentage = countEasingOut(ref.current / totalFrame)
            const currentCount = RoundDown(Number(maxCount) * percentage, p)
            // console.log(percentage, currentCount)
            setCount(currentCount)

            ref.current = ref.current += 1
            const counting = requestAnimationFrame(onCount)

            if (Number(maxCount) === currentCount) {
                cancelAnimationFrame(counting)
            }
        }

        const counting = requestAnimationFrame(onCount)

        return () => {
            cancelAnimationFrame(counting)
        }
    }, [maxCount])

    return count
}

const changeDate = (timestamp) => {
    const date = new Date(timestamp);

    const year = date.getUTCFullYear();
    const month = padZero(date.getUTCMonth() + 1);
    const day = padZero(date.getUTCDate());

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp, Decimalization, useCounter, changeDate }