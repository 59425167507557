import { useSelector } from 'react-redux';
import '../css/modal.css'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

function Namu_Message({ message, buyModal, setBuyModal, setW_confirm, res }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    const onChangeBtn = () => {
        if (res >= 1) {
            setW_confirm(true)
        }
    }

    return (
        <>
            {
                buyModal
                    ?
                    <>
                        <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(3px)" }}></div>
                        <div className={`message-modal ${darkname}`}>
                            <div className='message-modal-top'>
                                <div className='message-modal-top-1'>{message.type == "okx" ? message.code == 0 ? `${t('message_1')}` : `${t('message_2')}` : message.code >= 0 ? `${t('message_1')}` : `${t('message_2')}`}</div>
                                {
                                    res == 2
                                        ?
                                        <div className='message-modal-top-2' onClick={() => { setBuyModal(false) }}>close</div>
                                        :
                                        ""
                                }
                            </div>
                            <div className='message-modal-2'>{message.msg}</div>
                            <div className='message-modal-btn' onClick={() => { setBuyModal(false); onChangeBtn(); }}>{t('message_3')}</div>
                        </div>
                    </>
                    :
                    ""
            }

        </>
    )
}

export default Namu_Message;