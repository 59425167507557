import '../css/spot.css'
import Chart from '../spotchart.js'
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectType, checkLogin, check_Error } from '../../store.js';
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary.js"
import { changeNum } from "../common/function.js"

import { W_goex6 } from '../common/C_spot/W_goex6.js';
import '@fortawesome/fontawesome-free/css/all.css';

import GoexBnS1 from './goexBnS/goexBnS1.js';
import GoexBnS4 from './goexBnS/goexBnS4.js';
import GoexBnS7 from './goexBnS/goexBnS7.js';
import GoexBnS8 from './goexBnS/goexBnS8.js';
import GoexBnS1_1 from './goexBnS/goexBnS1_1.js';
import GoexBnS8_1 from './goexBnS/goexBnS8_1.js';
import GoexBnS8_2 from './goexBnS/goexBnS8_2.js';

let data = []
let data_first = 0
let prePrice = 0

function GOEXBnSpot() {
    const { t } = useTranslation();
    let BinanceS = useSelector((state) => { return state.Binance_coinS });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let Binance_wallet_spot = useSelector((state) => { return state.Binance_wallet_spot });

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)

    const [openorder, setOpenorder] = useState([])
    const [balance, setBalance] = useState([])
    const [currentUSDT, setCurrentUSDT] = useState("")
    const [exinfo, setExinfo] = useState([])
    const [max, setMax] = useState(0)

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab5, setTab5] = useState(0)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.000001)
    const [btnbtn, setBtnbtn] = useState(0);
    const [orderReload, setOrderReload] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
        data_first = 0
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let USDTSymbolS = BinanceS ? BinanceS : [];
    dispatch(checkSelectType(1))

    USDTSymbolS.map(function (a, i) {
        if (a.symbol === symbol) {
            if (data_first === 0) {
                setCurrentPrice(a.lastPrice)
                setMax(a.point)
                data_first = 1
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    useEffect(() => {
        ExchangeInformation()
    }, [symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            SpotBalance(token);
            SpotOpenOrder(token);
        }
    }, [Binance_wallet_spot])

    const SpotOpenOrder = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/open/orders", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                setOpenorder(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 54, "msg": error }))
        })
    }

    const SpotBalance = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/bn/spot/info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp.length) {
                for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].asset == "USDT") {
                        setCurrentUSDT(tmp[i])
                    }
                }
                setBalance(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 56, "msg": error }))
        })
    }

    const ExchangeInformation = async () => {
        await axios.get('https://api.binance.com/api/v3/exchangeInfo'
        ).then((response) => {
            let tmp = response?.data?.symbols

            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) setExinfo(a.filters)
                })
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 57, "msg": error }))
        })
    }

    // *********************************************************************************************************************
    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)
    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "/USDT" : ""} | Binance`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "/USDT" : ""} | Binance`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    let balanceD = balance.filter((item) => item.asset + "USDT" == symbol)
    return (
        <div className={`futures ${darkname} font-family`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            {
                windowtypeM
                    ?
                    <div className='futures-box'>
                        <div className='futures-section11'>
                            <W_goex6 isBlinking={isBlinking} handleButtonClick={handleButtonClick} changeTab={changeTab} tab={tab} data={data} currentPrice={currentPrice} priceDirect={priceDirect} max={max} />
                            <div className='futures-section14'>
                                <ErrorBoundary>
                                    <GoexBnS1 tab={tab} changeTab={changeTab} USDTSymbolS={USDTSymbolS} symbol={symbol}/>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div className={tab1 ? `futures-section16 ${darkname}` : `futures-section16-check ${darkname}`}>
                                        <div style={{ width: "100%", height: "880px", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                            <Chart exchange={"Binance"} market={"spot"} />
                                            <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "500px", width: "50%" }} /></div>
                                        </div>
                                    </div>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <GoexBnS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                            </div>
                            <div className={`futures-section15 ${darkname}`}>
                                <ErrorBoundary>
                                    <GoexBnS8 tab5={tab5} setTab5={setTab5} symbol={symbol} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} currentPrice={currentPrice} orderReload={orderReload} openorder={openorder} balance={balance} BinanceS={BinanceS}/>
                                </ErrorBoundary>
                            </div>
                        </div>
                        <ErrorBoundary>
                            <GoexBnS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} exinfo={exinfo} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn}/>
                        </ErrorBoundary>
                    </div>
                    :
                    <div className={`futuresM ${darkname}`}>
                        <div className='futuresM-list' onClick={() => setMtab(2)}>
                            <div className={`futuresM-list-1 ${darkname}`}>{symbol}</div>
                            <div className={`futuresM-list-2 ${data?.priceChangePercent >= 0 ? "green" : "red"}`}>
                                <div className='futuresM-list-2-1'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                <div className='futuresM-list-2-2'>{Number(data?.priceChangePercent ?? 0).toFixed(2)}%</div>
                            </div>
                        </div>
                        <div className={`futuresM-top ${darkname}`}>
                            <div className='futuresM-top-box'>
                                <div className={Mtab == 0 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(0)}>차트</div>
                                <div className={Mtab == 1 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(1)}>호가</div>
                                <div className={Mtab == 2 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(2)}>리스트</div>
                                <div className={Mtab == 3 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(3)}>보유</div>
                                <div className={Mtab == 4 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(4)}>내역</div>
                            </div>
                        </div>

                        <div className='futuresM-mid-1' style={{ display: Mtab == 0 ? "" : "none" }}>
                            <div style={{ width: "100%", height: "calc(100% - 60px)", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                <Chart exchange={"Binance"} market={"spot"} />
                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "280px", width: "50%" }} /></div>
                            </div>
                            <div className='futuresM-mid-1-btn'>
                                <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-1`}>{t('futures_17')}</div>
                                </div>
                                <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-2`}>{t('futures_18')}</div>
                                </div>
                            </div>
                        </div>

                        <div className='futuresM-mid-2' style={{ display: Mtab == 1 ? "" : "none" }}>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <GoexBnS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                                {
                                    Mordertab
                                        ?
                                        <ErrorBoundary>
                                            <GoexBnS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} exinfo={exinfo} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn} />
                                        </ErrorBoundary>
                                        :
                                        ""
                                }
                            </div>
                            <div className='futures-M-mid-2-btn' onClick={() => setMordertab(e => !e)}>
                                {
                                    Mordertab
                                        ?
                                        <i class="fa-solid fa-circle-check" style={{ color: "#4285F4", marginRight: "7px" }}></i>
                                        :
                                        <i class="fa-regular fa-circle-check" style={{ color: "gray", marginRight: "7px" }}></i>
                                }
                                <span className="text">주문창</span>
                            </div>
                        </div>
                        {
                            Mtab === 2
                                ?
                                <GoexBnS1_1 USDTSymbolS={USDTSymbolS} symbol={symbol} balance={balance} />
                                :
                                ""
                        }

                        <div style={{ display: Mtab == 3 ? "" : "none", width: "100%" }}>
                            <GoexBnS8_1 symbol={symbol} balance={balanceD[0] ?? []} openorder={openorder} BinanceS={BinanceS} />
                        </div>

                        <div style={{ display: Mtab == 4 ? "" : "none", width: "100%" }}>
                            <GoexBnS8_2 symbol={symbol} orderReload={orderReload} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default GOEXBnSpot;