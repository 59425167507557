import { parseFullSymbol } from "./helpers";

export const OKX_RESOLUSION = {
  1: "1",
  3: "3",
  5: "5",
  15: "15",
  30: "30",
  60: "60",
  120: "120",
  240: "240",
  360: "360",
  720: "720",
  "1D": "D",
  "1W": "W",
  "1M": "M",
};

let preChannel: any;
let preInstId: any;
let preInterval: any;

let preChannelString: any;
let preSubscriptionItem: any;

let nextChannel: any;

export default class SocketClient {
  socket!: WebSocket;
  channelToSubscription!: Map<string, any>;

  constructor() {
    // console.log("[SocketClient] init");
    this._createSocket();
  }

  _createSocket() {
    this.socket = new WebSocket("wss://stream.bybit.com/v5/public/spot");

    this.channelToSubscription = new Map();

    this.socket.addEventListener("open", () => {
      console.log("[socket] Connected");

      setTimeout(() => {
        if (preInstId) {
          const message = {
            req_id: "test",
            op: "subscribe",
            args: [`kline.${preInterval}.${preInstId}`],
          };
          this.emit("subscribe", [JSON.stringify(message)]);
        }
      }, 1000);
    });

    this.socket.addEventListener("close", (reason: any) => {
      console.log("[socket] Disconnected:", reason);
      // window.location.reload();
      this._createSocket();
    });

    this.socket.addEventListener("error", (error: any) => {
      console.log("[socket] Error:", error);
      window.location.reload();
    });

    this.socket.addEventListener("message", (data: any) => {
      // console.log("[socket] Message:", data);
      let res = JSON.parse(data.data);
      if (res.ret_code === "400") {
        return;
      }
      const { data: kline, topic } = res;
      if (!kline) {
        return;
      }

      if(topic !== nextChannel.args[0]) {
        return;
      }

      const tradeTime = parseInt(kline[0].timestamp);
      const channelString = `${preChannel}~${preInstId}`;
      this.channelToSubscription.set(preChannelString, preSubscriptionItem);
      const subscriptionItem = this.channelToSubscription.get(channelString);
      if (subscriptionItem === undefined) {
        return;
      }

      const lastDailyBar = subscriptionItem.lastDailyBar;
      if (subscriptionItem.lastDailyBar == null) {
        return;
      }
      let nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 1);
      switch (subscriptionItem?.resolution) {
        case "1":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 1);
          break;
        case "3":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 3);
          break;
        case "5":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 5);
          break;
        case "15":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 15);
          break;
        case "60":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 1);
          break;
        case "120":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 2);
          break;
        case "240":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 4);
          break;
        case "360":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 6);
          break;
        case "720":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 12);
          break;
        case "D":
          nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 1);
          break;
        case "W":
          nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 7);
          break;
        case "M":
          nextDailyBarTime = this.getNextMonthBarTime(lastDailyBar.time, 1);
          break;
      }

      let bar: {
        time: number;
        open: number;
        high: number;
        low: number;
        close: number;
        volume: number;
      };

      if (tradeTime > lastDailyBar.time) {
        bar = {
          time: parseFloat(kline[0].end),
          open: parseFloat(kline[0].open),
          high: parseFloat(kline[0].high),
          low: parseFloat(kline[0].low),
          close: parseFloat(kline[0].close),
          volume: parseFloat(kline[0].volume),
        };
      } else {
        bar = {
          ...lastDailyBar,
          high: parseFloat(kline[0].high),
          low: parseFloat(kline[0].low),
          close: parseFloat(kline[0].close),
          volume: parseFloat(kline[0].volume),
        };
        // console.log("[socket] Update the latest bar by", bar);
      }

      subscriptionItem.lastDailyBar = bar;

      // Send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler: { callback: (arg0: any) => any }) => handler.callback(bar));
    });
  }

  public subscribeOnStream(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, onRealtimeCallback: TradingView.SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void, lastDailyBar: TradingView.Bar | undefined) {
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    if (parsedSymbol) {
      const channelString = `candle${OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]}~${parsedSymbol.instId}`;
      const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
      };
      let subscriptionItem = this.channelToSubscription.get(channelString);
      if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
      }
      subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
      };
      this.channelToSubscription.set(channelString, subscriptionItem);
      // console.log(channelString, subscriptionItem);
      // console.log(
      //   "[subscribeBars]: Subscribe to streaming. Channel:",
      //   channelString
      // );

      preSubscriptionItem = subscriptionItem;
      preChannelString = channelString;
      preInterval = OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION];

      preChannel = `candle${OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]}`;
      preInstId = parsedSymbol.instId;

      const message = {
        req_id: "test", // optional
        op: "subscribe",
        args: [`kline.${preInterval}.${preInstId}`],
      };
      this.emit("subscribe", [JSON.stringify(message)]);
    }
  }

  public unsubscribeFromStream(subscriberUID: string) {
    for (const channelString of this.channelToSubscription.keys()) {
      const subscriptionItem = this.channelToSubscription.get(channelString);
      const handlerIndex = subscriptionItem.handlers.findIndex((handler: { id: string }) => handler.id === subscriberUID);

      if (handlerIndex !== -1) {
        // Remove from handlers
        subscriptionItem.handlers.splice(handlerIndex, 1);

        if (subscriptionItem.handlers.length === 0) {
          const str = subscriberUID;
          const match:any = str.match(/:([^_]+)/);
          let result = match[1];
          result = result.replace("/", "");

          const message = {
            req_id: "test", // optional
            op: "subscribe",
            args: [`kline.${OKX_RESOLUSION[subscriptionItem.resolution as keyof typeof OKX_RESOLUSION]}.${result}`],
          };
          this.emit("unsubscribe", [JSON.stringify(message)]);

          this.channelToSubscription.delete(channelString);
          break;
        }
      }
    }
  }

  emit(method: string, args: any) {
    if (this.socket.readyState !== WebSocket.OPEN) {
      // console.log("[socket] Socket is not open, cannot subscribe");
      return;
    } else {
      if(method !== "unsubscribe") {
        nextChannel = JSON.parse(args);
      }
      this.socket.send(args);
    }
  }

  close() {
    // this.socket.close();
  }

  private getNextMinuteBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setMinutes(date.getMinutes() + plus);
    return date.getTime();
  }

  private getNextHourBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setHours(date.getHours() + plus);
    return date.getTime();
  }

  private getNextDailyBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setDate(date.getDate() + plus);
    return date.getTime();
  }

  private getNextMonthBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setMonth(date.getMonth() + plus);
    return date.getTime();
  }
}
