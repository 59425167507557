import '../css/spot.css'
import Chart from '../spotchart.js'
import { List } from 'react-virtualized';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faMagnifyingGlass, faStar, faCircleXmark, faArrowUp, faArrowDown, faRotate, faCircleChevronRight, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectType, change_Binance_S_Favorite, checkLogin, check_Error } from '../../store.js';
import { Range, getTrackBackground } from 'react-range';
import Modal from '../message/modal.js'
import { ClipLoader } from "react-spinners";
import IMG from "../img.js"
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary.js"
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, UTCTimestamp } from "../common/function.js"

let arr = []
let data = []
let data_first = 0
let pre_symbol = ""

let current = 0

function BSpot() {
    const { t } = useTranslation();
    let BinanceS = useSelector((state) => { return state.Binance_coinS });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let Binance_wallet_spot = useSelector((state) => { return state.Binance_wallet_spot });
    let API_Address = useSelector((state) => { return state.API_Address.value })

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [tab, setTab] = useState(0)
    const [tab1, setTab1] = useState(0)
    const [tabList, setTabList] = useState(true)
    const [search, setSearch] = useState("");
    const [up, setUp] = useState([]);
    const [down, setDown] = useState([]);
    const [sumUp, setSumUp] = useState(0);
    const [sumDown, setSumDown] = useState(0);
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)

    const [openorder, setOpenorder] = useState([])
    const [orderhistory, setOrderhistory] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [balance, setBalance] = useState([])
    const [currentUSDT, setCurrentUSDT] = useState("")
    const [exinfo, setExinfo] = useState([])

    const [holding, setHolding] = useState(false)
    const [orderbookState, setOrderbookState] = useState(0)
    const [selectVolume, setSelectVolume] = useState("1")
    const [smallNum, setSmallNum] = useState("1")
    const [checkup, setCheckup] = useState([]);
    const [checkdown, setCheckdown] = useState([]);
    const [isClicked, setIsClicked] = useState(true);
    const [isClicked1, setIsClicked1] = useState(true);
    const [isClicked2, setIsClicked2] = useState(true);
    const [max, setMax] = useState(0)
    const [max1, setMax1] = useState(4)

    const [windowtype, setWindowtype] = useState(true);
    const [orderbookReload, setOrderbookReload] = useState(false)
    const [catchError, setCatchError] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [priceDirect, setPriceDirect] = useState(false)
    const [close, setClose] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 950) setWindowtype(true)
            else setWindowtype(false)
            if (window.innerWidth >= 1500) setTabList(true)
            else setTabList(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let imgcheck = true
    if (IMG && !IMG.includes(data.ccy)) {
        imgcheck = false
    }

    let USDTSymbolS = BinanceS ? BinanceS : [];
    dispatch(checkSelectType(1))

    USDTSymbolS.map(function (a, i) {
        if (a.symbol === symbol) {
            if (data_first === 0) {
                setCurrentPrice(a.lastPrice)
                data_first = 1
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    current = currentPrice ? Number(currentPrice) : Number(data.lastPrice)

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    const onChangeVolume = (e) => {
        setSelectVolume(e.target.value)
    }

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "/USDT" : ""} | Binance`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "/USDT" : ""} | Binance`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    useEffect(() => {
        let newup = []
        let newdown = []

        if (up) {
            for (let i = 0; i < up.length; i++) {
                let num = 0
                let check = true

                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(up[i][0]) - (Number(up[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(up[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                        setCatchError(true)
                    }
                }

                for (let j = 0; j < newup.length; j++) {
                    if (newup[j][0] === num) {
                        newup[j][1] += Number(up[i][1])
                        check = false
                    }
                }
                if (check) {
                    newup.push([num, Number(up[i][1])])
                }
            }
        }

        if (down) {
            for (let i = 0; i < down.length; i++) {
                let num = 0
                let check = true

                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(down[i][0]) - (Number(down[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(down[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                        setCatchError(true)
                    }
                }

                for (let j = 0; j < newdown.length; j++) {
                    if (newdown[j][0] === num) {
                        newdown[j][1] += Number(down[i][1])
                        check = false
                    }
                }
                if (check) {
                    newdown.push([num, Number(down[i][1])])
                }
            }
        }

        newup.sort((a, b) => Number(a[0]) - Number(b[0]));
        newdown.sort((a, b) => Number(b[0]) - Number(a[0]));

        if (data.priceChangePercent >= 0) {
            newup = newup.filter((p) => p[0] >= current)
            newdown = newdown.filter((p) => p[0] < current)
        }
        else {
            newup = newup.filter((p) => p[0] > current)
            newdown = newdown.filter((p) => p[0] <= current)
        }

        setCheckup(newup)
        setCheckdown(newdown)

        if (newup && newup.length > 0 && newdown && newdown.length > 0) {
            setIsClicked(false)
            setIsClicked1(false)
        }

    }, [selectVolume, up, down])

    useEffect(() => {
        if (exinfo[1]) {
            let point = findSmallest(Number(exinfo[1].minQty))
            setMax1(point)
        }
    }, [exinfo])

    const makeSamllest = (n) => {
        if (n === 0) {
            return 1;
        }

        let cn = ""
        for (let i = 0; i < n; i++) {
            if (n - i === 1) {
                cn += "1"
            }
            else {
                cn += "0"
            }
        }
        return `0.${cn}`;
    }

    useEffect(() => {
        arr = []
        data_first = 0
        let tradeA = []
        let tradeB = []
        let newtradeA = []
        let newtradeB = []
        let maxPoint = 0;
        let cntVolume = true

        let socket = new WebSocket(`wss://stream.binance.com/stream?streams=${symbol.toLowerCase()}@aggTrade/${symbol.toLowerCase()}@depth20@100ms`);

        socket.onmessage = function (event) {
            let tmp = JSON.parse(event.data);
            if (tmp.data != undefined) {
                pre_symbol = symbol
                if (tmp.stream.includes("aggTrade")) {
                    if (arr && arr.length <= 18) {
                        arr.unshift(tmp.data)
                    }
                    else {
                        arr.splice(18, 1)
                        arr.unshift(tmp.data)
                    }
                    setCurrentPrice(tmp.data.p)
                }
                else {
                    let tradeArr = tmp.data
                    if (tradeArr != undefined) {
                        let asks = tradeArr.asks;
                        let bids = tradeArr.bids;

                        if (asks) {
                            for (let i = 0; i < asks.length; i++) {
                                let cnt = false
                                for (let j = 0; j < tradeA.length; j++) {
                                    if (asks[i][0] === tradeA[j][0]) {
                                        tradeA[j] = asks[i]
                                        cnt = true
                                        break;
                                    }
                                }
                                if (!cnt) {
                                    tradeA.push(asks[i])
                                }
                                if (i >= 0 && i <= 3) {
                                    maxPoint = Math.max(maxPoint, findSmallest(asks[i][0]))
                                }
                            }
                        }
                        if (bids) {
                            for (let i = 0; i < bids.length; i++) {
                                let cnt = false
                                for (let j = 0; j < tradeB.length; j++) {
                                    if (bids[i][0] === tradeB[j][0]) {
                                        tradeB[j] = bids[i]
                                        cnt = true
                                        break;
                                    }
                                }
                                if (!cnt) {
                                    tradeB.push(bids[i])
                                }
                            }
                        }

                        newtradeA = tradeA ? tradeA.filter((p) => Number(p[1]) > 0 && Number(p[0]) >= current) : []
                        newtradeB = tradeB ? tradeB.filter((p) => Number(p[1]) > 0 && Number(p[0]) <= current) : []

                        newtradeA.sort((a, b) => Number(a[0]) - Number(b[0]))
                        newtradeB.sort((a, b) => Number(b[0]) - Number(a[0]))

                        newtradeA = newtradeA.splice(0, 500)
                        newtradeB = newtradeB.splice(0, 500)

                        setUp(newtradeA)
                        setDown(newtradeB)
                        tradeA = newtradeA
                        tradeB = newtradeB

                        setSmallNum(makeSamllest(maxPoint))
                        if (cntVolume) {
                            setSelectVolume(makeSamllest(maxPoint))
                            cntVolume = false
                        }
                        setMax(maxPoint)
                    }
                }
            }
        }

        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };

        socket.error = function (error) {
            window.location.reload()
        }

        return () => {
            socket.close();
        }

    }, [symbol, close])

    const SpotOpenOrder = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/open/orders", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                setOpenorder(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 54, "msg": error }))
        })
    }

    const SpotOrderHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/history", {
            params: {
                symbol: symbol,
                begin: changeTime(selectedDate),
                end: changeTime(selectedDate) + 86399999
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data) {
                let tmp = response?.data?.data?.data
                if (tmp) {
                    // let arrTmp = tmp.filter(i => Date.parse(i.transaction_time) >= changeTime(selectedDate) && Date.parse(i.transaction_time) <= changeTime(selectedDate) + 86400000)
                    setOrderhistory(tmp)
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 55, "msg": error }))
        })
    }

    const SpotBalance = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/bn/spot/info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp.length) {
                for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].asset == "USDT") {
                        setCurrentUSDT(Number(tmp[i].free))
                    }
                }
                setBalance(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 56, "msg": error }))
        })
    }

    const ExchangeInformation = async () => {
        await axios.get('https://api.binance.com/api/v3/exchangeInfo'
        ).then((response) => {
            let tmp = response?.data?.symbols

            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) setExinfo(a.filters)
                })
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 57, "msg": error }))
        })
    }
    // console.log(exinfo)

    useEffect(() => {
        ExchangeInformation()
        setTab(0)
    }, [symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            SpotOrderHistory(token);
        }
    }, [symbol, selectedDate, Binance_wallet_spot])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            SpotBalance(token);
            SpotOpenOrder(token);
        }
    }, [Binance_wallet_spot])

    let filterSymbol = USDTSymbolS.filter((p) => {
        return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    })

    useEffect(() => {
        handleClick2()
    }, [symbol])

    const handleClick2 = () => {
        setIsClicked2(true);

        setTimeout(() => {
            setIsClicked2(false);
        }, 1000);
    };

    return (
        <div className={`spot ${darkname}`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            <div className={`spot-left ${tabList ? "" : "check"}`}>
                <div className='spot-left-box'>
                    <div className={`spot-left-top ${darkname}`}>
                        <ErrorBoundary>
                            <div className='spot-left-top-1'>
                                <img src={imgcheck ? `/img/coin/${data.ccy}.png` : `/img/coin/origin.png`} ></img>
                                <div className='spot-left-top-1-symbol'><span>{data.ccy}</span><span style={{ color: "#81858c" }}>/USDT</span></div>
                                <div className={`spot-left-top-1-box ${data.priceChangePercent >= 0 ? "green" : "red"}`}>
                                    <div className={`spot-left-top-1-box-price ${priceDirect ? "green" : "red"}`}>
                                        <div>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                    </div>
                                    <div className='spot-left-top-1-box-1 '>
                                        <div className='spot-left-top-1-box-1-1'>
                                            <div><span>{data.priceChangePercent === undefined ? "-" : Number(data.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                        </div>
                                        <div className='spot-left-top-1-box-1-2'>
                                            <div>{data.priceChange === undefined ? "-" : changeNum(Number(data.priceChange).toFixed(max))}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='spot-left-top-2'>
                                <div className='spot-left-top-2-box'>
                                    <div className='spot-left-top-2-box-1'>
                                        <div className='spot-left-top-2-box-1-tit'>{t('market_6')}</div>
                                        <div className='spot-left-top-2-box-1-tit'>{t('market_7')}</div>
                                    </div>
                                    <div className='spot-left-top-2-box-2'>
                                        <div className='spot-left-top-2-box-1-tit'>{data.highPrice === undefined ? "-" : changeNum(Number(data.highPrice).toFixed(max))}</div>
                                        <div className='spot-left-top-2-box-1-tit'>{data.lowPrice === undefined ? "-" : changeNum(Number(data.lowPrice).toFixed(max))}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='spot-left-top-3'>
                                <div className='spot-left-top-3-box'>
                                    <div className='spot-left-top-3-box-1'>
                                        <div className='spot-left-top-3-box-1-tit'>{t('spot_0')}</div>
                                        <div className='spot-left-top-3-box-1-tit'>{t('spot_0')}</div>
                                    </div>
                                    <div className='spot-left-top-3-box-2'>
                                        <div className='spot-left-top-3-box-1-tit'><span>{data.volume === undefined ? "-" : changeNum((Number(data.volume) / 1000000).toFixed(3))}</span><span>M</span></div>
                                        <div className='spot-left-top-3-box-1-tit'><span>{data.volume === undefined ? "-" : changeNum((Number(data.lastPrice) * Number(data.volume) / 1000000).toFixed(3))}</span><span>M</span></div>
                                    </div>
                                    <div className='spot-left-top-3-box-3'>
                                        <div className='spot-left-top-3-box-1-tit'>{data.ccy}</div>
                                        <div className='spot-left-top-3-box-1-tit'>USDT</div>
                                    </div>
                                </div>
                            </div>
                            <div className='spot-left-top-4' onClick={() => setTabList(e => !e)}>
                                {
                                    tabList
                                        ?
                                        <FontAwesomeIcon icon={faCircleChevronRight} />
                                        :
                                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                                }
                            </div>
                        </ErrorBoundary>
                    </div>
                    <div className='spot-left-chart'>
                        <ErrorBoundary>
                            <div style={{ width: `100%`, height: windowtype ? "610px" : "400px", border: `1px solid ${darkname ? "#2f2f2f" : "#e2e2e2"}` }}>
                                <Chart exchange={"Binance"} market={"spot"} />
                            </div>
                        </ErrorBoundary>
                    </div>
                    <div className='spot-left-mid'>
                        <div className={`spot-left-mid-1 ${darkname}`}>
                            <ErrorBoundary>
                                <div className={`spot-left-mid-1-top ${darkname}`}>
                                    <div className='spot-left-mid-1-top-1'>{t('spot_1')}</div>
                                    <div className='spot-left-mid-1-top-2'>{t('spot_2')}</div>
                                    <div className='spot-left-mid-1-top-3'>{t('spot_0')}</div>
                                </div>
                                {
                                    isClicked
                                        ?
                                        <div className='spot-loading'>
                                            <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                                        </div>
                                        :
                                        arr.map(function (a, i) {
                                            return (
                                                <>
                                                    {
                                                        a
                                                            ?
                                                            <TradingTab key={i} price={a} max={max} max1={max1} />
                                                            :
                                                            ""
                                                    }
                                                </>
                                            )
                                        })}
                            </ErrorBoundary>
                        </div>
                        <div className={`spot-left-mid-2 ${darkname}`}>
                            <ErrorBoundary>
                                <div className={`spot-left-mid-2-top ${darkname}`}>
                                    <div className='spot-left-mid-2-top-1 spot-display-none-950'>
                                        <div className='spot-left-mid-2-top-1-box'>
                                            <div className='spot-left-mid-2-top-1-box-img' onClick={() => { setOrderbookState(0); handleClick2() }}>
                                                <img src='/img/box3-1.png'></img>
                                                <span>{t('spot_3')}</span>
                                            </div>
                                            <div className='spot-left-mid-2-top-1-box-img' onClick={() => { setOrderbookState(1); handleClick2() }}>
                                                <img src='/img/box2-2.png'></img>
                                                <span>{t('spot_4')}</span>
                                            </div>
                                            <div className='spot-left-mid-2-top-1-box-img' onClick={() => { setOrderbookState(2); handleClick2() }}>
                                                <img src='/img/box1-2.png'></img>
                                                <span>{t('spot_5')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='spot-left-mid-2-top-2'>
                                        <span className='spot-display-none-950'>{t('spot_0')}</span>
                                        <select value={selectVolume} onChange={onChangeVolume}>
                                            <option value={Number(smallNum).toFixed(max >= 0 ? max : 0)}><span>{smallNum}</span></option>
                                            <option value={(Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0)}><span>{changeNum((Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0))}</span></option>
                                            <option value={(Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0)}><span>{changeNum((Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0))}</span></option>
                                            <option value={(Number(smallNum) * 1000).toFixed(max - 3 >= 0 ? max - 3 : 0)}><span>{changeNum((Number(smallNum) * 1000).toFixed(max - 3 >= 0 ? max - 3 : 0))}</span></option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    isClicked1 || isClicked2
                                        ?
                                        catchError
                                            ?
                                            <div className='orderbook-error'>
                                                <FontAwesomeIcon icon={faRotate} onClick={() => setOrderbookReload(e => !e)} />
                                            </div>
                                            :
                                            <div className='spot-loading'>
                                                <ClipLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                                            </div>
                                        :
                                        <>
                                            {
                                                <Orderbook windowtype={windowtype} up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setSumUp={setSumUp} setSumDown={setSumDown} setCheckordertab={setCheckordertab} orderbookState={orderbookState} max={max} max1={max1} darkname={darkname} currentPrice={currentPrice} priceDirect={priceDirect} />
                                            }
                                            <div className={`spot-left-mid-2-bottom ${darkname}`}>
                                                <div className='spot-left-mid-2-bottom-1'>{orderbookState === 1 ? "" : changeNum(sumUp.toFixed(max1))}</div>
                                                <div className='spot-left-mid-2-bottom-2'>
                                                    {/* {t('spot_6')} */}
                                                    ({data.ccy})
                                                </div>
                                                <div className='spot-left-mid-2-bottom-3'>{orderbookState === 2 ? "" : changeNum(sumDown.toFixed(max1))}</div>
                                            </div>
                                        </>
                                }
                            </ErrorBoundary>
                        </div>
                        <div className={`spot-left-mid-3 ${darkname}`}>
                            <ErrorBoundary>
                                <div style={{ filter: check_KYC && check_KYC.bnKyc === 0 ? "blur(5px)" : "" }}>
                                    <div className='spot-left-mid-3-top'>
                                        <div className={tab === 0 ? `spot-left-mid-3-top-1 active  ${darkname}` : `spot-left-mid-3-top-1 ${darkname}`} onClick={() => setTab(0)}>{t('spot_7')}</div>
                                        <div className={tab === 1 ? `spot-left-mid-3-top-2 active ${darkname}` : `spot-left-mid-3-top-2 ${darkname}`} onClick={() => setTab(1)}>{t('spot_8')}</div>
                                        <div className={tab === 2 ? `spot-left-mid-3-top-3 active ${darkname}` : `spot-left-mid-3-top-3 ${darkname}`} onClick={() => setTab(2)}>{t('spot_9')}</div>
                                    </div>
                                    {
                                        tab === 0
                                            ?
                                            < Buy symbol={symbol} darkname={darkname} lastBuy={lastBuy} data={data} setCheckordertab={setCheckordertab} checkordertab={checkordertab} tab={tab} currentUSDT={currentUSDT} exinfo={exinfo} currentPrice={currentPrice} max1={max1} max={max} />
                                            :
                                            tab === 1
                                                ?
                                                <Sell symbol={symbol} darkname={darkname} data={data} lastSell={lastSell} setCheckordertab={setCheckordertab} checkordertab={checkordertab} tab={tab} balance={balance} exinfo={exinfo} currentPrice={currentPrice} max1={max1} max={max} />
                                                :
                                                <Balance symbol={symbol} darkname={darkname} BinanceS={BinanceS} balance={balance} />
                                    }
                                </div>
                                {
                                    check_KYC && check_KYC.bnKyc === 0
                                        ?
                                        <div className='spot-kyc-modal' style={{ position: "absolute" }}>
                                            <div className={`spot-kyc-modal-1 ${darkname}`}>
                                                <div className='spot-kyc-modal-text'><span>{t('message_4')}</span><br /><span>{t('message_5')}</span></div>
                                                <Link to={`/dashboard`} state={{ num: 1 }}>
                                                    <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div className={`spot-left-bottom ${darkname}`}>
                        <ErrorBoundary>
                            <div className='spot-left-bottom-top'>
                                <div className={tab1 === 0 ? `spot-left-bottom-top-1 active ${darkname}` : `spot-left-bottom-top-1 ${darkname}`} onClick={() => setTab1(0)}><span>{t('spot_10')}</span> (<span>{openorder ? openorder.length : "0"}</span>)</div>
                                <div className={tab1 === 1 ? `spot-left-bottom-top-2 active ${darkname}` : `spot-left-bottom-top-2 ${darkname}`} onClick={() => setTab1(1)}>{t('spot_11')}</div>
                            </div>
                            {
                                tab1 === 0
                                    ?
                                    <OpenOrder darkname={darkname} openorder={openorder} symbol={symbol} />
                                    :
                                    <ExecutedOrder darkname={darkname} orderhistory={orderhistory} selectedDate={selectedDate} setSelectedDate={setSelectedDate} symbol={symbol} />
                            }
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <ErrorBoundary>
                <div className={`spot-right ${darkname} ${tabList ? "" : "spot-right-check"}`}>
                    <div className='spot-right-box'>
                        <div className={`spot-right-box-position ${darkname}`}>
                            <div className={`spot-right-box-1 ${darkname}`}>
                                {
                                    <div className='spot-right-box-1-1'>
                                        <img src='/img/binance_logo.png' className='spot-right-box-1-img'></img>
                                        <div className='spot-right-box-1-3'>{t('spot_12')}</div>
                                    </div>
                                }
                                <div className='spot-right-box-1-2'>
                                    <div className={holding ? `spot-right-box-1-4 on ${darkname}` : `spot-right-box-1-4 ${darkname}`} onClick={() => setHolding(e => !e)}>{t('spot_14')}</div>
                                </div>
                            </div>
                            <div className={`spot-right-box-2 ${darkname}`}>
                                <input type='text' placeholder={t('market_0')} value={search} onChange={onChange} />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className='spot-right-box-2-ico' />
                            </div>
                        </div>
                        <BinanceSpot filterSymbol={filterSymbol} darkname={darkname} balance={balance} holding={holding} symbol={symbol} />
                    </div>
                </div>
            </ErrorBoundary>
        </div>

    )
}

function BinanceSpot({ filterSymbol, darkname, balance, holding, symbol }) {
    const { t } = useTranslation();
    const [name1, setName1] = useState(0);
    const [name2, setName2] = useState(0);
    const [name3, setName3] = useState(1);
    const [name4, setName4] = useState(0);

    if (holding) {
        if (balance) {
            filterSymbol = filterSymbol.filter(a => balance.some(b => b.asset === a.ccy))
        }
    }

    if (name1 === 1) {
        filterSymbol.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));
    }
    if (name1 === 2) {
        filterSymbol.sort((a, b) => Number(a.lastPrice) - Number(b.lastPrice));
    }
    if (name2 === 1) {
        filterSymbol.sort((a, b) => {
            if (a.priceChangePercent < b.priceChangePercent) return 1;
            if (a.priceChangePercent > b.priceChangePercent) return -1;
        });
    }
    if (name2 === 2) {
        filterSymbol.sort((a, b) => {
            if (a.priceChangePercent > b.priceChangePercent) return 1;
            if (a.priceChangePercent < b.priceChangePercent) return -1;
        });
    }
    if (name3 === 1) {
        filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    }
    if (name3 === 2) {
        filterSymbol.sort((a, b) => Number(a.volume) * Number(a.lastPrice) - Number(b.volume) * Number(b.lastPrice));
    }

    if (name4 === 1) {
        filterSymbol.sort((a, b) => {
            if (a.ccy > b.ccy) return 1;
            if (a.ccy < b.ccy) return -1;
        });
    }
    if (name4 === 2) {
        filterSymbol.sort((a, b) => {
            if (a.ccy < b.ccy) return 1;
            if (a.ccy > b.ccy) return -1;
        });
    }

    filterSymbol.sort((a, b) => b.favorite - a.favorite);

    return (
        <div className={`spot-right-box-3 ${darkname}`}>
            <div className='spot-3-table'>
                <div className='spot-3-head'>
                    <div className='spot-3-th' onClick={() => { { setName4(name4 => (name4 + 1) % 3); setName1(0); setName2(0); setName3(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name4 === 0 ? '' : 'orange'}>{t('spot_15')}</span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name4 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name4 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className='spot-3-th' onClick={() => { { setName1(name1 => (name1 + 1) % 3); setName4(0); setName2(0); setName3(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name1 === 0 ? '' : 'orange'}>{t('spot_16')}</span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className='spot-3-th' onClick={() => { { setName2(name2 => (name2 + 1) % 3); setName1(0); setName4(0); setName3(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name2 === 0 ? '' : 'orange'}>{t('spot_17')}</span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className='spot-3-th' onClick={() => { { setName3(name3 => (name3 + 1) % 3); setName1(0); setName2(0); setName4(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name3 === 0 ? '' : 'orange'}>{t('spot_0')}</span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>
                <div className='spot-3-body'>
                    <MyListComponent data={filterSymbol} darkname={darkname} symbol={symbol} />
                </div>
            </div>
        </div >
    )
}

function MyListComponent({ data, darkname, symbol }) {
    const rowRenderer = ({ index, key, style }) => {
        const post = data[index]
        return (
            <BinanceSpotItem item={post} darkname={darkname} symbol={symbol} key={key} style={style} />
        );
    };

    return (
        <List
            width={1000} // 전체 크기
            height={800} // 전체 높이
            rowCount={data.length} // 항목 개수
            rowHeight={50} // 항목 높이
            rowRenderer={rowRenderer} // 항목을 렌더링할 때 쓰는 함수
            list={data} // 배열
            style={{ outline: 'none', paddingRight: "5px", width: "100%" }} // List에 기본 적용되는 outline 스타일 제거
        />
    );
}

function BinanceSpotItem({ item, darkname, symbol, key, style }) {
    let dispatch = useDispatch();
    const [click, setClick] = useState(false)

    const onClick = () => {
        setClick(true)
        setTimeout(() => {
            setClick(false)
        }, 100)
    }

    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                exchange: "binance",
                type: 'spot',
                coin: item.ccy
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    dispatch(change_Binance_S_Favorite(item.ccy))
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 58, "msg": error }))
                })
        }
    }

    return (
        <div className={`spot-3-tr ${darkname} ${symbol === item.symbol ? `on ${click ? "click" : ""} ` : ``}`} key={key} style={style}>
            <div className='futures-item-body-ico' onClick={() => Favorite()} style={{ height: "20px" }}>
                {
                    item.favorite
                        ?
                        <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                        :
                        <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                }
            </div>
            <Link to={`/spot/binance/${item.symbol}`} style={{ textDecoration: "none", width: "100%", height: "100%", display: "flex", alignItems: "center" }} onClick={() => { onClick() }}>
                <div className='spot-3-td'>
                    <div className={`spot-item-body ${darkname}`}>
                        <div style={{ fontSize: "14px", paddingLeft: "18px" }}>
                            <span>{item.ccy}</span><span style={{ color: "#81858c" }}>/USDT</span>
                        </div>
                    </div>
                </div>
                <div className='spot-3-td'><span>{changeNum(Number(item.lastPrice).toFixed(item.point))}</span></div>
                {
                    item.priceChangePercent >= 0
                        ?
                        <div className="spot-3-td green"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                        :
                        <div className="spot-3-td red"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                }
                <div className='spot-3-td'><span>{changeNum((Number(item.lastPrice) * Number(item.volume) / 1000000).toFixed(2))}</span><span>M</span></div>
            </Link>
        </div >
    )
}

function TradingTab({ price, max, max1 }) {
    let arr = price.s.split('USDT')

    return (
        <>
            {
                price === undefined
                    ?
                    ""
                    :
                    price.m
                        ?
                        <div className='spot-left-mid-1-bottom green'>
                            <div className='spot-left-mid-1-bottom-1'>{arr[0]}</div>
                            <div className='spot-left-mid-1-bottom-2'>{changeNum(Number(price.p).toFixed(max))}</div>
                            <div className='spot-left-mid-1-bottom-3'>{changeNum(Number(price.q).toFixed(max1))}</div>
                        </div>
                        :
                        <div className='spot-left-mid-1-bottom red'>
                            <div className='spot-left-mid-1-bottom-1'>{arr[0]}</div>
                            <div className='spot-left-mid-1-bottom-2'>{changeNum(Number(price.p).toFixed(max))}</div>
                            <div className='spot-left-mid-1-bottom-3'>{changeNum(Number(price.q).toFixed(max1))}</div>
                        </div>
            }
        </>
    )
}

let newupp = []
let newdownn = []
let prePrice = 0

function Orderbook({ windowtype, up, down, setLastBuy, setLastSell, data, setSumUp, setSumDown, setCheckordertab, orderbookState, max, max1, darkname, currentPrice, priceDirect }) {
    let sum1 = 0;
    let sum2 = 0;

    useEffect(() => {
        if (orderbookState === 0) {
            newupp = up.splice(0, 8)
            newdownn = down.splice(0, 8)
        }
        else {
            newupp = up.splice(0, 18)
            newdownn = down.splice(0, 18)
        }
    }, [up, down])

    for (let i = 0; i < newupp.length; i++) {
        sum1 += Number(newupp[i][1])
    }
    for (let i = 0; i < newdownn.length; i++) {
        sum2 += Number(newdownn[i][1])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum5 = 0;
    let sum6 = 0;

    setSumUp(sum1)
    setSumDown(sum2)

    return (
        <>
            {
                orderbookState === 0
                    ?
                    <div className='spot-left-mid-2-mid'>
                        <div className='spot-left-mid-2-mid-1'>
                            <div style={{ width: "100%" }}>
                                {[...newupp].reverse().map(function (a, i) {
                                    sum1 -= sum5
                                    sum5 = Number(a[1])
                                    return (
                                        <OrderbookUp key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} max={max} windowtype={windowtype} max1={max1} />
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`spot-left-mid-2-mid-3 ${darkname}`}>
                            {
                                priceDirect
                                    ?
                                    <div className='futures-orderbook-2-4 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "5px", fontSize: "15px" }} />{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                    :
                                    <div className='futures-orderbook-2-4 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "5px", fontSize: "15px" }} />{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                            }
                        </div>
                        <div className='spot-left-mid-2-mid-2'>
                            <div style={{ width: "100%" }}>
                                {newdownn.map(function (a, i) {
                                    sum6 += Number(a[1])
                                    return (
                                        <OrderbookDown key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} max={max} windowtype={windowtype} max1={max1} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    :
                    orderbookState === 1
                        ?
                        <div className='spot-left-mid-2-mid'>
                            <div className='spot-left-mid-2-mid-2 on'>
                                <div style={{ width: "100%" }}>
                                    {newdownn.map(function (a, i) {
                                        sum6 += Number(a[1])
                                        return (
                                            <OrderbookDown key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} max={max} max1={max1} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        :
                        <div className='spot-left-mid-2-mid'>
                            <div className='spot-left-mid-2-mid-1 on'>
                                <div style={{ width: "100%" }}>
                                    {[...newupp].reverse().map(function (a, i) {
                                        sum1 -= sum5
                                        sum5 = Number(a[1])
                                        return (
                                            <OrderbookUp key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} max={max} max1={max1} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
            }
        </>
    )
}

function OrderbookUp({ price, setLastSell, sum1, sum3, setCheckordertab, max, windowtype, max1 }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1]).toFixed(max1)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                windowtype
                    ?
                    <div className="orderbook-box" onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                        <div className='spot-left-mid-2-mid-1-1'>
                            <div className='orderbook-bg' style={{ width: `${wid}%`, backgroundColor: 'rgba(239, 69, 74, 0.3)' }}></div>
                            <div style={{ textAlign: "right", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                        </div>
                        <div className='spot-left-mid-2-mid-1-2 red'>
                            {changeNum(str)}
                        </div>
                        <div className='spot-left-mid-2-mid-1-3'>
                        </div>
                    </div>
                    :
                    <div className="orderbook-box" onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                        <div className='spot-left-mid-2-mid-1-1' style={{ width: "100%" }}>
                            <div className='orderbook-bg' style={{ width: `${wid}%`, backgroundColor: 'rgba(239, 69, 74, 0.3)' }}></div>
                            <div style={{ textAlign: "right", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                        </div>
                        <div className='spot-left-mid-2-mid-1-2 red' style={{ width: "100%" }}>
                            {changeNum(str)}
                        </div>
                    </div>
            }
        </>
    )
}

function OrderbookDown({ price, setLastBuy, sum6, sum3, setCheckordertab, max, windowtype, max1 }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1]).toFixed(max1)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                windowtype
                    ?
                    <div className="orderbook-box" style={{ display: "flex" }} onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                        <div className='spot-left-mid-2-mid-2-1'>
                        </div>
                        <div className='spot-left-mid-2-mid-2-2 green'>
                            {changeNum(str)}
                        </div>
                        <div className='spot-left-mid-2-mid-2-3'>
                            <div className='orderbook-bg' style={{ width: `${wid}%`, backgroundColor: 'rgba(32, 178, 108, 0.3)' }}></div>
                            <div style={{ textAlign: "left", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                        </div>
                    </div>
                    :
                    <div className="orderbook-box" style={{ display: "flex" }} onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                        <div className='spot-left-mid-2-mid-2-3' style={{ justifyContent: "end", width: "100%" }}>
                            <div className='orderbook-bg' style={{ width: `${wid}%`, backgroundColor: 'rgba(32, 178, 108, 0.3)' }}></div>
                            <div style={{ textAlign: "right", width: "100%", zIndex: "10" }}>{changeNum(str1)}</div>
                        </div>
                        <div className='spot-left-mid-2-mid-2-2 green' style={{ width: "100%" }}>
                            {changeNum(str)}
                        </div>
                    </div>
            }
        </>

    )
}

let last = 0;

function Buy({ symbol, darkname, lastBuy, data, setCheckordertab, checkordertab, tab, currentUSDT, exinfo, currentPrice, max1, max }) {
    let login = useSelector((state) => { return state.login.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [checked, setChecked] = useState(true)
    const [checked1, setChecked1] = useState('1')
    const [buyorderprice, setBuyorderprice] = useState();
    const [qtyinput, setQtyinput] = useState('');
    const [quaninput, setQuaninput] = useState('');
    const [message, setMessage] = useState([])

    const [onDirect, setOnDirect] = useState(false)
    const [btnpercent, setBtnpercent] = useState([0]);
    const [caldirect, setCaldirect] = useState(false)

    const [delay, setDelay] = useState(true)

    const onChangeBuy = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setBuyorderprice(onlyNumber)
    }

    const onChangeQty = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQtyinput(onlyNumber)
        setQuaninput(RoundDown(Number(buyorderprice) * Number(e.target.value), 3))
        setCaldirect(false)
    }

    const onChangeQuan = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput(onlyNumber)
        // setQtyinput((Number(e.target.value) / Number(buyorderprice)).toFixed(MakeNumber(e.target.value)))
        setQtyinput(RoundDown((Number(e.target.value) / Number(buyorderprice)), max1))
        setCaldirect(true)
    }

    const onChangeChecked = (e) => {
        let bol = e.target.checked
        setChecked(bol)
        setCheckordertab(bol ? true : false)
    }

    const onChangeRadio = (e) => {
        let bol = e.target.value
        setChecked1(bol)
        if (bol === "2") {
            setChecked(true)
            setCheckordertab(true)
        }
    }

    const onChangeDirect = (e) => {
        if (e === 0) {
            setQuaninput('0')
        }
        else {
            setQuaninput(RoundDown(Number(currentUSDT) * Number(e) / 100, 3))
        }
        setCaldirect(true)
    }

    useEffect(() => {
        if (caldirect) {
            if (!isNaN(Number(quaninput) / Number(buyorderprice))) {
                // setQtyinput((Number(quaninput) / Number(buyorderprice)).toFixed(MakeNumber(quaninput)))
                setQtyinput(RoundDown((Number(quaninput) / Number(buyorderprice)), max1))
            }
        }
        else {
            if (!isNaN(Number(buyorderprice) * Number(qtyinput))) {
                setQuaninput(RoundDown(Number(buyorderprice) * Number(qtyinput), 3))
            }
        }
    }, [qtyinput, buyorderprice, caldirect, quaninput])

    useEffect(() => {
        setBuyorderprice(Number(lastBuy).toFixed(max))
        if (lastBuy != 0) setChecked(false)
    }, [lastBuy, max])

    useEffect(() => {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        setCheckordertab(true)
        setChecked(true)
        last = currentPrice
    }, [tab, max])

    if (last != currentPrice && checked && checkordertab) {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
    }

    const onSubmitBuy = async () => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order", {
                symbol: symbol,
                side: "BUY",
                type: checked1 === '1' ? 'LIMIT' : 'MARKET',
                quantity: qtyinput,
                price: buyorderprice,
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setQtyinput("")
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg?.msg })
                }

                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 59, "msg": error }))
            })
        }
    }

    return (
        <div className='spot-left-mid-3-body'>
            <Modal message={message} />
            <div className='spot-left-mid-3-body-1'>
                <div style={{ width: "30%" }}>{t('spot_18')}</div>
                <div style={{ width: "35%" }}>
                    <input type="radio" value="1" checked={checked1 === '1'} onChange={onChangeRadio} style={{ marginRight: "7px" }} />
                    <span>{t('spot_19')}</span>
                </div>
                <div style={{ width: "35%" }}>
                    <input type="radio" value="2" checked={checked1 === '2'} onChange={onChangeRadio} style={{ marginRight: "7px" }} />
                    <span>{t('spot_20')}</span>
                </div>
            </div>
            <div className='spot-left-mid-3-body-2'>
                <div className='spot-left-mid-3-body-2-box'>
                    <div style={{ width: "50%" }}>{t('spot_21')}</div>
                    <div style={{ width: "50%", textAlign: "right" }}><span>{changeNum(RoundDown(currentUSDT, 6))}</span> <span>USDT</span></div>
                </div>
            </div>
            <div className='spot-left-mid-3-body-3'>
                <div style={{ width: "30%" }}>{t('spot_22')}</div>
                <div style={{ width: "70%" }} className='spot-left-mid-3-body-3-box'>
                    {
                        checked1 === '2'
                            ?
                            <>
                                <div className='spot-left-mid-3-body-3-tit'>
                                    <span>{t('spot_23')}</span>
                                    <input type='checkbox' checked={true} />
                                </div>
                                <input type='number' className={`spot-left-mid-3-body-3-input ${darkname}`} value={buyorderprice} onChange={onChangeBuy} onClick={() => { setChecked(false); setCheckordertab(false) }} placeholder='0' disabled />
                            </>
                            :
                            <>
                                <div className='spot-left-mid-3-body-3-tit'>
                                    <span>{t('spot_23')}</span>
                                    <input type='checkbox' checked={checked} onChange={onChangeChecked} />
                                </div>
                                <input type='number' className={`spot-left-mid-3-body-3-input ${darkname}`} value={buyorderprice} onChange={onChangeBuy} onClick={() => { setChecked(false); setCheckordertab(false) }} placeholder='0' />
                            </>
                    }
                </div>
            </div>
            <div className='spot-left-mid-3-body-4'>
                <div className='spot-left-mid-3-body-4-box'>
                    <div className="spot-left-mid-3-body-4-box-3">{t('spot_24')}</div>
                    <input type='number' className={`spot-left-mid-3-body-4-input ${darkname}`} value={qtyinput} onChange={onChangeQty} placeholder={`${t('futures_30')} ${exinfo[1] && exinfo[1].minQty ? Number(exinfo[1].minQty) : 0} ${data.ccy}`} />
                </div>
                <div className='spot-left-mid-3-body-4-box'>
                    {/* <div className="spot-left-mid-3-body-4-box-3">{t('spot_25')}</div> */}
                    <div className='spot-left-mid-3-body-4-box-1'>
                        {
                            onDirect
                                ?
                                <>
                                    <div className='spot-left-mid-3-body-4-box-4'>
                                        <Range
                                            values={btnpercent}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={e => { setBtnpercent(e); onChangeDirect(e) }}
                                            renderTrack={({ props, children }) => (
                                                <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "5px", width: "100%", borderRadius: "4px",
                                                            background: getTrackBackground({
                                                                values: btnpercent,
                                                                colors: [`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`, "#ccc"],
                                                                min: 0,
                                                                max: 100
                                                            }),
                                                            alignSelf: "center"
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div {...props} style={{ ...props.style, height: "20px", width: "20px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                                    <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? `${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}` : "#CCC" }} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className={`spot-left-mid-3-body-4-box-2-btn ${darkname}`} >{btnpercent[0]}%</div>
                                    <div className={`spot-left-mid-3-body-4-box-2-btn ${darkname}`} onClick={() => setOnDirect(false)}>{t('spot_26')}</div>
                                </>
                                :
                                <>
                                    <div className='spot-left-mid-3-body-4-box-2'>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(0)}>{t('spot_27')}</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(25)}>25%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(50)}>50%</div>
                                    </div>
                                    <div className='spot-left-mid-3-body-4-box-2'>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(75)}>75%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(100)}>100%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => setOnDirect(true)}>{t('spot_28')}</div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className='spot-left-mid-3-body-4'>
                <div className='spot-left-mid-3-body-4-box'>
                    <div className="spot-left-mid-3-body-4-box-3"><span>{t('spot_29')}</span> <span style={{ fontSize: "11px" }}>(USDT)</span></div>
                    <input type='number' className={`spot-left-mid-3-body-4-input ${darkname}`} value={quaninput} onChange={onChangeQuan} placeholder='0' />
                </div>
            </div>
            <div className='spot-left-mid-3-body-5'>
                <div className='spot-left-mid-3-body-5-box'>
                    <div className='spot-left-mid-3-body-5-1'><span>{t('spot_30')}</span> <span>{exinfo[6] ? Number(exinfo[6].minNotional) : "0"}</span> <span>USDT</span></div>
                    <div className='spot-left-mid-3-body-5-2'><span>{t('spot_31')}</span> <span>0.1%</span></div>
                </div>
                {
                    login
                        ?
                        <div className={`spot-left-mid-3-body-5-btn ${darkname}`} onClick={() => onSubmitBuy()} >{
                            delay
                                ?
                                t('spot_7')
                                :
                                <ClipLoader color="#595959" size='17px' />
                        }</div>
                        :
                        <Link to={`/login`}><div className={`spot-left-mid-3-body-5-btn ${darkname}`}><span>{t('login_0')}</span></div></Link>
                }
            </div>
        </div >
    )
}


function Sell({ symbol, darkname, data, lastSell, setCheckordertab, checkordertab, tab, balance, exinfo, currentPrice, max1, max }) {
    let login = useSelector((state) => { return state.login.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [checked, setChecked] = useState(true)
    const [checked1, setChecked1] = useState('1')
    const [sellorderprice, setSellorderprice] = useState();
    const [qtyinput, setQtyinput] = useState('');
    const [quaninput, setQuaninput] = useState('');
    const [message, setMessage] = useState([])
    const [current, setCurrent] = useState("0")

    const [onDirect, setOnDirect] = useState(false)
    const [btnpercent, setBtnpercent] = useState([0]);
    const [caldirect, setCaldirect] = useState(false)

    const [delay, setDelay] = useState(true)

    const onChangeSell = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSellorderprice(onlyNumber)
    }

    const onChangeQty = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQtyinput(onlyNumber)
        setQuaninput(RoundDown(Number(sellorderprice) * Number(e.target.value), 3))
        setCaldirect(false)
    }

    const onChangeQuan = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput(onlyNumber)
        // setQtyinput((Number(e.target.value) / Number(sellorderprice)).toFixed(MakeNumber(e.target.value)))
        setQtyinput(RoundDown((Number(e.target.value) / Number(sellorderprice)), max1))
        setCaldirect(true)
    }

    const onChangeChecked = (e) => {
        let bol = e.target.checked
        setChecked(bol)
        setCheckordertab(bol ? true : false)
    }

    const onChangeRadio = (e) => {
        let bol = e.target.value
        setChecked1(bol)
        if (bol === "2") {
            setChecked(true)
            setCheckordertab(true)
        }
    }

    const onChangeDirect = (e) => {
        if (e === 0) {
            setQtyinput(0)
        }
        else {
            // setQtyinput((Number(current) * Number(e) / 100).toFixed(MakeNumber(current)))
            setQtyinput(RoundDown((Number(current) * Number(e) / 100), max1))
        }
        setCaldirect(false)
    }

    useEffect(() => {
        if (caldirect) {
            if (!isNaN(Number(quaninput) / Number(sellorderprice))) {
                // setQtyinput((Number(quaninput) / Number(sellorderprice)).toFixed(MakeNumber(qtyinput)))
                setQtyinput(RoundDown((Number(quaninput) / Number(sellorderprice)), max1))
            }
        }
        else {
            if (!isNaN(Number(sellorderprice) * Number(qtyinput))) {
                setQuaninput(RoundDown(Number(sellorderprice) * Number(qtyinput), 3))
            }
        }
    }, [qtyinput, sellorderprice, caldirect, quaninput])

    useEffect(() => {
        if (balance) {
            for (let i = 0; i < balance.length; i++) {
                if (balance[i].asset === data.ccy) {
                    setCurrent(balance[i].free)
                }
            }
        }
    }, [balance])

    useEffect(() => {
        setSellorderprice(Number(lastSell).toFixed(max))
        if (lastSell != 0) setChecked(false)
    }, [lastSell])

    useEffect(() => {
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
        setCheckordertab(true)
        setChecked(true)
    }, [tab])

    if (last != currentPrice && checked && checkordertab) {
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
    }

    const onSubmitSell = async () => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order", {
                symbol: symbol,
                side: "SELL",
                type: checked1 === '1' ? 'LIMIT' : 'MARKET',
                quantity: qtyinput,
                price: sellorderprice,
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setQtyinput("")
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg?.msg })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 60, "msg": error }))
            })
        }
    }

    return (
        <div className='spot-left-mid-3-body'>
            <Modal message={message} />
            <div className='spot-left-mid-3-body-1'>
                <div style={{ width: "30%" }}>{t('spot_18')}</div>
                <div style={{ width: "35%" }}>
                    <input type="radio" value="1" checked={checked1 === '1'} onChange={onChangeRadio} style={{ marginRight: "7px" }} />
                    <span>{t('spot_19')}</span>
                </div>
                <div style={{ width: "35%" }}>
                    <input type="radio" value="2" checked={checked1 === '2'} onChange={onChangeRadio} style={{ marginRight: "7px" }} />
                    <span>{t('spot_20')}</span>
                </div>
            </div>
            <div className='spot-left-mid-3-body-2'>
                <div className='spot-left-mid-3-body-2-box'>
                    <div style={{ width: "50%" }}>{t('spot_21')}</div>
                    <div style={{ width: "50%", textAlign: "right" }}><span>{changeNum(RoundDown(current, MakeNumber(current)))}</span> <span>{data.ccy}</span></div>
                </div>
                <div className='spot-left-mid-3-body-2-box'>
                    <div style={{ width: "100%", textAlign: "right" }}><span>{isNaN(Number(current) * Number(currentPrice)) ? "0" : changeNum(RoundDown(Number(current) * Number(currentPrice), 6))}</span> <span>USDT</span></div>
                </div>
            </div>
            <div className='spot-left-mid-3-body-3'>
                <div style={{ width: "30%" }}>{t('spot_32')}</div>
                <div style={{ width: "70%" }} className='spot-left-mid-3-body-3-box'>
                    {
                        checked1 === '2'
                            ?
                            <>
                                <div className='spot-left-mid-3-body-3-tit'>
                                    <span>{t('spot_23')}</span>
                                    <input type='checkbox' checked={true} />
                                </div>
                                <input type='number' className={`spot-left-mid-3-body-3-input ${darkname}`} value={sellorderprice} onChange={onChangeSell} onClick={() => { setChecked(false); setCheckordertab(false) }} placeholder='0' disabled />
                            </>
                            :
                            <>
                                <div className='spot-left-mid-3-body-3-tit'>
                                    <span>{t('spot_23')}</span>
                                    <input type='checkbox' checked={checked} onChange={onChangeChecked} />
                                </div>
                                <input type='number' className={`spot-left-mid-3-body-3-input ${darkname}`} value={sellorderprice} onChange={onChangeSell} onClick={() => { setChecked(false); setCheckordertab(false) }} placeholder='0' />
                            </>
                    }
                </div>
            </div>
            <div className='spot-left-mid-3-body-4'>
                <div className='spot-left-mid-3-body-4-box'>
                    <div className="spot-left-mid-3-body-4-box-3">{t('spot_24')}</div>
                    <input type='number' className={`spot-left-mid-3-body-4-input ${darkname}`} value={qtyinput} onChange={onChangeQty} placeholder={`Min. Qty is ${exinfo[1] && exinfo[1].minQty ? Number(exinfo[1].minQty) : 0} ${data.ccy}`} />
                </div>
                <div className='spot-left-mid-3-body-4-box'>
                    {/* <div className="spot-left-mid-3-body-4-box-3">{t('spot_25')}</div> */}
                    <div className='spot-left-mid-3-body-4-box-1'>
                        {
                            onDirect
                                ?
                                <>
                                    <div className='spot-left-mid-3-body-4-box-4'>
                                        <Range
                                            values={btnpercent}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={e => { setBtnpercent(e); onChangeDirect(e) }}
                                            renderTrack={({ props, children }) => (
                                                <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "5px", width: "100%", borderRadius: "4px",
                                                            background: getTrackBackground({
                                                                values: btnpercent,
                                                                colors: [`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`, "#ccc"],
                                                                min: 0,
                                                                max: 100
                                                            }),
                                                            alignSelf: "center"
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div {...props} style={{ ...props.style, height: "20px", width: "20px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                                    <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? `${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}` : "#CCC" }} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className={`spot-left-mid-3-body-4-box-2-btn ${darkname}`} >{btnpercent[0]}%</div>
                                    <div className={`spot-left-mid-3-body-4-box-2-btn ${darkname}`} onClick={() => setOnDirect(false)}>{t('spot_26')}</div>
                                </>
                                :
                                <>
                                    <div className='spot-left-mid-3-body-4-box-2'>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(0)}>{t('spot_27')}</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(25)}>25%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(50)}>50%</div>
                                    </div>
                                    <div className='spot-left-mid-3-body-4-box-2'>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(75)}>75%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => onChangeDirect(100)}>100%</div>
                                        <div className={`spot-left-mid-3-body-4-box-1-btn ${darkname}`} onClick={() => setOnDirect(true)}>{t('spot_28')}</div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className='spot-left-mid-3-body-4'>
                <div className='spot-left-mid-3-body-4-box'>
                    <div className="spot-left-mid-3-body-4-box-3"><span>{t('spot_29')}</span> <span style={{ fontSize: "11px" }}>(USDT)</span></div>
                    <input type='number' className={`spot-left-mid-3-body-4-input ${darkname}`} value={quaninput} onChange={onChangeQuan} placeholder='0' />
                </div>
            </div>
            <div className='spot-left-mid-3-body-5'>
                <div className='spot-left-mid-3-body-5-box'>
                    <div className='spot-left-mid-3-body-5-1'><span>{t('spot_30')}</span> <span>{exinfo[6] ? Number(exinfo[6].minNotional) : "0"}</span> <span>USDT</span></div>
                    <div className='spot-left-mid-3-body-5-2'><span>{t('spot_31')}</span> <span>0.2%</span></div>
                </div>
                {
                    login
                        ?
                        <div className={`spot-left-mid-3-body-6-btn ${darkname}`} onClick={() => onSubmitSell()}>{
                            delay
                                ?
                                t('spot_8')
                                :
                                <ClipLoader color="#595959" size='17px' />
                        }</div>
                        :
                        <Link to={`/login`}><div className={`spot-left-mid-3-body-6-btn ${darkname}`}>{t('login_0')}</div></Link>
                }
            </div>
        </div>
    )
}

function Balance({ BinanceS, balance }) {
    const { t } = useTranslation();
    return (
        <div className='balance'>
            <div className='balance-top'>
                <div className='balance-top-1'>{t('spot_1')}</div>
                <div className='balance-top-1'>{t('spot_33')}</div>
                <div className='balance-top-1'>{t('spot_34')}</div>
            </div>
            {
                balance.map(function (a, i) {
                    if (BinanceS) {
                        for (let j = 0; j < BinanceS.length; j++) {
                            if (BinanceS[j].ccy === a.asset) {
                                if (a.asset != "USDT") {
                                    return (
                                        <BalanceItem key={i} item={a} data={BinanceS[j]} />
                                    )
                                }
                            }
                        }
                    }
                })
            }
        </div>
    )
}

function BalanceItem({ item, data }) {
    return (
        <div className='balance-bottom'>
            <div className='balance-top-1'>{item.asset}</div>
            <div className='balance-top-1'>{changeNum(RoundDown(Number(item.free) * Number(data.lastPrice), 8))}</div>
            <div className='balance-top-1'>{changeNum(RoundDown(item.free, 8))}</div>
        </div>
    )
}

function OpenOrder({ darkname, openorder, symbol }) {
    const { t } = useTranslation();
    const [selectCoins, setSelectCoins] = useState('0')
    const [selectOrders, setSelectOrders] = useState('0')
    const [newData, setNewData] = useState([])
    const [message, setMessage] = useState([])

    let dispatch = useDispatch()

    useEffect(() => {
        let newopenorder = openorder
        if (selectCoins === "0") {
            if (selectOrders === "0") {
            }
            else if (selectOrders === "1") {
                newopenorder = newopenorder.filter(e => e.side == "BUY")
            }
            else if (selectOrders === "2") {
                newopenorder = newopenorder.filter(e => e.side == "SELL")
            }
        }
        else {
            if (selectOrders === "0") {
                newopenorder = newopenorder.filter(e => e.symbol === symbol)
            }
            else if (selectOrders === "1") {
                newopenorder = newopenorder.filter(e => e.symbol === symbol)
                newopenorder = newopenorder.filter(e => e.side == "BUY")
            }
            else if (selectOrders === "2") {
                newopenorder = newopenorder.filter(e => e.instId === symbol)
                newopenorder = newopenorder.filter(e => e.side == "SELL")
            }
        }
        setNewData(newopenorder)
    }, [openorder, selectCoins, selectOrders])

    const ChangeCoins = (event) => {
        setSelectCoins(event.target.value);
    };

    const ChangeOrders = (event) => {
        setSelectOrders(event.target.value);
    };

    let arr = []
    if (symbol) {
        arr = symbol.split('USDT')
    }

    const [delay, setDelay] = useState(true)

    const CancelAll = () => {
        let list = []

        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i].symbol
                if (!list.includes(s)) {
                    list.push(s)
                }
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/all-order/cancel", {
                symbol: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 61, "msg": error }))
            })
        }
    }

    return (
        <div className='spot-left-bottom-body'>
            <Modal message={message} />
            <div className='spot-left-bottom-body-1'>
                <div className='spot-left-bottom-body-1-1'>
                    {
                        delay
                            ?
                            <div className='spot-left-bottom-body-1-1-1' onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className='spot-left-bottom-body-1-1-1'><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <div className='spot-left-bottom-body-1-2'>
                    <select className={`spot-left-bottom-body-1-2-select ${darkname}`} value={selectCoins} onChange={(ChangeCoins)} >
                        <option value={0}><span>{t('spot_35')}</span></option>
                        <option value={1}><span>{arr[0]}</span></option>
                    </select>
                    <select className={`spot-left-bottom-body-1-2-select ${darkname}`} value={selectOrders} onChange={(ChangeOrders)} >
                        <option value={0}><span>{t('spot_36')}</span></option>
                        <option value={1}><span>{t('spot_7')}</span></option>
                        <option value={2}><span>{t('spot_8')}</span></option>
                    </select>
                </div>
            </div>
            <div className={`spot-left-bottom-body-2 ${darkname}`}>
                <div className='spot-table'>
                    <div className='spot-table-head'>
                        <div className='spot-table-th'><span>{t('spot_38')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_39')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_40')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_41')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_42')}</span></div>
                        <div className='spot-table-th display-none-600'><span>{t('spot_43')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_37')}</span></div>
                    </div>
                    <div className='spot-table-body' style={{ position: "relative" }}>
                        {
                            newData && newData.length === 0
                                ?
                                <div className='spot-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p><span>{t('message_0')}</span></p>
                                </div>
                                :
                                newData.map(function (a, i) {
                                    return (
                                        <OpenOrderItem key={i} item={a} setMessage={setMessage} />
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

function OpenOrderItem({ item, setMessage }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [delay, setDelay] = useState(true)

    let arr = item.symbol.split('USDT')
    const date = UTCTimestamp(new Date(Number(item.time)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    const FuturesOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/order/cancel", {
                symbol: item.symbol,
                orderId: item.orderId,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setDelay(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 62, "msg": error }))
                })
        }
    }

    return (
        <div className='spot-opeenorder'>
            <div className='spot-opeenorder-td'><span>{formattedDate}</span></div>
            <div className='spot-opeenorder-td'><span>{arr[0]}</span><span>/USDT</span></div>
            <div className='spot-opeenorder-td'><span>{item.side}</span></div>
            <div className='spot-opeenorder-td'><span>{changeNum(Number(item.price))}</span></div>
            <div className='spot-opeenorder-td'><span>{changeNum(Number(item.origQty))}</span></div>
            <div className='spot-opeenorder-td display-none-600'><span>{changeNum(RoundDown(Number(item.origQty) * Number(item.price), 3))}</span></div>
            <div className='spot-opeenorder-td' style={{ cursor: "pointer" }} onClick={() => { FuturesOpenOrderCancel() }}>
                {
                    delay
                        ?
                        <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: "14px", opacity: ".4" }} />
                        :
                        <ClipLoader color="#595959" size='17px' />
                }
            </div>
        </div>
    )
}

function ExecutedOrder({ darkname, orderhistory, selectedDate, setSelectedDate, symbol }) {
    const { t } = useTranslation();
    const [selectCoins, setSelectCoins] = useState('0')
    const [selectOrders, setSelectOrders] = useState('0')
    const [newData, setNewData] = useState([])

    useEffect(() => {
        let newopenorder = orderhistory

        newopenorder = newopenorder.filter(e => e.current_order_status == "FILLED")
        if (selectCoins === "0") {
            if (selectOrders === "0") {
            }
            else if (selectOrders === "1") {
                newopenorder = newopenorder.filter(e => e.side == "BUY")
            }
            else if (selectOrders === "2") {
                newopenorder = newopenorder.filter(e => e.side == "SELL")
            }
        }
        else {
            if (selectOrders === "0") {
                // newopenorder = newopenorder.filter(e => e.symbol === symbol)
            }
            else if (selectOrders === "1") {
                newopenorder = newopenorder.filter(e => e.symbol === symbol)
                newopenorder = newopenorder.filter(e => e.side == "BUY")
            }
            else if (selectOrders === "2") {
                newopenorder = newopenorder.filter(e => e.symbol === symbol)
                newopenorder = newopenorder.filter(e => e.side == "SELL")
            }
        }
        setNewData(newopenorder)
    }, [orderhistory, selectOrders])

    const ChangeCoins = (event) => {
        setSelectCoins(event.target.value);
    };

    const ChangeOrders = (event) => {
        setSelectOrders(event.target.value);
    };

    return (
        <div className='spot-left-bottom-body'>
            <div className='spot-left-bottom-body-1'>
                <div className={`spot-left-bottom-body-1-1 ${darkname}`}>
                    <FontAwesomeIcon icon={faCalendarDays} style={{ color: "#8d8d8d", margin: "0px 7px" }} />
                    <span style={{ marginRight: "10px" }}>{t('spot_45')}</span>
                    <div className={`f-trade-history-1-date ${darkname}`}>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            maxDate={new Date()}
                            dateFormat="yyyy-MM-dd"
                            shouldCloseOnSelect
                            minDate={new Date('2000-01-01')}
                        />
                    </div>
                </div>
                <div className='spot-left-bottom-body-1-2'>
                    {/* <select className={`spot-left-bottom-body-1-2-select ${darkname}`} value={selectCoins} onChange={(ChangeCoins)} >
                        <option value={0}>All Coins</option>
                        <option value={1}>{arr[0]}</option>
                    </select> */}
                    <select className={`spot-left-bottom-body-1-2-select ${darkname}`} value={selectOrders} onChange={(ChangeOrders)} >
                        <option value={0}><span>{t('spot_36')}</span></option>
                        <option value={1}><span>{t('spot_7')}</span></option>
                        <option value={2}><span>{t('spot_8')}</span></option>
                    </select>
                </div>
            </div>
            <div className={`spot-left-bottom-body-3 ${darkname}`}>
                <div className='spot-table'>
                    <div className='spot-table-head'>
                        <div className='spot-table-th'><span>{t('spot_38')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_39')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_40')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_41')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_42')}</span></div>
                        <div className='spot-table-th display-none-600'><span>{t('spot_43')}</span></div>
                        <div className='spot-table-th'><span>{t('spot_31')}</span></div>
                    </div>
                    <div className='spot-table-body' style={{ position: "relative" }}>
                        {
                            newData && newData.length === 0
                                ?
                                <div className='spot-right-bottom-4-bottom-1'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p><span>{t('message_0')}</span></p>
                                </div>
                                :
                                newData.map(function (a, i) {
                                    return (
                                        <ExecutedItem key={i} item={a} />
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function ExecutedItem({ item }) {
    let arr = item.symbol.split('USDT')
    const date = UTCTimestamp(new Date(Date.parse(item.transaction_time)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <div className='spot-opeenorder'>
            <div className='spot-opeenorder-td'><span>{formattedDate}</span></div>
            <div className='spot-opeenorder-td'><span>{arr[0]}</span><span>/USDT</span></div>
            <div className='spot-opeenorder-td'><span>{item.side}</span></div>
            <div className='spot-opeenorder-td'><span>{changeNum(Number(item.last_executed_price))}</span></div>
            <div className='spot-opeenorder-td'><span>{changeNum(Number(item.last_executed_quantity))}</span></div>
            <div className='spot-opeenorder-td display-none-600'><span>{changeNum(RoundDown(Number(item.last_executed_price) * Number(item.last_executed_quantity), 3))}</span></div>
            <div className='spot-opeenorder-td'><span>{changeNum(RoundDown(Number(item.commission_amount) * (item.side == "BUY" ? Number(item.last_executed_price) : 1), 4))}</span></div>
        </div>
    )
}


export default BSpot;