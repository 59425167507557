import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import '../../css/main.css'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, check_Error } from '../../../store';
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp, useCounter } from "../function"

import { Pagination, Autoplay, FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

let BinanceTotalTrade = 0;
let OKXTotalTrade = 0;

function Main() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    let BinanceF = useSelector((state) => { return state.Binance_coinF });
    let OKXF = useSelector((state) => { return state.OKX_coinF });

    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    if (BinanceF) {
        let total = 0;
        for (let i = 0; i < BinanceF.length; i++) {
            total += Number(BinanceF[i].volume) * Number(BinanceF[i].lastPrice)
        }
        BinanceTotalTrade = total
    }
    if (OKXF) {
        let total = 0;
        for (let i = 0; i < OKXF.length; i++) {
            total += Number(OKXF[i].volume) * Number(OKXF[i].lastPrice)
        }
        OKXTotalTrade = total
    }

    useEffect(() => {
        document.title = "goEx│One-Stop Trading Platform - Crypto Trading Universe";
    }, []);

    return (
        <div className={`main ${darkname}`}
        // style={{ paddingLeft: API_Address == "dev" ? "50px" : "" }}
        >
            <div className='main-section1'>
                <div className='main-section1-box'>
                    <p className='main-section1-box-1'>Reliable GOEX</p>
                    <p className='main-section1-box-2'>OPEN API services with major global exchanges</p>
                    <div className='main-section1-box-3'>
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            spaceBetween={60}
                            slidesPerView={1}
                            loop={true}
                            touchStartPreventDefault={false}
                            touchStartForcePreventDefault={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            speed={2500}
                            breakpoints={{
                                900: {
                                    slidesPerView: 3,
                                }
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='main-section1-box-3-1'>
                                    <div className='main-section1-box-3-1-1'>
                                        <div className='main-section1-box-3-1-1-img'>
                                            <img src='/img/okx_logo.svg' style={{ height: "35px" }}></img>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p>Check it out at OKX</p>
                                            <input type='button' value="OKX LINK PROGRAM" className='main-section1-box-3-1-1-txt1'
                                                onClick={() => window.open('https://www.okx.com/broker/home')}></input>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p style={{ fontSize: "0.8rem" }}>
                                                24h
                                                <span style={{ fontSize: "1.6rem" }}>&nbsp;{changeNum(useCounter(Math.floor(OKXTotalTrade)), 0)}&nbsp;</span >
                                                <span style={{ fontSize: "0.8rem" }}>USDT</span >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='main-section1-box-3-1'>
                                    <div className='main-section1-box-3-1-1'>
                                        <div className='main-section1-box-3-1-1-img'>
                                            <img src='/img/binance_logo.svg' style={{ height: "35px" }}></img>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p style={{ cursor: "pointer" }}>Check it out at BINANCE</p>
                                            <input type='button' value="BINANCE LINK PROGRAM" className='main-section1-box-3-2-1-txt1'
                                                onClick={() => window.open('https://www.binance.com/en/link')}></input>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p style={{ fontSize: "0.8rem" }}>
                                                24h
                                                <span style={{ fontSize: "1.6rem" }}>&nbsp;{changeNum(useCounter(Math.floor(BinanceTotalTrade)), 0)}&nbsp;</span >
                                                <span style={{ fontSize: "0.8rem" }}>USDT</span >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='main-section1-box-3-1'>
                                    <div className='main-section1-box-3-1-1'>
                                        <div className='main-section1-box-3-1-1-img'>
                                            <img src='/img/bybit_logo.svg' style={{ height: "35px" }}></img>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p>Check it out at Bybit</p>
                                            <input type='button' value="BYBIT LINK PROGRAM" className='main-section1-box-3-1-1-txt1'
                                                onClick={() => window.open('https://www.bybit.com/en-us/promo/global/broker/')}></input>
                                        </div>
                                        <div className='main-section1-box-3-1-1-txt'>
                                            <p style={{ fontSize: "0.8rem" }}>
                                                24h
                                                <span style={{ fontSize: "1.6rem" }}>&nbsp;{changeNum(useCounter(9701239872), 0)}&nbsp;</span >
                                                <span style={{ fontSize: "0.8rem" }}>USDT</span >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='main-section1-box-4'>
                        <img src='/img/video/goex_main2.gif'></img>
                    </div>
                </div>
            </div>
            <div className='main-section2'>
                <div className='main-secition2-box'>
                    <p className='main-section2-txt'>Check out the contents of GOEX</p>
                    <p className='main-sextion2-txt1'>Provides a variety of content and information</p>
                    <div style={{ display: "flex", position: "relative" }}>
                        <div className={`box-pre ${darkname}`}></div>
                        <div className={`box-next ${darkname}`}></div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='main-section2-box-1'>
                                    <div className='main-section2-box-1-1'>
                                        <Link onClick={() => alert("Coming soon.")}><img src='/img/im_pt_slide_banner_okx_opening.png' className='main-section2-box-img' ></img></Link>
                                        <div className='main-section2-box-1-1-txt'>
                                            <p className='main-section2-box-1-1-txt1'>NEW SERVICE</p>
                                            <p className='main-section2-box-1-1-txt2'>OKX service coming soon</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='main-section2-box-1'>
                                    <div className='main-section2-box-1-1'>
                                        <Link onClick={() => alert("Coming soon.")}>
                                            <img src='/img/goex_event2.jpg' className='main-section2-box-img'></img>
                                        </Link>
                                        <div className='main-section2-box-1-1-txt'>
                                            <p className='main-section2-box-1-1-txt1'>NEW SERVICE</p>
                                            <p className='main-section2-box-1-1-txt2'>Experience BYBIT services on GOEX</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='main-section2-box-1'>
                                    <div className='main-section2-box-1-1'>
                                        <Link onClick={() => alert("Coming soon.")}>
                                            <img src='/img/goex_event1.jpg' className='main-section2-box-img'></img>
                                        </Link>
                                        <div className='main-section2-box-1-1-txt'>
                                            <p className='main-section2-box-1-1-txt1'>Member compensation</p>
                                            <p className='main-section2-box-1-1-txt2'>BINANCE &amp; BYBIT Fee Maximum Benefits</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='main-section2-box-1'>
                                    <div className='main-section2-box-1-1'>
                                        <Link onClick={() => alert("Coming soon.")}><img src='/img/video/main2.png' className='main-section2-box-img'></img></Link>
                                        <div className='main-section2-box-1-1-txt'>
                                            <p className='main-section2-box-1-1-txt1'>New Listing: BONK/USDT</p>
                                            <p className='main-section2-box-1-1-txt2'>BONK Up for Grabs!</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='main-section2'>
                <div className='main-secition2-box'>
                    <p className='main-section2-txt'>API Linked Trading Tool</p>
                    <p className='main-sextion2-txt1'>Provides a variety of trading and community</p>
                    <div className='main-section2-flex-1'>
                        <div className='main-section2-flex-2'>
                            <div className='main-section2-box-1'>
                                <div className='main-section2-box-1-1'>
                                    <img src='/img/im_pt_api_img1.svg' className='main-section3-box-img'></img>
                                    <div className='main-section3-box-1-1-txt'>
                                        <p className='main-section3-box-1-1-txt1'>BINANCE</p>
                                        <p className='main-section3-box-1-1-txt2'>Industry-leading trading volume and liquidity provided<br />Various trading pairs<br />Up to 40% trade fee benefit</p>
                                    </div>
                                </div>
                            </div>
                            <div className='main-section2-box-1'>
                                <div className='main-section2-box-1-1'>
                                    <img src='/img/im_pt_api_img2_dark.svg' className='main-section3-box-img'></img>
                                    <div className='main-section3-box-1-1-txt'>
                                        <p className='main-section3-box-1-1-txt1'>BYBIT</p>
                                        <p className='main-section3-box-1-1-txt2'>Solid security and reliable trading system, Built databases for numerous users, BYBIT unique user-friendly customer service<br />Up to 30% trade fee benefit</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='main-section2-flex-2'>
                            <div className='main-section2-box-1'>
                                <div className='main-section2-box-1-1'>
                                    <img src='/img/im_pt_api_img3_dark.svg' className='main-section3-box-img'></img>
                                    <div className='main-section3-box-1-1-txt'>
                                        <p className='main-section3-box-1-1-txt1'>Social Trading</p>
                                        <p className='main-section3-box-1-1-txt2'>Sharing skills and information from experienced traders , Cryptocurrency Information Community for General/New users, Join users all over the world.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='main-section2-box-1'>
                                <div className='main-section2-box-1-1'>
                                    <img src='/img/im_pt_api_img4.svg' className='main-section3-box-img'></img>
                                    <div className='main-section3-box-1-1-txt'>
                                        <p className='main-section3-box-1-1-txt1'>Portal Trading Platform</p>
                                        <p className='main-section3-box-1-1-txt2'>What will be the next platform?<br />API Linkage with validated exchanges<br />You can experience the new exchange with the same interface.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={`main-hr ${darkname}`} />
            <div className='main-section4'>
                <div className='main-section4-box'>
                    <p className='main-section2-txt'>Why GOEX?</p>
                    <p className='main-sextion2-txt1'>Provides a variety of information through easy, convenient functions and communities.</p>
                    <div className='main-section4-box-1'>
                        <div className='main-section4-box-1-1'>
                            <div className='main-section4-box-1-2'>
                                <svg viewBox="0 0 343.9 344" className="im_pt_choice_box_desc_ico">
                                    <path className="st0" d="M0.2,112.2c114.7,0,229.1,0,343.7,0c0,43.8,0,87.3,0,131.3c-1.1-0.5-1.9-0.9-2.8-1.4
                                    c-26.1-15.1-52.2-30.2-78.4-45.2c-18.2-10.4-40.3-3.8-49.7,14.5c-4.6,8.9-4.2,18.2-2.5,27.7c5.1,28,10,56,14.8,84
                                    c1.3,7.7,4.5,14.4,10.3,20.5c-1.6,0.1-2.6,0.3-3.6,0.3c-59.6,0-119.2,0.1-178.8,0c-30,0-52.9-22.2-53-51.9
                                    c-0.2-59.5-0.1-119-0.1-178.5C0.1,113.2,0.1,112.8,0.2,112.2z"></path>
                                    <path className="st0" d="M343.7,87.8c-114.4,0-228.8,0-343.6,0c0-10.5-0.2-21,0.1-31.4C0.3,50.6,0.5,44.6,1.8,39
                                    C7.1,16.8,27.2,0.7,50.1,0.2c14.5-0.3,29-0.1,43.5-0.1c65.7,0,131.4,0,197.2,0c25.6,0,46,15.9,51.9,40.2c0.4,1.8,1,3.7,1,5.5
                                    C343.7,59.8,343.7,73.7,343.7,87.8z M132.1,48.1c0,6.5,5.4,12,11.9,12c6.4,0,12-5.5,12.1-11.9c0.1-6.6-5.5-12.1-12.2-12
                                    C137.2,36.2,132.1,41.4,132.1,48.1z M103.9,36.1c-6.6,0-11.9,5.4-12,11.9c0,6.4,5.5,12,11.9,12.1c6.6,0.1,12.1-5.5,12-12.2
                                    C115.9,41.2,110.6,36.1,103.9,36.1z M76,48.1c0-6.5-5.2-11.9-11.7-12C57.5,36,52,41.2,52,47.9c0,6.6,5.6,12.2,12.1,12.1
                                    C70.6,60,75.9,54.5,76,48.1z"></path>
                                    <path className="st1" d="M309.3,299c6.2,8.8,12.2,17.4,18.1,26c3.2,4.7,2.9,10.4-0.5,14.6c-3.2,4-9.2,5.6-13.9,3.3
                                    c-2.2-1.1-4.3-3-5.8-5.1c-6-8.2-11.6-16.5-17.7-25.1c-4.8,6.2-9.3,12.3-14.1,18.1c-6.1,7.4-16.2,9.1-24.4,4.5
                                    c-5.6-3.1-8.8-8-9.9-14.2c-5.3-29.8-10.6-59.7-15.7-89.6c-1.4-8.2,1.1-15.2,8.1-20c6.8-4.7,14-4.8,21.2-0.7
                                    c26.5,15.2,52.9,30.5,79.4,45.8c7.4,4.3,10.7,11.1,9.7,19.5c-1,8-5.7,13.4-13.3,16C323.5,294.6,316.5,296.7,309.3,299z"></path>
                                </svg>
                                <div className='main-section4-box-1-3'>
                                    <p className='main-section4-box-1-3-txt'>API Portal Trading Service</p>
                                    <div className='main-section4-box-1-3-txt1'>
                                        <p style={{ color: "#F5AC1D" }}>API Linkage technology that connects everything</p>
                                        <p style={{ opacity: ".5" }}>GOEX provides portal trading services by using OPEN API keys to integrate with various exchanges. You can use our services without visiting each exchange. Experience various services and trading tools with GOEX.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='main-section4-box-1-2-1'>
                                <svg viewBox="0 0 626.9 624.3" className="im_pt_choice_box_desc_ico">
                                    <path className="st1" d="M626.9,175c-5.6,16.4-12.2,32.3-23,46.1c-4.1,5.3-8.6,10.3-13,15.7c-9.1-5.2-17.7-10.2-25.2-14.5
                                    c-11.6,6.2-22.5,11.9-33.2,18c-1.5,0.8-2.6,3.4-2.7,5.2c-0.3,8.6-0.1,17.1-0.1,25.6c-16.4,7.3-51.1,7.5-72.9,0.3
                                    c0-8.5,0.2-17.2-0.1-25.9c-0.1-1.9-1.6-4.4-3.2-5.3c-10.4-5.9-21-11.5-32.7-17.8c-7.1,4.1-15.9,9.1-24.8,14.2
                                    c-17.8-17.8-29.8-38.7-36.3-63c7.8-4.5,15.5-8.7,22.9-13.4c1.5-1,2.7-3.7,2.7-5.6c0.3-10.8-0.2-21.5,0.3-32.3
                                    c0.1-3.3-0.8-4.7-3.4-6.1c-7.4-4.1-14.8-8.5-22.5-12.9c6.5-24.4,18.5-45.2,36.3-63.1c7.7,4.4,15.2,8.9,22.8,13
                                    c1.5,0.8,4.3,1.1,5.7,0.2c10.5-6.2,20.7-12.7,32.1-19.7c0-8.2,0-18.1,0-28.4c24.5-7.1,48.6-6.9,73.1-0.2c0,8.9-0.2,17.5,0.2,26
                                    c0.1,2,1.9,4.6,3.7,5.7c9.1,5.6,18.5,11,27.9,16.2c1.7,0.9,4.5,1.2,6.1,0.3c7.7-4.1,15.2-8.6,22.9-13.1
                                    c15.9,15.8,27.1,34.2,34,55.2c0.7,2.1,1.6,4.1,2.4,6.2c0,0.8,0,1.6,0,2.4c-8.1,4.5-16.3,8.8-24.3,13.5c-1,0.6-1.3,3-1.3,4.6
                                    c-0.1,10.7,0.1,21.5-0.2,32.2c-0.1,3.1,0.5,4.7,3.3,6.2c7.6,4.2,15.1,8.8,22.6,13.2C626.9,174.2,626.9,174.6,626.9,175z
                                        M544,138.1c-0.2-28.2-23.1-50.9-51.1-50.8c-28,0.2-50.9,23.3-50.8,51.3c0.1,27.9,23.5,51,51.3,50.8
                                    C521.5,189.2,544.2,166.2,544,138.1z"></path>
                                    <path className="st0" d="M36.6,274.3c11.9,4.6,23.3,8.9,34.1,13c10.6-11.2,21-22.1,31.7-33.4c-4.7-10.5-9.6-21.6-14.7-33.1
                                    c27.8-21,58.6-34.8,93-41.7c4.6,10.3,9.3,20.2,13.5,30.3c1.3,3.1,2.8,3.7,5.9,3.5c12.2-0.6,24.4-1.1,36.6-1c3.8,0,5.3-1.2,6.5-4.6
                                    c3.6-9.9,7.5-19.8,11.5-30.3c34.5,4.8,65.9,17,95.2,36.5c-4.4,11.6-8.8,23.1-12.9,33.9c10.4,10.3,20.4,20.3,30.7,30.1
                                    c1.1,1,4.1,1,5.7,0.4c10.1-4.2,20.1-8.7,30.4-13.3c20.9,27.9,34.8,58.7,41.4,93.1c-10.1,4.5-19.8,9-29.7,13.2
                                    c-3.3,1.4-4.4,3-4.2,6.7c0.7,12.2,1,24.4,1.1,36.6c0,3.2,0.8,4.6,3.8,5.7c10.3,3.7,20.5,7.8,31,11.8
                                    c-4.8,34.6-16.9,65.9-36.5,95.1c-10.1-3.8-20.1-7.5-30-11.6c-3.5-1.4-5.5-1.2-8.2,1.9c-8.8,10.1-18,19.9-27.5,30.2
                                    c4.1,9.3,8.7,19.2,12.8,29.4c0.6,1.5-0.2,4.7-1.4,5.7c-22.7,18-60.3,34.4-89.9,39.3c-4.3-9.6-8.8-19.3-13-29.1
                                    c-1.4-3.4-2.9-4.6-7.1-4.4c-13.2,0.6-26.4,0.2-40.2,0.2c-4.7,12-9.2,23.7-13.9,35.6c-33.5-4.9-64.3-16.3-92.3-34.9
                                    c-1.1-0.7-1.7-3.8-1.2-5.3c3.3-9.4,7.1-18.6,10.4-28c0.5-1.5-0.2-4.3-1.4-5.4c-10-9.5-20.3-18.8-31.2-28.6
                                    c-10.3,4.5-21.5,9.5-33.2,14.7C22.5,508.8,8.7,478,2,443.6c11.5-5.2,22.8-10.2,32.8-14.7c0-14.9,0.1-29-0.2-43
                                    c0-1.5-2-3.7-3.6-4.4c-10.3-4.2-20.7-8.1-31.1-12.1C5.1,334.7,16.9,303.2,36.6,274.3z M223.5,502.6
                                    c55.6,0.3,101.8-45.5,102.1-101.2c0.3-56.4-45.1-102.5-101-102.8c-56.7-0.3-102.7,45-103.1,101.4
                                    C121.2,456.3,166.9,502.3,223.5,502.6z"></path>
                                </svg>
                                <div className='main-section4-box-1-3'>
                                    <p className='main-section4-box-1-3-txt'>Trading Tool</p>
                                    <div className='main-section4-box-1-3-txt1'>
                                        <p style={{ color: "#F5AC1D" }}>Provides various easy trading tools</p>
                                        <p style={{ opacity: ".5" }}>GOEX offers a wide range of trading tools adequate from beginners to professionals.<br />
                                            [Representative function : Auto Price] Scalping and day-trading also available for beginners, 50% off commission effect! It can only be experienced on the GOEX</p>
                                    </div>
                                </div>
                            </div>
                            <div className='main-section4-box-1-2-1'>
                                <svg viewBox="0 0 512 512" className="im_pt_choice_box_desc_ico">
                                    <path className="st0" d="M309,0c57,0,114,0,171,0c9.7,2.4,18.7,6.3,24.3,15c3.3,5.2,5.2,11.3,7.7,17c0,78.3,0,156.7,0,235
                                    c-0.3,0.6-0.7,1.1-0.8,1.7c-4.3,18.2-18.4,29.9-37.3,30c-52.8,0.2-105.6,0.2-158.4,0c-22.1-0.1-38.2-16.1-38.2-38.2
                                    c-0.2-74.1-0.1-148.2,0-222.4c0-11.6,4-21.9,13.3-28.9C296,5.2,302.8,3,309,0z"></path>
                                    <path className="st1" d="M203,0c6.3,3.1,13.3,5.3,18.8,9.5c9.1,6.9,12.9,17.2,12.9,28.6c0.1,31.5,0.2,63,0,94.5
                                    c-0.2,22.1-16.2,38.1-38.3,38.1c-52.6,0.2-105.3,0.2-157.9,0C15.8,170.6,0.1,154.3,0,131.6c0-30.8,0-61.6,0-92.5
                                    c0-19.7,10.7-33.3,29.9-38.3C30.3,0.7,30.6,0.3,31,0C88.4,0,145.7,0,203,0z"></path>
                                    <path className="st0" d="M512,480c-3.1,6.4-5.3,13.4-9.4,18.9c-6.5,8.7-16.1,12.9-26.9,13c-54,0.1-107.9,0.2-161.9,0
                                    c-20.1-0.1-36.1-16.2-36.4-36.9c-0.4-32.1-0.4-64.3,0-96.4c0.3-21.1,16-37,37.2-37.2c53.5-0.3,106.9-0.3,160.4,0
                                    c18.4,0.1,32.1,12,36.2,29.9c0.1,0.6,0.5,1.2,0.8,1.7C512,408.7,512,444.3,512,480z"></path>
                                    <path className="st0" d="M0,362.3c0-36.3,0-72.7,0-109c0-24.3,15.7-40.1,39.9-40.1c51.7,0,103.3,0,155,0c23.9,0,39.8,15.8,39.8,39.7
                                    c0,73.2,0.1,146.3,0,219.5c0,23.6-15.9,39.5-39.5,39.5c-52,0-104,0-156,0C16,512,0.1,496.1,0,472.8C0,436,0,399.2,0,362.3z"></path>
                                </svg>
                                <div className='main-section4-box-1-3'>
                                    <p className='main-section4-box-1-3-txt'>Same Interface</p>
                                    <div className='main-section4-box-1-3-txt1'>
                                        <p style={{ color: "#F5AC1D" }}>Accessibility and ease of use with familiar interfaces</p>
                                        <p style={{ opacity: ".5" }}>GOEX applies easy-to-use and optimized trading tools uniformly to multiple exchanges to enhance user accessibility and convenience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='main-section4-box-1-2-1'>
                                <svg viewBox="0 0 364.1 363.9" className="im_pt_choice_box_desc_ico">
                                    <path className="st0" d="M0,135c0-5.5,0-10.9,0-16.4c0.2-0.9,0.4-1.8,0.6-2.7c1-6.5,1.6-13.2,3.1-19.6C11,66.6,27,42.5,51.6,24.4
                                    c20-14.8,42.6-22.8,67.4-24.2c24.1-1.4,46.8,3.8,67.9,15.5c24.2,13.5,42.3,32.9,53.7,58.2c13.2,29.4,14.9,59.7,5.3,90.3
                                    c-8.3,26.3-23.8,47.7-46.3,63.8c-27.7,19.9-58.7,27.1-92.4,22.5c-17.9-2.5-34.5-8.9-49.6-18.8c-1.9-1.3-3.5-1.4-5.6-0.6
                                    c-8.1,2.8-16.3,5.5-24.5,8.2c-7.2,2.4-14.4,4.8-22,7.3c0.3-1.1,0.5-1.7,0.7-2.2c4.9-14.7,9.7-29.4,14.7-44
                                    c0.9-2.5,0.6-4.3-0.8-6.5c-8.6-13.2-14.5-27.6-17.4-43.1C1.6,145.4,0.9,140.2,0,135z"></path>
                                    <path className="st1" d="M228.9,363.9c-6.6-1.1-13.3-2-19.8-3.5c-27.9-6.6-50.9-21.2-69-43.3c-10-12.2-17.3-25.9-22.2-41.4
                                    c44.4,1.7,82.8-12.7,114-43.9c31.2-31.2,45.5-69.5,43.9-114c9.9,2.9,19,7.1,27.5,12.4c29,18.1,48.4,43.6,56.7,76.7
                                    c8.6,34.2,3.5,66.7-14.9,96.9c-2.2,3.6-2.8,6.5-1.3,10.7c5.1,14.3,9.7,28.8,14.7,43.9c-1.7-0.5-2.9-0.8-4-1.2
                                    c-14.3-4.8-28.6-9.6-43-14.3c-1.3-0.4-3.4-0.3-4.5,0.4c-17.7,11.4-37,18.2-58,20c-0.6,0-1.1,0.4-1.6,0.6
                                    C241.3,363.9,235.1,363.9,228.9,363.9z"></path>
                                </svg>
                                <div className='main-section4-box-1-3'>
                                    <p className='main-section4-box-1-3-txt'>Community & Cryptocurrency Information</p>
                                    <div className='main-section4-box-1-3-txt1'>
                                        <p style={{ color: "#F5AC1D" }}>Diverse information community for users</p>
                                        <p style={{ opacity: ".5" }}>Provides the latest cryptocurrency issues and information for a better communication channel for users, and furthermore, a community for social trading</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='main-section4-box-2'>
                            <img src='/img/video/main_capture.jpg' style={{ width: "88%" }}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-section5'>
                <div className='main-section5-box'>
                    <p className='main-section1-box-1'>How can I help you?</p>
                    <p className='main-section1-box-2'>Check the items you need help with.</p>
                    <div className='main-section5-box-1'>
                        <div className={`main-section5-box-1-1 ${darkname}`}>
                            <div className='main-section5-box-1-1-ico'>
                                <img src='/img/notice.svg'></img>
                            </div>
                            <div className='main-section5-box-1-1-txt'>
                                <div className='main-section5-box-1-1-txt1'>Notice</div>
                                <div className='main-section5-box-1-1-txt2'>You can check the announcement.</div>
                                <Link className='main-section5-box-1-1-a' onClick={() => alert("Coming soon.")}>LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                            </div>
                        </div>
                        <div className={`main-section5-box-1-1 ${darkname}`}>
                            <div className='main-section5-box-1-1-ico'>
                                <img src='/img/faq.svg'></img>
                            </div>
                            <div className='main-section5-box-1-1-txt'>
                                <div className='main-section5-box-1-1-txt1'>FAQ</div>
                                <div className='main-section5-box-1-1-txt2'>You can check out various questions</div>
                                <Link className='main-section5-box-1-1-a' onClick={() => alert("Coming soon.")}>LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                            </div>
                        </div>
                        <div className={`main-section5-box-1-1 ${darkname}`}>
                            <div className='main-section5-box-1-1-ico'>
                                <img src='/img/tutorial.svg'></img>
                            </div>
                            <div className='main-section5-box-1-1-txt'>
                                <div className='main-section5-box-1-1-txt1'>Tutorial</div>
                                <div className='main-section5-box-1-1-txt2'>Manual for those new to trading</div>
                                <Link className='main-section5-box-1-1-a' onClick={() => alert("Coming soon.")}>LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-section6'>
                <div className='main-section6-box'>
                    <p className='main-section1-box-1'>Partnership</p>
                    <p className='main-section1-box-2'>GOEX partners with top companies.</p>
                    <div className='main-section6-box-1'>
                        <div className='main-section6-box-1-1'>
                            {
                                dark
                                    ?
                                    <>
                                        <a href='https://www.binance.com/en' target='_black'><img src='/img/binance_logo.svg'></img></a>
                                        <a href='https://www.okx.com/' target='_black'><img src='/img/okx_logo.svg'></img></a>
                                        <a href='https://www.bybit.com/en-US/' target='_black'><img src='/img/bybit_logo.svg'></img></a>
                                    </>
                                    :
                                    <>
                                        <a href='https://www.binance.com/en' target='_black'><img src='/img/binance.png'></img></a>
                                        <a href='https://www.okx.com/' target='_black'><img src='/img/OKX.jpg'></img></a>
                                        <a href='https://www.bybit.com/en-US/' target='_black'><img src='/img/bybit.png'></img></a>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Main;