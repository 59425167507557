import { parseFullSymbol } from "./helpers";

export const OKX_RESOLUSION = {
  1: "1m",
  3: "3m",
  5: "5m",
  15: "15m",
  30: "30m",
  60: "1H",
  120: "2H",
  240: "4H",
  360: "6H",
  720: "12H",
  "1D": "1D",
  "2D": "2D",
  "3D": "3D",
  "1W": "1W",
  "1M": "1M",
};

let preChannel: any;
let preInstId: any;

let preChannelString:any;
let preSubscriptionItem:any;

let nextChannel: any;

export default class SocketClient {
  socket!: WebSocket;
  channelToSubscription!: Map<string, any>;

  constructor() {
    // console.log("[SocketClient] init");
    this._createSocket();
  }

  _createSocket() {
    this.socket = new WebSocket("wss://wsaws.okx.com:8443/ws/v5/business");

    this.channelToSubscription = new Map();

    this.socket.addEventListener("open", () => {
      console.log("[socket] Connected");
    });

    this.socket.addEventListener("close", (reason: any) => {
      console.log("[socket] Disconnected:", reason);
      // window.location.reload();
      this._createSocket();
      setTimeout(()=> {
        this.emit("subscribe", [
          {
            channel: preChannel,
            instId: preInstId,
          },
        ]);
      }, 3000);
    });

    this.socket.addEventListener("error", (error: any) => {
      console.log("[socket] Error:", error);
      window.location.reload();
    });

    this.socket.addEventListener("message", ({ data }) => {
      // console.log("[socket] Message:", data);
      const { channel, instId } = JSON.parse(data).arg;
      if(channel !== nextChannel.channel || instId !== nextChannel.instId) {
        return;
      }
      const { data: kline } = JSON.parse(data);

      if (!kline) {
        // Skip all non-trading events
        return;
      }
      const tradePrice = parseFloat(kline[0][4]);
      const tradeTime = parseInt(kline[0][0]) + 1;
      const tradeVolume = parseFloat(kline[0][5]);
      const channelString = `${channel}~${instId}`;
      this.channelToSubscription.set(preChannelString, preSubscriptionItem);
      const subscriptionItem = this.channelToSubscription.get(channelString);
      if (subscriptionItem === undefined) {
        return;
      }

      // console.log(subscriptionItem);
      const lastDailyBar = subscriptionItem.lastDailyBar;
      if (subscriptionItem.lastDailyBar == null) {
        return;
      }
      let nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 1);
      switch (subscriptionItem?.resolution) {
        case "1":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 1);
          break;
        case "3":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 3);
          break;
        case "5":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 5);
          break;
        case "15":
          nextDailyBarTime = this.getNextMinuteBarTime(lastDailyBar.time, 15);
          break;
        case "60":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 1);
          break;
        case "120":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 2);
          break;
        case "240":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 4);
          break;
        case "360":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 6);
          break;
        case "720":
          nextDailyBarTime = this.getNextHourBarTime(lastDailyBar.time, 12);
          break;
        case "1D":
          nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 1);
          break;
        case "3D":
          nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 3);
          break;
        case "1W":
          nextDailyBarTime = this.getNextDailyBarTime(lastDailyBar.time, 7);
          break;
        case "1M":
          nextDailyBarTime = this.getNextMonthBarTime(lastDailyBar.time, 1);
          break;
      }

      let bar: {
        time: number;
        open: number;
        high: number;
        low: number;
        close: number;
        volume: number;
      };
      // console.log(tradeTime > nextDailyBarTime);
      if (tradeTime > nextDailyBarTime) {
        bar = {
          time: nextDailyBarTime,
          open: parseFloat(kline[0][1]),
          high: parseFloat(kline[0][2]),
          low: parseFloat(kline[0][3]),
          close: tradePrice,
          volume: tradeVolume,
        };
        // console.log("[socket] Generate new bar", bar);
      } else {
        bar = {
          ...lastDailyBar,
          high: Math.max(lastDailyBar.high, parseFloat(kline[0][2])),
          low: Math.min(lastDailyBar.low, parseFloat(kline[0][3])),
          close: tradePrice,
          volume: tradeVolume,
        };
        // console.log("[socket] Update the latest bar by", bar);
      }

      subscriptionItem.lastDailyBar = bar;

      // Send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler: { callback: (arg0: any) => any }) => handler.callback(bar));
    });
  }

  public subscribeOnStream(symbolInfo: TradingView.LibrarySymbolInfo, resolution: TradingView.ResolutionString, onRealtimeCallback: TradingView.SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void, lastDailyBar: TradingView.Bar | undefined) {
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    if (parsedSymbol) {
      const channelString = `candle${OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]}~${parsedSymbol.instId}`;
      const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
      };
      let subscriptionItem = this.channelToSubscription.get(channelString);
      if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
      }
      subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
      };
      this.channelToSubscription.set(channelString, subscriptionItem);
      // console.log(channelString, subscriptionItem);
      // console.log(
      //   "[subscribeBars]: Subscribe to streaming. Channel:",
      //   channelString
      // );

      preSubscriptionItem = subscriptionItem;
      preChannelString = channelString;

      preChannel = `candle${OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]}`;
      preInstId = parsedSymbol.instId;

      this.emit("subscribe", [
        {
          channel: `candle${OKX_RESOLUSION[resolution as keyof typeof OKX_RESOLUSION]}`,
          instId: parsedSymbol.instId,
        },
      ]);
    }
  }

  public unsubscribeFromStream(subscriberUID: string) {
    for (const channelString of this.channelToSubscription.keys()) {
      const subscriptionItem = this.channelToSubscription.get(channelString);
      const handlerIndex = subscriptionItem.handlers.findIndex((handler: { id: string }) => handler.id === subscriberUID);

      if (handlerIndex !== -1) {
        // Remove from handlers
        subscriptionItem.handlers.splice(handlerIndex, 1);

        if (subscriptionItem.handlers.length === 0) {
          // Unsubscribe from the channel if it is the last handler
          // console.log(
          //   "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
          //   channelString
          // );
          const [channel, instId] = channelString.split("~");
          this.emit("unsubscribe", [
            {
              channel,
              instId,
            },
          ]);

          this.channelToSubscription.delete(channelString);
          break;
        }
      }
    }
  }

  emit(method: string, args: any) {
    if (this.socket.readyState !== WebSocket.OPEN) {
      // console.log("[socket] Socket is not open, cannot subscribe");
      return;
    } else {
      nextChannel = args[0];
      this.socket.send(
        JSON.stringify({
          op: method,
          args: args,
        })
      );
    }
  }

  close(){
    // this.socket.close();
  }

  private getNextMinuteBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setMinutes(date.getMinutes() + plus);
    return date.getTime();
  }

  private getNextHourBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setHours(date.getHours() + plus);
    return date.getTime();
  }

  private getNextDailyBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setDate(date.getDate() + plus);
    return date.getTime();
  }

  private getNextMonthBarTime(barTime: number, plus: number) {
    const date = new Date(barTime);
    date.setMonth(date.getMonth() + plus);
    return date.getTime();
  }
}
