import './css/login.css'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCheck, faCircleCheck, faEyeSlash, faEye, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, check_Error } from '../store';
import Country from './country'
import { useTranslation } from "react-i18next";
import Modal from './message/modal';

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Regist() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();
    const location = useLocation();

    const [btn, setBtn] = useState(0)
    const [btn1, setBtn1] = useState(false)
    const [checked, setChecked] = useState(true);
    const [eye, setEye] = useState(false);

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tel, setTel] = useState('')
    const [password, setPassword] = useState("")
    const [passwordcheck, setPasswordcheck] = useState("")
    const [name, setName] = useState('')
    const [ref, setRef] = useState('')

    const [checkemail, setCheckEmail] = useState(false);
    const [checktel, setCheckTel] = useState(false)
    const [checkpassword, setCheckPassword] = useState(false)
    const [checkpasswordcheck, setCheckPasswordcheck] = useState(false)
    const [checkname, setCheckname] = useState(false)
    const [passwordOK, setPasswordOK] = useState(false);
    const [nameOK, setNameOK] = useState(false);

    const [minCharacters, setMinCharacters] = useState(false);
    const [leastLetter, setLeastLetter] = useState(false);
    const [upperLetter, setUpperLetter] = useState(false);
    const [leastNumber, setleastNumber] = useState(false);
    const [specialCharacter, setspecialCharacter] = useState(false);

    const [nameCharacters, setNameCharacters] = useState(false);
    const [namespecialCharacter, setNamespecialCharacter] = useState(false);
    const [message, setMessage] = useState([])

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const passwordRegEx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?'":{}|<>`;/])[A-Za-z\d!@#$%^&*(),.?'":{}|<>`;/]{6,15}$/;
    const nameRegEx = /^[a-zA-Z0-9]{2,10}$/;
    const regex = /[!@#$%^&*(),.?'":{}|<>`;/]/;
    const regex1 = /[A-Z]/;
    const regex2 = /^[0-9]+$/;
    const regex3 = /[a-z]/;
    const regex4 = /[0-9]/;
    const regex5 = /^[1-9][0-9]{8,10}$/;

    useEffect(() => {
        if (location.state) {
            setBtn(location.state.type)
            if (location.state.type === 0) {
                setEmail(location.state.id)
                emailCheck(location.state.id)
            }
            else {
                let onlyNumber = location.state.id;
                if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
                    onlyNumber = onlyNumber.substring(1);
                }
                setTel(onlyNumber);
                telCheck(onlyNumber);
            }
        }
    }, [location])

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
        emailCheck(onlyNumber)
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const onChangeTel = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setTel(onlyNumber);
        telCheck(onlyNumber);
    }

    const onChangePassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, "")
        setPassword(onlyNumber)

        if (onlyNumber && onlyNumber.length >= 6 && onlyNumber.length <= 15) setMinCharacters(true)
        else setMinCharacters(false)

        if (regex3.test(onlyNumber)) setLeastLetter(true)
        else setLeastLetter(false)

        if (regex1.test(onlyNumber)) setUpperLetter(true)
        else setUpperLetter(false)

        if (regex4.test(onlyNumber)) setleastNumber(true)
        else setleastNumber(false)

        if (regex.test(onlyNumber)) setspecialCharacter(true)
        else setspecialCharacter(false)

        passwordCheck(onlyNumber)

        if (onlyNumber && onlyNumber === passwordcheck && onlyNumber.length > 0) setCheckPasswordcheck(true)
        else setCheckPasswordcheck(false)
    }

    const onChangePasswordCheck = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, "")
        setPasswordcheck(onlyNumber)

        if (onlyNumber && onlyNumber === password && onlyNumber.length > 0) setCheckPasswordcheck(true)
        else setCheckPasswordcheck(false)
    }

    const onChangeName = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setName(onlyNumber)

        if (onlyNumber && onlyNumber.length >= 2 && onlyNumber.length <= 10) setNameCharacters(true)
        else setNameCharacters(false)

        if (regex.test(onlyNumber)) setNamespecialCharacter(false)
        else setNamespecialCharacter(true)

        if (onlyNumber == "") setNamespecialCharacter(false)

        nameCheck(onlyNumber)
    }

    const onChangeRef = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setRef(onlyNumber)
    }

    const emailCheck = (username) => {
        if (emailRegEx.test(username)) setCheckEmail(true)
        else setCheckEmail(false);
    }

    const telCheck = (username) => {
        if (regex5.test(username)) setCheckTel(true)
        else setCheckTel(false);
    }

    const passwordCheck = (username) => {
        if (passwordRegEx.test(username)) setCheckPassword(true)
        else setCheckPassword(false)
    }

    const nameCheck = (username) => {
        if (nameRegEx.test(username)) setCheckname(true)
        else setCheckname(false)
    }

    const passwordMouseEnter = () => {
        setPasswordOK(true);
    };

    const passwordMouseLeave = () => {
        setPasswordOK(false);
    };

    const nameMouseEnter = () => {
        setNameOK(true);
    };

    const nameMouseLeave = () => {
        setNameOK(false);
    };

    const navigate = useNavigate();

    // 회원가입 API
    const onSubnitCheck = () => {
        if (btn === 0) {
            if (!checkemail) {
                setMessage({ type: "binance", code: -1, msg: t('message_25') })
                return
            }
        }
        else if (btn === 1) {
            if (!checktel) {
                setMessage({ type: "binance", code: -1, msg: t('message_24') })
                return
            }
        }
        if (!checkpassword) setMessage({ type: "binance", code: -1, msg: t('message_23') })
        else if (!checkpasswordcheck) setMessage({ type: "binance", code: -1, msg: t('message_23') })
        else if (!checkname) setMessage({ type: "binance", code: -1, msg: t('message_22') })
        else if (!checked) setMessage({ type: "binance", code: -1, msg: t('message_28') })
        else onSubmit()
    }

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/regist", {
            type: btn === 0 ? "email" : "mobile",
            email: btn === 0 ? email : "",
            mobile: btn === 0 ? "" : tel,
            countryCode: btn === 0 ? "" : phone,
            nickname: name,
            password: password,
            referral: ref,
        }).then((response) => {
            if (response?.data?.code > 0) {
                navigate('/otp', {
                    state: {
                        page: 0,
                        id: btn === 0 ? email : phone + tel,
                        userId: response.data.data.userId,
                        type: btn === 0 ? "email" : "mobile",
                        otp: false,
                        loginCheck: false
                    }
                })
            }
            else {
                setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 19, "msg": error }))
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
                navigate('/')
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const refParam = searchParams.get('ref');

        if (refParam) {
            setRef(refParam);
            setBtn1(true)
        }
    }, [location.search]);

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, [API_Address]);

    return (
        <div className={`regist ${darkname}`}>
            {
                API_Address == "dev"
                    ?
                    <Modal message={message} />
                    :
                    ""
            }
            <div className='regist-box'>
                <div className={`regist-box-left ${darkname}`}>
                    <h4 className='regist-box-left-title' style={{ color: "inherit" }}>{t('regist_0')}</h4>
                    <ul className='regist-box-left-ul'>
                        <li className={btn === 0 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(0)}>{t('regist_1')}</li>
                        <li className={btn === 1 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(1)}>{t('regist_2')}</li>
                    </ul>
                    <div className='regist-box-left-form'>
                        <div className='regist-box-left-form-1' style={{ height: "110px" }}>
                            {
                                btn === 0
                                    ?
                                    <div className='regist-box-left-form-1-1'>
                                        <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                            {
                                                checkemail
                                                    ?
                                                    <div className='regist-box-left-form-1-ico-1'></div>
                                                    :
                                                    <div className='regist-box-left-form-1-ico'></div>
                                            }
                                            <input className='regist-box-left-form-1-input' type='text' value={email} onChange={onChangeEmail} placeholder={`${t('regist_3')}`} />
                                        </div>
                                        {
                                            email && email.length != 0
                                                ?
                                                checkemail
                                                    ?
                                                    ""
                                                    :
                                                    <p className='regist-box-left-form-1-p'>
                                                        {t('regist_4')}<br />
                                                        {t('regist_4_1')}
                                                    </p>
                                                :
                                                ""
                                        }
                                    </div>
                                    :
                                    <div className='regist-box-left-form-1-1'>
                                        <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                            {
                                                checktel
                                                    ?
                                                    <div className='regist-box-left-form-1-ico-1'></div>
                                                    :
                                                    <div className='regist-box-left-form-1-ico'></div>
                                            }
                                            <div className='regist-box-left-form-country'>
                                                <input type="search" className='regist-box-left-form-country-input' placeholder='Select' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                                <datalist id="list">
                                                    {
                                                        Country.map(function (a, i) {
                                                            return (
                                                                <option value={a.value}>{a.name} ({a.value})</option>
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                            </div>
                                            <input className='regist-box-left-form-1-input' type='text' value={tel} onChange={onChangeTel} placeholder={`${t('regist_5')}`} />
                                        </div>
                                        {
                                            tel && tel.length != 0
                                                ?
                                                checktel
                                                    ?
                                                    ""
                                                    :
                                                    <p className='regist-box-left-form-1-p'>
                                                        {t('regist_6')}<br />
                                                        {t('regist_6_1')}
                                                    </p>
                                                :
                                                ""
                                        }
                                    </div>
                            }

                        </div>
                        <div className='regist-box-left-form-1-1'>{t('regist_7')}</div>
                        <div className='regist-box-left-form-1-1' onMouseEnter={passwordMouseEnter} onMouseLeave={passwordMouseLeave}>
                            <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                {
                                    checkpassword
                                        ?
                                        <div className='regist-box-left-form-1-ico-1'></div>
                                        :
                                        <div className='regist-box-left-form-1-ico'></div>
                                }
                                <input className='regist-box-left-form-1-input' type={eye ? 'text' : 'password'} value={password} onChange={onChangePassword} placeholder={`${t('regist_7')}`} autoComplete='new-password' />
                                {
                                    passwordOK
                                        ?
                                        <div className={`signup_password_modal ${darkname}`}>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={minCharacters ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_8')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={leastNumber ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_9')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={leastLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_10')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={upperLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_11')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={specialCharacter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">
                                                    {t('regist_12')}<br />
                                                    (!@#$%^&*())
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    eye
                                        ?
                                        <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                        :
                                        <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                }
                            </div>
                        </div>
                        <div className='regist-box-left-form-1-1'>{t('regist_13')}</div>
                        <div className='regist-box-left-form-1-1'>
                            <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                {
                                    checkpasswordcheck
                                        ?
                                        <div className='regist-box-left-form-1-ico-1'></div>
                                        :
                                        <div className='regist-box-left-form-1-ico'></div>
                                }
                                <input className='regist-box-left-form-1-input' type={eye ? 'text' : 'password'} value={passwordcheck} onChange={onChangePasswordCheck} placeholder={`${t('regist_13')}`} />
                                {
                                    checkpasswordcheck
                                        ?
                                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: "16px", color: "green" }} />
                                        :
                                        ""
                                }
                                {
                                    eye
                                        ?
                                        <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                        :
                                        <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                }
                            </div>
                        </div>
                        <div className='regist-box-left-form-1-1'>{t('regist_14')}</div>
                        <div className='regist-box-left-form-1-1' onMouseEnter={nameMouseEnter} onMouseLeave={nameMouseLeave}>
                            <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                {
                                    checkname
                                        ?
                                        <div className='regist-box-left-form-1-ico-1'></div>
                                        :
                                        <div className='regist-box-left-form-1-ico'></div>
                                }
                                <input className='regist-box-left-form-1-input' type='text' value={name} onChange={onChangeName} onKeyDown={handleKeyPress} placeholder={`${t('regist_14')}`} />
                                {
                                    nameOK
                                        ?
                                        <div className={`signup_password_modal ${darkname}`}>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={nameCharacters ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_15')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={namespecialCharacter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_16')}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className='regist-box-left-form-2'>
                        <div className='regist-box-left-form-2-1'>
                            <div className='regist-box-left-form-2-2'>
                                <div className='regist-box-left-form-2-btn' onClick={() => setBtn1(btn1 => !btn1)}>{t('regist_17')} &nbsp;
                                    {
                                        btn1
                                            ?
                                            <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: "12px" }} />

                                            :
                                            <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: "12px" }} />
                                    }

                                </div>
                            </div>
                            {
                                btn1
                                    ?
                                    <div className={`regist-box-left-form-2-3 ${darkname}`}>
                                        <input className='regist-box-left-form-1-input' type='text' placeholder={`${t('regist_18')}`} maxLength={10} onKeyDown={handleKeyPress} value={ref} onChange={onChangeRef} />
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className='regist-box-left-form-3'>
                        <label className='regist-box-left-form-3-label' >
                            <span className={checked ? 'regist-box-left-form-3-span1 checked' : 'regist-box-left-form-3-span1'} onClick={() => setChecked(checked => !checked)}></span>
                            <span className='regist-box-left-form-3-span2'>
                                {t('regist_19')}&nbsp;
                                <a href={`${API_Address == "rf" ? "https://namubit.zendesk.com/hc/en-us/articles/28798542131993" : "https://hb-goex.zendesk.com/hc/ko"}`} target="_cs">{t('regist_20')}</a>
                                &nbsp;{t('regist_21')}&nbsp;
                                <a href={`${API_Address == "rf" ? "https://namubit.zendesk.com/hc/en-us/articles/28798558893465" : "https://hb-goex.zendesk.com/hc/ko"}`} target="_cs">{t('regist_22')}</a>
                            </span>
                        </label>
                    </div>
                    <div className='regist-box-left-form-4' onClick={() => onSubnitCheck()}>{t('regist_0')}</div>
                    <div className='regist-box-left-form-5'>
                        <p>{t('regist_23')}</p>
                        <Link to={`/login`} className='regist-box-left-form-5-span'>{t('login_0')}</Link>
                    </div>
                    <div className={`regist-box-left-form-hr ${darkname}`}></div>
                    <a href='' className='regist-box-left-form-6'>
                        <div className='regist-box-left-form-6-1' onClick={() => alert("Coming Soon")}>
                            <img src='/img/google_simbol.svg'></img>
                            <div className='regist-box-left-form-6-btn'>{t('login_7')}</div>
                        </div>
                    </a>
                </div>
                <div className='regist-box-right'>
                    <div className='regist-box-right-1'>
                        <div className='regist-box-right-1-1'>
                            <p className='regist-box-right-1-p'>{t('login_8')}</p>
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={true}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                className="mySwiper">
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">1</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_9')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_10')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">2</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_11')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_12')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">3</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_13')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {/* <span>{t('login_14')}</span>  */}
                                            {t('login_15')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">4</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_16')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_17')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">5</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_18')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_19')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default Regist;