import "../../css/wallet.css";
import axios from "axios";
import base64 from "base-64";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { check_Error } from "../../../store";
import { UTCTimestamp, padZero } from "../../common/function";

import { C_Deposit } from '../../common/C_wallet/W_wallet';

function OKX_Deposit({ walletData, fastCoin }) {
    const { t } = useTranslation();
    let API_Address = useSelector((state) => { return state.API_Address.value })

    let dispatch = useDispatch();
    const [message, setMessage] = useState([])
    const [buyModal, setBuyModal] = useState(false);

    const [search, setSearch] = useState(fastCoin);
    const [selectSearch, setSelectSearch] = useState(fastCoin);
    const [selectCoin, SetSelectCoin] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [currency, setCurrency] = useState([]);
    const [dhistory, setDhistory] = useState([]);
    const [selectnetwork, setSelectnetwork] = useState([]);
    const [okxuid, setOkxuid] = useState("");
    const [BselectCoinList, SetBSelectCoinList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [dhistoryList, setDhistoryList] = useState([])

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        let arr = selectCoin.filter((item) => item.chain === event.target.value);
        setSelectnetwork(arr);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    let BS_filterSymbol = [];
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (
                p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            );
        });
    }

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositLink(token);
            DepositCurrency(token);
        }
    }, [selectSearch]);

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = JSON.parse(base64.decode(payload));

            setOkxuid(dec.okxUid);
        }

        DepositHistory(0);
    }, []);

    const DepositLink = async (token) => {
        if (selectSearch && selectSearch.length > 0) {
            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-address`, {
                params: {
                    ccy: selectSearch,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let tmp = response.data.data;
                if (tmp) {
                    SetSelectCoin(tmp);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 136, "msg": error }));
            });
        }
    };

    const DepositCurrency = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/currencies`, {
            params: {
                ccy: selectSearch,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data.data;
            if (tmp) {
                setCurrency(tmp);
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 137, "msg": error }));
        });
    };

    const DepositHistory = async (n) => {
        const objString = localStorage.getItem("token");
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-history`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let tmp = response.data;
                if (tmp?.code === "0") {
                    if (n === 1) setMessage({ type: "okx", code: tmp?.code, msg: "Refresh successful" })
                    setDhistory(tmp.data);
                }
                else {
                    setMessage({ type: "okx", code: tmp?.code, msg: "Refresh Failed" })
                }
                if (API_Address == "rf" && n == 1) setBuyModal(true)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 138, "msg": error }));
            });
        }
    };

    useEffect(() => {
        if (selectnetwork && selectnetwork[0]) {
            SetBSelectCoinList({
                address: selectnetwork[0].addr,
                tag: selectnetwork[0].tag
            })
        }
    }, [selectnetwork])

    useEffect(() => {
        if (selectCoin && currency) {
            let arr = []
            // for (let i = 0; i < selectCoin.length; i++) {
            //     arr.push({
            //         chain: selectCoin[i].chain,
            //         chainType: selectCoin[i].chain,
            //         confirmation: selectCoin[i].minDepArrivalConfirm
            //     })
            // }
            setCurrencyList(arr)
        }
    }, [selectCoin, currency])

    useEffect(() => {
        if (dhistory) {
            let arr = []
            for (let i = 0; i < dhistory.length; i++) {
                const date = UTCTimestamp(dhistory[i].ts);
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                arr.push({
                    time: formattedDate,
                    status: dhistory[i].state,
                    txId: dhistory[i].txId,
                    amount: dhistory[i].amt,
                    coin: dhistory[i].ccy,
                    chain: dhistory[i].chain
                })
            }
            setDhistoryList(arr)
        }
    }, [dhistory])

    return (
        <C_Deposit message={message} setMessage={setMessage} buyModal={buyModal} setBuyModal={setBuyModal} search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} setSelectSearch={setSelectSearch} BselectCoin={BselectCoinList} selectedOption={selectedOption} handleSelectChange={handleSelectChange} currency={currencyList} dhistory={dhistoryList} BS_filterSymbol={BS_filterSymbol} okxuid={okxuid} />

    );
}

export default OKX_Deposit;
