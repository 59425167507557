import '../css/spot.css'
import Chart from '../spotchart.js'
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { checkSelectType, checkLogin, check_Error } from '../../store.js';
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary.js"
import isEqual from 'lodash/isEqual';
import { changeNum } from "../common/function.js"

import { W_goex6 } from '../common/C_spot/W_goex6.js';
import '@fortawesome/fontawesome-free/css/all.css';

import GoexByS1 from './goexByS/goexByS1.js';
import GoexByS4 from './goexByS/goexByS4.js';
import GoexByS7 from './goexByS/goexByS7.js';
import GoexByS8 from './goexByS/goexByS8.js';
import GoexByS1_1 from './goexByS/goexByS1_1.js';
import GoexByS8_1 from './goexByS/goexByS8_1.js';
import GoexByS8_2 from './goexByS/goexByS8_2.js';

let data = []
let prePrice = 0
let data_first = 0

let balance_data = []

function GOEXBySpot() {
    const { t } = useTranslation();
    const CryptoJS = require('crypto-js');
    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    let BybitS = useSelector((state) => { return state.Bybit_coinS });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let Bybit_wallet = useSelector((state) => { return state.Bybit_wallet });
    let Bybit_wallet_usdt = useSelector((state) => { return state.Bybit_wallet_usdt });

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab5, setTab5] = useState(0)

    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)

    const [openorder, setOpenorder] = useState([])
    const [balance, setBalance] = useState([])
    const [currentUSDT, setCurrentUSDT] = useState("")
    const [max, setMax] = useState(0)
    const [instrumentsData, setInstrumentData] = useState([])

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.000001)
    const [btnbtn, setBtnbtn] = useState(0);
    const [orderReload, setOrderReload] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
        data_first = 0
    }, [symbol])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let USDTSymbolS = BybitS ? BybitS : [];
    dispatch(checkSelectType(2))

    USDTSymbolS.map(function (a, i) {
        if (a.symbol === symbol) {
            if (data_first === 0) {
                setCurrentPrice(a.lastPrice)
                setMax(a.point)
                data_first = 1
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    useEffect(() => {
        SpotInstruments()
    }, [symbol])

    const SpotInstruments = async () => {
        await axios.get('https://api.bybit.com/v5/market/instruments-info?category=spot'
        ).then((response) => {
            let tmp = response?.data?.result?.list
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) {
                        setInstrumentData(a?.lotSizeFilter)
                    }
                })
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            SpotOpenOrder()
        }
    }, [balance, Bybit_wallet_usdt, By_apiKey])

    const SpotOpenOrder = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}category=spot`
            await axios.get(`https://api.bybit.com/v5/order/realtime?category=spot`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    SpotOpenOrder()
                }
                else if (tmp.retCode == 0) {
                    setOpenorder(tmp?.result?.list ?? [])
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 110, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                SpotOpenOrder()
            }, 1000)
        }
    }

    useEffect(() => {
        if (Bybit_wallet) {
            let bybit_wallet_data = []
            for (let i = 0; i < Bybit_wallet.length; i++) {
                if (Bybit_wallet[i].coin != "USDT") {
                    bybit_wallet_data.push({ "ccy": Bybit_wallet[i].coin, "bal": Bybit_wallet[i].availableToWithdraw })
                }
            }

            let ArraysEqual = isEqual(balance_data, bybit_wallet_data);

            if (!ArraysEqual) {
                setBalance(Bybit_wallet)
                balance_data = bybit_wallet_data
            }
        }
    }, [Bybit_wallet])

    useEffect(() => {
        if (Bybit_wallet_usdt && Bybit_wallet_usdt[0]) {
            setCurrentUSDT(Bybit_wallet_usdt[0])
        }
    }, [Bybit_wallet_usdt])

    // *********************************************************************************************************************
    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)
    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "/USDT" : ""} | Bybit`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "/USDT" : ""} | Bybit`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    let balanceD = balance.filter((item) => item.coin + "USDT" == symbol)

    return (
        <div className={`futures ${darkname} font-family`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            {
                windowtypeM
                    ?
                    <div className='futures-box'>
                        <div className='futures-section11'>
                            <W_goex6 isBlinking={isBlinking} handleButtonClick={handleButtonClick} changeTab={changeTab} tab={tab} data={data} currentPrice={currentPrice} priceDirect={priceDirect} max={max} />
                            <div className='futures-section14'>
                                <ErrorBoundary>
                                    <GoexByS1 tab={tab} changeTab={changeTab} USDTSymbolS={USDTSymbolS} symbol={symbol} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div className={tab1 ? `futures-section16 ${darkname}` : `futures-section16-check ${darkname}`}>
                                        <div style={{ width: "100%", height: "880px", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                            <Chart exchange={"bybit"} market={"spot"} />
                                            <div className={`img-bg ${darkname}`}><img src={dark ? '/img/bybit_logo_dark.png' : '/img/bybit.png'} style={{ maxWidth: "240px", width: "50%" }} /></div>
                                        </div>
                                    </div>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <GoexByS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                            </div>
                            <div className={`futures-section15 ${darkname}`}>
                                <ErrorBoundary>
                                    <GoexByS8 tab5={tab5} setTab5={setTab5} symbol={symbol} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} currentPrice={currentPrice} orderReload={orderReload} openorder={openorder} balance={balance} />
                                </ErrorBoundary>
                            </div>
                        </div>
                        <ErrorBoundary>
                            <GoexByS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} instrumentsData={instrumentsData} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn} />
                        </ErrorBoundary>
                    </div>
                    :
                    <div className={`futuresM ${darkname}`}>
                        <div className='futuresM-list' onClick={() => setMtab(2)}>
                            <div className={`futuresM-list-1 ${darkname}`}>{symbol}</div>
                            <div className={`futuresM-list-2 ${data?.priceChangePercent >= 0 ? "green" : "red"}`}>
                                <div className='futuresM-list-2-1'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                                <div className='futuresM-list-2-2'>{Number(data?.priceChangePercent ?? 0).toFixed(2)}%</div>
                            </div>
                        </div>
                        <div className={`futuresM-top ${darkname}`}>
                            <div className='futuresM-top-box'>
                                <div className={Mtab == 0 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(0)}>차트</div>
                                <div className={Mtab == 1 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(1)}>호가</div>
                                <div className={Mtab == 2 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(2)}>리스트</div>
                                <div className={Mtab == 3 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(3)}>보유</div>
                                <div className={Mtab == 4 ? `futuresM-top-1 active ${darkname}` : `futuresM-top-1 ${darkname}`} onClick={() => setMtab(4)}>내역</div>
                            </div>
                        </div>

                        <div className='futuresM-mid-1' style={{ display: Mtab == 0 ? "" : "none" }}>
                            <div style={{ width: "100%", height: "calc(100% - 60px)", border: `1px solid none`, boxSizing: "border-box", position: "relative" }}>
                                <Chart exchange={"bybit"} market={"spot"} />
                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/bybit_logo_dark.png' : '/img/bybit.png'} style={{ maxWidth: "200px", width: "50%" }} /></div>
                            </div>
                            <div className='futuresM-mid-1-btn'>
                                <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-1`}>{t('futures_17')}</div>
                                </div>
                                <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => setMtab(1)}>
                                    <div className={`futures-section4-top-5-3-1-2`}>{t('futures_18')}</div>
                                </div>
                            </div>
                        </div>

                        <div className='futuresM-mid-2' style={{ display: Mtab == 1 ? "" : "none" }}>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <GoexByS7 tab1={tab1} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} windowtypeM={windowtypeM} Mordertab={Mordertab} btnbtn={btnbtn} />
                                </ErrorBoundary>
                                {
                                    Mordertab
                                        ?
                                        <ErrorBoundary>
                                            <GoexByS4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} instrumentsData={instrumentsData} max={max} currentPrice={currentPrice} balance={balance} currentUSDT={currentUSDT} btnbtn={btnbtn} setBtnbtn={setBtnbtn} />
                                        </ErrorBoundary>
                                        :
                                        ""
                                }
                            </div>
                            <div className='futures-M-mid-2-btn' onClick={() => setMordertab(e => !e)}>
                                {
                                    Mordertab
                                        ?
                                        <i class="fa-solid fa-circle-check" style={{ color: "#4285F4", marginRight: "7px" }}></i>
                                        :
                                        <i class="fa-regular fa-circle-check" style={{ color: "gray", marginRight: "7px" }}></i>
                                }
                                <span className="text">주문창</span>
                            </div>
                        </div>
                        {
                            Mtab === 2
                                ?
                                <GoexByS1_1 USDTSymbolS={USDTSymbolS} symbol={symbol} balance={balance} />
                                :
                                ""
                        }

                        <div style={{ display: Mtab == 3 ? "" : "none", width: "100%" }}>
                            <GoexByS8_1 symbol={symbol} balance={balanceD[0] ?? []} openorder={openorder} />
                        </div>

                        <div style={{ display: Mtab == 4 ? "" : "none", width: "100%" }}>
                            <GoexByS8_2 symbol={symbol} orderReload={orderReload} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default GOEXBySpot;