import '../../css/futures.css'
import { List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { change_Binance_F_Favorite, change_Bybit_F_Favorite, change_OKX_F_Favorite, check_Error } from '../../../store';
import axios from 'axios';
import { changeNum } from "../function"
import IMG from '../../img';
import { useTranslation } from "react-i18next";


function BinanceFutures({ filterSymbol, darkname, symbol, dimensions, positionData, FuturesPositionClose, exchange }) {
    const { t } = useTranslation();
    const [listSort, setListSort] = useState(0)

    if (listSort === 1) {
        filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    }
    else if (listSort === 2) {
        filterSymbol.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));
    }
    else if (listSort === 3) {
        filterSymbol.sort((a, b) => {
            if (Number(a.priceChangePercent) < Number(b.priceChangePercent)) return 1;
            if (Number(a.priceChangePercent) > Number(b.priceChangePercent)) return -1;
        });
    }
    else if (listSort === 4) {
        filterSymbol.sort((a, b) => {
            if (Number(a.priceChangePercent) > Number(b.priceChangePercent)) return 1;
            if (Number(a.priceChangePercent) < Number(b.priceChangePercent)) return -1;
        });
    }

    filterSymbol.sort((a, b) => b.favorite - a.favorite);

    return (
        <div className={`futures-section1-box-3 ${darkname}`}>
            <div className='futures-3-table'>
                <div className='futures-3-head'>
                    <div className={`futures-3-th ${listSort === 0 ? 'active' : ''}`} onClick={() => { { setListSort(0); } }} style={{ cursor: "pointer" }} >
                        <span>포지션</span>
                    </div>
                    <div className={`futures-3-th ${listSort === 1 ? 'active' : ''}`} onClick={() => { { setListSort(1); } }} style={{ cursor: "pointer" }}>
                        <span>거래량</span>
                    </div>
                    <div className={`futures-3-th ${listSort === 2 ? 'active' : ''}`} onClick={() => { { setListSort(2); } }} style={{ cursor: "pointer" }}>
                        <span>가격</span>
                    </div>
                    <div className={`futures-3-th ${listSort === 3 ? 'active' : ''}`} onClick={() => { { setListSort(3); } }} style={{ cursor: "pointer" }}>
                        <span>최고</span>
                    </div>
                    <div className={`futures-3-th ${listSort === 4 ? 'active' : ''}`} onClick={() => { { setListSort(4); } }} style={{ cursor: "pointer" }}>
                        <span>최저</span>
                    </div>
                </div>
                <div className='futures-3-body' style={{ height: dimensions.height }}>
                    {
                        listSort === 0
                            ?
                            <>
                                {
                                    positionData && positionData.length === 0
                                        ?
                                        <div className='history-right-bottom-4-bottom-1'>
                                            <img src='/img/img_nofound.png'></img>
                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                            <p>{t('message_0')}</p>
                                        </div>
                                        :
                                        positionData.map(function (a, i) {
                                            return (
                                                <PositionItem data={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} />
                                            )
                                        })
                                }
                            </>
                            :
                            <MyListComponent data={filterSymbol} darkname={darkname} symbol={symbol} dimensions={dimensions} exchange={exchange} />
                    }
                </div>
            </div>
        </div>
    )
}

function MyListComponent({ data, darkname, symbol, dimensions, exchange }) {
    const rowRenderer = ({ index, key, style }) => {
        const post = data[index]
        return (
            <BinanceFuturesItem item={post} index={index} darkname={darkname} symbol={symbol} key={key} style={style} exchange={exchange} />
        );
    };

    return (
        <List
            width={dimensions.width}
            height={dimensions.height}
            rowCount={data.length}
            rowHeight={60}
            rowRenderer={rowRenderer}
            list={data} // 배열
            style={{ outline: 'none', paddingRight: '5px' }}
        />
    );
}

function BinanceFuturesItem({ item, index, darkname, symbol, key, style, exchange }) {
    let dispatch = useDispatch();

    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                exchange: exchange,
                type: 'futures',
                coin: item.ccy
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (exchange == "binance") {
                    dispatch(change_Binance_F_Favorite(item.ccy))
                } else if (exchange == 'okx') {
                    dispatch(change_OKX_F_Favorite(item.ccy))
                } else if (exchange == 'bybit') {
                    dispatch(change_Bybit_F_Favorite(item.ccy))
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 64, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-3-tr ${darkname} ${symbol === item.symbol ? `on` : ``}`} key={key} style={style}>
            <Link to={`/futures/${exchange}/${item.symbol}`} className={`futures-section18-1-list ${darkname}`}>
                <div className='futures-section18-1-list-1'>
                    <div className='futures-section18-1-list-1-1'>{index + 1}</div>
                    <div className='futures-section18-1-list-1-2'><img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} style={{ width: "30px" }} /></div>
                    <div className='futures-section18-1-list-1-3'>
                        <div className='futures-section18-1-list-1-3-1'>{item.ccy}USDT</div>
                        <div className={`futures-section18-1-list-1-3-2 ${darkname}`}>
                            <div>{changeNum(Number(item.lastPrice))}</div>
                            <div>
                                {
                                    item.priceChangePercent >= 0
                                        ?
                                        <div className="green"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                        :
                                        <div className="red"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <div className='futures-section18-1-list-2' onClick={() => Favorite()}>
                {
                    item.favorite
                        ?
                        <FontAwesomeIcon icon={faHeart} className='heart-color-on' />
                        :
                        <FontAwesomeIcon icon={faHeart} className='heart-color-off' />
                }
            </div>
        </div>
    )
}

function PositionItem({ data, darkname, FuturesPositionClose }) {
    let imgcheck = true
    if (IMG && data.ccy && !IMG.includes(data.ccy)) {
        imgcheck = false
    }

    return (
        <div className={`futures-3-tr ${darkname}`}>
            <Link to={`/futures/${data.ex}/${data.symbol}`} className={`futures-section18-1-list ${darkname}`}>
                <div className='futures-section18-1-list-1'>
                    <div className='futures-section18-1-list-1-2'><img src={imgcheck ? `/img/coin/${data.ccy}.png` : `/img/coin/origin.png`} style={{ width: "30px" }} /></div>
                    <div className='futures-section18-1-list-1-3'>
                        <div className='futures-section18-1-list-1-3-1'>{data.ccy}USDT</div>
                        <div className={`futures-section18-1-list-1-3-2 ${darkname}`}>
                            <div>{data.entryPrice}</div>
                            <div className={`${Number(data.pnl) >= 0 ? "green" : "red"}`}>{data.pnl}</div>
                        </div>
                    </div>
                </div>
            </Link>

            <div className='futures-section18-1-list-2'>
                <div className={`position-table-bg-1-1 ${darkname}`} onClick={() => FuturesPositionClose(data)}>Close</div>
            </div>
        </div>
    )
}

export { BinanceFutures }