import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { BinanceFutures } from '../../common/C_futures/W_goex1';

function GoexByF1({ tab, changeTab, USDTSymbolF, symbol }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    const [search, setSearch] = useState("");

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    let filterSymbol = USDTSymbolF.filter((p) => {
        return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    })

    let Hot_coin = [...USDTSymbolF];

    Hot_coin.sort((a, b) => {
        if (Number(a.priceChangePercent) < Number(b.priceChangePercent)) return 1;
        if (Number(a.priceChangePercent) > Number(b.priceChangePercent)) return -1;
    });

    return (
        <div className={tab ? `futures-section18 ${darkname}` : `futures-section1-check ${darkname}`}>
            <FontAwesomeIcon icon={faXmark} className={`futures-section1-ico ${darkname}`} onClick={() => changeTab(false)} />
            <div className='futures-section1-box'>
                <div className={`futures-section1-box-position ${darkname}`}>
                    <div className={`futures-section1-box-1 ${darkname}`}>
                        <div className='futures-section1-box-1-1'>
                            <img src={dark ? '/img/bybit_dark.png' : '/img/bybit_logo.png'} className='futures-section1-box-1-img' style={{ width: "25px" }}></img>
                            <div className='futures-section1-box-1-3'>{t('futures_3-1')}</div>
                        </div>
                    </div>
                    <div className={`futures-section1-box-2 ${darkname}`}>
                        <input type='text' placeholder={`${t('market_0')}`} value={search} onChange={onChange} />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='futures-section1-box-2-ico' />
                    </div>
                </div>
                <BinanceFutures filterSymbol={filterSymbol} USDTSymbolF={USDTSymbolF} Hot_coin={Hot_coin[0]} darkname={darkname} symbol={symbol} changeTab={changeTab} exchange={"bybit"} />
            </div>
        </div>
    )
}


export default GoexByF1;