import "./css/spot.css";
import React, { useEffect, useRef, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "../store";
import base64 from 'base-64';
import { TVChartContainer } from '../chart/components/TVChartContainer/index';

let cnt = 0;
let cnt_chart = 0;
let langList = ["ar", "zh", "cs", "da_DK", "nl_NL", "en", "et_EE", "fr", "de", "el", "he_IL", "hu_HU", "id_ID", "it", "ja", "ko", "fa", "pl", "pt", "ro", "ru", "sk_SK", "es", "sv", "th", "tr", "vi", "no", "ms_MY", "zh_TW"];

function Chart({ exchange, market }) {
  const objString = localStorage.getItem("token");
  let dec = "";
  if (objString) {
    const obj = JSON.parse(objString);
    let token = obj.value;
    let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
    dec = JSON.parse(base64.decode(payload));
  }

  let dark = useSelector((state) => { return state.Darkmode.value; });
  let Languages = useSelector((state) => { return state.Languages.value; });

  const [chart_check, setChart_check] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);  // 로드 완료 시 상태 업데이트
  }

  let { symbol } = useParams();
  let arr = [];
  if (exchange === "okx") {
    arr = symbol.split("-");
  } else {
    arr = symbol.split("USDT");
  }
  let lang = Languages.split("-");

  useEffect(() => {
    cnt_chart = 0;
  }, [exchange, dark, Languages]);

  if (cnt_chart === 1) {
    setChart_check(true);
  }
  cnt_chart += 1;
  
  let defaultWidgetProps = {
    symbol: arr[0],
    exid: exchange,
    mode: dark ? "dark" : "light",
    lang: lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en',
    type: market === "spot" ? "1" : "2",
    id: dec.userId,
    interval: '1D',
    library_path: '/charting_library/',
    locale: 'en',
    host: "chart.alp.kr",
    charts_storage_url: "https://save-chart.rf-rev.com",
    charts_storage_api_version: "1.1",
    // timezone: "Asia/Seoul",
    // save_load_adapter: save_load_adapter,
    // client_id: 'tradingview.com',
    // user_id: 'public_user_id',
    fullscreen: false,
    autosize: true,
  };

  return (
    <>
      {
        chart_check
          ?
          <TVChartContainer {...defaultWidgetProps} />
          // <iframe src={`https://chart.alp.kr?symbol=${arr[0]}&exid=${exchange}&mode=${dark ? "dark" : "light"}&lang=${lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en'}&type=${market === "spot" ? 1 : 2}&id=${dec.userId}`}
          //   style={{ width: "100%", height: "100%", border: "none", backgroundColor: dark ? "#1f2029" : "#fff", visibility: loaded ? 'visible' : 'hidden' }} onLoad={handleLoad} />
          // <iframe src={`https://chart.rf-rev.com?symbol=${arr[0]}&exid=${exchange}&mode=${dark ? "dark" : "light"}&lang=${lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en'}&type=${market === "spot" ? 1 : 2}&id=${dec.userId}`}
          //   style={{ width: "100%", height: "100%", border: "none" }} />
          :
          ""
      }
    </>
  );
}

export default Chart;