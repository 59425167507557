import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretDown, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { changeTime, padZero, UTCTimestamp } from "../../common/function"
import { ClipLoader } from "react-spinners";

import { Multi, Alarm, OpenOrder, OrderHistory, TradeHistory, Position } from '../../common/C_futures/W_goex8.js';

function GoexOKXF8({ tab5, setTab5, symbol, USDTSymbolF, setPositionAmount, positionData, setTradeVolume, tradeVolume, currentPrice, orderReload, openorder, FuturesOpenOrder }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });

    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();

    const [positionbtn, setPositionbtn] = useState(true)
    const [message, setMessage] = useState([])
    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [openorderList, setOpenorderList] = useState([])
    const [positionList, setPositionList] = useState([])

    useEffect(() => {
        let arr = []
        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let sym = positionData[i].instId.split('-')
                let ctVal = OKX_Instruments.filter((p) => p.instId === positionData[i].instId)

                let myData1 = USDTSymbolF.filter((item) => { return item.instId === positionData[i].instId })
                let myData = myData1[0] ?? []

                arr.push({
                    entryPrice: myData?.point ? Number(positionData[i].entryPrice).toFixed(myData.point) : Number(positionData[i].entryPrice),
                    value: positionData[i].notionalUsd,
                    ccy: sym[0],
                    symbol: positionData[i].instId,
                    side: Number(positionData[i].pos) >= 0 ? 'Buy' : 'Sell',
                    sz: Number(positionData[i].pos) * Number(ctVal[0].ctVal),
                    mark: myData?.point ? Number(positionData[i].markPx).toFixed(myData.point) : Number(positionData[i].markPx),
                    mode: positionData[i].mgnMode == "cross" ? "Cross" : "Isolated",
                    liq: myData?.point ? Number(positionData[i].liqPx).toFixed(myData.point) : Number(positionData[i].liqPx),
                    pnl: positionData[i].upl,
                    leverage: positionData[i].lever,
                })
            }
        }
        setPositionList(arr)
    }, [positionData])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let ctVal = OKX_Instruments.filter((p) => p.instId === openorder[i].instId)
                let sym = openorder[i].instId.split('-')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].instId,
                    orderId: openorder[i].orderId,
                    type: openorder[i].ordType,
                    side: openorder[i].side,
                    px: openorder[i].px,
                    sz: Number(openorder[i].fillSz) * Number(ctVal[0].ctVal)
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = () => {
        let list = []
        let algo = []
        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i]
                if (s.ordType == "conditional" || s.ordType == "trigger") {
                    if (s.instId == symbol) algo.push({ algoId: s.algoId, instId: s.instId })
                }
                else {
                    if (s.instId == symbol) list.push({ ordId: s.ordId, instId: s.instId })
                }
            }
        }

        if (list && list.length > 0) {
            FuturesAllOpenOrderCancel(list)
        }
        if (algo && algo.length > 0) {
            FuturesAllOpenOrderCancel_Algo(algo)
        }
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel", {
                ordArr: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 130, "msg": error }))
            })
        }
    }

    const FuturesAllOpenOrderCancel_Algo = async (algo) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel/algo", {
                ordArr: algo
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response?.data?.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    FuturesOpenOrder()
                }
                else {
                    setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 131, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = (item) => {
        if (item.ordType == "conditional" || item.ordType == "trigger") FuturesOpenOrderAlgoCancel(item)
        else FuturesOpenOrderSWAPCancel(item)
    }

    const FuturesOpenOrderSWAPCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/cancel", {
                ordId: item.ordId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 134, "msg": error }))
                })
        }
    }

    const FuturesOpenOrderAlgoCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/tpsl/order/cancel", {
                algoId: item.algoId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 135, "msg": error }))
                })
        }
    }

    const positionClose = (p, z) => {
        if (z === 0) {
            FuturesPositionHalfClose(p)
        }
        else {
            FuturesPositionClose(p)
        }
    }

    const FuturesPositionClose = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/close/position", {
                instId: p.instId,
                mgnMode: p.mgnMode,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response?.data?.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response?.data?.code), msg: response?.data?.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 132, "msg": error }))
                })
        }
    }

    const createPostData = (p) => {
        const timestamp1 = '' + Date.now();

        return {
            instId: p.symbol,
            tdMode: p.mode,
            lever: p.leverage,
            clOrdId: timestamp1,
            side: Number(p.sz) >= 0 ? "sell" : "buy",
            ordType: "market",
            px: "",
            sz: Math.abs(p.sz) / 2,
            // sz: Math.floor(Math.abs(p.pos) / 2) > 0 ? Math.floor(Math.abs(p.pos) / 2) : Math.abs(p.pos),
            reduceOnly: true,
            tag: "ff5b6baef008SCDE",
            tgtCcy: Number(p.sz) >= 0 ? "base_ccy" : "quote_ccy",
        };
    };

    const onSubmitFutures = async (postData, url, p) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (Number(response?.data?.code) === 0) {
                    setMessage({ type: "okx", code: Number(response?.data?.code), msg: `${t('message_14')}` })
                }
                else {
                    if (Number(response.data.data[0].sCode == "51121")) {
                        FuturesPositionClose(p)
                    }
                    else {
                        setMessage({ type: "okx", code: Number(response?.data?.code), msg: response.data.data[0].sMsg })
                    }
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 133, "msg": error }))
            })
        }
    }

    const FuturesPositionHalfClose = (p) => {
        let url = process.env.REACT_APP_API + '/api/v1/trade/okx/swap/order'
        let postData = createPostData(p);
        onSubmitFutures(postData, url, p);
    };

    return (
        <div className={`futures-section15 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('futures_79')}</div>
                    <div className={tab5 === 1 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(3)}>{t('futures_45')}</div>
                    <div className={tab5 === 4 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(4)}>{t('futures_46')}</div>
                    <div className={tab5 === 5 ? `futures-section8-top-1-1 display-none-1000 active ${darkname}` : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(5)}>{t('futures_82')}</div>
                    {/* <div className={tab5 === 4 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(4)} style={{ color: "#F5AC1D" }}>{t('futures_83')}</div> */}
                    <div className='futures-section8-top-1-2 display-none-1000'>
                        <input type='checkbox' disabled={true} />
                    </div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className={`futures-section8-top-1-3 ${darkname}`} onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className={`futures-section8-top-1-3 ${darkname}`}><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <FontAwesomeIcon icon={positionbtn ? faSquareCaretDown : faSquareCaretUp} className='display-none-700-block' style={{ color: "gray", fontSize: "18px", paddingRight: "5px" }} onClick={() => setPositionbtn(btn => !btn)} />
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Position setPositionAmount={setPositionAmount} positionList={positionList} symbol={symbol} currentPrice={currentPrice} FuturesPositionClose={positionClose} exchange={"okx"} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder openorder={openorderList} symbol={symbol} all={false} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                            :
                            tab5 === 2
                                ?
                                <OpenOrder openorder={openorderList} symbol={symbol} all={true} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                :
                                tab5 === 3
                                    ?
                                    <FuturesOrderHistory orderReload={orderReload} />
                                    :
                                    tab5 === 4
                                        ?
                                        <FuturesTradeHistory orderReload={orderReload} />
                                        :
                                        <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                    // :
                    // <Multi />
                }
            </div>
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ orderReload }) {
    let dispatch = useDispatch();
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/order-history", {
                params: {
                    reqId: o_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response.data.reqId
                let tmp = response.data.data
                if (tmp && o_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 124, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let ctVal = OKX_Instruments.filter((p) => p.instId === orderlist[i].instId)

                let arr = orderlist[i].instId.split('-')

                const date = UTCTimestamp(orderlist[i].uTime);
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                summary.push({
                    symbol: orderlist[i].instId,
                    ccy: arr[0],
                    volume: Number(orderlist[i].px) * Number(orderlist[i].fillSz) * Number(ctVal[0].ctVal),
                    type: orderlist[i].ordType,
                    time: formattedDate,
                    side: orderlist[i].side,
                    price: orderlist[i].px,
                    size: Number(orderlist[i].fillSz) * Number(ctVal[0].ctVal),
                    status: orderlist[i].state
                })
            }
        }
        setOrderData(summary)
    }, [orderlist])

    return (
        <OrderHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderdata} spinner={spinner} />
    )
}

let t_id = 0
function FuturesTradeHistory({ orderReload }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderlistSum, setOrderlistSum] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)
    const [personal_fee, setPersonal_fee] = useState(0.5)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/trade-history`, {
                params: {
                    reqId: t_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response.data.reqId
                let tmp = response.data.data
                if (tmp && t_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 125, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let ctVal = OKX_Instruments.filter((p) => p.instId === orderlist[i].instId)
                let arr = orderlist[i].instId.split('-')
                summary.push({
                    symbol: orderlist[i].instId,
                    ccy: arr[0],
                    volume: Number(orderlist[i].fillPx) * Number(orderlist[i].fillSz) * ctVal[0].ctVal,
                    pnl: Number(orderlist[i].fillPnl),
                    fee: Number(orderlist[i].fee),
                    time: orderlist[i].time,
                    reward: Number(orderlist[i].reward),
                    side: orderlist[i].side,
                    price: orderlist[i].fillPx,
                    size: orderlist[i].fillSz,
                })
            }
        }
        setOrderlistSum(summary)
    }, [orderlist])

    return (
        <TradeHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderlistSum} spinner={spinner} />
    )
}

export default GoexOKXF8;